import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useDirectoryCategories } from "./useDirectoryCategories";
import { DataDirectoryCategories } from "../../../redux/@types";
import {
  deleteDirectoryCategories,
  directoryCategoriesChangeModalState,
  refreshTableDirectoryCategories,
  setDirectoryCategoriesForUpdate,
  setIsDirectoryCategoriesForUpdate,
} from "../../../redux";

const { confirm } = Modal;

export const useTableDirectoryCategories = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useDirectoryCategories();

  const changeDirectoryCategoriesForUpdate = (val: DataDirectoryCategories) => {
    dispatch(setIsDirectoryCategoriesForUpdate(true));
    dispatch(setDirectoryCategoriesForUpdate(val));
    dispatch(directoryCategoriesChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la categoría con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar categoría" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar categoría",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteDirectoryCategories(valId)
          .then(() => {
            dispatch(refreshTableDirectoryCategories(true));
            message.success(
              `Se ha borrado con éxito la Categoría con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeDirectoryCategoriesForUpdate,
    showDeleteConfirm,
  };
};
