import { DataIRankings, IProductCategoriesState, IUserResFind } from "../@types"
import { IOrder } from "../@types/orders"

export const RANKINGS_CHANGE_MODAL_STATE = '[ RANKINGS ] Rankings Change Modal State'

export interface IRankingsChangeModalState {
    type: typeof RANKINGS_CHANGE_MODAL_STATE
}

export const GET_RANKING_FOR_UPDATE = '[ RANKINGS ] Get Ranking For Update'

export interface IGetRankingsForUpdate {
    type: typeof GET_RANKING_FOR_UPDATE
    payload: DataIRankings
}

export const IS_RANKING_FOR_UPDATE = '[ RANKINGS ] Is Ranking For Update'

export interface IIsRankingsForUpdate {
    type: typeof IS_RANKING_FOR_UPDATE
    payload: boolean
}

export const SWITCH_RANKINGS_REFRESH_TABLE = '[ RANKINGS ] Switch Rankings Refresh Table'

export interface ISwitchRankingsRefreshTable {
    type: typeof SWITCH_RANKINGS_REFRESH_TABLE
    payload: boolean
}

export const GET_USERS_FOR_RANKING_SELECT = '[ RANKINGS ] Get Users For Ranking Select'

export interface IGetUsersForRankingSelect {
    type: typeof GET_USERS_FOR_RANKING_SELECT
    payload: IUserResFind[]
}

export const GET_ORDERS_FOR_RANKING_SELECT = '[ RANKINGS ] Get Orders For Ranking Select'

export interface IGetOrdersForRankingSelect {
    type: typeof GET_ORDERS_FOR_RANKING_SELECT
    payload: IOrder[]
}

export const GET_PRODUCTS_FOR_RANKING_SELECT = '[ RANKINGS ] Get Products For Ranking Select'

export interface IGetProductsForRankingSelect {
    type: typeof GET_PRODUCTS_FOR_RANKING_SELECT
    payload: IProductCategoriesState[]
}