import type {EnhancedInputProps} from "../@types";
import type {FieldValues} from "react-hook-form";
import type {ReactNode} from "react";

import {Controller} from "react-hook-form";
import React from "react";
import clsx from "clsx";

function RenderLabel({children, htmlFor}: {children: ReactNode; htmlFor: string}) {
  if (typeof children === "string") {
    return (
      <label className="block text-sm font-semibold" htmlFor={htmlFor}>
        {children}
      </label>
    );
  }

  return <>{children}</>;
}

export function withControl<C extends object>(WrappedComponent: React.ComponentType<C>) {
  return function EnhancedInput<TFieldValues extends FieldValues = FieldValues>(
    props: C & EnhancedInputProps<TFieldValues>,
  ) {
    const randomId = React.useId();

    const componentId = React.useMemo(
      () => `${props.id || "input"}-${randomId}`,
      [randomId, props.id],
    );

    if (!props.control)
      return (
        <div>
          {!!props.label && <RenderLabel htmlFor={componentId}>{props.label}</RenderLabel>}
          <WrappedComponent {...props} id={componentId} />
        </div>
      );

    const {control, defaultValue, name, shouldUnregister, label, ...restProps} = props;

    return (
      <Controller<TFieldValues>
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({field, fieldState}) => {
          return (
            <div>
              {!!label && <RenderLabel htmlFor={componentId}>{label}</RenderLabel>}
              <WrappedComponent
                {...(restProps as C)}
                {...field}
                id={componentId}
                {...(fieldState.error
                  ? {
                      className: clsx(
                        (restProps as Record<string, string>).className,
                        "ring-red-500",
                      ),
                    }
                  : {})}
              />
              {!!fieldState.error && (
                <span className="mt-1 block text-xs text-red-500">{fieldState.error.message}</span>
              )}
            </div>
          );
        }}
        shouldUnregister={shouldUnregister}
      />
    );
  };
}
