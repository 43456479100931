"use client";
import type { Resolver } from "react-hook-form";

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProgramSchedulePaymentStudentType } from "../../../types/dto/scheduleStudent";
import {
  programPaymentScheduleStudentAcademy,
} from "../../../shared/services";
import { message } from "antd";


export default function useCreatePaymentScheduleStudent() {
  const [loading, setLoading] = React.useState(false);
  const [loadingUpdateOrCreate, setLoadingUpdateOrCreate] = React.useState(false);

  const { control, handleSubmit: onSubmit } = useForm<any>({
    resolver: yupResolver(
      yup.object({
        program_student_id: yup.number().required("El ID del estudiante del programa es requerido"),
        payment_schedule_id: yup.number().required("El ID del cronograma de pago es requerido"),
      })
    ) as unknown as Resolver<ProgramSchedulePaymentStudentType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);

    try {
      await programPaymentScheduleStudentAcademy.create({
        program_student_id: +data.program_student_id,
        payment_schedule_id: +data.payment_schedule_id,
      });
      message.success("Se ha creado con éxito el cronograma del estudiante");
    } catch (err: any) {
      message.error("Algo salió mal: " + err.message);
    } finally {
      setLoading(false);
    }
  });

  const updateOrCreateScheduleStudent = async ({program_student_id, payment_schedule_id}: {program_student_id:number, payment_schedule_id:number}) => {
    if (loadingUpdateOrCreate) return;
    setLoadingUpdateOrCreate(true);
    try {
      
      const record = await programPaymentScheduleStudentAcademy.patch(
        payment_schedule_id,
        {program_student_id, payment_schedule_id },
        { query: { $client: { updateOrCreateScheduleStudent: 'true' } } }
      )
      console.log("record", record);
      
      if (record.status === "active") {
        message.success(
          "Se ha asignado con éxito el cronograma de pago al estudiante"
        );
      }else if (record.status === "inactive") {

        message.success(
          "Se ha removido con éxito el cronograma de pago al estudiante"
        );
      }

      return record;
    } catch (error: any) {
      message.error("Algo salió mal: " + error.message);
      return false;
    } finally {
      setLoadingUpdateOrCreate(false);
    }
  };

  return {
    control,
    loading,
    loadingUpdateOrCreate,
    handleSubmit,
    updateOrCreateScheduleStudent,
  };
}
