"use client";

import Button from "../../../../../shared/components/ui/Button";
import useCreateProgramSchedule from "../../../../academyProgramDetail/hooks/schedule/useCreateProgramsSchedule";
import ProgramScheduleForm from "./ProgramScheduleForm";

export interface CreateProgramScheduleProps {
  id: number,
  setOpenModal: (value: boolean) => void;
}

export default function CreateProgramSchedule({id, setOpenModal}: CreateProgramScheduleProps) {
  const {control, loading, handleSubmit} = useCreateProgramSchedule({
    id,
    setOpenModal,
  });

  return (
    <form onSubmit={handleSubmit}>
      <ProgramScheduleForm control={control} />
      <div className="mt-4 flex items-center justify-end">
        <Button primary className="bg-[#072082]" loading={loading} type="submit" variant="filled">
           Crear
        </Button>
      </div>
    </form>
  );
}
