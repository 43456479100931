import { TRootState } from '../@types/redux';


export const selectModalProductCategories= ( state: TRootState) =>state.productCategories.productCategoriesModalIsOpen

export const selectIsUpdateProductCategories= ( state: TRootState) =>state.productCategories.isUpdateProductCategories

export const selectProductCategoryParent= ( state: TRootState) =>state.productCategories.productCategoryParent

export const selectProductCategoriesForUpdate= ( state: TRootState) =>state.productCategories.productCategoriesForUpdate

export const selectProductCategoriesRefreshTable= ( state: TRootState) =>state.productCategories.refreshTableProductCategories

export const selectArrayParentIdCategories = (state: TRootState ) => state.productCategories.parentArrayId