import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useDiscountCode } from "./useDiscountCode";
import { DataDiscountCode } from "../../../redux/@types/discountCode";
import {
  deleteDiscountCode,
  discountCodeChangeModalState,
  refreshTableDiscountCode,
  setDiscountCodeForUpdate,
  setIsDiscountCodeForUpdate,
} from "../../../redux/actions/discountCode.actions";

const { confirm } = Modal;

export const useTableDiscountCode = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useDiscountCode();

  const changeDiscountCodeForUpdate = (val: DataDiscountCode) => {
    dispatch(setIsDiscountCodeForUpdate(true));
    dispatch(setDiscountCodeForUpdate(val));
    dispatch(discountCodeChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el código de descuento con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar código de descuento" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar código de descuento",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        await deleteDiscountCode(valId)
          .then(() => {
            dispatch(refreshTableDiscountCode(true));
            message.success(
              `Se ha borrado con éxito el código de descuento con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };
  return {
    //state
    //methods
    //functions
    changeDiscountCodeForUpdate,
    showDeleteConfirm,
  };
};
