import { DataWalletMovements, IUserResFind } from "../@types"

export const WALLET_MOVEMENTS_MODAL_STATE = '[ WALLET MOVEMENTS ] Wallet Movements Modal State'

export interface IWalletMovementsModalState {
    type: typeof WALLET_MOVEMENTS_MODAL_STATE
}

export const GET_WALLET_MOVEMENTS_FOR_UPDATE = '[ WALLET MOVEMENTS ] Get Wallet Movements For Update'

export interface IGetWalletMovementsForUpdate {
    type: typeof GET_WALLET_MOVEMENTS_FOR_UPDATE
    payload: DataWalletMovements
}

export const IS_WALLET_MOVEMENTS_FOR_UPDATE ='[ WALLET MOVEMENTS ] Is Wallet Movements For Update'

export interface IIsWalletMovementsForUpdate {
    type: typeof IS_WALLET_MOVEMENTS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_WALLET_MOVEMENTS_REFRESH_TABLE = '[ WALLET MOVEMENTS ] Switch Wallet Movements Refresh Table'

export interface ISwitchWalletMovementsRefreshTable{
    type: typeof SWITCH_WALLET_MOVEMENTS_REFRESH_TABLE
    payload: boolean
}

export const GET_USERS_WALLET_MOVEMENTS = '[ WALLET MOVEMENTS ] Get Users Wallet Movements'

export interface IGetUsersWalletMovements {
    type: typeof GET_USERS_WALLET_MOVEMENTS
    payload: IUserResFind[]
}

export const GET_USERS_OBJ_WALLET_MOVEMENTS = '[ WALLET MOVEMENTS ] Get Users Obj Wallet Movements'

export interface IGetUsersObjWalletMovements {
    type: typeof GET_USERS_OBJ_WALLET_MOVEMENTS
    payload: { [key: string]: string; }
}

