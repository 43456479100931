import { Button, Col, Typography } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { programsTeacherAcademy } from "../../../../shared/services";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import Table from "../../../../shared/components/ui/Table";
import { ProgramStudentType } from "../../../../types/dto/program";
import { ModalUpdateTeacher } from "../../../../shared/components/ui/modals/ModalUpdateTeacher";
import React from "react";
import useDeleteTeacher from "../../hooks/teacher/useDeleteTeacher";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

interface TableProgramProps {
  program_id: number;
}

export const TableProgramTeacher = ({ program_id }: TableProgramProps) => {
  const [openModalUpdateTeacher, setOpenModalUpdateTeacher] =
  React.useState<boolean>(false);
  const navigate = useNavigate();

const {showDeleteConfirm} = useDeleteTeacher()

const hanldeRedirectPersonalInfo = (user_id: number) => {
  navigate(`/gestion-academy/personal-info/teacher/${user_id}/${program_id}`);
};
  const columns: TableColumns<ProgramStudentType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 1,
      filterType: "filterByID",
    },
    {
      title: "Usuario",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {`${item?.user?.first_name} ${item?.user?.last_name}`}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Email",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {item?.user?.email}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Telefono",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {item?.user?.phone}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Estadeo",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title
              style={{
                fontSize: "0.74rem",
                fontWeight: "500",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                item.user.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
              level={5}
            >
              {item?.status}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Acciones",
      key: "operation",
      width: 1,
      fixed: "right",
      render: (_, item: ProgramStudentType) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              gap: 15
            }}
          >
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              size="large"
              onClick={()=> hanldeRedirectPersonalInfo(item.user_id)}
              />
            <Button
              type="primary"
              danger
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
              onClick={()=> showDeleteConfirm(item.id, item.user.first_name)}
              />
          </Col>
        );
      },
    },
  ];

  return (
    <>
      <Table<ProgramStudentType>
        columns={columns}
        scroll={{}}
        fetchQueryProps={{
          program_id,
          $sort: { id: -1 },
        }}
        service={programsTeacherAcademy}
      />
    </>
  );
};
