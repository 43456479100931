import { Col, Collapse, Typography } from "antd";
import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";

import { SimpleInputSelect } from "../../../../shared/components";
import { usePrice } from "../../hooks";
import { CollapseFulfillmentCompany } from "../styled";
import { TableForPriceBySomeThing } from "./TableForPriceBySomeThing";

const { Panel } = Collapse;
const { Title } = Typography;

export const Price = () => {
  const {
    valueSelector,
    fulfillmentCities,
    singleFulfillmentCompany,
    changeSelectValue,
  } = usePrice();
  return (
    <>
      <Col
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          flexDirection: "column",
        }}
      >
        <Title
          style={{ marginBottom: "12.5px", color: "var(--primary)" }}
          level={3}
        >
          {singleFulfillmentCompany?.name}
        </Title>
        <SimpleInputSelect
          valueSelect={valueSelector}
          onChange={changeSelectValue}
          label={"Seleccione un tipo"}
          dataOptions={[
            { title: "Peso", value: "weight" },
            { title: "Unidad", value: "units" },
            { title: "Precio", value: "price" },
            { title: "Volumen", value: "volume" },
          ]}
          maxWidth="200px"
        />
      </Col>
      <Col
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          flexDirection: "column",
        }}
      >
        {fulfillmentCities.map((item, idx) => (
          <CollapseFulfillmentCompany
            expandIcon={({ isActive }) =>
              isActive ? (
                <MinusSquareOutlined
                  style={{
                    fontSize: "1rem",
                    alignSelf: "center",
                    color: "var(--primary)",
                  }}
                />
              ) : (
                <PlusSquareOutlined
                  style={{
                    display: "flex",
                    fontSize: "1rem",
                    alignSelf: "center",
                    color: "var( --primary)",
                  }}
                />
              )
            }
            key={idx}
          >
            <Panel header={item.location_city.name} key={item.id}>
              <TableForPriceBySomeThing
                key={`${idx} A`}
                type={valueSelector}
                city_id={item.location_city_id}
              />
            </Panel>
          </CollapseFulfillmentCompany>
        ))}
      </Col>
    </>
  );
};
