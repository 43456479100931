// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useState } from "react";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getCategoriesCourses,
  getProductsCourses,
  selectArrCategoriesForCourses,
  selectArrProductsForCourses,
  selectCoursesForUpdate,
  setCoursesForUpdate,
  setIsCoursesForUpdate,
} from "../../../../redux";
import {
  coursesCategoriesService,
  coursesService,
  productsService,
} from "../../../../shared/services";
import { message } from "antd";

export const useCoursesById = () => {
  const dataUpdateCourses = useSelector(selectCoursesForUpdate);
  const arrProducts = useSelector(selectArrProductsForCourses);
  const arrCategories = useSelector(selectArrCategoriesForCourses);
  const [isValidate, setIsValidate] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const getSingleCourse = async (id: number) => {
    return await coursesService
      .get(id)
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        navigate(`/courses`);
        message.error(err.message);
      });
  };
  useEffect(() => {
    if (pathname !== "/courses/new") {
      if (isNaN(pathname.split("/")[2])) return navigate(`/courses`);
      dispatch(setIsCoursesForUpdate(true));
      getSingleCourse(Number(pathname.split("/")[2])).then((res) =>
        dispatch(setCoursesForUpdate(res))
      );
      setIsValidate(true);
    } else if (pathname === "/courses/new") {
      dispatch(setIsCoursesForUpdate(false));
      setIsValidate(true);
    } else {
      navigate(`/courses`);
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getArrOfServices = async () => {
    await coursesCategoriesService.find().then(({ data }) => {
      dispatch(getCategoriesCourses(data));
    });
    await productsService
      .find({ query: { course: "true", $limit: 9999999 } })
      .then(({ data }) => {
        // console.log({ data });
        dispatch(getProductsCourses(data));
      });
  };

  useEffect(() => {
    // if (!arrCategories.length) {
      getArrOfServices();
    // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    //state
    dataUpdateCourses,
    arrProducts,
    arrCategories,
    isValidate,
    //methods
    //functions
  };
};
