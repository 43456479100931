import { useNavigate } from "react-router-dom";
import { setOrdersForUpdate } from "../../../redux";
import { IOrder } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";

export const useTableOrders = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const changeOrderForUpdate = (order: IOrder) => {
    dispatch(setOrdersForUpdate(order));
    navigate(`/orders/${order.id}`);
  };

  return {
    //state
    //methods
    //functions
    changeOrderForUpdate,
  };
};
