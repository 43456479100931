"use client";

import React from "react";
import { message, Modal } from "antd";
import { programsStudentModuleTechniqueAcademy } from "../../../../shared/services";

const { confirm } = Modal;

export default function useDeleteQualificationStudent() {
  const [loading, setLoading] = React.useState(false);

  const showDeleteConfirm = (valId: number, val: string) => {
    if (!valId ) {
      message.error("Ocuurio un error");
      return;
    }

    confirm({
      title: `Estás seguro de borrar la calificacin del estudiante con el id: ${valId}`,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar la calificacin del estudiante" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar la calificacin del estudiante",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        setLoading(true);
        try {
           await programsStudentModuleTechniqueAcademy.remove(valId).then(()=> {
            message.success(`Se ha borrado con éxito la calificacin del estudiante con el id:${valId}`);
          });
        } catch (err) {
          console.error(err);
          message.error("Error al borrar la calificacin del estudiante.");
        } finally {
          setLoading(false);
        }
      },
      onCancel() {},
    });
  };

  return { showDeleteConfirm, loading };
}
