import { DataWorkOffersCategories } from "../@types"


export const WORK_OFFERS_CATEGORIES_MODAL_STATE = '[ WORK OFFERS CATEGORIES ] Courses Categories Modal State'

export interface IWorkOffersCategoriesModalState {
    type: typeof WORK_OFFERS_CATEGORIES_MODAL_STATE
}

export const GET_WORK_OFFERS_CATEGORIES_FOR_UPDATE = '[ WORK OFFERS CATEGORIES ] Get Courses Categories For Update'

export interface IGetWorkOffersCategoriesForUpdate {
    type: typeof GET_WORK_OFFERS_CATEGORIES_FOR_UPDATE
    payload: DataWorkOffersCategories
}

export const IS_WORK_OFFERS_CATEGORIES_FOR_UPDATE ='[ WORK OFFERS CATEGORIES ] Is Courses Categories For Update'

export interface IIsWorkOffersCategoriesForUpdate {
    type: typeof IS_WORK_OFFERS_CATEGORIES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_WORK_OFFERS_CATEGORIES_REFRESH_TABLE = '[ WORK OFFERS CATEGORIES ] Switch Courses Categories Refresh Table'

export interface ISwitchWorkOffersCategoriesRefreshTable {
    type: typeof SWITCH_WORK_OFFERS_CATEGORIES_REFRESH_TABLE
    payload: boolean
}
