"use client";
import React from "react";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import clsx from "clsx";

import { useLevelContext } from "../../../../academyPrograms/context";
import useSaveSortedProgramModule from "../../../../academyPrograms/hooks/module/useSaveSortedProgramsModule";
import useProgramModule from "../../../../academyPrograms/hooks/module/useProgramsModule";
import CreateProgramModule from "./CreateProgramModule";
import {
  DeleteOutlined,
  EditOutlined,
  FullscreenOutlined,
  PlusOutlined,
} from "@ant-design/icons";
// import RemoveProgramModule from "./RemoveProgramModule";
import { ProgramModuleType } from "../../../../../types/dto/program";
import Button from "../../../../../shared/components/ui/Button";
import Modal from "../../../../../shared/components/ui/modal";
import SortableList from "../../../../../shared/components/ui/Sortable";
import { Spin } from "antd";
import UpdateProgramModule from "./UpdateProgramModule";
import useDeleteProgramModule from "../../../../academyPrograms/hooks/module/useDeleteProgramsModule";

export function ProgramsModuleItem({
  programsModule,
  selected,
  dragListeners,
  opacity,
  onClick,
  onUpdate,
}: {
  programsModule: ProgramModuleType;
  selected?: boolean;
  dragListeners?: SyntheticListenerMap;
  opacity?: number;
  onClick?: () => void;
  onUpdate?: (levelSection: ProgramModuleType) => void;
}) {
  const [showModalUpdateTopic, setShowModalUpdateTopic] = React.useState(false);
  const [showModalRemoveProgramModule, setShowModalRemoveProgramModule] =
    React.useState(false);
    const {showDeleteConfirm} = useDeleteProgramModule()
console.log(programsModule);

  return (
    <>
      <Modal
        className="w-[90%]"
        title="Editar Modulo"
        visible={showModalUpdateTopic}
        onCancel={() => {
          setShowModalUpdateTopic(false);
        }}
      >
        <UpdateProgramModule
          programModule={programsModule}
          onSuccess={(topic) => {
            setShowModalUpdateTopic(false);
            onUpdate?.(topic as ProgramModuleType);
          }}
        />
      </Modal>
      {/* <Modal
        className="w-[90%]"
        title="Eliminar Modulos"
        visible={showModalRemoveProgramModule}
        onCancel={() => {
          setShowModalRemoveProgramModule(false);
        }}
      >
        <RemoveProgramModule
          programModule={programsModule}
          onDelete={() => {
            onUpdate?.(programsModule);
            setShowModalRemoveProgramModule(false);
          }}
        />
      </Modal> */}
      <div
        aria-hidden="true"
        className={clsx(
          "mb-2 flex w-full cursor-pointer items-center gap-2  rounded-lg px-4 py-2 pr-1 shadow-md",
          "hover:ring-2 hover:ring-primary-500 hover:ring-offset-2 hover:ring-offset-white",
          "hover:transition-all hover:duration-200",
          {
            "bg-white text-black/50": !selected,
            "bg-blue-600 text-white": selected,
          }
        )}
        role="button"
        style={{ opacity }}
        onClick={onClick}
      >
        <div className="flex-1">
          <p className={clsx("text-sm font-semibold")}>{programsModule.name}</p>
        </div>
        <div className="flex items-center text-sm">
          <Button
            primary={!selected}
            size="small"
            type="button"
            variant="text"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setShowModalUpdateTopic(true);
            }}
          >
            <EditOutlined />
          </Button>
          <Button
            danger
            size="small"
            type="button"
            variant="text"
            onClick={() => {
              showDeleteConfirm(programsModule.id, programsModule.name)
            }}
          >
            <DeleteOutlined />
          </Button>
          <Button
            className="cursor-move"
            size="small"
            type="button"
            variant="text"
            {...dragListeners}
          >
            <FullscreenOutlined />
          </Button>
        </div>
      </div>
    </>
  );
}

export default function ProgramModule({ programId }: { programId?: number }) {
  const [showModalCreateModule, setShowModalCreateModule] =
    React.useState(false);

  const { programModule, loading, refresh, setProgramModule } =
    useProgramModule(programId);

  const { selectedProgramModuleId, onSelectedProgramModuleId } =
    useLevelContext();
  const saveSorted = useSaveSortedProgramModule();

  return (
    <div style={{ margin: 20 }}>
      <Modal
        className="w-[90%]"
        title="Crear modulo"
        visible={showModalCreateModule}
        onCancel={() => {
          setShowModalCreateModule(false);
        }}
      >
        <div className="flex flex-col gap-2">
          {!!programId && (
            <CreateProgramModule
              programId={programId}
              onSuccess={() => {
                refresh()
                  .then(() => {
                    // TODO
                  })
                  .catch(console.error);
                setShowModalCreateModule(false);
              }}
            />
          )}
        </div>
      </Modal>
      <div className="w-72 flex-none rounded-lg bg-white p-3 shadow-lg">
        <div className="flex items-center gap-2">
          <h3 className="font-semibold">Modulos</h3>
          <div className="flex items-center gap-1">
            <Button
              primary
              disabled={!programId}
              size="small"
              type="button"
              variant="text"
              onClick={() => {
                setShowModalCreateModule(true);
              }}
            >
              <PlusOutlined />
            </Button>
          </div>
        </div>
        <div className="mt-2">
          {loading ? (
            <div className="py-2 text-center">
              <Spin />
            </div>
          ) : null}
          {programModule.length === 0 && !loading && (
            <p className="py-2 text-center text-sm text-black/30">
              <i className="fa-solid fa-info-circle" /> No hay modulos creados
            </p>
          )}
          <SortableList
            key="id"
            customActivator
            id="id"
            items={programModule}
            renderItem={(programModule, props) => (
              <ProgramsModuleItem
                programsModule={programModule}
                opacity={props?.activeId === programModule.id ? 0.5 : 1}
                selected={programModule.id === selectedProgramModuleId}
                onClick={() => {
                  onSelectedProgramModuleId(programModule.id);
                }}
                onUpdate={() => {
                  refresh().catch(console.error);
                }}
              />
            )}
            onSort={(sortedProgramModule) => {
              const sortedPosition = sortedProgramModule.map(
                (level, position) => ({
                  ...level,
                  position: position + 1,
                })
              );

              setProgramModule(sortedPosition);
              saveSorted(
                sortedPosition.map(({ id, position }) => ({
                  id: id.toString(), // Convertir id a string
                  position,
                }))
              ).catch(console.error);
            }}
          />
        </div>
      </div>
    </div>
  );
}
