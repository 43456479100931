import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { DataConfigurationCashback } from '../../../redux/@types';
import { setIsConfigurationCashbackForUpdate, setConfigurationCashbackForUpdate, configurationCashbackChangeModalState } from '../../../redux/actions';

export const useTableConfigurationCashback = () => {
    const dispatch = useAppDispatch();

    const changeConfigurationCashbackForUpdate = (val: DataConfigurationCashback) => {
        dispatch(setIsConfigurationCashbackForUpdate(true));
        dispatch(setConfigurationCashbackForUpdate(val));
        dispatch(configurationCashbackChangeModalState());
      };

    return {
        //state
        //methods
        //functions
        changeConfigurationCashbackForUpdate,
    }
}
