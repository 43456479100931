"use client";

import React from "react";
import { message, Modal } from "antd";
import { programsTeacherAcademy } from "../../../../shared/services";
import { ProgramType } from "../../../../types/dto/program";

const { confirm } = Modal;

export interface UseDeleteLevelProps {
  onSuccess?: (programId: ProgramType) => void;
}

export default function useDeleteTeacher() {
  const [loading, setLoading] = React.useState(false);

  const showDeleteConfirm = (valId: number, val: string) => {
    if (!valId ) {
      message.error("Profesor id no válido");
      return;
    }

    confirm({
      title: `Estás seguro de borrar al profesor del programa con el id: ${valId}`,
      // icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar al profesor del programa" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar al profesor del programa",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        setLoading(true);
        try {
          await programsTeacherAcademy.remove(valId);
            message.success(`Se ha borrado con éxito al profesor del programa con id:${valId}`);
        } catch (err) {
          console.error(err);
          message.error("Error al borrar al profesor del programa.");
        } finally {
          setLoading(false);
        }
      },
      onCancel() {},
    });
  };

  return { showDeleteConfirm, loading };
}
