import { IParentId, IProductCategoriesState } from "../@types";
import { productCategoriesActions } from "../action-types";

type productCategoriesActionType =
| { type: typeof productCategoriesActions.PRODUCT_CATEGORIES_CHANGE_MODAL_STATE }
| { type: typeof productCategoriesActions.GET_PRODUCT_CATEGORIES_FOR_PARENT; payload: any }
| { type: typeof productCategoriesActions.GET_PRODUCT_CATEGORIES_FOR_UPDATE; payload: any }
| { type: typeof productCategoriesActions.IS_PRODUCT_CATEGORIES_FOR_UPDATE; payload: boolean }
| { type: typeof productCategoriesActions.SWITCH_PRODUCT_CATEGORIES_REFRESH_TABLE; payload: boolean }
| { type: typeof productCategoriesActions.GET_PARENTS_ID_PRODUCT_CATEGORIES; payload: IParentId[] }

const INITIAL_STATE: IProductCategoriesState = {
    productCategoriesForUpdate: undefined,
    productCategoryParent: undefined,
    isUpdateProductCategories: false,
    productCategoriesModalIsOpen: false,
    refreshTableProductCategories: false,
    parentArrayId: [],
}

export default function productCategoriesReducer(state: IProductCategoriesState = INITIAL_STATE, action: productCategoriesActionType ): IProductCategoriesState {
    switch ( action.type ) {
        case productCategoriesActions.PRODUCT_CATEGORIES_CHANGE_MODAL_STATE:
            return{
                ...state,
                productCategoriesModalIsOpen: !state.productCategoriesModalIsOpen
            }
        case productCategoriesActions.GET_PRODUCT_CATEGORIES_FOR_PARENT:
            return{
                ...state,
                productCategoryParent: action.payload
            }
        case productCategoriesActions.GET_PRODUCT_CATEGORIES_FOR_UPDATE:
            return{
                ...state,
                productCategoriesForUpdate: action.payload
            }
        case productCategoriesActions.IS_PRODUCT_CATEGORIES_FOR_UPDATE:
            return{
                ...state,
                isUpdateProductCategories: action.payload
            }
        case productCategoriesActions.SWITCH_PRODUCT_CATEGORIES_REFRESH_TABLE:
            return{
                ...state,
                refreshTableProductCategories: action.payload
            }
        case productCategoriesActions.GET_PARENTS_ID_PRODUCT_CATEGORIES:
            return {
                ...state,
                parentArrayId: action.payload
            }
        default:
            return state
    }
}