import * as yup from 'yup'

export const validateUpdatePricesList = yup.object().shape({
    name:yup
    .string()
    .min(2, 'El NOMBRE debe tener más de 2 caracteres'),
    description:yup
    .string()
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    start_at:yup
    .string(),
    ends_at:yup
    .string(),
    priority:yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    prices: yup.array()
    .of(
      yup.object().shape({
        product_id: yup.number().typeError('Campo requerido.'),
        price: yup.number().typeError('Campo requerido.'),
      })
    ),
    customer_groups: yup.array()
    .of(
      yup.object().shape({
        customer_group_id: yup.number().typeError('Campo requerido.')
      })
    ),
})