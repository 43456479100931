import React from "react";
import { programPaymenAcademy } from "../../../shared/services";
import { Resolver, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProgramPaymentType } from "../../../types/dto/program";
import useProgramStudent from "../../academyProgramDetail/hooks/students/useProgramStudent";
import { message } from "antd";

export interface UseProgramStudentProps {
  userId: number;
  setOpenModal: (val: boolean) => void
}

export default function useCreatePayStudentProgramFromStudentView({
  userId,
  setOpenModal
}: UseProgramStudentProps) {

   const {
    control,
    handleSubmit: onSubmit,
    reset,
    watch
  } = useForm<ProgramPaymentType>({
    resolver: yupResolver(
      yup.object({})
    ) as unknown as Resolver<ProgramPaymentType>,
  });

  const programIdNew = watch('program_id')

  const [loading, setLoading] = React.useState(false);
  const { getStudentsByUserIdAndProgramId, programStudentsByUserIdAndProgramId } = useProgramStudent({ program_id: +programIdNew!, user_id: userId});
  
 

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    
    const dataProgramStudent = await getStudentsByUserIdAndProgramId()

    await programPaymenAcademy
      .create({
        ...data,
        program_student_id: dataProgramStudent[0]?.id,
        program_payment_schedule_id: +data.program_payment_schedule_id,
        program_id: +data.program_id,
        user_id: userId,
        amount: +data.amount,
      })
      .then((res) => {
        message.success('Se ha creado con exito el pago del estudiante')
        setOpenModal(false)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    control,
    loading,
    handleSubmit,
    watch
  };
}
