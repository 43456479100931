// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { message, Select } from "antd";
import { useEffect } from "react";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import { InputError } from "./styled";

const { Option } = Select;

interface Props {
  dataOptions: { value: string | number; title: string }[];
  width?: string;
  label: string;
  name: string;
  control: any;
  showSearch?: boolean;
  defaultValue?: string;
  isClearable?: boolean;
}

interface PropsContent {
  errors: Partial<FieldErrorsImpl<{
    [x: string]: any;
  }>>;
  name: string;
}

const ErrorComponent = ({errors,name}:PropsContent) =>{
  useEffect(() => {
    if(errors?.[name]?.message){
      message.error(`${errors?.[name]?.message}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])
  return(
    <>
    {!!errors[name] && (
      <InputError>{errors?.[name]?.message}</InputError>
      )}
      </>
  )
}


export const InputSelect = ({
  width = "100%",
  dataOptions,
  label,
  name,
  control,
  showSearch = false,
  defaultValue,
  isClearable = false,
}: Props) => {
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Select
              placeholder={label}
              value={!!value ? `${value}` : undefined}
              style={{ width }}
              defaultValue={!!defaultValue ? `${defaultValue}` : undefined }
              showSearch={showSearch ? true : false}
              optionFilterProp="children"
              onChange={onChange}
              allowClear={isClearable}
              onClear={()=>onChange(null)}
            >
              {dataOptions.map((item, idx) => (
                <Option value={item.value} key={idx + ""}>
                  {item.title}
                </Option>
              ))}
            </Select>
            <ErrorComponent errors={errors} name={name}/>
          </div>
        );
      }}
    />
  );
};
