import clsx from "clsx";
import React from "react";

import {withControl} from "./lib/withControl";

function BaseInput({
  className,
  ...props
}: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
  return (
    <input
      className={clsx(
        className,
        "block w-full rounded-md px-3 py-2 text-sm outline-none",
        "border-0 ring-1 ring-inset ring-gray-300",
        "focus:ring-primary-500 focus:ring-offset-1 focus:transition-all focus:duration-100",
      )}
      {...props}
    />
  );
}

function BaseTextArea({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>) {
  return (
    <textarea
      className={clsx(
        className,
        "block w-full rounded-md px-3 py-2 text-sm outline-none",
        "border-0 ring-1 ring-inset ring-gray-300",
        "focus:ring-primary-500 focus:ring-offset-1 focus:transition-all focus:duration-100",
      )}
      {...props}
    />
  );
}

function BaseSelect({
  className,
  ...props
}: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>) {
  return (
    <select
      className={clsx(
        className,
        "block w-full rounded-md px-3 py-2 text-sm outline-none",
        "border-0 ring-1 ring-inset ring-gray-300",
        "focus:ring-primary-500 focus:ring-offset-1 focus:transition-all focus:duration-100",
      )}
      {...props}
    />
  );
}

function BaseCheckbox({
  className,
  ...props
}: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
  return (
    <input
      className={clsx(
        className,
        "focus:border-primary-300 focus:ring-primary-200 rounded-md border-gray-300 text-primary-500 shadow-sm focus:ring",
      )}
      type="checkbox"
      {...props}
    />
  );
}

export const Input = withControl(BaseInput);

export const TextArea = withControl(BaseTextArea);

export const Select = withControl(BaseSelect);

export const Checkbox = withControl(BaseCheckbox);
