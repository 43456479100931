import { Button, Col } from "antd";
import {  EditOutlined } from "@ant-design/icons";

import Table from "../../../shared/components/ui/Table"
import { configurationCashbackService } from "../../../shared/services";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { DataConfigurationCashback } from "../../../redux/@types";
import { useTableConfigurationCashback } from '../hook/useTableConfigurationCashback';
import { useConfigurationCashback } from "../hook";

export const TableConfigurationCashback = () => {
  const {  changeConfigurationCashbackForUpdate } =
    useTableConfigurationCashback();
  const { setTableProps } = useConfigurationCashback();
  const columns: TableColumns<DataConfigurationCashback> = [
    {
      title:'ID',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title:'Estado del cashback',
      dataIndex: 'cashback_status',
      width: '10%',
      render:  (v) => {
        return ({
          'active': 'Activo',
          'inactive': 'Inactivo'
        } as any)[v as keyof any]
      }
    },
    {
      title:'Tipo de cashback',
      dataIndex: 'cashback_type',
      width: '10%',
      render: (v) => {
        return ({
          product_percentage: 'Porcentual por producto',
          fixed_order_amount: 'Monto fijo sobre la orden',
        } as any)[v as keyof any]
      }
    },
    {
      title:'Valor del cashback',
      dataIndex: 'cashback_value',
      width: '10%',
      render: (v: number, record) => {
        if (record.cashback_type === 'fixed_order_amount') {
          return v?.toLocaleString('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
          })
        }

        return v?.toLocaleString('es-CO', {
          style: 'percent',
        })
      }
    },
    {
      title:'Monto máximo de cashback',
      dataIndex: 'cashback_max_amount',
      width: '10%',
      render: (v: number) => v?.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      })
    },
    {
      title:'Días de expiración',
      dataIndex: 'cashback_expiration_days',
      width: '10%',
    },
    {
      title:'Compra mínimas requerida',
      dataIndex: 'order_min_amount',
      width: '10%',
      render: (v: number) => v?.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      })
    },
    {
      title:'Aplica a productos en descuento',
      dataIndex: 'cashback_apply_to_offer_products',
      width: '10%',
      render:  (v) => {
        return ({
          'true': 'Si',
          'false': 'No'
        } as any)[v as keyof any]
      }
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeConfigurationCashbackForUpdate(item as any)}
              icon={<EditOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <Table<DataConfigurationCashback>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={configurationCashbackService}
      onLoad={setTableProps}
    />
  )
}
