import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";

import { AdminMenuLayout, ModalDiscountCode } from "../../../shared/components";
import { WrapperCardDiscountCode } from "./styled";
import { selectModalDiscountCode } from "../../../redux/selectors/discountCode.selectors";
import { useDiscountCode } from "../hooks";
import { TableDiscountCode } from "./TableDiscountCode";

export const DiscountCode = () => {
  const modalDiscountCode = useSelector(selectModalDiscountCode);

  const {
    isUpdateDiscountCode,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalDiscountCodeState,
  } = useDiscountCode();

  return (
    <AdminMenuLayout pageDescription="DiscountCode" title="DiscountCode">
      <WrapperCardDiscountCode>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalDiscountCodeState}
            >
              Agregar nuevo código de descuento
            </Button>
          </Col>
        </Row>
        <TableDiscountCode />
        {modalDiscountCode && isUpdateDiscountCode && (
          <FormProvider {...formMethodsUpdate}>
            <ModalDiscountCode />
          </FormProvider>
        )}
        {modalDiscountCode && !isUpdateDiscountCode && (
          <FormProvider {...formMethodsCreate}>
            <ModalDiscountCode />
          </FormProvider>
        )}
      </WrapperCardDiscountCode>
    </AdminMenuLayout>
  );
};
