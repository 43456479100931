import { IAuthState, IUserRes } from '../@types'

import { authActions } from '../action-types'

type authActionType =

| { type: typeof authActions.AUTH_USER_SET_TOKEN_CREDENTIAL; payload: string }
| { type: typeof authActions.AUTH_USER_SET_CREDENTIALS; payload: IUserRes }
| { type: typeof authActions.AUTH_USER_LOGOUT; }
let token = localStorage.getItem('jwt')
let user = localStorage.getItem('user')

const INITIAL_STATE: IAuthState = {
  isAuth: !!token,
  user: user ? JSON.parse(user) : null,
  token: null,
}

export default function authReducer(state: IAuthState = INITIAL_STATE, action: authActionType): IAuthState {
  switch (action.type) {
    // case authActions.AUTH_USER_FROM_TOKEN_REQUEST:
    //   return {
    //     ...state,
    //     token: action.payload
    //   }
    // case authActions.AUTH_USER_FROM_TOKEN_SUCCEEDED:
    //   return {
    //     ...state,
    //     isAuth: true,
    //     user: action.payload,
    //   }
    // case authActions.AUTH_USER_FROM_TOKEN_FAILED:
    //   return {
    //     ...state,
    //     isAuth: false,
    //     user: null,
    //   }
    case authActions.AUTH_USER_SET_TOKEN_CREDENTIAL:
      return {
        ...state,
        token: action.payload,
        isAuth: true,
      }
    case authActions.AUTH_USER_SET_CREDENTIALS:
      return {
        ...state,
        user: action.payload
      }
    case authActions.AUTH_USER_LOGOUT:
      return {
        isAuth: false,
        user: null,
        token: null,
      }
    default:
      return state
  }
}
