import { TRootState } from "../@types"

export const selectModalWorkOffers = ( state: TRootState ) =>state.workOffers.workOffersModalIsOpen

export const selectIsUpdateWorkOffers = ( state: TRootState ) =>state.workOffers.isUpdateWorkOffers

export const selectWorkOffersForUpdate = ( state: TRootState ) =>state.workOffers.workOffersForUpdate

export const selectWorkOffersRefreshTable =  ( state: TRootState ) =>state.workOffers.refreshTableWorkOffers

export const selectArrCategoriesForWorkOffers = ( state: TRootState ) =>state.workOffers.arrCategories

export const selectArrCitiesForWorkOffers = ( state: TRootState ) =>state.workOffers.arrCities