import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useCities, WrapperModalCities } from "../../../../modules";
import {
  selectArrState,
  selectCityForUpdate,
  selectIsUpdateCity,
  selectModalCities,
} from "../../../../redux";
import { DataCities } from "../../../../redux/@types";
import { InputSelect, InputText } from "../inputs";

const { Title } = Typography;

export const ModalCities = () => {
  const modalCities = useSelector(selectModalCities);
  const isUpdateCity = useSelector(selectIsUpdateCity);
  const updateCity = useSelector(selectCityForUpdate);
  const arrState = useSelector(selectArrState);

  const { isLoading, changeModalCitiesState, onSubmitCreateOrUpdate } =
    useCities();

  const { control, handleSubmit: onSubmit } = useFormContext<DataCities>();
  return (
    <WrapperModalCities
      title={
        isUpdateCity
          ? `Actualizando ciudad: '${updateCity?.id!}'`
          : `Creando ciudad`
      }
      open={modalCities}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalCitiesState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText placeHolder="Barranquilla" name="name" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Departamento:
        </Title>
        <InputSelect
          name="state_id"
          control={control}
          dataOptions={arrState.map((item) => ({
            title: item.name,
            value: item.id.toString(),
          }))}
          label="Departamento"
        />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateCity ? "Actualizar información" : "Crear ciudad"}
      </Button>
    </WrapperModalCities>
  );
};
