import { Button, Col, Typography } from "antd";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import { useStates, WrapperModalStates } from "../../../../modules/states";
import {
  selectIsUpdateState,
  selectModalState,
  selectStateForUpdate,
} from "../../../../redux";
import { DataStates } from "../../../../redux/@types";
import { InputText } from "../inputs";

const { Title } = Typography;

export const ModalStates = () => {
  const modalStates = useSelector(selectModalState);
  const isUpdateState = useSelector(selectIsUpdateState);
  const updateState = useSelector(selectStateForUpdate);

  const { isLoading, changeModalStates, onSubmitCreateOrUpdate } = useStates();

  const { control, handleSubmit: onSubmit } = useFormContext<DataStates>();
  return (
    <WrapperModalStates
      title={
        isUpdateState
          ? `Actualizando ciudad: '${updateState?.id!}'`
          : `Creando ciudad`
      }
      open={modalStates}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalStates}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText placeHolder="Atlántico" name="name" control={control} />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateState ? "Actualizar información" : "Crear ciudad"}
      </Button>
    </WrapperModalStates>
  );
};
