import { IParentId } from '../@types';

export const PRODUCT_CATEGORIES_CHANGE_MODAL_STATE = '[ PRODUCT CATEGORIES ] Product Categories Change Modal State'

export interface IProductCategoriesChangeModalState {
    type: typeof PRODUCT_CATEGORIES_CHANGE_MODAL_STATE
}

export const GET_PRODUCT_CATEGORIES_FOR_PARENT = '[ PRODUCT CATEGORIES ] Get Product Categories For Parent'

export const GET_PRODUCT_CATEGORIES_FOR_UPDATE = '[ PRODUCT CATEGORIES ] Get Product Categories For Update'

export interface IGetProductCategoriesForUpdate {
    type: typeof GET_PRODUCT_CATEGORIES_FOR_UPDATE
    payload: any
}

export const IS_PRODUCT_CATEGORIES_FOR_UPDATE = '[ PRODUCT CATEGORIES ] Is Product Categories For Update'

export interface IIsProductCategoriesForUpdate {
    type: typeof IS_PRODUCT_CATEGORIES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PRODUCT_CATEGORIES_REFRESH_TABLE = '[ PRODUCT CATEGORIES ] Switch Product Categories Refresh Table'

export interface ISwitchProductCategoriesRefreshTable {
    type: typeof SWITCH_PRODUCT_CATEGORIES_REFRESH_TABLE
    payload: boolean
}

export const GET_PARENTS_ID_PRODUCT_CATEGORIES = '[ PRODUCT CATEGORIES ] Get Parents Id Product Categories'

export interface IGetParentsIdProductCategories {
    type: typeof GET_PARENTS_ID_PRODUCT_CATEGORIES
    payload: IParentId[]
}