import React from "react";
import ProgramPaymentsForm from "./ProgramPaymentsForm";
import Button from "../../../../shared/components/ui/Button";
import useCreatePayment from "../../hooks/payments/useCreatePayment";
import { useUsers } from "../../../users/hooks/useUsers";

export interface CreateProgramPaymentStudentProps {
  programId: number;
  scheduleId: number;
  minAmount: number;
  setOpenModal: (value: boolean) => void;
}

export default function CreateProgramPaymentStudent({
  programId,
  scheduleId,
  setOpenModal,
  minAmount
}: CreateProgramPaymentStudentProps) {
  const { userStudents, findUserStudents } = useUsers();

  React.useEffect(() => {
    findUserStudents();
  }, []);

  const { control, loading, handleSubmit } = useCreatePayment({
    programId,
    scheduleId,
    setOpenModal
  });

  return (
    <form style={{}} onSubmit={handleSubmit}>
      <ProgramPaymentsForm control={control} dataUser={userStudents!} minAmount={minAmount} />
      <div className="mt-4 flex items-center justify-end">
        <Button
          primary
          className="bg-[#072082]"
          loading={loading}
          type="submit"
          variant="filled"
        >
          Crear
        </Button>
      </div>
    </form>
  );
}
