"use client";
import Button from "../../../../shared/components/ui/Button";
import EditProgramStudentFrom from "./EditProgramStudentFrom";
import useProgramStudent from "../../../academyProgramDetail/hooks/students/useProgramStudent";
import useUpdateStudentProgram from "../../../academyProgramDetail/hooks/students/useUpdateStudentProgram";
import React from "react";

export default function EditProgramStudent({
  setOpenModal,
  programStudentId,
}: {
  setOpenModal: (val: boolean) => void;
  programStudentId: number;
}) {
  const { programStudentData, getProgramStudentById } = useProgramStudent({});
  React.useEffect(() => {
    getProgramStudentById(programStudentId);
  }, [programStudentId]);
  const { control, handleSubmit, loading } = useUpdateStudentProgram({
    programStudent: programStudentData!,
    setOpenModal,
  });

  return (
    <form onSubmit={handleSubmit}>
      <EditProgramStudentFrom control={control} />
      <div className="mt-4 flex items-center justify-end">
        <Button primary className="bg-[#072082]" type="submit" variant="filled">
          <i className="fa-regular fa-floppy-disk" /> Actualizar
        </Button>
      </div>
    </form>
  );
}
