import { Button, Col, Row } from "antd";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { FormProvider } from "react-hook-form";

import { selectModalWorkOffersCategories } from "../../../redux";
import {
  AdminMenuLayout,
  ModalWorkOffersCategories,
} from "../../../shared/components";
import { useWorkOffersCategories } from "../hook";
import { WrapperCardWorkOffersCategories } from "./styled";
import { TableWorkOffersCategories } from "./TableWorkOffersCategories";

export const WorkOffersCategories = () => {
  const modalWorkOffersCategories = useSelector(
    selectModalWorkOffersCategories
  );
  const {
    isUpdateWorkOffersCategories,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalWorkOffersCategoriesState,
  } = useWorkOffersCategories();

  return (
    <AdminMenuLayout
      pageDescription="WorkOffersCategories"
      title="WorkOffersCategories"
    >
      <WrapperCardWorkOffersCategories>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalWorkOffersCategoriesState}
            >
              Agregar nueva categoría de trabajo
            </Button>
          </Col>
        </Row>
        <TableWorkOffersCategories />
        {modalWorkOffersCategories && isUpdateWorkOffersCategories && (
          <FormProvider {...formMethodsUpdate}>
            <ModalWorkOffersCategories />
          </FormProvider>
        )}
        {modalWorkOffersCategories && !isUpdateWorkOffersCategories && (
          <FormProvider {...formMethodsCreate}>
            <ModalWorkOffersCategories />
          </FormProvider>
        )}
      </WrapperCardWorkOffersCategories>
    </AdminMenuLayout>
  );
};
