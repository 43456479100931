import { brandsService } from "../../shared/services"
import { DataBrands } from "../@types"
import { brandsActions } from "../action-types"

export const brandsChangeModalState = (): brandsActions.IBrandsModalState =>({
    type: brandsActions.BRANDS_MODAL_STATE
})

export const setBrandForUpdate = (val: DataBrands): brandsActions.IGetBrandForUpdate =>({
    type: brandsActions.GET_BRAND_FOR_UPDATE,
    payload: val
})

export const setIsBrandForUpdate = (val: boolean): brandsActions.IIsBrandForUpdate =>({
    type: brandsActions.IS_BRANDS_FOR_UPDATE,
    payload: val
})

export const createBrand = async(data: Partial<DataBrands>) =>{
    return await brandsService.create(data)
    .then((res: DataBrands) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateBrand =async(data: Partial<DataBrands>) =>{
    return await brandsService.patch(data?.id!, data)
    .then((res: DataBrands) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteBrand = async(id: number) => {
    return await brandsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableBrands = (val: boolean): brandsActions.ISwitchBrandsRefreshTable =>({
    type: brandsActions.SWITCH_BRANDS_REFRESH_TABLE,
    payload: val
})