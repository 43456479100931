import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useSelector } from 'react-redux';
import { selectTaxForUpdate, selectTaxRefreshTable } from '../../../redux';
import { DataTax } from '../../../redux/@types';
import { refreshTableTax, taxChangeModalState, updateTax } from '../../../redux/actions/tax.actions';

import useAppDispatch from '../../../redux/hooks/useAppDispatch'
import { TableProperties } from '../../../shared/components/ui/Table/types';
import { message } from 'antd';
import { validateUpdateTax } from '../../../helpers';

export const useTax = () => {
  const dispatch = useAppDispatch();

  const dataUpdateTax = useSelector(selectTaxForUpdate)
  const isRefreshTableTax = useSelector(selectTaxRefreshTable)

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>()

  const changeModalTaxState = () => {
    dispatch(taxChangeModalState())
  }

  const formMethodsUpdate = useForm<DataTax>({
    resolver: yupResolver(validateUpdateTax)
  })

  useEffect(() => {
    formMethodsUpdate.reset({ ...dataUpdateTax });

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdateTax]);

  const onSubmitUpdate = async (data: DataTax) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
      //console.log("Actualiando===>", data);
      await updateTax({
        id: data.id,
        name: data.name,
        value: data.value
      })
      .then((res: Partial<DataTax>) => {
        setIsLoading(false);
        // dispatch(mappingUpdatedUser(res))
        if (res.id) {
          // dispatch(taxChangeModalState());
          message.success(
            `Se ha actualizado con éxito el IVA con id:${res.id}`
          );
          dispatch(refreshTableTax(true));
        } else {
          message.error(res as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (isRefreshTableTax){
      tableProps?.refresh();
      dispatch(refreshTableTax(false))
    }
  },[isRefreshTableTax])

  return{
    //state
    isLoading,
    tableProps,
    //methods
    formMethodsUpdate,
    setTableProps,
    //functions
    changeModalTaxState,
    onSubmitUpdate,
  }
}
