import { IWorkOffersState, IDataForUpdateWorkOffers, DataWorkOffersCategories, DataCities } from "../@types";
import { workOffersActions } from "../action-types";

type workOffersType =
| { type: typeof workOffersActions.WORK_OFFERS_MODAL_STATE }
| { type: typeof workOffersActions.GET_WORK_OFFERS_FOR_UPDATE; payload: IDataForUpdateWorkOffers }
| { type: typeof workOffersActions.IS_WORK_OFFERS_FOR_UPDATE; payload: boolean }
| { type: typeof workOffersActions.SWITCH_WORK_OFFERS_REFRESH_TABLE; payload: boolean }
| { type: typeof workOffersActions.GET_CATEGORIES_WORK_OFFERS; payload: DataWorkOffersCategories[] }
| { type: typeof workOffersActions.GET_CITIES_WORK_OFFERS; payload: DataCities[] }

const INITIAL_STATE: IWorkOffersState ={
    workOffersForUpdate: undefined,
    isUpdateWorkOffers: false,
    workOffersModalIsOpen: false,
    refreshTableWorkOffers: false,
    arrCategories: [],
    arrCities: [],
}

export default function workOffersReducer(state: IWorkOffersState = INITIAL_STATE, action: workOffersType): IWorkOffersState {
    switch(action.type){
        case workOffersActions.WORK_OFFERS_MODAL_STATE:
            return{
                ...state,
                workOffersModalIsOpen: !state.workOffersModalIsOpen
            }
        case workOffersActions.GET_WORK_OFFERS_FOR_UPDATE:
            return{
                ...state,
                workOffersForUpdate: action.payload
            }
        case workOffersActions.IS_WORK_OFFERS_FOR_UPDATE:
            return{
                ...state,
                isUpdateWorkOffers: action.payload
            }
        case workOffersActions.SWITCH_WORK_OFFERS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableWorkOffers: action.payload
            }
        case workOffersActions.GET_CATEGORIES_WORK_OFFERS:
            return{
                ...state,
                arrCategories: action.payload
            }
        case workOffersActions.GET_CITIES_WORK_OFFERS:
            return{
                ...state,
                arrCities: action.payload
            }
        default:
            return state
    }
}