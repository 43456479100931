// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { bannersService } from "../../shared/services"
import { DataBanner } from "../@types"
import { bannersActions } from "../action-types"

export const bannersChangeModalState = (): bannersActions.IBannersChangeModalState => ({
    type: bannersActions.BANNERS_CHANGE_MODAL_STATE
})

export const setBannersForUpdate = (val: DataBanner): bannersActions. IGetBannersForUpdate => ({
    type: bannersActions.GET_BANNER_FOR_UPDATE,
    payload: val
})

export const setIsBannersForUpdate = (val: boolean): bannersActions.IIsBannersForUpdate =>({
    type: bannersActions.IS_BANNER_FOR_UPDATE,
    payload: val
})

export const createBanner = async(data: Partial<DataBanner>) => {
    return bannersService.create(data)
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateBanner = async(data:Partial<DataBanner>) => {
    return await bannersService.patch(data?.id!, data)
    .then((res: any) => {
        // console.log({res});
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteBanner = async (id: number ) =>{
    return  await bannersService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableBanners =( val: boolean ): bannersActions.ISwitchBannersRefreshTable=>({
    type: bannersActions.SWITCH_BANNERS_REFRESH_TABLE,
    payload: val
})

export const refreshTableBannersProducts =( val: boolean ): bannersActions.ISwitchBannersProductsRefreshTable=>({
    type: bannersActions.SWITCH_BANNERS_PRODUCTS_REFRESH_TABLE,
    payload: val
})