import { useEffect, useState } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import {
  selectWorkOffersCategoriesForUpdate,
  selectWorkOffersCategoriesRefreshTable,
  selectIsUpdateWorkOffersCategories,
  setIsWorkOffersCategoriesForUpdate,
  workOffersCategoriesChangeModalState,
  updateWorkOffersCategories,
  refreshTableWorkOffersCategories,
  createWorkOffersCategories,
} from "../../../redux";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { DataWorkOffersCategories } from "../../../redux/@types";
import {
  validateCreateWorkOffersCategories,
  validateUpdateWorkOffersCategories,
} from "../../../helpers";


export const useWorkOffersCategories = () => {
  const dispatch = useAppDispatch();

  const isUpdateWorkOffersCategories = useSelector(
    selectIsUpdateWorkOffersCategories
  );
  const dataUpdateWorkOffersCategories = useSelector(
    selectWorkOffersCategoriesForUpdate
  );
  const isRefreshTableWorkOffersCategories = useSelector(
    selectWorkOffersCategoriesRefreshTable
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalWorkOffersCategoriesState = () => {
    dispatch(setIsWorkOffersCategoriesForUpdate(false));
    dispatch(workOffersCategoriesChangeModalState());
  };

  const formMethodsCreate = useForm<DataWorkOffersCategories>({
    resolver: yupResolver(validateCreateWorkOffersCategories),
  });

  const formMethodsUpdate = useForm<DataWorkOffersCategories>({
    resolver: yupResolver(validateUpdateWorkOffersCategories),
  });

  useEffect(() => {
    if (isUpdateWorkOffersCategories) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateWorkOffersCategories });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateWorkOffersCategories, dataUpdateWorkOffersCategories]);

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await updateWorkOffersCategories({
      id,
      status: value,
    })
    .then((res: Partial<DataWorkOffersCategories>) => {
      setIsLoading(false);
      if (res.id) {
        // dispatch(bannersChangeModalState());
        message.success(
          `Se ha actualizado con éxito el estado de la categoría de trabajo con id:${res.id}`
        );
        dispatch(refreshTableWorkOffersCategories(true));
      } else {
        message.error(res as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }

  const onSubmitCreateOrUpdate = async (data: DataWorkOffersCategories) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateWorkOffersCategories) {
      //console.log("Actualiando===>", data);
      //console.log({data})
      await updateWorkOffersCategories({
        id: data.id,
        name: data.name,
        status: data.status,
        position: data.position,
      })
        .then((res: Partial<DataWorkOffersCategories>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // dispatch(workOffersCategoriesChangeModalState());
            message.success(
              `Se ha actualizado con éxito la categoría de trabajo con id:${res.id}`
            );
            dispatch(refreshTableWorkOffersCategories(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createWorkOffersCategories({...data, position: data.position ? data.position : 0})
        .then((res: DataWorkOffersCategories) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(workOffersCategoriesChangeModalState());
            message.success(
              `Se ha creado con éxito la categoría de trabajo con id:${res.id}`
            );
            dispatch(refreshTableWorkOffersCategories(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableWorkOffersCategories) {
      tableProps?.refresh();
      dispatch(refreshTableWorkOffersCategories(false));
    }
  }, [isRefreshTableWorkOffersCategories]);
  return {
    //state
    isUpdateWorkOffersCategories,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalWorkOffersCategoriesState,
    updateStatus,
  };
};
