"use client";

import React from "react";
import { message, Modal } from "antd";
import { academyEquipmentRentsService } from "../../../../shared/services";

const { confirm } = Modal;

export default function useDeleteEquipmentRents() {
  const [loading, setLoading] = React.useState(false);

  const showDeleteConfirm = (valId: number, val: string) => {
    if (!valId) {
      message.error("ID del alquiler no válido");
      return;
    }

    confirm({
      title: `¿Estás seguro de borrar este alquiler con el id: ${valId}?`,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}". Si deseas continuar, dale click a "Borrar alquiler". En caso contrario, solo oprime "Cancelar".`,
      okText: "Borrar alquiler",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        try {
          setLoading(true);
          await academyEquipmentRentsService.remove(valId, {
            query: { $client: { markAsDeletedAndUpdateQuantity: true } },
            
          });
          message.success(
            `Se ha borrado con éxito el alquiler con id: ${valId}`
          );
        } catch (err: any) {
          message.error(`Error: ${err.message}`);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  return { showDeleteConfirm, loading };
}
