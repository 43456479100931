"use client";
import React from "react";
import Button from "../../../../../shared/components/ui/Button";
import { useUsers } from "../../../../users/hooks/useUsers";
import useCreateEquipmentRents from "../../../hooks/equipmentRents/useCreateEquipmentRents";
import CreateEquipmentRentsForm from "./CreateEquipmentRentsForm";
import useEquipment from "../../../../AcademyEquipments/hooks/equipments/useEquipment";
import { useParams } from "react-router-dom";

export interface CreateEquipmentRentsProps {
  setOpenModal: (val: boolean) => void;
}

export default function CreateEquipmentRents({setOpenModal}: CreateEquipmentRentsProps) {
  const {userStudents, findUserStudents} = useUsers()
  const {equipmentsData} = useEquipment()
  const {equipment_id} = useParams()

  React.useEffect(()=>{findUserStudents()},[])
  const {control, loading, handleSubmit} = useCreateEquipmentRents({setOpenModal, equipment_id: +equipment_id!});

  return (
    <form onSubmit={handleSubmit}>
      <CreateEquipmentRentsForm control={control} users={userStudents} equipmentsData={equipmentsData} />
      <div className="mt-4 flex items-center justify-end">
        <Button primary className="bg-[#072082]" loading={loading} type="submit" variant="filled">
           Crear
        </Button>
      </div>
    </form>
  );
}
