import { ISinglePriceList } from "../@types"

export const PRICE_LIST_MODAL_STATE = '[ PRICE LIST ] Price List Modal State'

export interface IPriceListModalState {
    type: typeof PRICE_LIST_MODAL_STATE
}

export const GET_PRICE_LISTS_FOR_UPDATE = '[ PRICE LISTS ] Get List Customer Groups For Update'

export interface IGetPriceListForUpdate {
    type: typeof GET_PRICE_LISTS_FOR_UPDATE
    payload: ISinglePriceList
}

export const IS_PRICE_LISTS_FOR_UPDATE ='[ PRICE LISTS ] Is List Customer Groups For Update'

export interface IIsPriceListForUpdate {
    type: typeof IS_PRICE_LISTS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PRICE_LISTS_REFRESH_TABLE = '[ PRICE LISTS ] Switch List Customer Groups Refresh Table'

export interface ISwitchPriceListRefreshTable{
    type: typeof SWITCH_PRICE_LISTS_REFRESH_TABLE
    payload: boolean
}
