
import { DataCMS, ICMSState } from "../@types/cms";
import { cMSActions } from "../action-types";


type cMSType =
| { type: typeof cMSActions.CMS_MODAL_STATE }
| { type: typeof cMSActions.GET_CMS_FOR_UPDATE; payload: DataCMS }
| { type: typeof cMSActions.IS_CMS_FOR_UPDATE; payload: boolean }
| { type: typeof cMSActions.SWITCH_CMS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: ICMSState ={
    cMSForUpdate: undefined,
    isUpdateCMS: false,
    cMSModalIsOpen: false,
    refreshTableCMS: false,
}

export default function cMSReducer(state: ICMSState = INITIAL_STATE, action: cMSType): ICMSState {
    switch(action.type){
        case cMSActions.CMS_MODAL_STATE:
            return{
                ...state,
                cMSModalIsOpen: !state.cMSModalIsOpen
            }
        case cMSActions.GET_CMS_FOR_UPDATE:
            return{
                ...state,
                cMSForUpdate: action.payload
            }
        case cMSActions.IS_CMS_FOR_UPDATE:
            return{
                ...state,
                isUpdateCMS: action.payload
            }
        case cMSActions.SWITCH_CMS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableCMS: action.payload
            }
        default:
            return state
    }
}