// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { message } from 'antd';
import { useNavigate } from "react-router-dom";

import { createProduct, setProductsForUpdate, refreshTableProducts, selectIsUpdateProduct, selectProductForUpdate, selectProductRefreshTable, setIsProductsForUpdate, updateProduct } from "../../../redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { IProducts } from "../../../redux/@types";
import { validateCreateProducts, validateUpdateProducts } from "../../../helpers";

interface PropsArray {
  [k: string]: number;
}

const helperObjQueryKey = {
  total_without_categories:         null,
  total_without_images:             'images',
  total:                            null,
  total_active:                     'status',
  total_inactive:                   'status',
  total_with_out_description_short: 'description_short',
  total_with_out_weight:            'weight',
  total_with_out_height:            'height',
  total_with_out_width:             'width',
  total_with_out_long:              'long',
}

const helperObjQueryVal = {
  total_without_categories:         `$or:[{category_id: 'null'},{category_id : 1}]`,
  total_without_images:             'false',
  total:                            null,
  total_active:                     'active',
  total_inactive:                   'inactive',
  total_with_out_description_short: 'null',
  total_with_out_weight:            'null',
  total_with_out_height:            'null',
  total_with_out_width:             'null',
  total_with_out_long:              'null',
}

export const useProducts = () => {
  const [querySelectorTransform, setQuerySelectorTransform] = useState< PropsArray>({})
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isUpdateProduct = useSelector(
    selectIsUpdateProduct
  );
  const dataUpdateProduct = useSelector(
    selectProductForUpdate
  );
  const isRefreshTableProducts = useSelector(
    selectProductRefreshTable
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const navigateProductsCreate = () => {
    dispatch(setIsProductsForUpdate(false));
    navigate(`/products/new`);
  };

  const formMethodsCreate = useForm<IProducts>({
    resolver: yupResolver(validateCreateProducts),
  });

  const formMethodsUpdate = useForm<IProducts>({
    resolver: yupResolver(validateUpdateProducts),
  });

useEffect(() => {
    if (isUpdateProduct) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateProduct });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateProduct, dataUpdateProduct]);

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await updateProduct({
      id,
      status: value,
    })
    .then((res: Partial<IProducts>) => {
      setIsLoading(false);
      if (res.id) {
        // dispatch(bannersChangeModalState());
        message.success(
          `Se ha actualizado con éxito el estado del producto con id:${res.id}`
        );
        dispatch(refreshTableProducts(true));
      } else {
        message.error(res as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }

const onSubmitCreateOrUpdate = async (data: IProducts) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateProduct) {
    // console.log("Actualiando===>", data);
      await updateProduct({
        id:data.id,
        name:data.name,
        code: data.code,
        category_id: Number(data.category_id),
        ...(data.category_id_2  ? {category_id_2:  Number(data.category_id_2 )} : {}),
        ...(data.category_id_3  ? {category_id_3:  Number(data.category_id_3 )} : {}),
        brand_id: Number(data.brand_id),
        price: data.price,
        tax_rule_id: Number(data.tax_rule_id),
        discount_price: data.discount_price,
        price_with_tax: data.price_with_tax,
        // discount_price_whit_tax: data.discount_price_whit_tax,
        quantity: data.quantity,
        cashback_status: data.cashback_status,
        ...(data.cashback_status === 'specific'  ? {cashback_specific_percentage: data.cashback_specific_percentage} : null),
        ...(data.quantity_per_unit_measure  ? {quantity_per_unit_measure:  data.quantity_per_unit_measure} : {}),
        ...(data.unit_measure  ? {unit_measure: data.unit_measure} : {}),
        weight: data.weight,
        heigh: data.heigh,
        width: data.width,
        long: data.long,
        ...(data.description  ? {description: data.description === "<p><br></p>" ? "" :data.description} : {}),
        status: data.status,
        position_more_sales: data.position_more_sales,
        user_guide_composition: data.user_guide_composition,
        user_guide_care: data.user_guide_care,
        course: data.course.toString(),
      })
        .then((res: Partial<IProducts>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            //navigate(`/products`)
            message.success(
              `Se ha actualizado con éxito el producto con id:${res.id}`
            );
            dispatch(setProductsForUpdate(res as any))
            dispatch(refreshTableProducts(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      // setIsLoading(false);
      await createProduct({
        name:data.name,
        code: data.code,
        category_id: Number(data.category_id),
        ...(data.category_id_2  ? {category_id_2:  Number(data.category_id_2 ) || 1} : {}),
        ...(data.category_id_3  ? {category_id_3:  Number(data.category_id_3 ) || 1} : {}),
        brand_id: Number(data.brand_id) || 1,
        price: data.price,
        tax_rule_id: Number(data.tax_rule_id) || 1,
        discount_price: data.discount_price,
        price_with_tax: data.price_with_tax,
        discount_price_whit_tax: data.discount_price_whit_tax,
        quantity: data.quantity,
        unit_measure: data.unit_measure,
        quantity_per_unit_measure: data.quantity_per_unit_measure,
        weight: data.weight,
        heigh: data.heigh,
        width: data.width,
        cashback_status: data.cashback_status,
        ...(data.cashback_specific_percentage  ? {cashback_status: data.cascashback_specific_percentagehback_status} : {}),
        long: data.long,
        description: data.description === "<p><br></p>" ? "" :data.description,
        status: data.status,
        position_more_sales: data.position_more_sales,
        user_guide_composition: data.user_guide_composition,
        user_guide_care: data.user_guide_care,
        course: data.course ? data.course.toString() : "false",
      })
        .then((res: any) => {
          setIsLoading(false);
          if (res.id) {
            // dispatch(productsChangeModalState());
            navigate(`/products`)
            window.scrollTo(0, 0)
            message.success(
              `Se ha creado con éxito el producto con id:${res.id}`
            );
            dispatch(refreshTableProducts(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableProducts) {
      tableProps?.refresh();
      dispatch(refreshTableProducts(false));
    }
 // eslint-disable-next-line react-hooks/exhaustive-deps
}, [isRefreshTableProducts]);

const handleQueryTransform = ( val: [] | string[])=>{
  if(val.length === 0){
    return setQuerySelectorTransform({})
  }
  else{
    const objAux: PropsArray= {}
    val.forEach((item)=>{
      if(helperObjQueryKey.hasOwnProperty(item) && item === 'total_without_categories'){
        return objAux['$or'] = [{category_id: 'null'},{category_id : 1}]
      } else if (helperObjQueryKey.hasOwnProperty(item)) {
        return objAux[helperObjQueryKey[item as keyof typeof helperObjQueryKey]] = helperObjQueryVal[item as keyof typeof helperObjQueryVal]
      }
    })
    return setQuerySelectorTransform(objAux)
  }
}

  return {
    //state
    querySelectorTransform,
    isUpdateProduct,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    navigateProductsCreate,
    onSubmitCreateOrUpdate,
    updateStatus,
    handleQueryTransform
  }
}
