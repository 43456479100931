import { DataListCustomerGroups } from "../@types"

export const LIST_CUSTOMER_GROUPS_MODAL_STATE = '[ LIST CUSTOMER GROUPS ] List Customer Groups Modal State'

export interface IListCustomerGroupsModalState {
    type: typeof LIST_CUSTOMER_GROUPS_MODAL_STATE
}

export const GET_LIST_CUSTOMER_GROUPS_FOR_UPDATE = '[ LIST CUSTOMER GROUPS ] Get List Customer Groups For Update'

export interface IGetListCustomerGroupsForUpdate {
    type: typeof GET_LIST_CUSTOMER_GROUPS_FOR_UPDATE
    payload: DataListCustomerGroups
}

export const IS_LIST_CUSTOMER_GROUPS_FOR_UPDATE ='[ LIST CUSTOMER GROUPS ] Is List Customer Groups For Update'

export interface IIsListCustomerGroupsForUpdate {
    type: typeof IS_LIST_CUSTOMER_GROUPS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_LIST_CUSTOMER_GROUPS_REFRESH_TABLE = '[ LIST CUSTOMER GROUPS ] Switch List Customer Groups Refresh Table'

export interface ISwitchListCustomerGroupsRefreshTable{
    type: typeof SWITCH_LIST_CUSTOMER_GROUPS_REFRESH_TABLE
    payload: boolean
}
