import { statesService } from "../../shared/services"
import { DataStates } from "../@types/states"
import { statesActions } from "../action-types"

export const statesChangeModalState = (): statesActions.IStatesModalState =>({
    type: statesActions.STATES_MODAL_STATE
})

export const setStateForUpdate = (val: DataStates): statesActions.IGetStateForUpdate =>({
    type: statesActions.GET_STATE_FOR_UPDATE,
    payload: val
})

export const setIsStateForUpdate = (val: boolean): statesActions.IIsStateForUpdate =>({
    type: statesActions.IS_STATES_FOR_UPDATE,
    payload: val
})

export const createState = async(data: Partial<DataStates>) =>{
    return await statesService.create(data)
    .then((res: DataStates) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateState =async(data: Partial<DataStates>) =>{
    return await statesService.patch(data?.id!, data)
    .then((res: DataStates) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteState = async(id: number) => {
    return await statesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableStates = (val: boolean): statesActions.ISwitchStatesRefreshTable =>({
    type: statesActions.SWITCH_STATES_REFRESH_TABLE,
    payload: val
})