import { FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import { ListCustomerGroupsById, useListCustomerGroups } from "../modules";
import { selectIsUpdateListCustomerGroups } from "../redux";

export const ListCustomerGroupsByIdPage = () => {
  const { formMethodsUpdate } = useListCustomerGroups();
  const isUpdateListCustomerGroups = useSelector(
    selectIsUpdateListCustomerGroups
  );
  return (
    <>
      {isUpdateListCustomerGroups && (
        <FormProvider {...formMethodsUpdate}>
          <ListCustomerGroupsById />
        </FormProvider>
      )}
    </>
  );
};
