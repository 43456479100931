import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  deleteListCustomerGroups,
  refreshTableListCustomerGroups,
  setListCustomerGroupsForUpdate,
  setIsListCustomerGroupsForUpdate,
} from "../../../redux";
import { DataListCustomerGroups } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useListCustomerGroups } from "./useListCustomerGroups";

const { confirm } = Modal;

export const useTableListCustomerGroups = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tableProps } = useListCustomerGroups();

  const changeListCustomerGroupsForUpdate = async (
    val: DataListCustomerGroups
  ) => {
    dispatch(setIsListCustomerGroupsForUpdate(true));
    dispatch(setListCustomerGroupsForUpdate(val));
    navigate(`/listCustomerGroups/${val.id}`);
    // dispatch(contactsDirectoryChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la Lista de grupos de clientes con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar list. grup. clientes" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar list. grup. clientes",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteListCustomerGroups(valId)
          .then(() => {
            dispatch(refreshTableListCustomerGroups(true));
            message.success(
              `Se ha borrado con éxito la Lista de grupos de clientes con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeListCustomerGroupsForUpdate,
    showDeleteConfirm,
  };
};
