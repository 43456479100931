import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";

import {
  AdminMenuLayout,
  ModalAddressesUsers,
} from "../../../shared/components";
import { WrapperCardAddresses } from "./styled";
import { TableAddressesUsers } from "./TableAddressesUsers";
import { selectModalAddressesUsers } from "../../../redux";
import { useAddressesUsers } from "../hooks";

export const AddressesUsers = () => {
  const modalAddressesUser = useSelector(selectModalAddressesUsers);
  const {
    isUpdateAddressesUsers,
    formMethodsCreate,
    formMethodsUpdate,
    statesAddressesUsers,
    citiesAddressesUsers,
    changeModalAddressesUsersState,
  } = useAddressesUsers();
  return (
    <AdminMenuLayout pageDescription="AddressesUsers" title="AddressesUsers">
      <WrapperCardAddresses>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalAddressesUsersState}
            >
              Agregar nueva dirección
            </Button>
          </Col>
        </Row>
        <TableAddressesUsers statesAddressesUsers={statesAddressesUsers} citiesAddressesUsers={citiesAddressesUsers}/>
        {modalAddressesUser && isUpdateAddressesUsers && (
          <FormProvider {...formMethodsUpdate}>
            <ModalAddressesUsers />
          </FormProvider>
        )}
        {modalAddressesUser && !isUpdateAddressesUsers && (
          <FormProvider {...formMethodsCreate}>
            <ModalAddressesUsers />
          </FormProvider>
        )}
      </WrapperCardAddresses>
    </AdminMenuLayout>
  );
};
