import { Button, Col } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

import { DataCourseBenefits } from "../../../../redux/@types";
import { courseBenefitsService } from "../../../../shared/services";
import Table from "../../../../shared/components/ui/Table";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import useAppSelector from "../../../../redux/hooks/useAppSelector";
import { selectModalCourseBenefits } from "../../../../redux";
import { FormProvider } from "react-hook-form";
import { useCourseBenefits } from "../../hook";
import { ModalCourseBenefits } from "../../../../shared/components";

export const TableCourseBenefits = () => {
  const { id } = useParams();
  const modalCourseBenefits = useAppSelector(selectModalCourseBenefits);

  const {
    isUpdateCourseBenefits,
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    changeModalCourseBenefitsState,
    changeCourseBenefitsForUpdate,
    showDeleteConfirm,
  } = useCourseBenefits();
  const columns: TableColumns<DataCourseBenefits> = [
    {
      title: "ID",

      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Nombre",

      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "Posición",

      dataIndex: "position",
      key: "position",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",

      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeCourseBenefitsForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <>
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          size="large"
          onClick={changeModalCourseBenefitsState}
        >
          Agregar nuevo beneficio
        </Button>
      </Col>
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Table<DataCourseBenefits>
          columns={columns}
          fetchQueryProps={{
            $sort: { id: -1 },
            course_id: id,
          }}
          service={courseBenefitsService}
          onLoad={setTableProps}
        />
      </Col>
      {modalCourseBenefits && isUpdateCourseBenefits && (
        <FormProvider {...formMethodsUpdate}>
          <ModalCourseBenefits />
        </FormProvider>
      )}
      {modalCourseBenefits && !isUpdateCourseBenefits && (
        <FormProvider {...formMethodsCreate}>
          <ModalCourseBenefits />
        </FormProvider>
      )}
    </>
  );
};
