"use client";

import React from "react";
import { programsModuleTechniquesAcademy } from "../../../../shared/services";
import { PaginatedResponse, ProgramTechniquesType } from "../../../../types/dto/program";

export default function useProgramModuleTechniques(topicId?: number) {
  const [loading, setLoading] = React.useState(false);
  const [programTechniques, setProgramTechniques] = React.useState<ProgramTechniquesType[]>([]);
  const [pagination, setPagination] = React.useState<Omit<PaginatedResponse, "data">>({
    limit: 50,
    skip: 0,
    total: 0,
  });

  const getSectionProgramTecnics = async () => {
    if (loading || !topicId) return;
    setLoading(true);

   await programsModuleTechniquesAcademy
      .find({ query: {
        program_module_topic_id: topicId,
        $limit: pagination.limit,
        $skip: pagination.skip,
        $sort:{
          position: 1
        }
       }})
      .then((response) => {
        setProgramTechniques(response.data);
        setPagination({
          limit: response.limit,
          skip: response.skip,
          total: response.total,
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  React.useEffect(() => {
    setProgramTechniques([])
    getSectionProgramTecnics();
  }, [topicId]);

  return {
    loading,
    pagination,
    programTechniques,
    refresh: getSectionProgramTecnics,
    setProgramTechniques,
  };
}
