import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";
import { useWalletMovements } from "../hooks";
import { selectModalWalletMovements } from "../../../redux";
import {
  AdminMenuLayout,
  ModalWalletMovements,
} from "../../../shared/components";
import { WrapperCardWalletMovements } from "./styled";
import { TableWalletMovements } from "./TableWalletMovements";

export const WalletMovements = () => {
  const modalWalletMovements = useSelector(selectModalWalletMovements);
  const {
    isUpdateWalletMovements,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalWalletMovementsState,
  } = useWalletMovements();

  return (
    <AdminMenuLayout pageDescription="WalletMovements" title="WalletMovements">
      <WrapperCardWalletMovements>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalWalletMovementsState}
            >
              Agregar movimiento
            </Button>
          </Col>
        </Row>
        <TableWalletMovements />
        {modalWalletMovements && isUpdateWalletMovements && (
          <FormProvider {...formMethodsUpdate}>
            <ModalWalletMovements />
          </FormProvider>
        )}
        {modalWalletMovements && !isUpdateWalletMovements && (
          <FormProvider {...formMethodsCreate}>
            <ModalWalletMovements />
          </FormProvider>
        )}
      </WrapperCardWalletMovements>
    </AdminMenuLayout>
  );
};
