import { Button, Col, Select, Spin, Typography } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useWalletMovements,
  WrapperModalWalletMovements,
} from "../../../../modules/walletMovements";
import {
  selectIsUpdateWalletMovements,
  selectModalWalletMovements,
} from "../../../../redux";
import { DataWalletMovements, IUser } from "../../../../redux/@types";
import {
  selectWalletMovementsForUpdate,
  selectArrUsersForWalletMovements,
} from "../../../../redux/selectors/walletMovements.selectors";
import { InputData, InputNumber, InputSelect, InputText } from "../inputs";
import { usersService } from "../../../services";
import useFilterUserByStatus from "../../../../modules/academyProgramDetail/hooks/students/useFilterUserByStatus";

const { Title } = Typography;

export const ModalWalletMovements = () => {
  const modalWalletMovements = useSelector(selectModalWalletMovements);
  const isUpdateWalletMovements = useSelector(selectIsUpdateWalletMovements);
  const updateWalletMovements = useSelector(selectWalletMovementsForUpdate);
  const arrUsers = useSelector(selectArrUsersForWalletMovements);

  const { isLoading, changeModalWalletMovementsState, onSubmitCreateOrUpdate } =
    useWalletMovements();

  const { control, handleSubmit: onSubmit } =
    useFormContext<DataWalletMovements>();

    const { setQuery, filteredData } = useFilterUserByStatus<any>({
      service: usersService,
      initialData: arrUsers || [],
      queryKey: 'q',
    });
  return (
    <WrapperModalWalletMovements
      title={
        isUpdateWalletMovements
          ? `Actualizando movimiento: '${updateWalletMovements?.id!}'`
          : `Creando movimiento`
      }
      open={modalWalletMovements}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalWalletMovementsState}
    >
     
      <div>
      <Title style={{ marginBottom: "1.5px" }} level={5}>
          Usuario a realizar transacción:
        </Title>
        <Controller
          control={control}
          name="user_id"
          render={({ field, fieldState }) => (
            <div>
              <Select
                {...field}
                showSearch
                style={{ width: '100%', height: 30 }}
                placeholder="Selecciona un usuario"
                optionFilterProp="label"
                onSearch={(value) => setQuery(value)}
                filterOption={false}
                notFoundContent={filteredData.length === 0 ? <Spin size="small" style={{ justifyContent: 'center', display: 'flex', padding: 10 }} /> : null}
                options={
                  filteredData.length > 0
                    ? filteredData.map((it) => ({
                        key: `${it.id}`,
                        value: `${it.id}`,
                        label: `${it.first_name} ${it.last_name}`
                      }))
                    : []
                }
              />
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
     
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Cantidad neta:
        </Title>
        <InputNumber
          placeHolder="3000"
          name="amount_net"
          control={control}
          min
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Descripción:
        </Title>
        <InputText
          placeHolder="Esta cantidad es mandada para compensar un pago..."
          name="description"
          control={control}
          rows
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Fecha de vencimiento:
        </Title>
        <InputData
          placeHolder="29/10/2022"
          name="expired_day"
          control={control}
        />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateWalletMovements
          ? "Actualizar información"
          : "Crear movimiento"}
      </Button>
    </WrapperModalWalletMovements>
  );
};
