import { DataWalletMovements, IUserResFind, IWalletMovementsState } from "../@types";
import { walletMovementsActions } from "../action-types";

type walletMovementsType =
| { type: typeof walletMovementsActions.WALLET_MOVEMENTS_MODAL_STATE }
| { type: typeof walletMovementsActions.GET_WALLET_MOVEMENTS_FOR_UPDATE; payload: DataWalletMovements }
| { type: typeof walletMovementsActions.IS_WALLET_MOVEMENTS_FOR_UPDATE; payload: boolean }
| { type: typeof walletMovementsActions.SWITCH_WALLET_MOVEMENTS_REFRESH_TABLE; payload: boolean }
| { type: typeof walletMovementsActions.GET_USERS_WALLET_MOVEMENTS; payload: IUserResFind[] }
| { type: typeof walletMovementsActions.GET_USERS_OBJ_WALLET_MOVEMENTS; payload: { [key: string]: string; } }


const INITIAL_STATE: IWalletMovementsState ={
    walletMovementsForUpdate: undefined,
    isUpdateWalletMovements: false,
    walletMovementsModalIsOpen: false,
    refreshTableWalletMovements: false,
    arrUsers: [],
    objUsers: {},
}

export default function walletMovementsReducer(state: IWalletMovementsState = INITIAL_STATE, action: walletMovementsType): IWalletMovementsState {
    switch(action.type){
        case walletMovementsActions.WALLET_MOVEMENTS_MODAL_STATE:
            return{
                ...state,
                walletMovementsModalIsOpen: !state.walletMovementsModalIsOpen
            }
        case walletMovementsActions.GET_WALLET_MOVEMENTS_FOR_UPDATE:
            return{
                ...state,
                walletMovementsForUpdate: action.payload
            }
        case walletMovementsActions.IS_WALLET_MOVEMENTS_FOR_UPDATE:
            return{
                ...state,
                isUpdateWalletMovements: action.payload
            }
        case walletMovementsActions.SWITCH_WALLET_MOVEMENTS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableWalletMovements: action.payload
            }
        case walletMovementsActions.GET_USERS_WALLET_MOVEMENTS:
            return{
                ...state,
                arrUsers: action.payload
            }
        case walletMovementsActions.GET_USERS_OBJ_WALLET_MOVEMENTS:
            return{
                ...state,
                objUsers: action.payload
            }
        default:
            return state
    }
}