import { DataCities, DataDirectoryCategories, IDirectoryCategoriesState } from "../@types";
import { directoryCategoriesActions } from "../action-types";

type directoryCategoriesType =
| { type: typeof directoryCategoriesActions.DIRECTORY_CATEGORIES_MODAL_STATE }
| { type: typeof directoryCategoriesActions.GET_DIRECTORY_CATEGORIES_FOR_UPDATE; payload: DataDirectoryCategories }
| { type: typeof directoryCategoriesActions.IS_DIRECTORY_CATEGORIES_FOR_UPDATE; payload: boolean }
| { type: typeof directoryCategoriesActions.SWITCH_DIRECTORY_CATEGORIES_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IDirectoryCategoriesState ={
    directoryCategoriesForUpdate: undefined,
    isUpdateDirectoryCategories: false,
    directoryCategoriesModalIsOpen: false,
    refreshTableDirectoryCategories: false,
}

export default function directoryCategoriesReducer(state: IDirectoryCategoriesState = INITIAL_STATE, action: directoryCategoriesType): IDirectoryCategoriesState {
    switch(action.type){
        case directoryCategoriesActions.DIRECTORY_CATEGORIES_MODAL_STATE:
            return{
                ...state,
                directoryCategoriesModalIsOpen: !state.directoryCategoriesModalIsOpen
            }
        case directoryCategoriesActions.GET_DIRECTORY_CATEGORIES_FOR_UPDATE:
            return{
                ...state,
                directoryCategoriesForUpdate: action.payload
            }
        case directoryCategoriesActions.IS_DIRECTORY_CATEGORIES_FOR_UPDATE:
            return{
                ...state,
                isUpdateDirectoryCategories: action.payload
            }
        case directoryCategoriesActions.SWITCH_DIRECTORY_CATEGORIES_REFRESH_TABLE:
            return{
                ...state,
                refreshTableDirectoryCategories: action.payload
            }
        default:
            return state
    }
}