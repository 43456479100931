import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useConfigurationCashback,
  WrapperModalConfigurationCashback,
} from "../../../../modules";
import {
  selectIsUpdateConfigurationCashback,
  selectModalConfigurationCashback,
  selectConfigurationCashbackForUpdate,
} from "../../../../redux";
import { DataConfigurationCashback } from "../../../../redux/@types";
import { InputSelect, InputText } from "../inputs";

const { Title } = Typography;

export const ModalConfigurationCashback = () => {
  const modalConfigurationCashback = useSelector(
    selectModalConfigurationCashback
  );
  const isUpdateConfigurationCashback = useSelector(
    selectIsUpdateConfigurationCashback
  );
  const updateConfigurationCashback = useSelector(
    selectConfigurationCashbackForUpdate
  );

  const {
    isLoading,
    changeModalConfigurationCashbackState,
    onSubmitCreateOrUpdate,
  } = useConfigurationCashback();

  const {
    control,
    handleSubmit: onSubmit,
  } = useFormContext<DataConfigurationCashback>();
  return (
    <WrapperModalConfigurationCashback
      title={
        isUpdateConfigurationCashback
          ? `Actualizando variable: '${updateConfigurationCashback?.id!}'`
          : `Creando variable`
      }
      open={modalConfigurationCashback}
      width={"320px"}
      destroyOnClose
      onClose={changeModalConfigurationCashbackState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Estado:
        </Title>
        <InputSelect
          label=""
          dataOptions={[
            {
              title: "Activo",
              value: "active",
            },
            {
              title: "Inactivo",
              value: "inactive",
            },
          ]}
          name="cashback_status"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Tipo:
        </Title>
        <InputSelect
          label=""
          dataOptions={[
            {
              title: "Porcentaje del producto",
              value: "product_percentage",
            },
            {
              title: "Monto fijo sobre la orden",
              value: "fixed_order_amount",
            },
          ]}
          name="cashback_type"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Valor del cashback:
        </Title>
        <InputText placeHolder="" name="cashback_value" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Monto máximo del cashback
        </Title>
        <InputText
          placeHolder=""
          name="cashback_max_amount"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Días de expiración
        </Title>
        <InputText
          placeHolder=""
          name="cashback_expiration_days"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Compra mínima
        </Title>
        <InputText placeHolder="" name="order_min_amount" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          ¿Aplicar a productos en descuento?:
        </Title>
        <InputSelect
          label=""
          dataOptions={[
            {
              title: "Si",
              value: "true",
            },
            {
              title: "No",
              value: "false",
            },
          ]}
          name="cashback_apply_to_offer_products"
          control={control}
        />
      </Col>

      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateConfigurationCashback
          ? "Actualizar información"
          : "Crear variable de sistema"}
      </Button>
    </WrapperModalConfigurationCashback>
  );
};
