import React from "react";
import { useParams } from "react-router-dom";
import useAssignedStudentProgram from "../../hooks/students/useAssignedStudentProgram";
import AssignedStudentProgramForm from "./AssignedStudentProgramForm";
import useProgramSchedule from "../../hooks/schedule/useProgramsSchedule";
import { Button } from "antd";

export default function AssignedStudentPrograms({
  setOpenModal,
}: {
  setOpenModal: (val: boolean) => void;
}) {
  const { id: program_id } = useParams();
  const { programScheduleByProgramAndCurrentMonth } = useProgramSchedule({
    programId: +program_id!,
  });

  const [selectedSchedules, setSelectedSchedules] = React.useState<
    { id: number; price: number }[]
  >([]);

  const { control, loading, handleSubmit } = useAssignedStudentProgram({
    programId: +program_id!,
    setOpenModal,
    selectedSchedules, // Pasar los schedules seleccionados al hook
  });

  console.log(programScheduleByProgramAndCurrentMonth);
  const diseable = programScheduleByProgramAndCurrentMonth.length  === 0;
  
  return (
    <form onSubmit={handleSubmit}>
      <AssignedStudentProgramForm
        control={control}
        programScheduleByProgram={programScheduleByProgramAndCurrentMonth}
        setSelectedSchedules={setSelectedSchedules} // Pasar la función para actualizar los schedules seleccionados
      />
      <div className="mt-4 flex items-center justify-end">
        <Button
        htmlType="submit"
          style={{background: '#072082', color: 'white', borderRadius: 10, border: 'none', fontWeight: 'bold', padding: '0px 20px', height: 36}}
          loading={loading}
          disabled={diseable}
        >
          <i className="fa-regular fa-floppy-disk" /> Crear
        </Button>
      </div>
    </form>
  );
}
