// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { rankingsService } from "../../shared/services";
import { DataIRankings, IOrder, IProductCategoriesState, IUserResFind } from "../@types";
import { rankingsActions } from "../action-types";

export const rankingsChangeModalState = (): rankingsActions.IRankingsChangeModalState => ({
    type: rankingsActions.RANKINGS_CHANGE_MODAL_STATE
})

export const setRankingsForUpdate = (val: DataIRankings): rankingsActions. IGetRankingsForUpdate => ({
    type: rankingsActions.GET_RANKING_FOR_UPDATE,
    payload: val
})

export const setIsRankingsForUpdate = (val: boolean): rankingsActions.IIsRankingsForUpdate =>({
    type: rankingsActions.IS_RANKING_FOR_UPDATE,
    payload: val
})

export const createRanking = async(data: Partial<DataIRankings>) => {
    return rankingsService.create(data)
    .then((res: DataIRankings) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateRanking = async(data:Partial<DataIRankings>) => {
    return await rankingsService.patch(data?.id!, data)
    .then((res: DataIRankings) => {
        // console.log({res});
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteRanking = async (id: number ) =>{
    return  await rankingsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableRankings =( val: boolean ): rankingsActions.ISwitchRankingsRefreshTable=>({
    type: rankingsActions.SWITCH_RANKINGS_REFRESH_TABLE,
    payload: val
})

export const getUserForRankings = (val: IUserResFind[]): rankingsActions.IGetUsersForRankingSelect =>({
    type: rankingsActions.GET_USERS_FOR_RANKING_SELECT,
    payload: val
})

export const getOrdersForRankings = (val: IOrder[]): rankingsActions.IGetOrdersForRankingSelect =>({
    type: rankingsActions.GET_ORDERS_FOR_RANKING_SELECT,
    payload: val
})

export const getProductsForRankings = (val: IProductCategoriesState[]): rankingsActions.IGetProductsForRankingSelect =>({
    type: rankingsActions.GET_PRODUCTS_FOR_RANKING_SELECT,
    payload: val
})