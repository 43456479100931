"use client";

import React from "react";
import { programsModuleTechniquesAcademy } from "../../../shared/services";
import { ProgramTechniquesType } from "../../../types/dto/program";

export default function useProgramTechniques(topicId: number) {
  const [loading, setLoading] = React.useState(false);
  const [programTechniqueByTopicId, setProgramTechniqueBytopicId] = React.useState<ProgramTechniquesType[]>([]);

  const getSectionProgramTechniquesByTopicId = async () => {
    if (loading || !topicId) return;
    setLoading(true);

    await programsModuleTechniquesAcademy
      .find({
        query: {
          program_module_topic_id: topicId,
        }
      })
      .then((response) => {
        setProgramTechniqueBytopicId(response.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getSectionProgramTechniquesByTopicId();
  }, [topicId]);

  return {
    loading,
    programTechniqueByTopicId,
    getSectionProgramTechniquesByTopicId
  };
}
