import { Controller, type Control } from "react-hook-form";
import { EquipmentsAttributesType, CopyRentEquipmentStudentsAttributesType } from "../../../../../types/dto/equipments";
import { DatePicker, Select, Spin, Input } from "antd";
import { usersService } from "../../../../../shared/services";
import { CaUserAcademy } from "../../../../../types/dto/program";
import useFilterUserByStatus from "../../../../academyProgramDetail/hooks/students/useFilterUserByStatus";
import { IUser } from "../../../../../redux/@types";

export default function CreateEquipmentRentsForm({
  control,
  users,
  equipmentsData,
}: {
  control?: Control<any>;
  users: IUser[];
  equipmentsData: EquipmentsAttributesType[];
}) {
  
  const { setQuery, filteredData } = useFilterUserByStatus<CaUserAcademy>({
    service: usersService,
    initialData: users || [],
    queryKey: 'q',
  });

  return (
    <div className="flex flex-col gap-3">
     
      <div>
      <label>
          <strong>Selecciona un estudiante</strong>
        </label>
        <Controller
          control={control}
          name="user_id"
          render={({ field, fieldState }) => (
            <div>
              <Select
                {...field}
                showSearch
                style={{ width: '100%', height: 30 }}
                placeholder="Selecciona un usuario"
                optionFilterProp="label"
                onSearch={(value) => setQuery(value)}
                filterOption={false}
                notFoundContent={filteredData.length === 0 ? <Spin size="small" style={{ justifyContent: 'center', display: 'flex', padding: 10 }} /> : null}
                options={
                  filteredData.length > 0
                    ? filteredData.map((it) => ({
                        key: `${it.id}`,
                        value: `${it.id}`,
                        label: `${it.first_name} ${it.last_name}`
                      }))
                    : []
                }
              />
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
     

      <div>
      <label>
          <strong>Fecha de retiro</strong>
        </label>
        <Controller
          control={control}
          name="rent_date"
          render={({ field, fieldState }) => (
            <div>
               <DatePicker style={{width: '100%', borderRadius: 5, height:36}} onChange={field.onChange}/>
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
     

      <div>
      <label>
          <strong>Fecha estimada de entrega</strong>
        </label>
        <Controller
          control={control}
          name="return_expected_date"
          render={({ field, fieldState }) => (
            <div>
               <DatePicker style={{width: '100%', borderRadius: 5, height:36}} onChange={field.onChange}/>
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
      <div>
      <label>
          <strong>Cantidad de equipos</strong>
        </label>
        <Controller
          control={control}
          name="quantity"
          render={({ field, fieldState }) => (
            <div>
               <Input style={{width: '100%', borderRadius: 5, height:36}} onChange={field.onChange}/>
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
    
    </div>
  );
}
