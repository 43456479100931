
export const BANNERS_CHANGE_MODAL_STATE = '[ BANNERS ] Banners Change Modal State'

export interface IBannersChangeModalState {
    type: typeof BANNERS_CHANGE_MODAL_STATE
}

export const GET_BANNER_FOR_UPDATE = '[ BANNERS ] Get Banner For Update'

export interface IGetBannersForUpdate {
    type: typeof GET_BANNER_FOR_UPDATE
    payload: any
}

export const IS_BANNER_FOR_UPDATE = '[ BANNERS ] Is Banner For Update'

export interface IIsBannersForUpdate {
    type: typeof IS_BANNER_FOR_UPDATE
    payload: boolean
}

export const SWITCH_BANNERS_REFRESH_TABLE = '[ BANNERS ] Switch Banners Refresh Table'

export interface ISwitchBannersRefreshTable {
    type: typeof SWITCH_BANNERS_REFRESH_TABLE
    payload: boolean
}

export const SWITCH_BANNERS_PRODUCTS_REFRESH_TABLE = '[ BANNERS ] Switch Banners Products Refresh Table'

export interface ISwitchBannersProductsRefreshTable {
    type: typeof SWITCH_BANNERS_PRODUCTS_REFRESH_TABLE
    payload: boolean
}
