// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getOrdersForRankings, getProductsForRankings, getUserForRankings, selectRankingsOrders, selectRankingsProducts, selectRankingsUsers } from "../../../redux";
import { IOrder, IProductCategoriesState, IUserResFind } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { ordersService, productsService, usersService } from "../../../shared/services";

export const useRankingModal = () => {
    const dispatch = useAppDispatch();
    const usersRankings = useSelector(selectRankingsUsers);
    const ordersRankings = useSelector(selectRankingsOrders);
    const productsRankings = useSelector(selectRankingsProducts);
    const servicesRankingsSelectors = async() => {
        await usersService.find({ query: {  $limit: 9999999999999  } }).then(({data}: IUserResFind[]) => dispatch(getUserForRankings(data)))
        await ordersService.find({ query: {  $limit: 9999999999999  } }).then(({data}: IOrder[]) => dispatch(getOrdersForRankings(data)))
        await productsService.find({ query: {  $limit: 9999999999999  } }).then(({data}: IProductCategoriesState[]) => dispatch(getProductsForRankings(data)))
      }

      useEffect(() => {
        if (!usersRankings && !ordersRankings && !productsRankings) {
          servicesRankingsSelectors();
        }
      }, []);

  return {
    usersRankings,
    ordersRankings,
    productsRankings,
  }
}
