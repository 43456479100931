// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { message, TimePicker } from "antd";
import type { Moment } from "moment";
import moment from "moment";
import { useEffect } from "react";
import { Controller, FieldErrorsImpl } from "react-hook-form";

import { InputError } from "./styled";


interface PropsContent {
  errors: Partial<FieldErrorsImpl<{
    [x: string]: any;
  }>>;
  name: string;
}

const ErrorComponent = ({errors,name}:PropsContent) =>{
  useEffect(() => {
    if(errors?.[name]?.message){
      message.error(`${errors?.[name]?.message}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])
  return(
    <>
    {!!errors[name] && (
      <InputError>{errors?.[name]?.message}</InputError>
      )}
      </>
  )
}

interface Props {
  width?: string;
  placeHolder: string;
  name: string;
  control: any;
}

export const InputTimePicker = ({
  width = "100%",
  placeHolder,
  name,
  control,
}: Props) => {
  const onChange = (time: Moment, timeString: string) => {
    console.log(time, timeString);
  };
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name}
      render={({
        field: { onChange, value = "00:00:00" },
        formState: { errors },
      }) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width,
              height: "80%",
              flexDirection: "column",
            }}
          >
            <TimePicker
              style={{ width: "100%" }}
              status={errors[name] ? "error" : ""}
              placeholder={placeHolder}
              onChange={(time: Moment, timeString: string) =>
                onChange(timeString)
              }
              value={moment(value, "HH:mm:ss")}
            />
            <ErrorComponent errors={errors} name={name}/>
          </div>
        );
      }}
    />
  );
};
