import * as yup from 'yup'

export const validateUpdateWorkOffers= yup.object().shape({
    path_cover:yup
    .string()
    .min(2, 'La IMAGEN debe tener más de 2 caracteres'),
    job:yup
    .string()
    .min(2, 'El TRABAJO debe tener más de 2 caracteres'),
    category_id:yup
    .number().typeError('Campo requerido.')
    .positive(),
    city_id:yup
    .number().typeError('Campo requerido.')
    .positive(),
    address: yup
    .string()
    .min(7, 'El DIRECCIÓN debe tener más de 6 caracteres'),
    position:yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    company_name:yup
    .string()
    .min(2, 'La COMPAÑÍA debe tener más de 2 caracteres'),
    salary:yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    job_type:yup
    .string()
    .oneOf(['tiempo_completo', 'medio_tiempo', 'trabajo_independiente'], 'Debes elegir entre estas opciones'),
    description:yup
    .string()
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    requirements:yup
    .string()
    .min(2, 'LOS REQUERIMIENTOS debe tener más de 2 caracteres'),
    responsibilities:yup
    .string()
    .min(2, 'LAS RESPONSABILIDADES debe tener más de 2 caracteres'),
    status:yup
    .string()
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
})