import { addressesUsersService } from "../../shared/services";
import { Datum, IAddressesUsersFind, ICitiesRes, IStatesRes } from "../@types";
import { addressesUsersActions } from "../action-types";

export const addressesUsersChangeModalState = (): addressesUsersActions.IAddressesUsersChangeModalState =>({
    type: addressesUsersActions.ADDRESSES_USERS_CHANGE_MODAL_STATE
})

export const setAddressesUserForUpdate = (val: IAddressesUsersFind ): addressesUsersActions.IGetAddressesUsersForUpdate => ({
    type: addressesUsersActions.GET_ADDRESSES_USERS_FOR_UPDATE,
    payload: val
})

export const setIsAddressesUserForUpdate = (val: boolean ): addressesUsersActions.IIsAddressesUsersForUpdate =>({
    type: addressesUsersActions.IS_ADDRESSES_USERS_FOR_UPDATE,
    payload: val
})

export const createAddressesUser =async( data: Partial<IAddressesUsersFind> ) => {
    return await addressesUsersService.create(data)
    .then((res: IAddressesUsersFind) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateAddressesUser = async(data:Partial<IAddressesUsersFind>) => {
    return await addressesUsersService.patch(data?.id!, data)
    .then((res: any) => {
        // console.log({res});
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteAddressesUser = async (id: number ) =>{
    return  await addressesUsersService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableAddressesUser =( val: boolean ): addressesUsersActions.ISwitchAddressesUsersRefreshTable =>({
    type: addressesUsersActions.SWITCH_ADDRESSES_USERS_REFRESH_TABLE,
    payload: val
})

export const getStates =( val: Datum[] ): addressesUsersActions.IGetStatesAddressesUsers => ({
    type: addressesUsersActions.GET_STATES_ADDRESSES_USERS,
    payload: val
})

export const getCities =( val: ICitiesRes[] ): addressesUsersActions.IGetCitiesAddressesUsers => ({
    type: addressesUsersActions.GET_CITIES_ADDRESSES_USERS,
    payload: val
})