import useAppDispatch from "../../../redux/hooks/useAppDispatch";
// import { useFulfillmentCompany } from "./useFulfillmentCompany";
import { DataFulfillmentCompany } from "../../../redux/@types";
import {
  setIsFulfillmentCompanyForUpdate,
  setFulfillmentCompanyForUpdate,
  fulfillmentCompanyChangeModalState,
} from "../../../redux";

export const useTableFulfillmentCompany = () => {
  const dispatch = useAppDispatch();
  // const { tableProps } = useFulfillmentCompany();

  const changeFulfillmentCompanyForUpdate = (val: DataFulfillmentCompany) => {
    dispatch(setIsFulfillmentCompanyForUpdate(true));
    dispatch(setFulfillmentCompanyForUpdate(val));
    dispatch(fulfillmentCompanyChangeModalState());
  };

  return {
    //state
    //methods
    //functions
    changeFulfillmentCompanyForUpdate,
  };
};
