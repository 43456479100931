import { workOffersService } from "../../shared/services"
import { DataWorkOffers, IDataForUpdateWorkOffers, DataWorkOffersCategories, DataCities } from "../@types"
import { workOffersActions } from "../action-types"

export const workOffersChangeModalState = (): workOffersActions.IWorkOffersModalState =>({
    type: workOffersActions.WORK_OFFERS_MODAL_STATE
})

export const setWorkOffersForUpdate = (val: IDataForUpdateWorkOffers): workOffersActions.IGetWorkOffersForUpdate =>({
    type: workOffersActions.GET_WORK_OFFERS_FOR_UPDATE,
    payload: val
})

export const setIsWorkOffersForUpdate = (val: boolean): workOffersActions.IIsWorkOffersForUpdate =>({
    type: workOffersActions.IS_WORK_OFFERS_FOR_UPDATE,
    payload: val
})

export const createWorkOffers = async(data: Partial<DataWorkOffers>) =>{
    return await workOffersService.create(data)
    .then((res: DataWorkOffers) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateWorkOffers =async(data: Partial<DataWorkOffers>) =>{
    return await workOffersService.patch(data?.id!, data)
    .then((res: DataWorkOffers) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteWorkOffers = async(id: number) => {
    return await workOffersService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableWorkOffers = (val: boolean): workOffersActions.ISwitchWorkOffersRefreshTable =>({
    type: workOffersActions.SWITCH_WORK_OFFERS_REFRESH_TABLE,
    payload: val
})

export const getCategoriesForWorkOffers = (val: DataWorkOffersCategories[]): workOffersActions.IGetCategoriesWorkOffers =>({
    type: workOffersActions.GET_CATEGORIES_WORK_OFFERS,
    payload: val
})

export const getCitiesForWorkOffers = (val: DataCities[]): workOffersActions.IGetCitiesWorkOffers =>({
    type: workOffersActions.GET_CITIES_WORK_OFFERS,
    payload: val
})
