import type {DragEndEvent, DragStartEvent, UniqueIdentifier} from "@dnd-kit/core";

import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React from "react";

import Item from "./Item";
import SortableItem from "./SortableItem";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SortItem = Record<string, any>;

export default function Context<T extends SortItem = SortItem>({
  items,
  id,
  key,
  customActivator,
  onSort,
  renderItem,
}: {
  items: T[];
  key: keyof T;
  id: keyof T;
  customActivator?: boolean;
  onSort?: (items: T[]) => void;
  renderItem: (item: T, props?: {activeId: UniqueIdentifier | null}) => React.ReactNode;
}) {
  const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null);

  const cId = React.useId();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event: DragStartEvent) => {
    const {active} = event;

    setActiveId(active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;

    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item[id] == active.id);
      const newIndex = items.findIndex((item) => item[id] == over?.id);

      const sortedItems = arrayMove([...items], oldIndex, newIndex);

      onSort?.(sortedItems);
    }

    setActiveId(null);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext
        items={items.map((item) => item[id] as UniqueIdentifier)}
        strategy={verticalListSortingStrategy}
      >
        {items.map((item) => (
          <SortableItem key={`${cId}-${item[key]}`} customActivator={customActivator} id={item[id]}>
            {renderItem(item, {activeId})}
          </SortableItem>
        ))}

        <DragOverlay>
          {activeId ? <Item>{renderItem(items.find((item) => item[id] == activeId)!)}</Item> : null}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
}
