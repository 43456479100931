import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";

import { useContactsDirectory } from "../hook";
import { selectModalContactsDirectory } from "../../../redux";
import {
  AdminMenuLayout,
  ModalContactsDirectory,
} from "../../../shared/components";
import { WrapperCardContactsDirectory } from "./styled";
import { TableContactsDirectory } from "./TableContactsDirectory";

export const ContactsDirectory = () => {
  const modalContactsDirectory = useSelector(selectModalContactsDirectory);
  const {
    isUpdateContactsDirectory,
    formMethodsCreate,
    changeModalContactsDirectoryState,
  } = useContactsDirectory();

  return (
    <AdminMenuLayout
      pageDescription="ContactsDirectory"
      title="ContactsDirectory"
    >
      <WrapperCardContactsDirectory>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalContactsDirectoryState}
            >
              Agregar directorio
            </Button>
          </Col>
        </Row>
        <TableContactsDirectory />
        {modalContactsDirectory && !isUpdateContactsDirectory && (
          <FormProvider {...formMethodsCreate}>
            <ModalContactsDirectory />
          </FormProvider>
        )}
      </WrapperCardContactsDirectory>
    </AdminMenuLayout>
  );
};
