import { TableColumns } from "../../../../shared/components/ui/Table/types";
import { ProgramPaymentType } from "../../../../types/dto/program";
import Table from "../../../../shared/components/ui/Table/prueba";
import { programPaymenAcademy } from "../../../../shared/services";
import {  Col, Typography } from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";

const { Text } = Typography;

export const TablePersonalInfoStudentPayment = () => {
  const {user_id} = useParams()
  const columns: TableColumns<ProgramPaymentType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 1,
      filterType: "filterByID",
    },
    {
      title: "Programa",
      dataIndex: ["program", "name"],
      key: "program",
      width: 3,
    },
    {
      title: "Fecha de cobro del programa",
      dataIndex: ["payment_date"],
      key: "payment_date",
      width: 3,
      render: (item)=>{
        
        return (
          <Col
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
          }}
        >
          <Text>
            {moment(item?.program_schedule?.month).format('YYYY / MMM')}
          </Text>
        </Col>
        )
      }
    },
    {
      title: "Fecha de pago del estudiante",
      dataIndex: ["payment_date"],
      key: "payment_date",
      width: 3,
      render: (item)=>{
        return (
          <Col
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
          }}
        >
          <Text >
            {`${moment(item?.payment_date).format('YYYY/MM/DD')}`}
          </Text>
        </Col>
        )
      }
    },
    {
      title: "Monto Pagado",
      dataIndex: ["amount"],
      key: "program_module",
      width: 2,
      render: (item)=>{
        const price = Number(item);
        const amount =  price.toLocaleString('es-CO', {
          style: 'currency',
          currency: 'COP',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        return (
          <Col
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
          }}
        >
          <Text >
            {amount}
          </Text>
        </Col>
        )
      }
    },
  ];

  return (
    <>
      <Table<ProgramPaymentType>
        columns={columns}
        scroll={{}}
        fetchQueryProps={{
          user_id: user_id, 
          $sort: { id: -1 },
        }}
        service={programPaymenAcademy}
      />
    </>
  );
};
