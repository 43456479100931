"use client";

import React from "react";
import { Resolver, useForm, UseFormHandleSubmit } from "react-hook-form";
import { message } from "antd";
import { ProgramStudentModuleTechniqueType } from "../../../../types/dto/program";
import { academyEquipmentsService } from "../../../../shared/services";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { EquipmentsAttributesType } from "../../../../types/dto/equipments";
export interface UseCreateEquipmentProps {
  setOpenModal: (val: boolean) => void;
}

export default function useCreateEquipment({
  setOpenModal,
}: UseCreateEquipmentProps) {
  const [loading, setLoading] = React.useState(false);

  const { control, handleSubmit: onSubmit } = useForm<EquipmentsAttributesType>({
    resolver: yupResolver(
      yup.object({
      
      })
    ) as unknown as Resolver<EquipmentsAttributesType>,
  });
  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    await academyEquipmentsService.create({...data, stock: +data.stock!, quantity_available: +data.stock! })
      .then((response) => {
        message.success("Se ha registrado exitosamente la califición");
        setOpenModal(false);
      })
      .catch((err: any) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    loading,
    control,
    handleSubmit,
  };
}
