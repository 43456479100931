import { TRootState } from '../@types/redux';

export const selectOrderForUpdate = ( state: TRootState ) => state.orders.orderForUpdate

export const selectOrderModalIsOpen = ( state: TRootState ) => state.orders.orderModalIsOpen

export const selectOrderShippedForUpdate = ( state: TRootState ) => state.orders.orderShippedForUpdate

export const selectOrderShippedModalIsOpen = ( state: TRootState ) => state.orders.orderShippedModalIsOpen

export const selectOrderPaymentConfirmations = ( state: TRootState ) => state.orders.orderPaymentConfirmations

export const selectOrderShipping = ( state: TRootState ) => state.orders.orderShipping

export const selectOrderShippingID = ( state: TRootState ) => state.orders.shippingIDForAdd
