import { walletMovementsService } from "../../shared/services"
import { DataWalletMovements, IUserResFind } from "../@types"
import { walletMovementsActions } from "../action-types"

export const walletMovementsChangeModalState = (): walletMovementsActions.IWalletMovementsModalState =>({
    type: walletMovementsActions.WALLET_MOVEMENTS_MODAL_STATE
})

export const setWalletMovementsForUpdate = (val: DataWalletMovements): walletMovementsActions.IGetWalletMovementsForUpdate =>({
    type: walletMovementsActions.GET_WALLET_MOVEMENTS_FOR_UPDATE,
    payload: val
})

export const setIsWalletMovementsForUpdate = (val: boolean): walletMovementsActions.IIsWalletMovementsForUpdate =>({
    type: walletMovementsActions.IS_WALLET_MOVEMENTS_FOR_UPDATE,
    payload: val
})

export const createWalletMovements = async(data: Partial<DataWalletMovements>) =>{
    return await walletMovementsService.create(data)
    .then((res: DataWalletMovements) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateWalletMovements =async(data: Partial<DataWalletMovements>) =>{
    return await walletMovementsService.patch(data?.id!, data)
    .then((res: DataWalletMovements) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableWalletMovements = (val: boolean): walletMovementsActions.ISwitchWalletMovementsRefreshTable =>({
    type: walletMovementsActions.SWITCH_WALLET_MOVEMENTS_REFRESH_TABLE,
    payload: val
})

export const getUsersForWalletMovements = (val: IUserResFind[]): walletMovementsActions.IGetUsersWalletMovements=>({
    type: walletMovementsActions.GET_USERS_WALLET_MOVEMENTS,
    payload: val
})

export const getObjUsersForWalletMovements = (val: { [key: string]: string; }): walletMovementsActions.IGetUsersObjWalletMovements =>({
    type: walletMovementsActions.GET_USERS_OBJ_WALLET_MOVEMENTS,
    payload: val
})