import { FormProvider } from "react-hook-form";

import { useWorkOffers, useWorkOffersById, WorkOffersById } from "../modules";

export const WorkOffersByIdPage = () => {
  const { isValidate } = useWorkOffersById();
  const { isUpdateWorkOffers, formMethodsCreate, formMethodsUpdate } =
    useWorkOffers();
  return (
    <>
      {isValidate && !isUpdateWorkOffers && (
        <FormProvider {...formMethodsCreate}>
          <WorkOffersById />
        </FormProvider>
      )}
      {isValidate && isUpdateWorkOffers && (
        <FormProvider {...formMethodsUpdate}>
          <WorkOffersById />
        </FormProvider>
      )}
    </>
  );
};
