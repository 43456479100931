import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useCities } from "./useCities";
import { DataCities } from "../../../redux/@types/cities";
import {
  citiesChangeModalState,
  deleteCity,
  refreshTableCities,
} from "../../../redux/actions/cities.actions";
import {
  setIsCityForUpdate,
  setCityForUpdate,
} from "../../../redux/actions/cities.actions";

const { confirm } = Modal;

export const useTableCities = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useCities();

  const changeCityForUpdate = (val: DataCities) => {
    dispatch(setIsCityForUpdate(true));
    dispatch(setCityForUpdate(val));
    dispatch(citiesChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la Ciudad con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar ciudad" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar ciudad",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteCity(valId)
          .then(() => {
            dispatch(refreshTableCities(true));
            message.success(
              `Se ha borrado con éxito la ciudad con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeCityForUpdate,
    showDeleteConfirm,
  };
};
