import AcademyEquipmentRents from "../modules/AcademyEquipmentRents/AcademyEquipmentRents";
import { AdminMenuLayout } from "../shared/components";

export default function AcademyEquipmentsRentsPage() {
  return (
    <AdminMenuLayout title="" pageDescription="">
        <AcademyEquipmentRents />
    </AdminMenuLayout>
  );
}
