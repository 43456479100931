import { Avatar, Button, Col, Typography } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { programsAcademy } from "../../../../shared/services";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import Table from "../../../../shared/components/ui/Table";
import { ProgramType } from "../../../../types/dto/program";
import { useNavigate } from "react-router-dom";
import { ModalEditProgram } from "../../../../shared/components/ui/modals/ModalEditProgram";
import React from "react";
import useDeletePrograms from "../../hooks/programs/useDeletePrograms";
import { formatNumberPrice } from "../../../../shared/utils/Helper";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux";

const { Title } = Typography;

interface TableProgramProps {
  program_id?: number;
}

export const TableProgram = ({ program_id }: TableProgramProps) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [programId, setProgramId] = React.useState<number>();
  const { showDeleteConfirm } = useDeletePrograms();
  const openModalEdit = (id: number) => {
    setOpenModal(true);
    setProgramId(id);
  };
  const user = useSelector(selectUser);

  const redirectDetailProgram = (id: number) => {
    navigate(`${`/gestion-academy/programs/details/${id}`}`);
  };
  
  const columns: TableColumns<ProgramType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 1,
      filterType: "filterByID",
    },
    {
      title: "Valor del programa",
      width: 2,
      dataIndex: 'price',
      render: (_, item) => {
        return (
          <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.image_path}`} />
        </Col>
        );
      },
    },
    {
      title: "Nombre del programa",
      dataIndex:'name',
      width: 2,
      filterType: "text",
    },
    {
      title: "Valor del programa",
      width: 2,
      dataIndex: 'price',
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <p >
              {formatNumberPrice(item.price)}
            </p>
          </Col>
        );
      },
    },
    {
      title: "Estado",
      width: 2,
      dataIndex:'status',
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >

            <Title
              style={{
                fontSize: "0.74rem", fontWeight: "500",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
               level={5}>
              {item?.status === 'active' ? 'Activo' : 'Inactivo' || 'N/A'}
            </Title>
          </Col>
        );
      },
      filters: [
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Inactivo",
          value: "inactive",
        },
      ],
    },
    {
      title: "Ciudad",
      width: 2,
      dataIndex:'city',
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >

            <Title
              style={{
                fontSize: "0.74rem", fontWeight: "500",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
              }}
               level={5}>
              {item?.city ? item?.city : 'Sin asignar ciudad'}
            </Title>
          </Col>
        );
      },
      filterType: "text",
    },
    {
      title: "Acciones",
      key: "operation",
      width: 2,
      fixed: "right",
      render: (_, item: ProgramType) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {
              user?.role !== 'teacher' && (
                <Button
                type="primary"
                shape="circle"
                onClick={() => {
                  openModalEdit(item.id);
                }}
                icon={<EditOutlined />}
                size="large"
              />
              )
            }
           
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                redirectDetailProgram(item.id);
              }}
              icon={<EyeOutlined />}
              size="large"
            />
            {
              user?.role !== 'teacher' && (
                <Button
                type="primary"
                shape="circle"
                danger
                onClick={() => {
                  showDeleteConfirm(item.id, "");
                }}
                icon={<DeleteOutlined />}
                size="large"
              />
            )
            }
          </Col>
        );
      },
    },
  ];

  return (
    <>
      <ModalEditProgram
        id={+programId!}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <Table<ProgramType>
        scroll={{}}
        columns={columns}
        fetchQueryProps={{
          $sort: { id: -1 },
        }}
        service={programsAcademy}
        //onLoad={setTableProps}
      />
    </>
  );
};
