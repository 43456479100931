import type {DraggableAttributes} from "@dnd-kit/core";
import type {ForwardedRef} from "react";
import type {ReactNode} from "react";

import React, {forwardRef} from "react";

type ItemProps = Partial<DraggableAttributes> &
  React.HTMLAttributes<HTMLDivElement> & {
    children: ReactNode;
  };

const Item = forwardRef<HTMLDivElement, ItemProps>((props, ref: ForwardedRef<HTMLDivElement>) => {
  const {children, ...rest} = props;

  return (
    <div {...rest} ref={ref}>
      {children}
    </div>
  );
});

Item.displayName = "Item";

export default Item;
