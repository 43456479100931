"use client";

import React from "react";
import { message, Modal } from "antd";

import { programsStudentAcademy } from "../../../../shared/services";
import { ProgramType } from "../../../../types/dto/program";

const { confirm } = Modal;

export interface UseDeleteLevelProps {
  onSuccess?: (programId: ProgramType) => void;
}

export default function useDeleteStudents(
) {
  const [loading, setLoading] = React.useState(false);

  const showDeleteConfirm = (valId: number, val: string) => {
    if (!valId ) {
      message.error("programación de pago no válido");
      return;
    }

    confirm({
      title: `Estás seguro de borrar al estudiante del programa con el id: ${valId}`,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar al estudiante del programa" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar al estudiante del programa",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        setLoading(true);
        try {
           await programsStudentAcademy.remove(valId).then(()=>
            message.success(`Se ha borrado con éxito al estudiante del programa con id:${valId}`)
          );
        } catch (err) {
          message.error("Error al borrar al estudiante del programa.");
        } finally {
          setLoading(false);
        }
      },
      onCancel() {},
    });
  };

  return { showDeleteConfirm, loading };
}
