import { dataProducsCharac, IProductCharacteristicsState } from "../@types";
import { productCharacteristicsActions } from "../action-types";

type productCharacteristicsActionType =
| { type: typeof productCharacteristicsActions.PRODUCT_CHARACTERISTICS_MODAL_STATE }
| { type: typeof productCharacteristicsActions.GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE; payload: dataProducsCharac }
| { type: typeof productCharacteristicsActions.IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE; payload: boolean }
| { type: typeof productCharacteristicsActions.SWITCH_PRODUCT_CHARACTERISTICS_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: IProductCharacteristicsState = {
    productCharacteristicForUpdate: undefined,
    isUpdateProductCharacteristic: false,
    productCharacteristicModalIsOpen: false,
    refreshTableProductCharacteristic: false,
}

export default function productCharacteristicsReducer(state: IProductCharacteristicsState = INITIAL_STATE, action: productCharacteristicsActionType ): IProductCharacteristicsState {
    switch(action.type){
        case productCharacteristicsActions.PRODUCT_CHARACTERISTICS_MODAL_STATE:
            return{
                ...state,
                productCharacteristicModalIsOpen: !state.productCharacteristicModalIsOpen,
            }
        case productCharacteristicsActions.GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE:
            return{
                ...state,
                productCharacteristicForUpdate: action.payload
            }
        case productCharacteristicsActions.IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE:
            return{
                ...state,
                isUpdateProductCharacteristic: action.payload
            }
        case productCharacteristicsActions.SWITCH_PRODUCT_CHARACTERISTICS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableProductCharacteristic: action.payload
            }
        default:
            return state
    }
}