import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  workOffersCategoriesChangeModalState,
  deleteWorkOffersCategories,
  refreshTableWorkOffersCategories,
  setWorkOffersCategoriesForUpdate,
  setIsWorkOffersCategoriesForUpdate,
} from "../../../redux";
import { DataWorkOffersCategories } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useWorkOffersCategories } from "./useWorkOffersCategories";

const { confirm } = Modal;

export const useTableWorkOffersCategories = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useWorkOffersCategories();

  const changeWorkOffersCategoriesForUpdate = (
    val: DataWorkOffersCategories
  ) => {
    dispatch(setIsWorkOffersCategoriesForUpdate(true));
    dispatch(setWorkOffersCategoriesForUpdate(val));
    dispatch(workOffersCategoriesChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la Categoría de trabajo con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar categoría" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar categoría",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteWorkOffersCategories(valId)
          .then(() => {
            dispatch(refreshTableWorkOffersCategories(true));
            message.success(
              `Se ha borrado con éxito la Categoría de trabajo con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeWorkOffersCategoriesForUpdate,
    showDeleteConfirm,
  };
};
