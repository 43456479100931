import { Drawer } from "antd";
import EditEquipmentRents from "../../../../modules/AcademyEquipmentRents/ui/equipmentRents/form/EditEquipmentRents";

export const ModalEditEquipmentRents = ({openModal, setOpenModal, equipmentRentById}: {openModal:boolean, setOpenModal: (value: boolean)=>void, equipmentRentById: number}) => {
  return (
    <Drawer
      title={`Actualiza un equipo rentado`}
      open={openModal}
      width={"320px"}
      destroyOnClose
      onClose={()=>setOpenModal(false)}
    >
      <EditEquipmentRents setOpenModal={setOpenModal} equipmentRentById={equipmentRentById} />
    </Drawer>
  );
};
