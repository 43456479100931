import { Col, Row, Spin, Tag, Typography } from "antd";
import { useSelector } from "react-redux";
import { selectOrderModalIsOpen } from "../../../../redux/selectors/orders.selectors";
import { AdminMenuLayout, ModalGenerate } from "../../../../shared/components";
import { useOrderById, useValidateSingleOrder } from "../../hooks/orderById";
import { WrapperOrderById, WrapperOrderByIdSpin } from "../styled";
import { InfoOrderDetails } from "./InfoOrderDetails";
import { InfoUserCard } from "./InfoUserCard";
import { PaymentConfirmation } from "./PaymentConfirmation";
import { Shippings } from "./Shippings";
import { TableOrderById } from "./TableOrderById";
import { decimal } from "../../../../shared/utils/Helper";

const { Title } = Typography;

export const OrderById = () => {
  const { orderForUpdate, changeOrderModal } = useOrderById();
  const { isValidate } = useValidateSingleOrder();
  const orderModalIsOpen = useSelector(selectOrderModalIsOpen);
  // console.log({ orderForUpdate });

  return (
    <AdminMenuLayout pageDescription="OrderById" title="OrderById">
      {!isValidate && (
        <WrapperOrderByIdSpin>
          <Spin size="large" />
        </WrapperOrderByIdSpin>
      )}
      {isValidate && (
        <WrapperOrderById>
          <Row justify="start" style={{ width: "100%" }} gutter={[8, 8]}>
            <Col
              span={24}
              style={{
                width: "100%",
                display: "flex",
                // padding: 5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "row",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "var(--primary)",
                  marginRight: 7,
                }}
                level={2}
              >
                Pedido # {orderForUpdate?.id}
              </Title>
              {orderForUpdate?.payment_method && (
                <Tag style={{ fontSize: 24, padding: 8, borderRadius: 5 }}>
                  Método de pago: {orderForUpdate?.payment_method}
                </Tag>
              )}
              {orderForUpdate?.sources && (
                <Tag style={{ fontSize: 14, padding: 8, borderRadius: 5 }}>
                  Comprado por: <b>{orderForUpdate?.sources === 'webApp' ? 'Pagina Web' : 'App Mobile'}</b>
                </Tag>
              )}
            </Col>
            <Col
              span={24}
              style={{
                width: "100%",
                display: "flex",
                // padding: 5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "row",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "var(--primary)",
                  marginRight: 7,
                }}
                level={3}
              >
                {orderForUpdate?.integration_id && (
                  <>Integration Id # {orderForUpdate?.integration_id}</>
                )}
              </Title>
            </Col>
            <Col
              span={12}
              style={{
                width: "100%",
                display: "flex",
                margin: "auto",
                // padding: 2.5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "var(--primary)",
                }}
                level={3}
              >
                Estado del pedido
              </Title>
              {orderForUpdate?.history && (
                <TableOrderById
                  data={orderForUpdate?.history}
                  userName={`${orderForUpdate?.user?.first_name} ${orderForUpdate?.user?.last_name}`}
                />
              )}
            </Col>
            <Col
              span={12}
              style={{
                width: "100%",
                display: "flex",
                margin: "auto",
                // padding: 2.5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "var(--primary)",
                }}
                level={3}
              >
                Datos del cliente
              </Title>
              {orderForUpdate?.shipping_address_meta_data && (
                <InfoUserCard
                  shipping={JSON.parse(
                    orderForUpdate?.shipping_address_meta_data
                  )}
                  dataUser={orderForUpdate?.user}
                />
              )}
            </Col>
            <Col
              span={24}
              style={{
                width: "100%",
                display: "flex",
                margin: "auto",
                // padding: 2.5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{
                  marginBottom: "3.5px",
                  color: "var(--primary)",
                }}
                level={3}
              >
                Detalles del Pedido
              </Title>
              {orderForUpdate?.order_details && (
                <InfoOrderDetails data={orderForUpdate?.order_details} />
              )}
            </Col>
            <Col
              span={12}
              style={{
                width: "100%",
                display: "flex",
                // margin: "auto",
                // padding: 2.5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                boxShadow: '0px 0px 8px #6767675c',
                borderRadius: 20,
                marginTop: 20,
              }}
            >
              <div style={{padding: 20}}>
              <Title
                style={{
                  marginBottom: "3.5px",
                  color: "var(--primary)",
                }}
                level={3}
              >
                Resumen de compra
              </Title>
              <div>
              {orderForUpdate?.discount_total_amount ? <><b>Total Discount Code:</b> - ${decimal(orderForUpdate?.discount_total_amount)}<br/></> : null}
              <b>Total Productos:</b> ${decimal(orderForUpdate?.total_price_shipping_cost_excl)}<br/>
              <b>Total Envío:</b> ${decimal(orderForUpdate?.total_shipping_cost)}<br/>
              {orderForUpdate?.amount_paid_from_wallet ? <><b>Total Pagado con Wallet (Cashback):</b> - ${decimal(orderForUpdate?.amount_paid_from_wallet)}<br/></> : null}
              {orderForUpdate?.amount_paid_from_wallet ? <><b>Total a pagar:</b> ${decimal(orderForUpdate?.total_price - orderForUpdate?.amount_paid_from_wallet)}<br/></> : null}
              <b>Total:</b> ${decimal(orderForUpdate?.total_price)}
              </div>
              </div>
            </Col>
            <Col
              span={24}
              style={{
                width: "100%",
                display: "flex",
                margin: "auto",
                // padding: 2.5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{ marginBottom: "3.5px", color: "var(--primary)" }}
                level={3}
              >
                Confirmación de Pagos
              </Title>
              {orderForUpdate?.order_details && <PaymentConfirmation />}
            </Col>
            <Col
              span={24}
              style={{
                width: "100%",
                display: "flex",
                margin: "auto",
                // padding: 2.5,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{ marginBottom: "3.5px", color: "var(--primary)" }}
                level={3}
              >
                Método de envío escogido por el cliente:{" "}
                {`${orderForUpdate?.fulfillment_company.name}`}
              </Title>
              <Title
                style={{ marginBottom: "3.5px", color: "var(--primary)" }}
                level={3}
              >
                Envíos
              </Title>
              {orderForUpdate?.order_details && <Shippings />}
            </Col>
          </Row>
        </WrapperOrderById>
      )}
      {orderModalIsOpen && (
        <ModalGenerate
          // height={"50%"}
          width={"calc(89% - 20px)"}
          title={"Generar Envío"}
          onCancel={changeOrderModal}
        />
      )}
    </AdminMenuLayout>
  );
};
