import { Col, Typography, Button } from "antd";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import { ProgramStudentModuleTechniqueType } from "../../../../types/dto/program";
import Table from "../../../../shared/components/ui/Table/prueba";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import React from "react";
import { WrapperModalQualificationStudent } from "../styled";
import EditStudentQualification from "./EditStudentQualification";
import { programsStudentModuleTechniqueAcademy } from "../../../../shared/services";
import { useParams } from "react-router-dom";
import useDeleteQualificationStudent from "../../hooks/qualifications/useDeleteQualificationStudent";

const { Text } = Typography;

export const TableListProgramQualification = () => {
  const {user_id} = useParams()
    const [openModalEditQualification, setOpenModalEditQualification] =
      React.useState<boolean>(false);
    const [techeniqueId, setTecheniqueId] = React.useState<number>();

    const { showDeleteConfirm } = useDeleteQualificationStudent();
  
    const handleOpemModalEdit = (value: number) => {
      setTecheniqueId(value);
      setOpenModalEditQualification(true);
    };
    
    const columns: TableColumns<ProgramStudentModuleTechniqueType> = [
      {
        title: "ID",
        dataIndex: "id",
        width: 3,
        key: "id",
        filterType: "filterByID",
      },
      {
        title: "Programa",
        width: 5,
        dataIndex: ["program_module_technique", "program_module_topic", "program_module", "program", "name"],
        key: "id",
      },
      {
        title: "Modulos",
        width: 5,
        dataIndex: ["program_module_technique", "program_module_topic", "program_module", "name"],
        key: "id",
      },
      {
        title: "Tema",
        width: 5,
        dataIndex: ["program_module_technique", "program_module_topic", "name"],
        key: "topic_name",
      },
      {
        title: "Tecnica",
        width: 5,
        dataIndex: ["program_module_technique", 'name'],
        key: "technique_name",
      },
      {
        title: "Calificación estado",
        width: 5,
        dataIndex: "qualification_check",
        key: "qualification_check",
        render: (_, item) => {
          const bgText =
          (item.qualification_check === 'approved' && "#4bd542") ||
          (item.qualification_check === 'disapproved' && "#ff4d4f");
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent:'center'
              }}
            >
              <Text
              style={{
                textAlign: 'center',
                background: String(bgText),
                padding: 8,
                borderRadius: 20,
              }}
            >
                {item.qualification_check === 'approved' && "Aprobado"}
                {item.qualification_check === 'disapproved' && "Reprobado"}
            </Text>
            </Col>
          );
        },
      },
      {
        title: "Calificación",
        width: 5,
        dataIndex: "qualification",
        key: "qualification",
        render: (_, item) => {
      
          return (
            <Col
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent:'center'
              }}
            >
              <Text >
                {item.qualification}
              </Text>
            </Col>
          );
        },
      },
      {
        title: "Acciones",
        key: "operation",
        width: 5,
        fixed: "right",
        render: (_, item) => {
          return (
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                gap: 15,
              }}
            >
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="large"
                onClick={() => handleOpemModalEdit(item.id)}
              />
              <Button
                type="primary"
                danger
                shape="circle"
                icon={<DeleteOutlined />}
                size="large"
                onClick={() =>
                  showDeleteConfirm(item.id, item.program_module_technique.name)
                }
              />
            </Col>
          );
        },
      },
    ];

    return (
      <>
        <WrapperModalQualificationStudent
          title={`Editar calificacion del estudiante`}
          open={openModalEditQualification}
          width={"320px"}
          destroyOnClose
          onClose={() => setOpenModalEditQualification(false)}
        >
          <EditStudentQualification
            setOpenModal={setOpenModalEditQualification}
            techeniqueId={+techeniqueId!}
          />
        </WrapperModalQualificationStudent>
        <Table<ProgramStudentModuleTechniqueType>
          columns={columns}
          fetchQueryProps={{
            user_id: +user_id!,
            $sort: { id: -1 },
          }}
          service={programsStudentModuleTechniqueAcademy}
        />
      </>
    );
  };
