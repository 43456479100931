
import { DataSystemVariables, ISystemVariablesState } from "../@types";
import { systemVariablesActions } from "../action-types";


type systemVariablesType =
| { type: typeof systemVariablesActions.SYSTEM_VARIABLES_MODAL_STATE }
| { type: typeof systemVariablesActions.GET_SYSTEM_VARIABLES_FOR_UPDATE; payload: DataSystemVariables }
| { type: typeof systemVariablesActions.IS_SYSTEM_VARIABLES_FOR_UPDATE; payload: boolean }
| { type: typeof systemVariablesActions.SWITCH_SYSTEM_VARIABLES_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: ISystemVariablesState ={
    systemVariablesForUpdate: undefined,
    isUpdateSystemVariables: false,
    systemVariablesModalIsOpen: false,
    refreshTableSystemVariables: false,
}

export default function systemVariablesReducer(state: ISystemVariablesState = INITIAL_STATE, action: systemVariablesType): ISystemVariablesState {
    switch(action.type){
        case systemVariablesActions.SYSTEM_VARIABLES_MODAL_STATE:
            return{
                ...state,
                systemVariablesModalIsOpen: !state.systemVariablesModalIsOpen
            }
        case systemVariablesActions.GET_SYSTEM_VARIABLES_FOR_UPDATE:
            return{
                ...state,
                systemVariablesForUpdate: action.payload
            }
        case systemVariablesActions.IS_SYSTEM_VARIABLES_FOR_UPDATE:
            return{
                ...state,
                isUpdateSystemVariables: action.payload
            }
        case systemVariablesActions.SWITCH_SYSTEM_VARIABLES_REFRESH_TABLE:
            return{
                ...state,
                refreshTableSystemVariables: action.payload
            }
        default:
            return state
    }
}