// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { message } from "antd";
import { useState, useEffect } from "react";

import { IProductsFind } from "../../../../redux/@types";
import { productsService } from "../../../../shared/services";

interface Props {
  dataFilter: Number[];
}

export const useModalPriceLis = ({ dataFilter }: Props) => {
  const [products, setProducts] = useState<
    Array<{ value: string; title: string }>
  >([]);
  const [valueSelector, setValueSelector] = useState<string | undefined>(
    undefined
  );
  const [valueSelectorName, setValueSelectorName] = useState<
    string | undefined
  >(undefined);
  const [valueNumber, setValueNumber] = useState<number | undefined>(undefined);

  const changeSelectValue = (val: string) => {
    setValueSelector(val);
    setValueSelectorName(products.find((item) => item.value === val).title);
  };

  const getProducts = async () => {
    await productsService
      .find({ query: { $limit: 50000 } })
      .then((res: IProductsFind) => {
        setProducts(
          res.data
            .filter((item) => !dataFilter.includes(item.id))
            .map((item) => ({
              value: item.id.toString(),
              title: `${item.name}`,
            }))
        );
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };
  // console.log({ products });
  useEffect(() => {
    getProducts();
  }, []);

  return {
    //state
    products,
    valueSelector,
    valueNumber,
    valueSelectorName,
    //methods
    setValueNumber,
    //functions
    changeSelectValue,
  };
};
