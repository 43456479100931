// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { setOrdersForUpdate } from "../../../../redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { ordersService } from "../../../../shared/services";

export const useValidateSingleOrder = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const [isValidate, setIsValidate] = useState(false);

  const getSingleOrder = async (id: number) => {
    return await ordersService
      .get(id)
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        navigate(`/orders`);
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (isNaN(pathname.split("/")[2])) return navigate(`/orders`);
    getSingleOrder(Number(pathname.split("/")[2])).then((res) =>
      dispatch(setOrdersForUpdate(res))
    );
    setIsValidate(true);
  }, []);

  return {
    //state
    isValidate,
    //methods
    //functions
  };
};
