"use client";
import React from "react";
import Button from "../../../../../shared/components/ui/Button";
import CreateEquipmentForm from "./createEquipmentForm";
import useEditEquipment from "../../../hooks/equipments/useEditEquipment";
import useEquipmentById from "../../../hooks/equipments/useEquipmentById";

export interface EditEquipmentProps {
  equipmentById: number;
  setOpenModal: (value: boolean) => void;
}

export default function EditEquipment({
  equipmentById,
  setOpenModal,
}: EditEquipmentProps) {
  const { equipmentData, getEquipmentById } = useEquipmentById();
  const { control, loading, handleSubmit } = useEditEquipment({
    equipmentData: equipmentData!,
    setOpenModal,
  });

  React.useEffect(() => {
    if (equipmentById) {
      getEquipmentById(equipmentById);
    }
  }, [equipmentById]);
  return (
    <form onSubmit={handleSubmit}>
      <CreateEquipmentForm control={control} />
      <div className="mt-4 flex items-center justify-end">
        <Button
          primary
          className="bg-[#072082]"
          loading={loading}
          type="submit"
          variant="filled"
        >
          Actualizar
        </Button>
      </div>
    </form>
  );
}
