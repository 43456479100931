"use client";

import React from "react";
import { ProgramTopicType } from "../../../types/dto/program";
import { programsTopicsAcademy } from "../../../shared/services";

export default function useProgramTopic(moduleId: number) {
  const [loading, setLoading] = React.useState(false);
  const [programModuleTopicByModuleId, setProgramModuleTopicByModuleId] = React.useState<ProgramTopicType[]>([]);

  const getTopicByModuleId = async () => {
    if (loading || !moduleId) return;
    setLoading(true);

    await programsTopicsAcademy
      .find({
       query: {
        program_module_id: moduleId,
       }
      })
      .then((response) => {
        setProgramModuleTopicByModuleId(response.data);
        })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getTopicByModuleId()
  }, [moduleId]);

  return {
    programModuleTopicByModuleId,
  };
}
