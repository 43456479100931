import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";

import { AdminMenuLayout, ModalCities } from "../../../shared/components";
import { WrapperCardCities } from "./styled";
import { useCities } from "../hooks";
import { TableCities } from "./TableCities";
import { selectModalCities } from "../../../redux";

export const Cities = () => {
  const modalCities = useSelector(selectModalCities);
  const {
    isUpdateCity,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalCitiesState,
  } = useCities();

  return (
    <AdminMenuLayout pageDescription="Cities" title="Cities">
      <WrapperCardCities>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalCitiesState}
            >
              Agregar nueva ciudad
            </Button>
          </Col>
        </Row>
        <TableCities />
        {modalCities && isUpdateCity && (
          <FormProvider {...formMethodsUpdate}>
            <ModalCities />
          </FormProvider>
        )}
        {modalCities && !isUpdateCity && (
          <FormProvider {...formMethodsCreate}>
            <ModalCities />
          </FormProvider>
        )}
      </WrapperCardCities>
    </AdminMenuLayout>
  );
};
