import { Select, Typography } from "antd";

const { Option } = Select;
const { Title } = Typography;

interface Props {
  dataOptions: { value: string; title: string }[];
  width?: string;
  label: string;
}

export const InputSelectSimple = ({
  width = "100%",
  dataOptions,
  label,
}: Props) => {
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Title style={{ marginBottom: "0px", fontSize: "0.7rem" }} level={5}>
        {label}
      </Title>
      <Select defaultValue="" style={{ width }} onChange={handleChange}>
        {dataOptions.map((item, idx) => (
          <Option value={item.value} key={idx}>
            {item.title}
          </Option>
        ))}
      </Select>
    </div>
  );
};
