import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  selectCourseBenefitsForUpdate,
  selectIsUpdateCourseBenefits,
  selectModalCourseBenefits,
} from "../../../../redux";
import { DataCourseBenefits } from "../../../../redux/@types";
import { WrapperModalCourses } from "../../../../modules/courses/ui/styled";
import { useCourseBenefits } from "../../../../modules";
import { InputNumber, InputText } from "../inputs";

const { Title } = Typography;

export const ModalCourseBenefits = () => {
  const modalCities = useSelector(selectModalCourseBenefits);
  const isUpdateCourseBenefits = useSelector(selectIsUpdateCourseBenefits);
  const updateCourseBenefits = useSelector(selectCourseBenefitsForUpdate);

  const { isLoading, changeModalCourseBenefitsState, onSubmitCreateOrUpdate } =
    useCourseBenefits();

  const { control, handleSubmit: onSubmit } =
    useFormContext<DataCourseBenefits>();
  return (
    <WrapperModalCourses
      title={
        isUpdateCourseBenefits
          ? `Actualizando beneficio: '${updateCourseBenefits?.id!}'`
          : `Creando beneficio`
      }
      open={modalCities}
      width={"320px"}
      destroyOnClose
      onClose={changeModalCourseBenefitsState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText placeHolder="Beneficio 1" name="name" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Posición:
        </Title>
        <InputNumber placeHolder="8" name="position" control={control} />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateCourseBenefits ? "Actualizar información" : "Crear beneficio"}
      </Button>
    </WrapperModalCourses>
  );
};
