import { configureStore } from '@reduxjs/toolkit'
// import createSagaMiddleware from '@redux-saga/core'

import rootReducer from './reducers'
// import rootSaga from './saga'

// const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
})

// sagaMiddleware.run(rootSaga)

export default store