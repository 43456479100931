// import { Button, Col, Row } from "antd"
// import { PlusOutlined } from "@ant-design/icons";

import { AdminMenuLayout } from "../../../shared/components"
import { WrapperCardConfigurationCashback } from "./styled"
import { FormProvider } from "react-hook-form";
import { TableConfigurationCashback } from "./TableConfigurationCashback";
import { useSelector } from "react-redux";
import { selectModalConfigurationCashback } from '../../../redux/selectors';
import { useConfigurationCashback } from "../hook";
import { ModalConfigurationCashback } from "../../../shared/components/ui/modals/ModalConfigurationCashback";

export const ConfigurationCashback = () => {

  const modalConfigurationCashback = useSelector(selectModalConfigurationCashback);
  const {
    isUpdateConfigurationCashback,
    // formMethodsCreate,
    formMethodsUpdate,
    // changeModalConfigurationCashbackState,
  } = useConfigurationCashback();

  return (
    <AdminMenuLayout pageDescription="Cashback" title="Configurar Cashback">
      <WrapperCardConfigurationCashback>
        {/* <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalSystemVariablesState}
            >
              Agregar variable del sistema
            </Button>
          </Col>
        </Row> */}
        <TableConfigurationCashback />
        {modalConfigurationCashback && isUpdateConfigurationCashback && (
          <FormProvider {...formMethodsUpdate}>
            <ModalConfigurationCashback />
          </FormProvider>
        )}
        {/* {modalConfigurationCashback && !isUpdateSystemVariables && (
          <FormProvider {...formMethodsCreate}>
            <ModalSystemVariables />
          </FormProvider>
        )} */}
      </WrapperCardConfigurationCashback>
    </AdminMenuLayout>
  )
}
