import { Card, Drawer, List, Modal, Tabs } from "antd";
import styled from "styled-components";
export const WrapperCard = styled(Card)`
  display: flex;
  width: 100%;
  height: auto;
  text-align: left;
  font-weight: 400;
  /* font-family: "Poppins", "font-awesome"; */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  border: 1px solid #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: #fff;
  border-radius: 1rem !important;
  min-height: 100%;

  .ant-card-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

//ModalProducts

export const WrapperModalProducts = styled(Drawer)`
  /* min-height: 100vh;
  padding: 0;
  display: flex;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
  width: 25vw;

  & .ant-modal-content {
    width: 100%;
  }

  & .ant-modal-body {
  }

  & .ant-modal-footer {
    display: none;
  }

  @media (max-width: 767px) {
    margin: 0;
  } */
`;

// Product by ID

export const WrapperProductById = styled(Card)`
  display: flex;
  width: 100%;
  height: auto;
  text-align: left;
  font-weight: 400;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  border: 1px solid #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: #fff;
  border-radius: 1rem !important;
  min-height: 100%;

  .ant-card-body {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .ant-tabs {
      width: 100%;
    }
  }
`;

export const WrapperTabNav = styled(Tabs)`
  width: 100%;
`;

//Card Product Gallery

// export const WrapperImgGallery = styled

export const MyList = styled(List)`
margin: 0 10px;
width: 100% !important;
.ant-row > div:first-child .ant-card.ant-card-bordered{
  background-color:#bbb5b5;
}
.ant-row > div:first-child, .ant-row > div:first-child .ant-card, .ant-row > div:first-child .ant-card-cover img {
  
  border: none;
}
  & .ant-card-bordered {
    /* height:150px!important;    */
  }
  & .ant-card-cover img {
    object-fit: contain !important;
    height: 100% !important;
  }
  & .ant-card {
    max-width: 260px;
  }
  & .ant-card-body {
    padding: 4px 0px !important;
  }
  & .ant-card-meta-description {
    margin: 10px !important;
  }
  & .ant-card-cover {
    height: 200px !important;
  
    overflow: hidden !important;
  }
  & .ant-card-meta-description > div {
    width: 100% !important;
  }
  & .ant-card-bordered ul.ant-card-actions {
    display: none !important;
  }
  & .ant-card-bordered .ant-btn {
    background: #fff !important;
  }
  & .ant-card-bordered .ant-btn.delete {
    color: red !important;
  }
  & .ant-card-bordered:hover ul.ant-card-actions {
    position: absolute;
    top: 0;
    background: transparent;
    width: 100%;
    background: #0c0c0c75;
    min-height: 200px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
`;

export const WrapperModalGallery = styled(Modal)`
  & .ant-modal-content {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 25px !important;
    border-radius: 10px 10px 8px 8px !important;
  }

  & .ant-modal-header {
    background: var(--color-primary) !important;
    border-radius: 8px 8px 0 0 !important;
    height: 100px !important;
  }
  & .ant-modal-body {
    box-sizing: border-box !important;
    padding-bottom: 0px !important;
  }

  & .ant-modal-title {
    color: var(--color-white) !important;
    font-style: italic !important;
    font-size: 1.125rem !important;
    font-weight: 600 !important;
  }
  & .modal-content.headline {
    transform: translate(0px, -60px);
    margin: 0px auto;
    text-align: center;
    overflow: hidden;
  }

  & .modal-content {
    /* width: calc(100% - 50px); */

    border-radius: 3px !important;
    margin-bottom: 0px !important;

    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
    min-height: 80px;
    overflow: hidden;

    /*  position: absolute; */
    display: block;

    background: var(--color-white) !important;
    padding: 20px 5% 0px 5%;
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
    box-sizing: border-box !important;
  }

  & .modal-content .ant-form {
    padding: 0px !important;
    margin: 0px !important;
    border: 0px !important;
    box-shadow: none !important;
    width: 100% !important;
  }
  & .ant-modal-close-x {
    color: #fff !important;
  }
`;

//uploaderDragAndDrop

export const WrapperDragAndDrop = styled.div`
  background-color: var(--light);
  border: 2px dashed var(--text-color-grey);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: ease 0.25s;
  &:hover {
    background-color: #eceff1;
    border-color: var(--primary);
    transition: ease 0.25s;
  }
`;

export const TextDaD1 = styled.p`
  margin: 0 0 4px;
  color: #000000d9;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, apple color emoji,
    segoe ui emoji, Segoe UI Symbol, noto color emoji;
`;

export const TextDaD2 = styled.p`
  color: #00000073;
  margin: 0 4px;
  text-align: center;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, apple color emoji,
    segoe ui emoji, Segoe UI Symbol, noto color emoji;
`;

//item render

export const TableProduct = styled.div`
  display: flex;
  width: 100%;
`

export const TableCounterProducts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  `

export const ContentTranslatedKey = styled.div`
  align-self: center;
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  height:100%;
  display: flex;
  width: 100%;
  padding: 5px;
  background: #fafafa;
  justify-content: center;
  align-items: center;
  writing-mode: horizontal-tb;
  border: 1px solid #f0f0f0;
  border-bottom: 0 solid transparent;
  padding: 0 10px;
`

export const ValForTable = styled.div`
  align-self: center;
  width: 100%;
  padding: 5px;
  display: flex;
  border: 1px solid #f0f0f0;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`