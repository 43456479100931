import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { selectIsUpdateProductCharacteristic } from './../../../../redux/selectors/productCharacteristics.selectors';
import { useSelector } from "react-redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { TableProperties } from '../../../../shared/components/ui/Table/types';
import { message } from 'antd';

import { IProductCharacteristics2 } from '../../../../redux/@types';
import { createProductCharacteristic2, productsCharacteristicChangeModalState, refreshTableProductsCharacteristics, selectIsUpdateProductCharacteristic2, selectProductCharacteristicForUpdate2, selectProductCharacteristicRefreshTable2, selectProductForUpdate, setIsProductsCharacteristicForUpdate, updateProductCharacteristic2 } from '../../../../redux';
import { validateCreateProductCharacteristics2, validateUpdateProductCharacteristics2 } from '../../../../helpers';

export const useProductCharacteristics2 = () => {
    const dispatch = useAppDispatch();
    const isUpdateProductCharacteristic = useSelector(
        selectIsUpdateProductCharacteristic2
    );
    const dataUpdateProductCharacteristic = useSelector(
        selectProductCharacteristicForUpdate2
    );
    const isRefreshTableProductCharacteristic = useSelector(
        selectProductCharacteristicRefreshTable2
    );
    const dataProduct = useSelector(selectProductForUpdate);

    const navigateProductCharacteristicCreate = () => {
        dispatch(setIsProductsCharacteristicForUpdate(false));
        dispatch(productsCharacteristicChangeModalState())
      };

    const [isLoading, setIsLoading] = useState(false);
    const [tableProps, setTableProps] = useState<TableProperties>();

    const formMethodsCreate = useForm<IProductCharacteristics2>({
        resolver: yupResolver(validateCreateProductCharacteristics2),
      });

      const formMethodsUpdate = useForm<IProductCharacteristics2>({
        resolver: yupResolver(validateUpdateProductCharacteristics2),
      });
    useEffect(() => {
        if (isUpdateProductCharacteristic) {
          formMethodsCreate.reset();
          formMethodsUpdate.reset({ ...dataUpdateProductCharacteristic });
        }

        return () => {
          formMethodsUpdate.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isUpdateProductCharacteristic, dataUpdateProductCharacteristic]);

    const onSubmitCreateOrUpdate = async (data: IProductCharacteristics2) => {
        console.log({ data });
        if (isLoading) return;
        setIsLoading(true);
        if (isUpdateProductCharacteristic) {
          //console.log("Actualiando===>", data);
          await updateProductCharacteristic2({
            id: data.id,
            characteristic_id: Number(data.characteristic_id),
            characteristic_value: data.characteristic_value,
            product_id: dataProduct?.id!,
          })
            .then((res: Partial<IProductCharacteristics2>) => {
              setIsLoading(false);
              // dispatch(mappingUpdatedUser(res))
              if (res.id) {
                dispatch(productsCharacteristicChangeModalState())
                message.success(`Se ha actualizado con éxito el producto con id:${res.id}`)
                dispatch(refreshTableProductsCharacteristics(true));
              } else {
                message.error(res as string);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        } else {
          // console.log('Creando===>',data)
          await createProductCharacteristic2({
            characteristic_id: Number(data.characteristic_id),
            characteristic_value: data.characteristic_value,
            product_id: dataProduct?.id!,
          })
            .then((res: any) => {
              setIsLoading(false);
              if (res.id) {
                dispatch(productsCharacteristicChangeModalState())
                message.success(
                  `Se ha creado con éxito el producto con id:${res.id}`
                );
                dispatch(refreshTableProductsCharacteristics(true));
              } else {
                message.error(res as any);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        }
      };

      useEffect(() => {
        if (isRefreshTableProductCharacteristic) {
          tableProps?.refresh();
          dispatch(refreshTableProductsCharacteristics(false));
        }
      }, [isRefreshTableProductCharacteristic]);
    return {
    //state
    isUpdateProductCharacteristic,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    navigateProductCharacteristicCreate,
  }
}
