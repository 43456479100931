"use client";

import React from "react";
import { Resolver, useForm } from "react-hook-form";
import { message } from "antd";
import { academyEquipmentRentsService } from "../../../../shared/services";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { RentEquipmentStudentsAttributesType } from "../../../../types/dto/equipments";

export interface UseEditEquipmentRentsProps {
  equipmentRentData: RentEquipmentStudentsAttributesType;
  setOpenModal: (val: boolean) => void;
}

export default function useEditEquipmentRents({
  equipmentRentData,
  setOpenModal,
}: UseEditEquipmentRentsProps) {
  const [loading, setLoading] = React.useState(false);

  const {
    control,
    handleSubmit: onSubmit,
    reset,
  } = useForm<RentEquipmentStudentsAttributesType>({
    resolver: yupResolver(
      yup.object({
        // Define tu esquema de validación aquí si es necesario
      })
    ) as unknown as Resolver<RentEquipmentStudentsAttributesType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    console.log(data);
    setLoading(true);

    if (data.real_return_date && moment(data.real_return_date).isValid()) {
      const real_rent_days = moment(data.real_return_date).diff(
        moment(data.rent_date),
        "days"
      );
      data.real_return_date = data.real_return_date;
      data.real_rent_days = real_rent_days + 1;
    } else {
    }

    await academyEquipmentRentsService
      .patch(
        equipmentRentData.id,
        {
          ...data,
          rent_equipment_id: +equipmentRentData.rent_equipment_id!,
          quantity: +data.quantity!,
        },
        {
          query: { $client: { setQuantityAvailableEquipment: true } },
        }
      )
      .then((response) => {
        message.success("Se ha actualizado exitosamente el equipo");
        setOpenModal(false);
      })
      .catch((err: any) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  React.useEffect(() => {
    if (equipmentRentData) {
      reset({
        user_id: equipmentRentData.user_id,
        rent_equipment_id: equipmentRentData.rent_equipment_id,
        rent_date: moment(equipmentRentData.rent_date, 'YYYY-MM-DD')as any,
        return_expected_date: moment(
          equipmentRentData.return_expected_date
        ).format("YYYY-MM-DD"),
        quantity: equipmentRentData.quantity,
        real_return_date: equipmentRentData.real_return_date
          ? moment(equipmentRentData.real_return_date).format("YYYY-MM-DD")
          : "",
      });
    }
  }, [equipmentRentData, reset]);

  return {
    loading,
    control,
    handleSubmit,
  };
}
