import {  DataCourseBenefits, DataCourseChapters, DataCoursesCategories, DataCourseSection, DataCoursesForUpdate, IProducts } from "../@types"

export const COURSES_MODAL_STATE = '[ COURSES ] Courses Modal State'

export interface ICoursesModalState {
    type: typeof COURSES_MODAL_STATE
}

export const GET_COURSES_FOR_UPDATE = '[ COURSES ] Get Courses For Update'

export interface IGetCoursesForUpdate {
    type: typeof GET_COURSES_FOR_UPDATE
    payload: DataCoursesForUpdate
}

export const IS_COURSES_FOR_UPDATE ='[ COURSES ] Is Courses For Update'

export interface IIsCoursesForUpdate {
    type: typeof IS_COURSES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_COURSES_REFRESH_TABLE = '[ COURSES ] Switch Courses Refresh Table'

export interface ISwitchCoursesRefreshTable {
    type: typeof SWITCH_COURSES_REFRESH_TABLE
    payload: boolean
}

export const GET_PRODUCTS_COURSES = '[ COURSES ] Get Products Courses'

export interface IGetProductsCourses {
    type: typeof GET_PRODUCTS_COURSES
    payload: IProducts[]
}

export const GET_CATEGORY_COURSES = '[ COURSES ] Get Categories Courses'

export interface IGetCategoriesCourses {
    type: typeof GET_CATEGORY_COURSES
    payload: DataCoursesCategories[]
}

// export const GET_CITIES_OBJ_COURSES = '[ COURSES ] Get Cities Obj Courses'

// export interface IGetCitiesObjCourses {
//     type: typeof GET_CITIES_OBJ_COURSES
//     payload: { [key: string]: string; }
// }

//course-sections

export const COURSE_SECTIONS_MODAL_STATE = '[ COURSE SECTIONS ] Course Sections Modal State'

export interface ICourseSectionsModalState {
    type: typeof COURSE_SECTIONS_MODAL_STATE
}

export const GET_COURSE_SECTIONS_FOR_UPDATE = '[ COURSE SECTIONS ] Get Course Sections For Update'

export interface IGetCourseSectionsForUpdate {
    type: typeof GET_COURSE_SECTIONS_FOR_UPDATE
    payload: DataCourseSection
}

export const IS_COURSE_SECTIONS_FOR_UPDATE ='[ COURSE SECTIONS ] Is Course Sections For Update'

export interface IIsCourseSectionsForUpdate {
    type: typeof IS_COURSE_SECTIONS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_COURSE_SECTIONS_REFRESH_TABLE = '[ COURSE SECTIONS ] Switch Course Sections Refresh Table'

export interface ISwitchCourseSectionsRefreshTable {
    type: typeof SWITCH_COURSE_SECTIONS_REFRESH_TABLE
    payload: boolean
}

//course-chapters

export const COURSE_CHAPTERS_MODAL_STATE = '[ COURSE CHAPTERS ] Course Chapters Modal State'

export interface ICourseChaptersModalState {
    type: typeof COURSE_CHAPTERS_MODAL_STATE
}

export const GET_COURSE_CHAPTERS_FOR_UPDATE = '[ COURSE CHAPTERS ] Get Course Chapters For Update'

export interface IGetCourseChaptersForUpdate {
    type: typeof GET_COURSE_CHAPTERS_FOR_UPDATE
    payload: DataCourseChapters
}

export const IS_COURSE_CHAPTERS_FOR_UPDATE ='[ COURSE CHAPTERS ] Is Course Chapters For Update'

export interface IIsCourseChaptersForUpdate {
    type: typeof IS_COURSE_CHAPTERS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_COURSE_CHAPTERS_REFRESH_TABLE = '[ COURSE CHAPTERS ] Switch Course Chapters Refresh Table'

export interface ISwitchCourseChaptersRefreshTable {
    type: typeof SWITCH_COURSE_CHAPTERS_REFRESH_TABLE
    payload: boolean
}

export const GET_SECTIONS__COURSE_CHAPTERS = '[ COURSE CHAPTERS ] Get Sections Course Chapters'

export interface IGetSectionsCourseChapters {
    type: typeof GET_SECTIONS__COURSE_CHAPTERS
    payload: DataCourseSection[]
}

// course-benefits

export const COURSE_BENEFITS_MODAL_STATE = '[ COURSE BENEFITS ] Course Benefits Modal State'

export interface ICourseBenefitsModalState {
    type: typeof COURSE_BENEFITS_MODAL_STATE
}

export const GET_COURSE_BENEFITS_FOR_UPDATE = '[ COURSE BENEFITS ] Get Course Benefits For Update'

export interface IGetCourseBenefitsForUpdate {
    type: typeof GET_COURSE_BENEFITS_FOR_UPDATE
    payload: DataCourseBenefits
}

export const IS_COURSE_BENEFITS_FOR_UPDATE ='[ COURSE BENEFITS ] Is Course Benefits For Update'

export interface IIsCourseBenefitsForUpdate {
    type: typeof IS_COURSE_BENEFITS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_COURSE_BENEFITS_REFRESH_TABLE = '[ COURSE BENEFITS ] Switch Course Benefits Refresh Table'

export interface ISwitchCourseBenefitsRefreshTable {
    type: typeof SWITCH_COURSE_BENEFITS_REFRESH_TABLE
    payload: boolean
}




