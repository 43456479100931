import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  deleteCourses,
  refreshTableCourses,
  setIsCoursesForUpdate,
} from "../../../redux";
import { DataCourses } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useCourses } from "./useCourses";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;

export const useTableCourses = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tableProps } = useCourses();

  const changeCoursesForUpdate = (val: DataCourses) => {
    dispatch(setIsCoursesForUpdate(true));
    navigate(`/courses/${val.id}`);

    // dispatch(coursesChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la curso con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar curso" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar curso",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteCourses(valId)
          .then(() => {
            dispatch(refreshTableCourses(true));
            message.success(`Se ha borrado con éxito la curso con id:${valId}`);
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeCoursesForUpdate,
    showDeleteConfirm,
  };
};
