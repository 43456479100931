import { useEffect, useState } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import {
  selectCoursesCategoriesForUpdate,
  selectCoursesCategoriesRefreshTable,
  selectIsUpdateCoursesCategories,
} from "../../../redux";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { DataCoursesCategories } from "../../../redux/@types";
import {
  validateCreateCoursesCategories,
  validateUpdateCoursesCategories,
} from "../../../helpers";
import {
  coursesCategoriesChangeModalState,
  refreshTableCoursesCategories,
  updateCoursesCategories,
  createCoursesCategories,
  setIsCoursesCategoriesForUpdate,
} from "../../../redux/actions/coursesCategories.actions";

export const useCoursesCategories = () => {
  const dispatch = useAppDispatch();

  const isUpdateCoursesCategories = useSelector(
    selectIsUpdateCoursesCategories
  );
  const dataUpdateCoursesCategories = useSelector(
    selectCoursesCategoriesForUpdate
  );
  const isRefreshTableCoursesCategories = useSelector(
    selectCoursesCategoriesRefreshTable
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalCoursesCategoriesState = () => {
    formMethodsCreate.reset();
    dispatch(setIsCoursesCategoriesForUpdate(false));
    dispatch(coursesCategoriesChangeModalState());
  };

  const formMethodsCreate = useForm<DataCoursesCategories>({
    resolver: yupResolver(validateCreateCoursesCategories),
  });

  const formMethodsUpdate = useForm<DataCoursesCategories>({
    resolver: yupResolver(validateUpdateCoursesCategories),
  });

  useEffect(() => {
    if (isUpdateCoursesCategories) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateCoursesCategories });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCoursesCategories, dataUpdateCoursesCategories]);


  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await updateCoursesCategories({
      id,
      status: value,
    })
    .then((res: Partial<DataCoursesCategories>) => {
      setIsLoading(false);
      if (res.id) {
        // dispatch(bannersChangeModalState());
        message.success(
          `Se ha actualizado con éxito el estado de la categoría con id:${res.id}`
        );
        dispatch(refreshTableCoursesCategories(true));
      } else {
        message.error(res as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }

  const onSubmitCreateOrUpdate = async (data: DataCoursesCategories) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCoursesCategories) {
      //console.log("Actualiando===>", data);
      await updateCoursesCategories({
        id: data.id,
        name: data.name,
        status: data.status,
        position: data.position,
        path_image: data.path_image,
      })
        .then((res: Partial<DataCoursesCategories>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // dispatch(coursesCategoriesChangeModalState());
            message.success(
              `Se ha actualizado con éxito la categoría de curso con id:${res.id}`
            );
            dispatch(refreshTableCoursesCategories(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createCoursesCategories(data)
        .then((res: DataCoursesCategories) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(coursesCategoriesChangeModalState());
            message.success(
              `Se ha creado con éxito la categoría de curso con id:${res.id}`
            );
            dispatch(refreshTableCoursesCategories(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCoursesCategories) {
      tableProps?.refresh();
      dispatch(refreshTableCoursesCategories(false));
    }
  }, [isRefreshTableCoursesCategories]);
  return {
    //state
    isUpdateCoursesCategories,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalCoursesCategoriesState,
    updateStatus,
  };
};
