import { systemVariablesService } from "../../shared/services"
import { DataSystemVariables } from "../@types"
import { systemVariablesActions } from "../action-types"


export const systemVariablesChangeModalState = (): systemVariablesActions.ISystemVariablesModalState =>({
    type: systemVariablesActions.SYSTEM_VARIABLES_MODAL_STATE
})

export const setSystemVariablesForUpdate = (val: DataSystemVariables): systemVariablesActions.IGetSystemVariablesForUpdate =>({
    type: systemVariablesActions.GET_SYSTEM_VARIABLES_FOR_UPDATE,
    payload: val
})

export const setIsSystemVariablesForUpdate = (val: boolean): systemVariablesActions.IIsSystemVariablesForUpdate =>({
    type: systemVariablesActions.IS_SYSTEM_VARIABLES_FOR_UPDATE,
    payload: val
})

export const createSystemVariables = async(data: Partial<DataSystemVariables>) =>{
    return await systemVariablesService.create(data)
    .then((res: DataSystemVariables) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateSystemVariables =async(data: Partial<DataSystemVariables>) =>{
    return await systemVariablesService.patch(data?.id!, data)
    .then((res: DataSystemVariables) => {return res})
    .catch((err: any) => { return err.message })
}

// export const deleteSystemVariables = async(id: number) => {
//     return await systemVariablesService.remove(id.toString())
//     .then((res: any) => {return res})
//     .catch((err: any) => { return err.message })
// }

export const refreshTableSystemVariables = (val: boolean): systemVariablesActions.ISwitchSystemVariablesRefreshTable =>({
    type: systemVariablesActions.SWITCH_SYSTEM_VARIABLES_REFRESH_TABLE,
    payload: val
})