import Button from "../../../../../shared/components/ui/Button";
import useEquipmentRentsById from "../../../hooks/equipmentRents/useEquipmentRentsById";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

export interface CreateEquipmentRentPaymentProps {
  equipmentRentId: number;
  setOpenModaEquipmentRentPayment: (val: boolean) => void
}

export default function CreateEquipmentRentPayment({
  equipmentRentId,
  setOpenModaEquipmentRentPayment
}: CreateEquipmentRentPaymentProps) {
  const {
    loading,
    totalAmountToPay,
    equipmentRentData,
    setQuantityAvailableEquipmentAndPayment,
  } = useEquipmentRentsById(equipmentRentId);

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
    }).format(amount);
  };

  const sendPayment = async (e: any) => {
    e.preventDefault();
    await setQuantityAvailableEquipmentAndPayment(equipmentRentId).then(()=>setOpenModaEquipmentRentPayment(false));
  };

  return (
    <form style={{ display: "flex", justifyContent: "center", gap: 6 }}>
      <div className=" ">
        <div style={{ display: "flex", gap: 10 }}>
          <p>
            <strong>Nombre del Usuario:&nbsp;</strong> <br />
            {equipmentRentData?.user.first_name ?? "N/A"}
          </p>
          <p>
            <strong>Apellidos del Usuario:&nbsp;</strong>
            <br />
            {equipmentRentData?.user.last_name ?? "N/A"}
          </p>
        </div>
        <div>
          <h3>
            <strong>Descripción del Producto</strong>
          </h3>
          <p>
            El equipo de ref <strong>{equipmentRentData?.rent_equipment.name}</strong> alquilado por <strong>{equipmentRentData?.user.first_name ?? "N/A"}</strong> alquiló
            desde el&nbsp;
            <strong>{moment(equipmentRentData?.rent_date).format("DD/MM/YYYY")}</strong> hasta el
            dia&nbsp;
            <strong>{moment(equipmentRentData?.return_expected_date).format(
              "DD/MM/YYYY"
            )}&nbsp;</strong>
            fue devuelto el&nbsp;
            <strong>
            {moment(equipmentRentData?.real_return_date).format("DD/MM/YYYY")}.&nbsp;
            </strong>
            obteniendo una totalidad de dias <strong>{equipmentRentData?.rent_days}</strong> reantados por un monto de <strong>{formatCurrency(+totalAmountToPay?.total_amount)}</strong>
          </p>
        </div>
        <div>
          {equipmentRentData?.status === "successful_payment" ? (
            <p>El pago del estudiante ya ha sido procesado correctamente.</p>
          ) : (
            <Button
              primary
              className="bg-[#072082] h-[36px] flex items-center"
              type="submit"
              variant="filled"
              onClick={(e) => sendPayment(e)}
            >
              Pagar
              {loading ? (
                <LoadingOutlined style={{ marginLeft: 15 }} />
              ) : (
                formatCurrency(+totalAmountToPay?.total_amount)
              )}
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}
