import { DataBrands, IBrandsState } from "../@types";
import { brandsActions } from "../action-types";

type brandsActionType =
| { type: typeof brandsActions.BRANDS_MODAL_STATE }
| { type: typeof brandsActions.GET_BRAND_FOR_UPDATE; payload: DataBrands }
| { type: typeof brandsActions.IS_BRANDS_FOR_UPDATE; payload: boolean }
| { type: typeof brandsActions.SWITCH_BRANDS_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: IBrandsState ={
    brandsForUpdate: undefined,
    isUpdateBrand: false,
    brandsModalIsOpen: false,
    refreshTableBrands: false,
}

export default function brandsReducer(state: IBrandsState = INITIAL_STATE, action: brandsActionType): IBrandsState {
    switch(action.type){
        case brandsActions.BRANDS_MODAL_STATE:
            return{
                ...state,
                brandsModalIsOpen: !state.brandsModalIsOpen
            }
        case brandsActions.GET_BRAND_FOR_UPDATE:
            return{
                ...state,
                brandsForUpdate: action.payload,
            }
        case brandsActions.IS_BRANDS_FOR_UPDATE:
            return{
                ...state,
                isUpdateBrand: action.payload
            }
        case brandsActions.SWITCH_BRANDS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableBrands: action.payload
            }
        default:
            return state
    }
}