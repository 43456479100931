import {  ICoursesState, DataCoursesForUpdate, IProducts, DataCoursesCategories, DataCourseSection, DataCourseChapters, DataCourseBenefits } from "../@types";
import { coursesActions } from "../action-types";

type coursesType =
| { type: typeof coursesActions.COURSES_MODAL_STATE }
| { type: typeof coursesActions.GET_COURSES_FOR_UPDATE; payload: DataCoursesForUpdate }
| { type: typeof coursesActions.IS_COURSES_FOR_UPDATE; payload: boolean }
| { type: typeof coursesActions.SWITCH_COURSES_REFRESH_TABLE; payload: boolean }
| { type: typeof coursesActions.GET_PRODUCTS_COURSES; payload: IProducts[] }
// | { type: typeof coursesActions.GET_CITIES_OBJ_COURSES; payload: { [key: string]: string; } }
| { type: typeof coursesActions.GET_CATEGORY_COURSES; payload: DataCoursesCategories[] }
| { type: typeof coursesActions.COURSE_SECTIONS_MODAL_STATE }
| { type: typeof coursesActions.GET_COURSE_SECTIONS_FOR_UPDATE; payload: DataCourseSection }
| { type: typeof coursesActions.IS_COURSE_SECTIONS_FOR_UPDATE; payload: boolean }
| { type: typeof coursesActions.SWITCH_COURSE_SECTIONS_REFRESH_TABLE; payload: boolean }
| { type: typeof coursesActions.COURSE_CHAPTERS_MODAL_STATE }
| { type: typeof coursesActions.GET_COURSE_CHAPTERS_FOR_UPDATE; payload: DataCourseChapters }
| { type: typeof coursesActions.IS_COURSE_CHAPTERS_FOR_UPDATE; payload: boolean }
| { type: typeof coursesActions.SWITCH_COURSE_CHAPTERS_REFRESH_TABLE; payload: boolean }
| { type: typeof coursesActions.GET_SECTIONS__COURSE_CHAPTERS; payload: DataCourseSection[] }
| { type: typeof coursesActions.COURSE_BENEFITS_MODAL_STATE }
| { type: typeof coursesActions.GET_COURSE_BENEFITS_FOR_UPDATE; payload: DataCourseBenefits }
| { type: typeof coursesActions.IS_COURSE_BENEFITS_FOR_UPDATE; payload: boolean }
| { type: typeof coursesActions.SWITCH_COURSE_BENEFITS_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: ICoursesState ={
    coursesForUpdate: undefined,
    isUpdateCourses: false,
    coursesModalIsOpen: false,
    refreshTableCourses: false,
    arrProducts: [],
    // objCities: {},
    arrCategories: [],
    courseSectionsForUpdate: undefined,
    isUpdateCourseSections: false,
    courseSectionsModalIsOpen: false,
    refreshTableCourseSections: false,
    courseChaptersForUpdate: undefined,
    isUpdateCourseChapters: false,
    courseChaptersModalIsOpen: false,
    refreshTableCourseChapters: false,
    arrSections: [],
    courseBenefitsForUpdate: undefined,
    isUpdateCourseBenefits: false,
    courseBenefitsModalIsOpen: false,
    refreshTableCourseBenefits: false,
}

export default function coursesReducer(state: ICoursesState = INITIAL_STATE, action: coursesType): ICoursesState {
    switch(action.type){
        case coursesActions.COURSES_MODAL_STATE:
            return{
                ...state,
                coursesModalIsOpen: !state.coursesModalIsOpen
            }
        case coursesActions.GET_COURSES_FOR_UPDATE:
            return{
                ...state,
                coursesForUpdate: action.payload
            }
        case coursesActions.IS_COURSES_FOR_UPDATE:
            return{
                ...state,
                isUpdateCourses: action.payload
            }
        case coursesActions.SWITCH_COURSES_REFRESH_TABLE:
            return{
                ...state,
                refreshTableCourses: action.payload
            }
        case coursesActions.GET_PRODUCTS_COURSES:
            return{
                ...state,
                arrProducts: action.payload
            }
        // case coursesActions.GET_CITIES_OBJ_COURSES:
        //     return{
        //         ...state,
        //         objCities: action.payload
        //     }
        case coursesActions.GET_CATEGORY_COURSES:
            return{
                ...state,
                arrCategories: action.payload
            }
        //coursesSections
        case coursesActions.COURSE_SECTIONS_MODAL_STATE:
            return{
                ...state,
                courseSectionsModalIsOpen: !state.courseSectionsModalIsOpen
            }
        case coursesActions.GET_COURSE_SECTIONS_FOR_UPDATE:
            return{
                ...state,
                courseSectionsForUpdate: action.payload
            }
        case coursesActions.IS_COURSE_SECTIONS_FOR_UPDATE:
            return{
                ...state,
                isUpdateCourseSections: action.payload
            }
        case coursesActions.SWITCH_COURSE_SECTIONS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableCourseSections: action.payload
            }
        //coursesChapters
        case coursesActions.COURSE_CHAPTERS_MODAL_STATE:
            return{
                ...state,
                courseChaptersModalIsOpen: !state.courseChaptersModalIsOpen
            }
        case coursesActions.GET_COURSE_CHAPTERS_FOR_UPDATE:
            return{
                ...state,
                courseChaptersForUpdate: action.payload
            }
        case coursesActions.IS_COURSE_CHAPTERS_FOR_UPDATE:
            return{
                ...state,
                isUpdateCourseChapters: action.payload
            }
        case coursesActions.SWITCH_COURSE_CHAPTERS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableCourseChapters: action.payload
            }
        case coursesActions.GET_SECTIONS__COURSE_CHAPTERS:
            return{
                ...state,
                arrSections: action.payload
            }
        //coursesBenefits
        case coursesActions.COURSE_BENEFITS_MODAL_STATE:
            return{
                ...state,
                courseBenefitsModalIsOpen: !state.courseBenefitsModalIsOpen
            }
        case coursesActions.GET_COURSE_BENEFITS_FOR_UPDATE:
            return{
                ...state,
                courseBenefitsForUpdate: action.payload
            }
        case coursesActions.IS_COURSE_BENEFITS_FOR_UPDATE:
            return{
                ...state,
                isUpdateCourseBenefits: action.payload
            }
        case coursesActions.SWITCH_COURSE_BENEFITS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableCourseBenefits: action.payload
            }
        default:
            return state
    }
}