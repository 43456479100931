import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  selectCourseSectionsForUpdate,
  selectIsUpdateCourseSections,
  selectModalCourseSections,
} from "../../../../redux";
import { DataCourseSection } from "../../../../redux/@types";
import { WrapperModalCourses } from "../../../../modules/courses/ui/styled";
import { useCourseSections } from "../../../../modules";
import { InputNumber, InputText } from "../inputs";

const { Title } = Typography;

export const ModalCourseSections = () => {
  const modalCities = useSelector(selectModalCourseSections);
  const isUpdateCourseSections = useSelector(selectIsUpdateCourseSections);
  const updateCourseSections = useSelector(selectCourseSectionsForUpdate);

  const { isLoading, changeModalCourseSectionsState, onSubmitCreateOrUpdate } =
    useCourseSections();

  const { control, handleSubmit: onSubmit } =
    useFormContext<DataCourseSection>();
  return (
    <WrapperModalCourses
      title={
        isUpdateCourseSections
          ? `Actualizando sección: '${updateCourseSections?.id!}'`
          : `Creando sección`
      }
      open={modalCities}
      width={"320px"}
      destroyOnClose
      onClose={changeModalCourseSectionsState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText placeHolder="Sección 1" name="name" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Posición:
        </Title>
        <InputNumber placeHolder="8" name="position" control={control} />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateCourseSections ? "Actualizar información" : "Crear sección"}
      </Button>
    </WrapperModalCourses>
  );
};
