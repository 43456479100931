import { DataCities, DataWorkOffersCategories, IDataForUpdateWorkOffers } from "../@types"


export const WORK_OFFERS_MODAL_STATE = '[ WORK OFFERS ] Work Offers Modal State'

export interface IWorkOffersModalState {
    type: typeof WORK_OFFERS_MODAL_STATE
}

export const GET_WORK_OFFERS_FOR_UPDATE = '[ WORK OFFERS ] Get Work Offers For Update'

export interface IGetWorkOffersForUpdate {
    type: typeof GET_WORK_OFFERS_FOR_UPDATE
    payload: IDataForUpdateWorkOffers
}

export const IS_WORK_OFFERS_FOR_UPDATE ='[ WORK OFFERS ] Is Work Offers For Update'

export interface IIsWorkOffersForUpdate {
    type: typeof IS_WORK_OFFERS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_WORK_OFFERS_REFRESH_TABLE = '[ WORK OFFERS ] Switch Work Offers Refresh Table'

export interface ISwitchWorkOffersRefreshTable {
    type: typeof SWITCH_WORK_OFFERS_REFRESH_TABLE
    payload: boolean
}

export const GET_CATEGORIES_WORK_OFFERS = '[ WORK OFFERS ] Get Categories Work Offers'

export interface IGetCategoriesWorkOffers {
    type: typeof GET_CATEGORIES_WORK_OFFERS
    payload: DataWorkOffersCategories[]
}



export const GET_CITIES_WORK_OFFERS = '[ WORK OFFERS ] Get Cities Work Offers'

export interface IGetCitiesWorkOffers {
    type: typeof GET_CITIES_WORK_OFFERS
    payload: DataCities[]
}

