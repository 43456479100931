import { taxService } from "../../shared/services";
import { DataTax } from "../@types";
import { taxActions } from "../action-types";

export const taxChangeModalState = (): taxActions.ITaxChangeModalState =>({
    type: taxActions.TAX_CHANGE_MODAL_STATE
})

export const setTaxForUpdate = (val: any): taxActions.IGetTaxForUpdate =>({
    type: taxActions.GET_TAX_FOR_UPDATE,
    payload: val
})

export const updateTax = async(data:Partial<DataTax>) =>{
    return await taxService.patch(data?.id!, data)
    .then((res: any) =>{
        // console.log({res})
        return res
    })
    .catch((err: any) => { return err.message })
}

export const refreshTableTax = ( val: boolean ): taxActions.ISwitchTaxRefreshTable =>({
    type: taxActions.SWITCH_TAX_REFRESH_TABLE,
    payload: val
})

