import Button from "../../../../../shared/components/ui/Button";
import { ProgramTopicType } from "../../../../../types/dto/program";
import useCreateTopic from "../../../../academyPrograms/hooks/topics/useCreateTopic";
import ProgramTopicsForm from "./ProgramTopicsForm";

export interface CreateProgramTopicsProps {
  moduleId: number,
  onSuccess?: (programTopic: ProgramTopicType) => void;
}

export default function CreateProgramTopics({onSuccess, moduleId}: CreateProgramTopicsProps) {
  const {control, loading, handleSubmit} = useCreateTopic({
    moduleId,
    onSuccess(data) {
      onSuccess?.(data);
    },
  });

  return (
      <form onSubmit={handleSubmit}>
        <ProgramTopicsForm control={control} />
        <div className="mt-2 flex items-center justify-end">
          <Button primary  className="bg-[#072082]" loading={loading} type="submit" variant="filled">
            <i className="fa-solid fa-save" /> Crear
          </Button>
        </div>
      </form>
  );
}
