import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import {
  selectIsUpdateProductCharacteristic,
  selectProductCharacteristicForUpdate,
  selectProductCharacteristicRefreshTable,
} from "../../../redux/selectors/productCharacteristics.selectors";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import {
  createProductCharacteristic,
  productCharacteristicsChangeModalState,
  refreshTableProductCharacteristics,
  setIsProductCharacteristicForUpdate,
  updateProductCharacteristic,
} from "../../../redux";
import { dataProducsCharac } from "../../../redux/@types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import {
  validateCreateProductCharacteristics,
  validateUpdateProductCharacteristics,
} from "../../../helpers";

export const useProductCharacteristics = () => {
  const dispatch = useAppDispatch();
  const isUpdateProductCharacteristic = useSelector(
    selectIsUpdateProductCharacteristic
  );
  const dataUpdateProductCharacteristic = useSelector(
    selectProductCharacteristicForUpdate
  );
  const isRefreshTableProductCharacteristic = useSelector(
    selectProductCharacteristicRefreshTable
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalProductCharacteristicsState = () => {
    dispatch(setIsProductCharacteristicForUpdate(false));
    dispatch(productCharacteristicsChangeModalState());
  };

  const formMethodsCreate = useForm<dataProducsCharac>({
    resolver: yupResolver(validateCreateProductCharacteristics),
  });

  const formMethodsUpdate = useForm<dataProducsCharac>({
    resolver: yupResolver(validateUpdateProductCharacteristics),
  });

  useEffect(() => {
    if (isUpdateProductCharacteristic) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateProductCharacteristic });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateProductCharacteristic, dataUpdateProductCharacteristic]);

  const onSubmitCreateOrUpdate = async (data: dataProducsCharac) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateProductCharacteristic) {
      //console.log("Actualiando===>", data);
      await updateProductCharacteristic({
        id: data.id,
        name: data.name,
      })
        .then((res: Partial<dataProducsCharac>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // dispatch(productCharacteristicsChangeModalState());
            message.success(
              `Se ha actualizado con éxito las características del producto con id:${res.id}`
            );
            dispatch(refreshTableProductCharacteristics(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createProductCharacteristic(data)
        .then((res: dataProducsCharac) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(productCharacteristicsChangeModalState());
            message.success(
              `Se ha creado con éxito las características del producto con id:${res.id}`
            );
            dispatch(refreshTableProductCharacteristics(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableProductCharacteristic) {
      tableProps?.refresh();
      dispatch(refreshTableProductCharacteristics(false));
    }
  }, [isRefreshTableProductCharacteristic]);

  return {
    //state
    isUpdateProductCharacteristic,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    changeModalProductCharacteristicsState,
    onSubmitCreateOrUpdate,
  };
};
