import { walletBonusService } from "../../shared/services"
import { DataWalletBonus } from "../@types"
import { walletBonusActions } from "../action-types"

export const walletBonusChangeModalState = (): walletBonusActions.IWalletBonusModalState =>({
    type: walletBonusActions.WALLET_BONUS_MODAL_STATE
})

export const setWalletBonusForUpdate = (val: DataWalletBonus): walletBonusActions.IGetWalletBonusForUpdate =>({
    type: walletBonusActions.GET_WALLET_BONUS_FOR_UPDATE,
    payload: val
})

export const setIsWalletBonusForUpdate = (val: boolean): walletBonusActions.IIsWalletBonusForUpdate =>({
    type: walletBonusActions.IS_WALLET_BONUS_FOR_UPDATE,
    payload: val
})

export const createWalletBonus = async(data: Partial<DataWalletBonus>) =>{
    return await walletBonusService.create(data)
    .then((res: DataWalletBonus) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateWalletBonus =async(data: Partial<DataWalletBonus>) =>{
    return await walletBonusService.patch(data?.id!, data)
    .then((res: DataWalletBonus) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteWalletBonus = async(id: number) => {
    return await walletBonusService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableWalletBonus = (val: boolean): walletBonusActions.ISwitchWalletBonusRefreshTable =>({
    type: walletBonusActions.SWITCH_WALLET_BONUS_REFRESH_TABLE,
    payload: val
})