import { DataListCustomerGroups, IListCustomerGroupsState } from "../@types";
import { listCustomerGroupsActions } from "../action-types";

type listCustomerGroupsType =
| { type: typeof listCustomerGroupsActions.LIST_CUSTOMER_GROUPS_MODAL_STATE }
| { type: typeof listCustomerGroupsActions.GET_LIST_CUSTOMER_GROUPS_FOR_UPDATE; payload: DataListCustomerGroups }
| { type: typeof listCustomerGroupsActions.IS_LIST_CUSTOMER_GROUPS_FOR_UPDATE; payload: boolean }
| { type: typeof listCustomerGroupsActions.SWITCH_LIST_CUSTOMER_GROUPS_REFRESH_TABLE; payload: boolean }



const INITIAL_STATE: IListCustomerGroupsState ={
    listCustomerGroupsForUpdate: undefined,
    isUpdateListCustomerGroups: false,
    listCustomerGroupsModalIsOpen: false,
    refreshTableListCustomerGroups: false,
}

export default function listCustomerGroupsReducer(state: IListCustomerGroupsState = INITIAL_STATE, action: listCustomerGroupsType): IListCustomerGroupsState {
    switch(action.type){
        case listCustomerGroupsActions.LIST_CUSTOMER_GROUPS_MODAL_STATE:
            return{
                ...state,
                listCustomerGroupsModalIsOpen: !state.listCustomerGroupsModalIsOpen
            }
        case listCustomerGroupsActions.GET_LIST_CUSTOMER_GROUPS_FOR_UPDATE:
            return{
                ...state,
                listCustomerGroupsForUpdate: action.payload
            }
        case listCustomerGroupsActions.IS_LIST_CUSTOMER_GROUPS_FOR_UPDATE:
            return{
                ...state,
                isUpdateListCustomerGroups: action.payload
            }
        case listCustomerGroupsActions.SWITCH_LIST_CUSTOMER_GROUPS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableListCustomerGroups: action.payload
            }
        default:
            return state
    }
}