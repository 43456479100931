import * as yup from "yup";

export const validateUpdateConfigurationCashback = yup.object().shape({
  cashback_status: yup
    .string()
    .required("Campo requerido")
    .oneOf(["active", "inactive"]),
  cashback_type: yup
    .string()
    .required("Campo requerido")
    .oneOf(["product_percentage", "fixed_order_amount"]),
  cashback_value: yup.number().required("Campo requerido"),
  cashback_max_amount: yup.number().required("Campo requerido"),
  cashback_expiration_days: yup.number().required("Campo requerido"),
  order_min_amount: yup.number().required("Campo requerido"),
  cashback_apply_to_offer_products: yup
    .string()
    .required("Campo requerido")
    .oneOf(["true", "false"]),
});
