import { useEffect, useState } from "react";
import { message } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { selectArrayParentIdCategories, selectIsUpdateProductCategories, selectProductCategoriesForUpdate, selectProductCategoriesRefreshTable } from '../../../redux/selectors/productCategories.selectors';
import { createProductCategories, getParentsId, productCategoriesChangeModalState, refreshTableProductCategories, setIsProductCategoriesForUpdate, setParentIdArray, updateProductCategories } from "../../../redux";
import { IProductCategory } from "../../../redux/@types";
import { validateCreateProductCategories, validateUpdateProductCategories } from "../../../helpers";

export const useProductCategories = () => {
  const dispatch = useAppDispatch();
  const isUpdateProductCategory = useSelector(
    selectIsUpdateProductCategories
  );
  const dataUpdateProductCategory = useSelector(
    selectProductCategoriesForUpdate
  );
  const isRefreshTableProductCategories = useSelector(
    selectProductCategoriesRefreshTable
  );
  const parentIdArray = useSelector(selectArrayParentIdCategories);
  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalProductCategoriesState = () => {
    dispatch(setIsProductCategoriesForUpdate(false));
    dispatch(productCategoriesChangeModalState());
  };

  const formMethodsCreate = useForm<IProductCategory>({
    resolver: yupResolver(validateCreateProductCategories),
  });

  const formMethodsUpdate = useForm<IProductCategory>({
    resolver: yupResolver(validateUpdateProductCategories),
  });

  useEffect(() => {
    if (isUpdateProductCategory) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateProductCategory });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateProductCategory, dataUpdateProductCategory]);

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await updateProductCategories({
      id,
      status: value,
    })
    .then((res: Partial<IProductCategory>) => {
      setIsLoading(false);
      if (res.id) {
        // dispatch(bannersChangeModalState());
        message.success(
          `Se ha actualizado con éxito el estado de la marca con id:${res.id}`
        );
        dispatch(refreshTableProductCategories(true));
      } else {
        message.error(res as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }

  const onSubmitCreateOrUpdate = async (data: IProductCategory) => {
    console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateProductCategory) {
      //console.log("Actualiando===>", data,data.parent_id);
      await updateProductCategories({
        id: data.id,
        name: data.name,
        parent_id: data.parent_id === undefined ? null : data.parent_id,
        position: data.position,
        path_icon: data.path_icon,
        path_icon2: data.path_icon2,
        path_image: data.path_image,
        status: data.status,
        banner_desktop_path: data.banner_desktop_path,
        banner_movile_path: data.banner_movile_path
      })
        .then((res: Partial<IProductCategory>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // dispatch(productCategoriesChangeModalState());
            message.success(
              `Se ha actualizado con éxito las categoría del producto con id:${res.id}`
            );
            dispatch(refreshTableProductCategories(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createProductCategories(data)
        .then((res: IProductCategory) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(productCategoriesChangeModalState());
            message.success(
              `Se ha creado con éxito las categoría del producto con id:${res.id}`
            );
            dispatch(refreshTableProductCategories(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableProductCategories) {
      tableProps?.refresh();
      dispatch(refreshTableProductCategories(false));
    }
  }, [isRefreshTableProductCategories]);

  const catchParentId= async() =>{
    await getParentsId()
      .then((res)=>{
        if(res.length > 0){
          dispatch(setParentIdArray(res));
        } else {
          message.error(res)
        }
      })
  }

  useEffect(()=>{
    if(parentIdArray.length === 0){
      catchParentId()
    }
  },[])

  return {
    //state
    isUpdateProductCategory,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    changeModalProductCategoriesState,
    onSubmitCreateOrUpdate,
    updateStatus,
  }
}
