import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { createState, refreshTableStates, selectIsUpdateState, selectStateForUpdate, selectStatesRefresh, setIsStateForUpdate, statesChangeModalState, updateState } from '../../../redux';
import { DataStates } from '../../../redux/@types';
import { validateCreateStates, validateUpdateStates } from '../../../helpers';
import { message } from 'antd';

export const useStates = () => {
    const dispatch = useAppDispatch();

    const isUpdateState = useSelector(selectIsUpdateState)
    const dataUpdateState = useSelector(selectStateForUpdate)
    const isRefreshTableStates = useSelector(selectStatesRefresh)

    const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalStates = () => {
    dispatch(setIsStateForUpdate(false))
    dispatch(statesChangeModalState())
  }

  const formMethodsCreate = useForm<DataStates>({
    resolver: yupResolver(validateCreateStates),
  });

  const formMethodsUpdate = useForm<DataStates>({
    resolver: yupResolver(validateUpdateStates),
  });

  useEffect(() => {
    if (isUpdateState) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateState });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateState, dataUpdateState]);

  const onSubmitCreateOrUpdate = async (data: DataStates) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateState) {
      //console.log("Actualiando===>", data);
      await updateState({
        id: data.id,
        name: data.name,
      })
        .then((res: Partial<DataStates>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // dispatch(statesChangeModalState());
            message.success(
              `Se ha actualizado con éxito el estado con id:${res.id}`
            );
            dispatch(refreshTableStates(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createState(data)
        .then((res: DataStates) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(statesChangeModalState());
            message.success(
              `Se ha creado con éxito el estado con id:${res.id}`
            );
            dispatch(refreshTableStates(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

useEffect(() => {
    if (isRefreshTableStates) {
      tableProps?.refresh();
      dispatch(refreshTableStates(false));
    }
  }, [isRefreshTableStates]);

  return {
    //state
    isUpdateState,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    changeModalStates,
    onSubmitCreateOrUpdate,
  }
}
