import { DataPaymentConfirmations, DataShipping, IOrder } from '../@types';

export const GET_ORDER_FOR_UPDATE = '[ ORDERS ] Get Order For Update'

export interface IGetOrderForUpdate {
    type: typeof GET_ORDER_FOR_UPDATE
    payload: IOrder
}

export const ORDER_CHANGE_MODAL_STATE = '[ ORDER ] Order Change Modal State'

export interface IOrderChangeModalState {
    type: typeof ORDER_CHANGE_MODAL_STATE
}

export const GET_ORDER_SHIPPED_FOR_UPDATE = '[ ORDERS ] Get Order Shipped For Update'

export interface IGetOrderShippedForUpdate {
    type: typeof GET_ORDER_SHIPPED_FOR_UPDATE
    payload: any
}

export const ORDER_CHANGE_MODAL_STATE_SHIPPED = '[ ORDER ] Order Change Modal State Shipped'

export interface IOrderChangeModalStateShipped {
    type: typeof ORDER_CHANGE_MODAL_STATE_SHIPPED
}

export const ORDER_CHANGE_PAYMENT_CONFIRMATIONS = '[ ORDER ] Order Change Payment Confirmations'

export interface IOrderChangePaymentConfirmations {
    type: typeof ORDER_CHANGE_PAYMENT_CONFIRMATIONS
    payload: DataPaymentConfirmations[]
}

export const ORDER_CHANGE_SHIPPING = '[ ORDER ] Order Change Shipping'

export interface IOrderChangeShipping {
    type: typeof ORDER_CHANGE_SHIPPING
    payload: DataShipping[]
}

export const ORDER_CHANGE_SHIPPING_ID = '[ ORDER ] Order Change Shipping ID'

export interface IOrderChangeShippingID {
    type: typeof ORDER_CHANGE_SHIPPING_ID
    payload: number
}