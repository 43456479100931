"use client";

import React from "react";
import {CSSTransition} from "react-transition-group";
import clsx from "clsx";
import {CloseOutlined} from "@ant-design/icons";

import Button from "../Button";

export interface ModalProps {
  children: React.ReactNode;
  title?: string;
  closable?: boolean;
  visible?: boolean;
  className?: string;
  onCancel?: () => void;
}

export default function Modal({
  children,
  title,
  closable = true,
  visible,
  className,
  onCancel,
}: ModalProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const nodeRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    setTimeout(() => {
      setIsModalOpen(visible ?? false);
    }, 0);
  }, [visible]);

  return (
    <CSSTransition
      unmountOnExit
      classNames="modal"
      in={isModalOpen}
      nodeRef={nodeRef}
      timeout={300}
    >
      <div ref={nodeRef} className="fixed inset-0 z-40 flex items-center justify-center">
        <div
          aria-hidden="true"
          className="fixed inset-0 cursor-default bg-black opacity-50"
          role="button"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            closable && onCancel?.();
          }}
        />
        <div className="hide-scrollbar flex h-[550px] min-w-[300px] flex-col items-center justify-center overflow-auto py-4">
          <div className={clsx(className, "relative w-full rounded-lg bg-white shadow-lg")}>
            {!!title && (
              <div className="px-4 py-2">
                <h3 className="text-lg font-semibold">{title}</h3>
              </div>
            )}
            <div className="absolute right-0 top-0 p-2">
              <Button size="small" type="button" variant="text" onClick={onCancel}>
                <CloseOutlined />
              </Button>
            </div>
            <div className="px-4 pb-2">{children}</div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
