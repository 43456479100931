import { DataStates, IStatesState } from "../@types/states";
import { statesActions } from "../action-types";

type statesActionType =
| { type: typeof statesActions.STATES_MODAL_STATE }
| { type: typeof statesActions.GET_STATE_FOR_UPDATE; payload: DataStates }
| { type: typeof statesActions.IS_STATES_FOR_UPDATE; payload: boolean }
| { type: typeof statesActions.SWITCH_STATES_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: IStatesState = {
    stateForUpdate: undefined,
    isUpdateState: false,
    statesModalIsOpen: false,
    refreshTableStates: false,
}

export default function statesReducer(state: IStatesState = INITIAL_STATE, action: statesActionType): IStatesState {
    switch(action.type){
        case statesActions.STATES_MODAL_STATE:
            return{
                ...state,
                statesModalIsOpen: !state.statesModalIsOpen
            }
        case statesActions.GET_STATE_FOR_UPDATE:
            return{
                ...state,
                stateForUpdate: action.payload
            }
        case statesActions.IS_STATES_FOR_UPDATE:
            return{
                ...state,
                isUpdateState: action.payload
            }
        case statesActions.SWITCH_STATES_REFRESH_TABLE:
            return{
                ...state,
                refreshTableStates: action.payload
            }
        default:
            return state
    }
}