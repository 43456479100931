import { Button, Col, Row } from "antd";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { FormProvider } from "react-hook-form";

import { selectModalCoursesCategories } from "../../../redux";
import {
  AdminMenuLayout,
  ModalCoursesCategories,
} from "../../../shared/components";
import { useCoursesCategories } from "../hook";
import { WrapperCardCoursesCategories } from "./styled";
import { TableCoursesCategories } from "./TableCoursesCategories";

export const CoursesCategories = () => {
  const modalCoursesCategories = useSelector(selectModalCoursesCategories);
  const {
    isUpdateCoursesCategories,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalCoursesCategoriesState,
  } = useCoursesCategories();

  return (
    <AdminMenuLayout
      pageDescription="CoursesCategories"
      title="CoursesCategories"
    >
      <WrapperCardCoursesCategories>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalCoursesCategoriesState}
            >
              Agregar nueva categoría de curso
            </Button>
          </Col>
        </Row>
        <TableCoursesCategories />
        {modalCoursesCategories && isUpdateCoursesCategories && (
          <FormProvider {...formMethodsUpdate}>
            <ModalCoursesCategories />
          </FormProvider>
        )}
        {modalCoursesCategories && !isUpdateCoursesCategories && (
          <FormProvider {...formMethodsCreate}>
            <ModalCoursesCategories />
          </FormProvider>
        )}
      </WrapperCardCoursesCategories>
    </AdminMenuLayout>
  );
};
