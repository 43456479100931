"use client";

import React from "react";
import { message } from "antd";
import { academyEquipmentRentsService } from "../../../../shared/services";
import { RentEquipmentStudentsAttributesType } from "../../../../types/dto/equipments";

export default function useEquipmentRentsById(id: number) {
  const [equipmentRentData, setEquipmentRentData] =
    React.useState<RentEquipmentStudentsAttributesType>();
  const [totalAmountToPay, setTotalAmountToPay] =
    React.useState<any>();
  
  const [loading, setLoading] = React.useState(false);

  const getEquipmentRentById = async () => {
    if (loading) return;
    setLoading(true);

    await academyEquipmentRentsService
      .get(id)
      .then((response) => {
        setEquipmentRentData(response);
      })
      .catch((err: any) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTotalMountToPay = async () => {
    if (loading) return;
    setLoading(true);

    await academyEquipmentRentsService
      .get(id, { query: { $client: { getTotalAmountToPay: true }, skipJoin: true } })
      .then((response) => {
        setTotalAmountToPay(response.total_amount);
      })
      .catch((err: any) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
 
  const setQuantityAvailableEquipmentAndPayment = async (equipmentStudentId: number) => {
    if (loading) return;
    setLoading(true);

    await academyEquipmentRentsService
      .patch(equipmentStudentId, {}, { query: { $client: { setQuantityAvailableEquipmentAndPayment: true } } })
      .then((response) => {
        return response;
      })
      .catch((err: any) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (id) {
      getEquipmentRentById();
      getTotalMountToPay()
    }
  }, [id]);

  return {
    loading,
    equipmentRentData,
    totalAmountToPay,
    getEquipmentRentById,
    getTotalMountToPay,
    setQuantityAvailableEquipmentAndPayment
  };
}
