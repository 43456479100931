import * as yup from 'yup'

export const validateUpdateRankings = yup.object().shape({
    user_id: yup
    .number().typeError('Campo requerido.')
    .positive(),
    product_id: yup
    .number().typeError('Campo requerido.')
    .positive(),
    score: yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(1, 'Debe de utilizar un numero que no sea negativo'),
    message: yup
    .string()
    .min(2, 'EL MENSAJE debe tener más de 1 caracteres'),
})