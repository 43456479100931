import {  Col, Typography } from "antd";
import { programPaymentScheduleStudentAcademy } from "../../../../shared/services";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import Table from "../../../../shared/components/ui/Table";
import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import { CopyProgramSchedulePaymentStudentType } from "../../../../types/dto/scheduleStudent";
import { formatNumberPrice } from "../../../../shared/utils/Helper";

const {Text, Title} = Typography;

export const TableAcademyStudentPendingPayment = () => {
  
  const columns: TableColumns<CopyProgramSchedulePaymentStudentType> = [
   
    {
      title: "Estudiante",
      dataIndex: "id",
      key: "name",
      width: 5,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {`${item?.program_student?.user?.first_name} ${item?.program_student?.user?.last_name}`}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Programa",
      key: "id",
      width: 4,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Text className="capitalize">
              {item?.program_student?.program.name}
            </Text>
          </Col>
        );
      },
    },
    {
      title: "Fecha de Pago Programada",
      dataIndex: "month",
      key: "month",
      width: 6,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <span className="capitalize">
              {moment(item?.payment_schedule?.month).format("MMM / YYYY")}
            </span>
          </Col>
        );
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
    },
    {
      title: "Fecha Limite de Pago",
      dataIndex: "month",
      key: "month",
      width: 6,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <span className="capitalize">
              {moment(item?.payment_schedule?.day_of_month_payment_limit).format("YYYY/MM/DD")}
            </span>
          </Col>
        );
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
    },
    {
      title: "Valor extraordinario",
      key: "program_payment_schedule_price",
      width: 5,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <span className="capitalize">
              {formatNumberPrice(+item?.program_payment_schedule_price)}
            </span>
          </Col>
        );
      },
    },
     {
      title: "Valor pronto pago",
      key: "program_payment_schedule_prompt_price",
      width: 5,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <span className="capitalize">
              {formatNumberPrice(+item?.program_payment_schedule_prompt_price)}
            </span>
          </Col>
        );
      },
    },
    {
      title: "Valor pagado del estudiante",
      key: "month",
      width: 6,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <span className="capitalize">
              {formatNumberPrice(item?.amount_paid)}
            </span>
          </Col>
        );
      },
    },
    {
      title: "Balance de cobro",
      key: "month",
      width: 6,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <span className="capitalize">
              {formatNumberPrice(item?.balance)}
            </span>
          </Col>
        );
      },
    },
    {
      title: "Fecha pago estudante",
      key: "month",
      width: 6,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <span className="capitalize">
              {item?.payment_due_date && moment(item?.payment_due_date).format('YYYY/MM/DD ')}
              {!item?.payment_due_date && 'Pendiente por pago'}
            </span>
          </Col>
        );
      },
    },
    {
      title: "Estado",
      dataIndex:'payment_status',
      key: "payment_status",
      width: 6,
      fixed: 'right',
      render: (_, item) => {
        const bgText =
          (item?.payment_status === "paid" && "#4bd542") ||
          (item?.payment_status === "unpaid" && "orange") ||
          (item?.payment_status === "overdue" && "#ff4d4f") ||
          (item?.payment_status === "partial_payment" && "yellow");
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                textAlign: 'center',
                background: String(bgText),
                padding: 8,
                borderRadius: 20,
              }}
            >
          {item?.payment_status === "paid" && "Pagado"} 
          {item?.payment_status === "unpaid" && "Pendiente por pago"} 
          {item?.payment_status === "overdue" && "En mora"} 
          {item?.payment_status === "partial_payment" && "Acuerdo de pago"}
            </Text>
          </Col>
        );
      },
      filters: [
        {
          text: "Pagado",
          value: "paid",
        },
        {
          text: "Pendiente por pago",
          value: "unpaid",
        },
        {
          text: "Acuerdo de pago",
          value: "partial_payment",
        },
      ],
    },
  ];

  return (
    <>
      <Table<CopyProgramSchedulePaymentStudentType>
        columns={columns}
        fetchQueryProps={{
          status: 'active'
        }}
        service={programPaymentScheduleStudentAcademy}
      />
    </>
  );
};
