import { Button, Col } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { DataCities } from "../../../redux/@types";

import { useStates, useTableStates } from "../hook";
import { DataStates } from "../../../redux/@types/states";
import Table from "../../../shared/components/ui/Table";
import { statesService } from "../../../shared/services";
import { TableColumns } from "../../../shared/components/ui/Table/types";

export const TableStates = () => {
  const { changeStateForUpdate, showDeleteConfirm } = useTableStates();
  const { setTableProps } = useStates();

  const columns: TableColumns<DataStates> = [
    {
      title: "ID",
      width: 5,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Nombre",
      width: 8,
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 3,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeStateForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <Table<DataStates>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      scroll={{ x: 'max-content' }}
      service={statesService}
      onLoad={setTableProps}
    />
  );
};
