import { useSelector } from "react-redux";
import { message } from "antd";
import { useParams } from "react-router-dom";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { selectOrderForUpdate } from "../../../../redux/selectors/orders.selectors";

import {
  changeOrderChangeShipping,
  changeOrderChangeShippingID,
  changeOrderModalIsOpen,
  changeOrderModalShippedIsOpen,
} from "../../../../redux/actions/orders.actions";
import { shippingService } from "../../../../shared/services";
import { useState } from "react";
import { DataShipping } from "../../../../redux/@types";

export const useOrderById = () => {
  const params = useParams();
  const orderForUpdate = useSelector(selectOrderForUpdate);
  const dispatch = useAppDispatch();

  const [orderShippingShow, setOrderShippingShow] = useState<DataShipping>();
  const createShipping = async () => {
    return await shippingService
      .create({ order_id: Number(params.id) })
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  const getShipping = async () => {
    return await shippingService
      .find({
        query: {
          order_id: Number(params.id),
          $limit: 9999999999999,
          shipping_status_id: 1,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  const changeOrderModal = () => {
    getShipping().then((res: any) => {
      if (res.data.length > 0) {
        dispatch(changeOrderChangeShippingID(res.data[res.data.length - 1].id));
        return console.log("ya existe una orden de envió");
      } else {
        createShipping().then((res2: any) => {
          if(!!res2){
            dispatch(changeOrderChangeShippingID(res2?.id ? res2?.id : 0));
            dispatch(changeOrderChangeShipping([res2]));
            message.success(
              `Petición de order creada con éxito con el id: ${res2?.id ? res2?.id : 0}`
            );
          }
        });
      }
    });
    dispatch(changeOrderModalIsOpen());
  };

  const changeOrderShippedModal = (item: any) => {
    setOrderShippingShow(item);
    dispatch(changeOrderModalShippedIsOpen());
  };

  return {
    //state
    orderForUpdate,
    orderShippingShow,
    // shippingIDForAdd,
    //methods
    //functions
    changeOrderModal,
    changeOrderShippedModal,
  };
};
