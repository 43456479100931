import React from "react";
import { programPaymentScheduleStudentAcademy, programsStudentAcademy } from "../../../../shared/services";
import { message } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver, useForm } from "react-hook-form";
import { ProgramScheduleType, ProgramStudentType } from "../../../../types/dto/program";
import * as yup from "yup";
import { CopyProgramSchedulePaymentStudentType } from "../../../../types/dto/scheduleStudent";

export interface UseProgramStudentProps {
  programId: number;
  setOpenModal: (value: boolean) => void;
  selectedSchedules: { id: number; price: number }[]; // Nueva prop para manejar id y price
}

export default function useAssignedStudentProgram({
  programId,
  setOpenModal,
  selectedSchedules, // Recibe los schedules seleccionados
}: UseProgramStudentProps) {
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit: onSubmit } = useForm<ProgramStudentType>({
    resolver: yupResolver(
      yup.object({
        user_id: yup.number().required("Campo Requerido"),
      })
    ) as unknown as Resolver<ProgramStudentType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);


     await programsStudentAcademy
      .create({
        user_id: +data.user_id,
        program_id: +programId,
      })
      .then( async (res) => {
        setOpenModal(false);
        for (const index in selectedSchedules) {
          await programPaymentScheduleStudentAcademy.create({
            payment_schedule_id: selectedSchedules[index].id,
            program_student_id: res.id,
          })
        }
        message.success(
          `Se ha asignado el estudiante al programa con id: ${programId}`
        );
        return res
      })
      .catch((err: Error) => {
        message.error(`Ha ocurrido un error: ${err.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    control,
    loading,
    handleSubmit,
  };
}
