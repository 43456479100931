import type { Resolver } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { programsModuleAcademy } from "../../../../shared/services";
import { ProgramModuleType } from "../../../../types/dto/program";

export interface UseCreateLevelTopicProps {
  initialData?: Partial<ProgramModuleType>;
  onSuccess?: (levelTopic: ProgramModuleType) => void;
}

export default function useProgramModule(props?: UseCreateLevelTopicProps) {
  const [loading, setLoading] = React.useState(false);
  const {
    control,
    handleSubmit: onSubmit,
    reset,
  } = useForm<ProgramModuleType>({
    resolver: yupResolver(
      yup.object({
        name: yup.string().required(),
        description: yup.string().required(),
        status: yup.string().required(),
      })
    ) as unknown as Resolver<ProgramModuleType>,
    defaultValues: props?.initialData,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);

    if (!props?.initialData?.program_id) return;
    await programsModuleAcademy
      .create({ ...data })
      .then((res) => {
        reset({});
        props?.onSuccess?.(res);
      })
      .catch((err: Error) => {})
      .finally(() => {
        setLoading(false);
      });
  });

  React.useEffect(() => {
    if (props?.initialData) {
      reset({ ...props.initialData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.initialData]);

  return {
    control,
    loading,
    handleSubmit,
  };
}
