// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useRef, useState } from "react";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import SunEditor from "suneditor-react";
import plugins from "suneditor/src/plugins";
import SunEditorCore from "suneditor/src/lib/core";
import "suneditor/dist/css/suneditor.min.css";
import lang from "suneditor/src/lang";

import { InputError } from "./styled";
import { message } from "antd";

interface Props {
  width?: string;
  placeHolder: string;
  name: string;
  control: any;
  isUpdating?: boolean;
}

interface PropsContent {
  errors: Partial<FieldErrorsImpl<{
    [x: string]: any;
  }>>;
  name: string;
}

const ErrorComponent = ({errors,name}:PropsContent) =>{
  useEffect(() => {
    if(errors?.[name]?.message){
      message.error(`${errors?.[name]?.message}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])
  return(
    <>
    {!!errors[name] && (
      <InputError>{errors?.[name]?.message}</InputError>
      )}
      </>
  )
}

export const InputHTML = ({
  width = "100%",
  placeHolder,
  name,
  control,
  isUpdating,
}: Props) => {
  const [loadInfo, setLoadInfo] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoadInfo(!loadInfo);
    }, 450);
  }, []);

  const editor = useRef<SunEditorCore>();
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name}
      render={({ field: { onChange, value = "" }, formState: { errors } }) => {
        // The sunEditor parameter will be set to the core suneditor instance when this function is called
        const getSunEditorInstance = (sunEditor: SunEditorCore) => {
          editor.current = sunEditor;
        };
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width,
              flexDirection: "column",
            }}
          >
            {isUpdating ? (
              <>
                {loadInfo && (
                  <SunEditor
                    getSunEditorInstance={getSunEditorInstance}
                    name={name}
                    defaultValue={value}
                    placeholder={placeHolder}
                    width="100%"
                    height="100%"
                    setOptions={{
                      plugins: plugins,
                      //katex: katex,
                      buttonList: [
                        [
                          ":p-More Paragraph-default.more_paragraph",
                          "font",
                          "fontSize",
                          "formatBlock",
                          "paragraphStyle",
                          "blockquote",
                        ],
                        [
                          "font",
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "subscript",
                          "superscript",
                        ],
                        ["fontColor", "hiliteColor", "textStyle"],
                        ["removeFormat"],
                        ["outdent", "indent"],
                        ["align", "horizontalRule", "list", "lineHeight"],
                        ["fullScreen", "showBlocks", "codeView", "preview"],
                      ],
                      lang: lang.es,
                    }}
                    onChange={(content: string) => {
                      onChange({ target: { value: content } });
                    }}
                  />
                )}
              </>
            ) : (
              <SunEditor
                getSunEditorInstance={getSunEditorInstance}
                name={name}
                defaultValue={value}
                placeholder={placeHolder}
                width="100%"
                height="100%"
                setOptions={{
                  //   plugins: [font],
                  //   buttonList: [["font"]],
                  lang: lang.es,
                }}
                onChange={(content: string) => {
                  onChange({ target: { value: content } });
                }}
              />
            )}
            <ErrorComponent errors={errors} name={name}/>
          </div>
        );
      }}
    />
  );
};
