import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  deleteUser,
  refreshTableUsers,
  setIsUserForUpdate,
  setUserForUpdate,
  userChangeModalState,
} from "../../../redux";
import { IUserResFind } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useUsers } from "./useUsers";

const { confirm } = Modal;

export const useTableUser = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useUsers();

  const changeUserForUpdate = (user: IUserResFind) => {
    dispatch(setIsUserForUpdate(true));
    dispatch(setUserForUpdate(user));
    dispatch(userChangeModalState());
  };

  const showDeleteConfirm = (valId: number, valFullName: string) => {
    confirm({
      title: `Estás seguro de borrar al usuario identificado con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${valFullName}", si deseas continuar dale click a "Borrar usuario" en caso tal solo oprime en "
      Cancelar".`,
      okText: "Borrar usuario",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteUser(valId)
          .then(() => {
            dispatch(refreshTableUsers(true));
            message.success(
              `Se ha borrado con éxito el usuario con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeUserForUpdate,
    showDeleteConfirm,
  };
};
