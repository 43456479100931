import { Card, Drawer, Modal } from "antd";
import styled from "styled-components";
export const WrapperCard = styled(Card)`
  display: flex;
  width: 100%;
  height: auto;
  text-align: left;
  font-weight: 400;
  /* font-family: "Poppins", "font-awesome"; */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  border: 1px solid #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: #fff;
  border-radius: 1rem !important;
  min-height: 100%;

  .ant-card-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

//ModalRankings

export const WrapperModalRankings = styled(Drawer)`
  /* min-height: 100vh;
  padding: 0;
  display: flex;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
  width: 25vw;

  & .ant-modal-content {
    width: 100%;
  }

  & .ant-modal-body {
  }

  & .ant-modal-footer {
    display: none;
  }

  @media (max-width: 767px) {
    margin: 0;
  } */
`;
