import {
  Button,
  Col,
  Dropdown,
  Input,
  List,
  Menu,
  Row,
  Typography,
  message,
} from "antd";
import {
  AdminMenuLayout,
  InputNumber,
  InputText,
} from "../../../../shared/components";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import React from "react";
import { getService } from "../../../../clients/feathers.clients";
import { WrapperList } from "./styled";
import { MyModal } from "./MyModal";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Map from "./map/Map";
import ColorField from "./colorField";

const { Title } = Typography;

const MenuItems = ({
  onChange,
  actions = { edit: true, delete: true },
  ...props
}: {
  record: any;
  onChange?: (key: string, record: any) => void;
  actions?: {
    edit: boolean;
    delete: boolean;
  };
  className?: string;
}) => (
  <Menu
    className={props?.className || "list-menu"}
    onClick={({ key }) => {
      onChange?.(key, props.record);
    }}
  >
    {actions.edit && (
      <Menu.Item key="edit">
        <EditOutlined />
        Editar
      </Menu.Item>
    )}
    {actions.delete && (
      <Menu.Item key="delete">
        <DeleteOutlined />
        Eliminar
      </Menu.Item>
    )}
  </Menu>
);

const ListPolygons = ({
  onSelect,
  filterDefaultValues,
  refresh,
  onSubmit,
  onItemSelect,
  fulfillmentCompany,
  reference,
  buttonText,
  title,
  onRemove,
  ...props
}: any) => {
  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [center, setCenter] = React.useState<{ lat: number; lng: number }[]>([
    { lat: 10.9996218, lng: -74.8124816 },
    { lat: 11.008604952841194, lng: -74.8124816 },
    { lat: 10.999621663119397, lng: -74.80333032397171 },
  ]);
  const [selected, setSelected] = React.useState<any | null>(null);

  const { handleSubmit: formSubmit, control, reset } = useForm();

  const handleSelect = (item: any) => {
    onItemSelect?.(item);
    setSelected(item);
  };

  const handleAdd = () => {
    setSelected(null);
    setVisible(true);
  };

  const removePolygon = (id: number) => {
    const service = getService("shipping-costs");

    service
      .remove(id)
      .then(() => {
        message.success("Polígono eliminado.");
        onRemove?.()
        getData();
      })
      .catch((err: any) => {
        message.error(err?.message);
      });
  };

  const handleChange = (key: string, item: any) => {
    if (key === "edit") {
      setSelected(item);
      setVisible(true);
      reset(item)
    } else if (key === "delete") {
      if (item?.id) removePolygon(item.id);
    }
  };

  const renderItem = (record: any, index: number) => {
    let item = record;

    return (
      <List.Item
        actions={[
          <div>
            <Dropdown
              trigger={["click"]}
              overlay={() => (
                <MenuItems
                  onChange={(key) => handleChange(key, item)}
                  record={item}
                />
              )}
            >
              <Button type="link">
                <MoreOutlined />
              </Button>
            </Dropdown>
          </div>,
        ]}
        className={`item ${
          selected && selected.id === item.id ? "selected" : ""
        }`}
        onClick={() => {
          if (item) handleSelect(item);
        }}
      >
        <div className="item-container">
          <div className="item-color">
            <div
              style={{
                background: item.color || "#cccccc4a",
              }}
              className="color"
            ></div>
          </div>
          <div className="item-name">
            <div className="name">{record.name}</div>
            {record.price && (
              <div className="item-price">
                {(record.price as number)?.toLocaleString("es-CO", {
                  style: "currency",
                  currency: "COP",
                  minimumFractionDigits: 0,
                })}
              </div>
            )}
          </div>
        </div>
      </List.Item>
    );
  };

  const getData = () => {
    if (reference) {
      const service = getService(reference);
      setLoading(true);

      service
        .find({
          query: {
            ...filterDefaultValues,
            fulfillment_company_id: fulfillmentCompany?.id,
            $limit: 50000,
          },
        })
        .then(({ data }: { data: any }) => {
          setDataSource(data);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          message.error(err?.message);
        });
    }
  };

  const handleOnSubmit = (data: any) => {
    setVisible(false);
    setSelected(null);
    onItemSelect?.(data);
    setSelected(data);
    onSubmit?.(data);
    getData();
  };

  React.useEffect(() => {
    if (fulfillmentCompany?.id) getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentCompany?.id]);

  React.useEffect(() => {
    if (refresh) getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  React.useEffect(() => {
    if (props?.selected) setSelected(props.selected);
  }, [props.selected]);

  React.useEffect(() => {
    onSelect(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  React.useEffect(() => {
    if (props?.center && props.center.lat && props.center.lng) {
      let polygons = [];
      let { google } = window as any;

      if (
        google &&
        google.maps &&
        google.maps.geometry &&
        google.maps.geometry.spherical
      ) {
        const distance = 1000;
        const pointA = new google.maps.LatLng(
          props.center.lat,
          props.center.lng
        );
        const pointB = google.maps.geometry.spherical.computeOffset(
          pointA,
          distance,
          0
        );
        const angle = google.maps.geometry.spherical.computeHeading(
          pointA,
          pointB
        );
        const newAngle = angle + 90;
        const pointC = google.maps.geometry.spherical.computeOffset(
          pointA,
          distance,
          newAngle
        );
        polygons.push({
          lat: pointA.lat(),
          lng: pointA.lng(),
        });
        polygons.push({
          lat: pointB.lat(),
          lng: pointB.lng(),
        });
        polygons.push({
          lat: pointC.lat(),
          lng: pointC.lng(),
        });
        setCenter(polygons);
      }
    }
  }, [props.center]);

  return (
    <WrapperList>
      <List
        header={
          <div className="head">
            <h3>{title}</h3>
            <div className="head-tools">
              <Button
                onClick={handleAdd}
                type="primary"
                block
                icon={<PlusOutlined />}
              >
                {buttonText || "Agregar zona de cobertura"}
              </Button>
            </div>
          </div>
        }
        renderItem={renderItem}
        dataSource={dataSource}
      />
      <MyModal
        title={selected && selected.id ? "Editar polígono" : "Crear polígono"}
        width={400}
        onCancel={() => {
          setVisible(false);
          setSelected(null);
        }}
        visible={visible}
      >
        <form  onSubmit={formSubmit(handleOnSubmit)}>
          <Col xs={24}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Nombre
            </Title>
            <InputText placeHolder="Nombre" name="name" control={control} />
          </Col>
          <Col xs={24}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Costo de envío
            </Title>
            <InputNumber
              placeHolder="Costo de envío"
              name="price"
              control={control}
            />
          </Col>
          <Col xs={24}>
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Color
            </Title>
            <Controller control={control} name="color" render={({ field: { onChange, value }}) => {
              return (
                <ColorField
                  name="color"
                  onChange={onChange}
                  value={value}
                  placeholder="Color"
                />
              )
            }} />
          </Col>
          <Col xs={24} style={{
            marginTop: '20px',
            marginBottom: 20
          }}>
            <Button htmlType="submit" type="primary" block>
              Guardar
            </Button>
          </Col>
        </form>
      </MyModal>
    </WrapperList>
  );
};

export default function PolygonFulFillmentCompany() {
  const { id: fulfillmentCompanyId } = useParams();
  const [fulfillmentCompany, setFulFillmentCompany] = React.useState<any>();
  const [item, setItem] = React.useState<any>();
  const [refresh, setRefresh] = React.useState(false);

  const generateDefaultPolygon = (lat: number, lng: number) => {
    const radiusKm = 1;
    const coords = [];
    const numPoints = 8;

    for (let i = 0; i < numPoints; i++) {
      const theta = (i / numPoints) * 2 * Math.PI;
      const pointLat = lat + radiusKm * Math.cos(theta) / 110.574;
      const pointLng = lng + (radiusKm * Math.sin(theta) / (111.32 * Math.cos(lat * (Math.PI / 180))));
      coords.push({ lat: pointLat, lng: pointLng });
    }

    return coords
  }


  const handleOnSubmit = (data: any) => {
    const service = getService("shipping-costs");

    const {id, ...rest} = data

    if (id) {
      service.patch(id, {
        name: rest.name,
        price: rest.price,
        color: rest.color,
      })
      .then(() => {
        setRefresh(true)
      })
      .catch((err: any) => {
        message.error(err?.message);
      });
    } else {
      service
        .create({
          ...data,
          polygon: generateDefaultPolygon(fulfillmentCompany?.default_lat || 11.0079481, fulfillmentCompany?.default_lng || -74.813056),
          fulfillment_company_id: fulfillmentCompany?.id,
        })
        .then(() => {
          setRefresh(true)
        })
        .catch((err: any) => {
          message.error(err?.message);
        });
    }
  };

  const handleOnSelect = (item: any) => {
    setItem(item);
  };

  const getFulfillmentCompany = (fulfillmentCompanyId: number) => {
    const service = getService("fulfillment-company");

    service
      .get(fulfillmentCompanyId)
      .then((res: any) => {
        setFulFillmentCompany(res);
      })
      .catch((err: any) => {
        message.error(err?.message);
      });
  };

  React.useEffect(() => {
    if (fulfillmentCompanyId) getFulfillmentCompany(+fulfillmentCompanyId);
  }, [fulfillmentCompanyId]);

  React.useEffect(() => {
    if (refresh) setRefresh(false)
  }, [refresh])

  return (
    <AdminMenuLayout pageDescription="Costos de envío" title="Costos de envío">
      <Row>
        <Col span={24}>
          <Typography.Title level={3} style={{
            textAlign: 'center'
          }}>
            {fulfillmentCompany?.name}
          </Typography.Title>
        </Col>
        <Col span={6}>
          <ListPolygons
            reference="shipping-costs"
            filterDefaultValues={{
              fulfillment_company_id: fulfillmentCompanyId,
            }}
            selected={item}
            refresh={refresh}
            onSelect={(item: any) => setItem(item)}
            onSubmit={handleOnSubmit}
            onRemove={() => {
              setRefresh(true)
            }}
            fulfillmentCompany={fulfillmentCompany}
            onItemSelect={handleOnSelect}
          />
        </Col>
        <Col span={18}>
      
            <Map
              fulfillmentCompany={fulfillmentCompany}
              onSelectItem={(item: any) => setItem(item)}
              selectedItem={item}
              refresh={refresh}
            />
      
        </Col>
      </Row>
    </AdminMenuLayout>
  );
}
