import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useDirectoryCategories,
  WrapperModalDirectoryCategories,
} from "../../../../modules";

import {
  selectDirectoryCategoriesForUpdate,
  selectIsUpdateDirectoryCategories,
  selectModalDirectoryCategories,
} from "../../../../redux";
import { DataDirectoryCategories } from "../../../../redux/@types";
import { InputText } from "../inputs";

const { Title } = Typography;

export const ModalDirectoryCategories = () => {
  const modalDirectoryCategories = useSelector(selectModalDirectoryCategories);
  const isUpdateDirectoryCategories = useSelector(
    selectIsUpdateDirectoryCategories
  );
  const updateDirectoryCategories = useSelector(
    selectDirectoryCategoriesForUpdate
  );

  const {
    isLoading,
    changeModalDirectoryCategoriesState,
    onSubmitCreateOrUpdate,
  } = useDirectoryCategories();

  const { control, handleSubmit: onSubmit } =
    useFormContext<DataDirectoryCategories>();
  return (
    <WrapperModalDirectoryCategories
      title={
        isUpdateDirectoryCategories
          ? `Actualizando categoría: '${updateDirectoryCategories?.id!}'`
          : `Creando categoría`
      }
      open={modalDirectoryCategories}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalDirectoryCategoriesState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText placeHolder="John Doe" name="name" control={control} />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateDirectoryCategories
          ? "Actualizar información"
          : "Crear categoría"}
      </Button>
    </WrapperModalDirectoryCategories>
  );
};
