import { Col, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

interface PropsTable {
  id: number;
  createdAt: Date;
  order_id: number;
  name: string;
  orders_status_id: number;
}

interface Props {
  userName: string;
  data: PropsTable[];
}

const { Title } = Typography;

export const TableOrderById = ({ data, userName }: Props) => {
  const columns: ColumnsType<PropsTable> = [
    {
      title: "Estado",
      dataIndex: "name",
    },
    {
      title: "Usuario",
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Title
            style={
              {
                // marginBottom: "1.5px",
              }
            }
            level={5}
          >
            {userName}
          </Title>
        </Col>
      ),
    },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Title
            style={
              {
                // marginBottom: "1.5px",
              }
            }
            level={5}
          >
            {moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")}
          </Title>
        </Col>
      ),
    },
  ];

  return (
    <Table
      style={{ width: "100%" }}
      pagination={false}
      bordered
      columns={columns}
      dataSource={data}
    />
  );
};
