import * as yup from 'yup'

export const validateUpdateWalletMovements = yup.object().shape({
    user_id:yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    amount_net:yup
    .number().typeError('Campo requerido.'),
    description:yup
    .string()
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    expired_day: yup
    .date(),
})