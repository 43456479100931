import { IWorkOffersCategoriesState } from "../@types";
import { workOffersCategoriesActions } from "../action-types";
import { DataWorkOffersCategories } from '../@types/workOffersCategories';

type workOffersCategoriesType =
| { type: typeof workOffersCategoriesActions.WORK_OFFERS_CATEGORIES_MODAL_STATE }
| { type: typeof workOffersCategoriesActions.GET_WORK_OFFERS_CATEGORIES_FOR_UPDATE; payload: DataWorkOffersCategories }
| { type: typeof workOffersCategoriesActions.IS_WORK_OFFERS_CATEGORIES_FOR_UPDATE; payload: boolean }
| { type: typeof workOffersCategoriesActions.SWITCH_WORK_OFFERS_CATEGORIES_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: IWorkOffersCategoriesState ={
    workOffersCategoriesForUpdate: undefined,
    isUpdateWorkOffersCategories: false,
    workOffersCategoriesModalIsOpen: false,
    refreshTableWorkOffersCategories: false,
}

export default function workOffersCategoriesReducer(state: IWorkOffersCategoriesState = INITIAL_STATE, action: workOffersCategoriesType): IWorkOffersCategoriesState {
    switch(action.type){
        case workOffersCategoriesActions.WORK_OFFERS_CATEGORIES_MODAL_STATE:
            return{
                ...state,
                workOffersCategoriesModalIsOpen: !state.workOffersCategoriesModalIsOpen
            }
        case workOffersCategoriesActions.GET_WORK_OFFERS_CATEGORIES_FOR_UPDATE:
            return{
                ...state,
                workOffersCategoriesForUpdate: action.payload
            }
        case workOffersCategoriesActions.IS_WORK_OFFERS_CATEGORIES_FOR_UPDATE:
            return{
                ...state,
                isUpdateWorkOffersCategories: action.payload
            }
        case workOffersCategoriesActions.SWITCH_WORK_OFFERS_CATEGORIES_REFRESH_TABLE:
            return{
                ...state,
                refreshTableWorkOffersCategories: action.payload
            }
        default:
            return state
    }
}