import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { createCMS, cMSChangeModalState, refreshTableCMS, selectIsUpdateCMS, selectCMSForUpdate, selectCMSRefreshTable, setIsCMSForUpdate, updateCMS } from "../../../redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { validateCreateCMS, validateUpdateCMS } from '../../../helpers';
import { DataCMS } from '../../../redux/@types/cms';

export const useCMS = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isUpdateCMS = useSelector(
      selectIsUpdateCMS
    );
    const dataUpdateCMS = useSelector(
      selectCMSForUpdate
    );
    const isRefreshTableCMS = useSelector(
      selectCMSRefreshTable
    );

    const [isLoading, setIsLoading] = useState(false);
    const [tableProps, setTableProps] = useState<TableProperties>();

    const changeModalCMSState = () => {
      dispatch(setIsCMSForUpdate(false));
      navigate(`/cms/new`);
    };

    const formMethodsCreate = useForm<DataCMS>({
      resolver: yupResolver(validateCreateCMS),
    });

    const formMethodsUpdate = useForm<DataCMS>({
      resolver: yupResolver(validateUpdateCMS),
    });

    useEffect(() => {
      if (isUpdateCMS) {
        formMethodsCreate.reset();
        formMethodsUpdate.reset({ ...dataUpdateCMS });
      }

      return () => {
        formMethodsUpdate.reset();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateCMS, dataUpdateCMS]);

    const updateStatus = async(value: "active" | "inactive", id: any) =>{
      if (isLoading) return;
      setIsLoading(true);
      await updateCMS({
        id,
        status: value,
      })
      .then((res: Partial<DataCMS>) => {
        setIsLoading(false);
        if (res.id) {
          // dispatch(bannersChangeModalState());
          message.success(
            `Se ha actualizado con éxito el estado de la marca con id:${res.id}`
          );
          dispatch(refreshTableCMS(true));
        } else {
          message.error(res as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
    }

    const onSubmitCreateOrUpdate = async (data: DataCMS) => {
      // console.log({ data });
      if (isLoading) return;
      setIsLoading(true);
      if (isUpdateCMS) {
        //console.log("Actualiando===>", data);
        await updateCMS({
          id: data.id,
          title: data.title,
          body: data.body,
          status: data.status,
        })
          .then(async(res: Partial<DataCMS>) => {
            setIsLoading(false);
            // dispatch(mappingUpdatedUser(res))
            if (res.id) {
              // dispatch(cMSChangeModalState());
              navigate(`/cms`);
              message.success(
                `Se ha actualizado con éxito el CMS con id:${res.id}`
              );
              dispatch(refreshTableCMS(true));
            } else {
              message.error(res as string);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            console.log(err);
            message.error(err.message);
          });
      } else {
        // console.log('Creando===>',data)
        await createCMS(data)
          .then((res: DataCMS) => {
            setIsLoading(false);
            if (res.id) {
              dispatch(cMSChangeModalState());
              navigate(`/cms`);
              message.success(
                `Se ha creado con éxito el CMS con id:${res.id}`
              );
              dispatch(refreshTableCMS(true));
            } else {
              message.error(res as any);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            console.log(err);
            message.error(err.message);
          });
      }
    };

    useEffect(() => {
      if (isRefreshTableCMS) {
        tableProps?.refresh();
        dispatch(refreshTableCMS(false));
      }
    }, [isRefreshTableCMS]);

  return {
    //state
    isUpdateCMS,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalCMSState,
    updateStatus,
  }
}
