import * as yup from 'yup'

export const validateCreateWalletMovements = yup.object().shape({
    // user_id:yup
    // .string()
    // .required('Campo requerido.')
    // .min(1, "debes de tener al menos un dígito"),
    amount_net:yup
    .number().typeError('Campo requerido.')
    .required('Campo requerido.'),
    description:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    expired_day: yup
    .date(),
    // .required('Campo requerido.'),
})