"use client";


import React from "react";
import { ProgramTopicType } from "../../../../types/dto/program";
import { programsTopicsAcademy } from "../../../../shared/services";

import { message, Modal } from "antd";
const { confirm } = Modal;

export interface UseDeleteProgramModuleProps {
  onSuccess?: (programModule: ProgramTopicType) => void;
}

export default function useDeleteTopic(
) {
  const [loading, setLoading] = React.useState(false);

  const showDeleteConfirm = (valId: number, val: string) => {
    if (!valId ) {
      message.error("Tema no valido");
      return;
    }

    confirm({
      title: `Estás seguro de borrar el tema con el id: ${valId}`,
      // icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar tama" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar tama",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        setLoading(true);
        try {
          await programsTopicsAcademy.remove(valId).then((res)=>{
            message.success(`Se ha borrado con éxito el tama con id:${valId}`);
          })
        } catch (err: any) {
          message.error(`Error: ${err.message}`);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {},
    });
  };

  return { showDeleteConfirm, loading };
}
