import { Button, Col, Row } from "antd";
import { FormProvider } from "react-hook-form";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { TableColumns } from "../../../../shared/components/ui/Table/types";
import { productCharacteristics2Service } from "../../../../shared/services";
import Table from "../../../../shared/components/ui/Table";
import { IProductCharacteristics2 } from "../../../../redux/@types";
import {
  useProductCharacteristics2,
  useTableProductCharacteristics2,
} from "../../hooks";
import { ProductCharacteristics2 } from "../../../../shared/components";
import { selectModalProductCharacteristic2 } from "../../../../redux";

export const TableProductCharacteristics = () => {
  const params = useParams();

  const modalProductCharacteristic = useSelector(
    selectModalProductCharacteristic2
  );
  const { changeProductCharacteristicForUpdate, showDeleteConfirm } =
    useTableProductCharacteristics2();
  const {
    isUpdateProductCharacteristic,
    setTableProps,
    navigateProductCharacteristicCreate,
    formMethodsCreate,
    formMethodsUpdate,
  } = useProductCharacteristics2();
  const columns: TableColumns<IProductCharacteristics2> = [
    {
      title: "ID",
      width: 5,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Característica",
      width: 5,
      dataIndex: "characteristic_id",
      key: "characteristic_id",
    },
    {
      title: "Valor de la característica",
      width: 12,
      dataIndex: "characteristic_value",
      key: "characteristic_value",
      filterType: "text",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 5,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeProductCharacteristicForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() =>
                showDeleteConfirm(item.id, `${item.characteristic_value}`)
              }
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <Row justify="start">
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          size="large"
          onClick={navigateProductCharacteristicCreate}
        >
          Agregar nueva característica
        </Button>
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Table<IProductCharacteristics2>
          columns={columns}
          fetchQueryProps={{
            product_id: params.id,
            $sort: { id: -1 },
          }}
          service={productCharacteristics2Service}
          onLoad={setTableProps}
        />
      </Col>
      {modalProductCharacteristic && isUpdateProductCharacteristic && (
        <FormProvider {...formMethodsUpdate}>
          <ProductCharacteristics2 />
        </FormProvider>
      )}
      {modalProductCharacteristic && !isUpdateProductCharacteristic && (
        <FormProvider {...formMethodsCreate}>
          <ProductCharacteristics2 />
        </FormProvider>
      )}
    </Row>
  );
};
