import { AdminMenuLayout } from "../shared/components";
import { AcademyProgramPersonalInfo } from "../modules/academyProgramPersonalInfo/ui/AcademyProgramPersonalInfo";

export default function AcademyProgramsPersonalInfoPage() {
  

  return (
    <AdminMenuLayout title="" pageDescription="">
     <AcademyProgramPersonalInfo/>
    </AdminMenuLayout>
  );
}

