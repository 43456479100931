import { WrapperAcademyEquipmentsRents } from "./style";
import React from "react";
import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Typography, Button } from "antd";
import { TableAcademyEquipmentRents } from "./ui/equipmentRents/table/TableAcademyEquipmentRents";
import { ModalEquipmentRents } from "../../shared/components/ui/modals/ModalEquipmentRents";
import { useNavigate } from "react-router-dom";
const {Title} = Typography

export default function AcademyEquipmentRents() {
  const [openModalCreateEquipmentRent, setOpenModalCreateEquipmentRent] =  React.useState<boolean>(false);
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/gestion-academy/equipments");
  };
  return (
    <WrapperAcademyEquipmentsRents>
       <span
              className="flex items-center underline text-gray-500 cursor-pointer"
              onClick={goBack}
            >
              <ArrowLeftOutlined className="mr-2" />
              Ir atras
            </span>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Title style={{ textAlign: "left", fontSize: 25 }}>
          Listado de equipos rentados
        </Title>
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          size="large"
          onClick={() => setOpenModalCreateEquipmentRent(true)}
        >
          Rentar un equipo
        </Button>{" "}
      </div>
       
      <TableAcademyEquipmentRents/>
      <ModalEquipmentRents
        openModal={openModalCreateEquipmentRent}
        setOpenModal={setOpenModalCreateEquipmentRent}
      />
    </WrapperAcademyEquipmentsRents>
    
  );
}