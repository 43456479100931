import { Input } from "antd";

interface Props {
  width?: string;
  placeHolder: string;
  value: string | undefined;
  name?: string;
  onChange: (val: any) => void;
}

export const InputTextSimple = ({
  width = "100%",
  placeHolder,
  value,
  onChange,
}: Props) => {
  return (
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     width,
    //     height: "80%",
    //     flexDirection: "column",
    //   }}
    // >
    <Input onChange={onChange} value={value} placeholder={placeHolder} />
    // </div>
  );
};
