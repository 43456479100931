import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createRanking, rankingsChangeModalState, refreshTableRankings, selectIsUpdateRankings, selectRankingsForUpdate, selectRankingsRefreshTable,  setIsRankingsForUpdate, updateRanking, } from "../../../redux";
import { DataIRankings } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateCreateRankings, validateUpdateRankings } from "../../../helpers";

export const useRankings = () => {
    const dispatch = useAppDispatch();
    const isUpdateRanking = useSelector(
        selectIsUpdateRankings
      );
      const dataUpdateRanking = useSelector(
        selectRankingsForUpdate
      );
      const isRefreshTableRankings = useSelector(
        selectRankingsRefreshTable
      );

      const [isLoading, setIsLoading] = useState(false);
      const [tableProps, setTableProps] = useState<TableProperties>();

      const changeModalRankingsState = () => {
        dispatch(setIsRankingsForUpdate(false));
        dispatch(rankingsChangeModalState());
      };

      const formMethodsCreate = useForm<DataIRankings>({
        resolver: yupResolver(validateCreateRankings),
      });

      const formMethodsUpdate = useForm<DataIRankings>({
        resolver: yupResolver(validateUpdateRankings),
      });

    useEffect(() => {
        if (isUpdateRanking) {
          formMethodsCreate.reset();
          formMethodsUpdate.reset({ ...dataUpdateRanking });
        }

        return () => {
          formMethodsUpdate.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isUpdateRanking, dataUpdateRanking]);

      const onSubmitCreateOrUpdate = async (data: DataIRankings) => {
        // console.log({ data });
        if (isLoading) return;
        setIsLoading(true);
        if (isUpdateRanking) {
          //console.log("Actualiando===>", data);
          await updateRanking({
            id: data.id,
            user_id: data.user_id,
            product_id: data.product_id,
            score: data.score,
            message: data.message,
            order_id: Number(data.order_id)
          })
            .then((res: Partial<DataIRankings>) => {
              setIsLoading(false);
              // dispatch(mappingUpdatedUser(res))
              if (res.id) {
                // dispatch(rankingsChangeModalState());
                message.success(
                  `Se ha actualizado con éxito la calificación con id:${res.id}`
                );
                dispatch(refreshTableRankings(true));
              } else {
                message.error(res as string);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        } else {
          // console.log('Creando===>',data)
          await createRanking({...data, order_id: Number(data.order_id)})
            .then((res: DataIRankings) => {
              setIsLoading(false);
              if (res.id) {
                dispatch(rankingsChangeModalState());
                message.success(
                  `Se ha creado con éxito la calificación con id:${res.id}`
                );
                dispatch(refreshTableRankings(true));
              } else {
                message.error(res as any);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        }
      };

      useEffect(() => {
        if (isRefreshTableRankings) {
          tableProps?.refresh();
          dispatch(refreshTableRankings(false));
        }
      }, [isRefreshTableRankings]);
  return {
    //state
    isUpdateRanking,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    changeModalRankingsState,
    onSubmitCreateOrUpdate,
  }
}
