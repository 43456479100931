import { dataProducsCharac } from "../@types"

export const PRODUCT_CHARACTERISTICS_MODAL_STATE = '[ PRODUCT CHARACTERISTICS ACTIONS ] Product Characteristics Modal State'

export interface IProductCharacteristicsModalState {
    type: typeof PRODUCT_CHARACTERISTICS_MODAL_STATE
}

export const GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE = '[ PRODUCT CHARACTERISTICS ACTIONS ] Get Product Characteristics For Update'

export interface IGetProductCharacteristicsForUpdate {
    type: typeof GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE
    payload: dataProducsCharac
}

export const IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE ='[ PRODUCT CHARACTERISTICS ACTIONS ] Is Product Characteristics For Update'

export interface IIsProductCharacteristicsForUpdate {
    type: typeof IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PRODUCT_CHARACTERISTICS_REFRESH_TABLE = '[ PRODUCT CHARACTERISTICS ACTIONS ] Switch Product Characteristics Refresh Table'

export interface ISwitchProductCharacteristicsRefreshTable {
    type: typeof SWITCH_PRODUCT_CHARACTERISTICS_REFRESH_TABLE
    payload: boolean
}