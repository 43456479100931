import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import {
  AdminMenuLayout,
  ModalListCustomerGroups,
} from "../../../shared/components";
import { WrapperCardListCustomerGroups } from "./styled";
import { useSelector } from "react-redux";
import { selectModalListCustomerGroups } from "../../../redux";
import { TableListCustomerGroups } from "./TableListCustomerGroups";
import { useListCustomerGroups } from "../hooks";
import { FormProvider } from "react-hook-form";

export const ListCustomerGroups = () => {
  const modalListCustomerGroups = useSelector(selectModalListCustomerGroups);
  const {
    isUpdateListCustomerGroups,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalListCustomerGroupsState,
  } = useListCustomerGroups();

  return (
    <AdminMenuLayout
      pageDescription="ListCustomerGroups"
      title="ListCustomerGroups"
    >
      <WrapperCardListCustomerGroups>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalListCustomerGroupsState}
            >
              Agregar nuevo grupo de cliente
            </Button>
          </Col>
        </Row>
        <TableListCustomerGroups />

        {/* {modalListCustomerGroups && isUpdateListCustomerGroups && (
          <FormProvider {...formMethodsUpdate}>
            <ModalListCustomerGroups />
          </FormProvider>
        )} */}
        {modalListCustomerGroups && !isUpdateListCustomerGroups && (
          <FormProvider {...formMethodsCreate}>
            <ModalListCustomerGroups />
          </FormProvider>
        )}
      </WrapperCardListCustomerGroups>
    </AdminMenuLayout>
  );
};
