import type { Resolver } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { programsTopicsAcademy } from "../../../../shared/services";
import { ProgramTopicType } from "../../../../types/dto/program";

export interface UseCreateTopicProps {
  moduleId: number;
  onSuccess?: (programTopic: ProgramTopicType) => void;
}

export default function useCreateTopic(props?: UseCreateTopicProps) {
  const [loading, setLoading] = React.useState(false);
  const {
    control,
    handleSubmit: onSubmit,
    reset,
  } = useForm<ProgramTopicType>({
    resolver: yupResolver(
      yup.object({
        name: yup.string().required(),
        description: yup.string().required(),
        status: yup.string().required(),
      })
    ) as unknown as Resolver<ProgramTopicType>,
    defaultValues: {},
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);

    await programsTopicsAcademy
      .create({
        ...data,
        program_module_id: +props?.moduleId!,
      })
      .then((res) => {
        reset({});
        props?.onSuccess?.(res);
      })
      .catch((err: Error) => {})
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    control,
    loading,
    handleSubmit,
  };
}
