import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  deleteContactsDirectory,
  refreshTableContactsDirectory,
  setIsContactsDirectoryForUpdate,
} from "../../../redux";
import { DataContactsDirectory } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useContactsDirectory } from "./useContactsDirectory";

const { confirm } = Modal;

export const useTableContactsDirectory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tableProps } = useContactsDirectory();

  const changeContactsDirectoryForUpdate = async (
    val: DataContactsDirectory
  ) => {
    dispatch(setIsContactsDirectoryForUpdate(true));
    navigate(`/directoryCategories/${val.id}`);
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el Directorio con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar directorio" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar directorio",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteContactsDirectory(valId)
          .then(() => {
            dispatch(refreshTableContactsDirectory(true));
            message.success(
              `Se ha borrado con éxito el Directorio con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeContactsDirectoryForUpdate,
    showDeleteConfirm,
  };
};
