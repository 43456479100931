import { DataCities } from "../@types"


export const CITIES_MODAL_STATE = '[ CITIES ] Cities Modal State'

export interface ICitiesModalState {
    type: typeof CITIES_MODAL_STATE
}

export const GET_CITY_FOR_UPDATE = '[ CITIES ] Get City For Update'

export interface IGetCityForUpdate {
    type: typeof GET_CITY_FOR_UPDATE
    payload: DataCities
}

export const IS_CITIES_FOR_UPDATE ='[ CITIES ] Is City For Update'

export interface IIsCityForUpdate {
    type: typeof IS_CITIES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_CITIES_REFRESH_TABLE = '[ CITIES ] Switch Cities Refresh Table'

export interface ISwitchCitiesRefreshTable {
    type: typeof SWITCH_CITIES_REFRESH_TABLE
    payload: boolean
}

export const GET_STATES_CITIES = '[ CITIES ] Get States Cities'

export interface IGetStatesCities {
    type: typeof GET_STATES_CITIES
    payload: DataCities[]
}

export const GET_STATE_OBJ_CITIES = '[ CITIES ] Get States Obj Cities'

export interface IGetStatesObjCities {
    type: typeof GET_STATE_OBJ_CITIES
    payload: { [key: string]: string; }
}