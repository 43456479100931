import { Row } from "antd";
import { FormProvider } from "react-hook-form";

import { AdminMenuLayout } from "../../../../shared/components";
import { useCourses, useCoursesById } from "../../hook";
import { WrapperCardCourses, WrapperTabNav } from "../styled";
import { CoursesFormById } from "./CoursesFormById";
import { TableCourseBenefits } from "./TableCourseBenefits";
import { TableCourseChapters } from "./TableCourseChapters";
import { TableCourseSections } from "./TableCourseSections";

export const CoursesById = () => {
  const { isValidate } = useCoursesById();
  const { isUpdateCourses, formMethodsCreate, formMethodsUpdate } =
    useCourses();

  return (
    <AdminMenuLayout pageDescription="CoursesById" title="CoursesById">
      <WrapperCardCourses>
        {isValidate && isUpdateCourses && (
          <WrapperTabNav
            items={[
              {
                label: `DATOS DEL CURSO`,
                key: "1",
                children: (
                  <>
                    {isUpdateCourses && (
                      <FormProvider {...formMethodsUpdate}>
                        <CoursesFormById />
                      </FormProvider>
                    )}
                  </>
                ),
              },
              {
                label: `SECCIONES DEL CURSO`,
                key: "2",
                children: (
                  <Row
                    gutter={[8, 8]}
                    style={{
                      padding: "10px",
                      width: "100%",
                    }}
                  >
                    <TableCourseSections />
                  </Row>
                ),
              },
              {
                label: `CAPÍTULOS DEL CURSO`,
                key: "3",
                children: (
                  <Row
                    gutter={[8, 8]}
                    style={{
                      padding: "10px",
                      width: "100%",
                    }}
                  >
                    <TableCourseChapters />
                  </Row>
                ),
              },
              {
                label: `BENEFICIOS DEL CURSO`,
                key: "4",
                children: (
                  <Row
                    gutter={[8, 8]}
                    style={{
                      padding: "10px",
                      width: "100%",
                    }}
                  >
                    <TableCourseBenefits />
                  </Row>
                ),
              },
            ]}
          />
        )}
        {isValidate && !isUpdateCourses && (
          <>
            {!isUpdateCourses && (
              <FormProvider {...formMethodsCreate}>
                <CoursesFormById />
              </FormProvider>
            )}
          </>
        )}
      </WrapperCardCourses>
    </AdminMenuLayout>
  );
};
