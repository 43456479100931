import { Button, Col } from "antd";
import { EditOutlined } from "@ant-design/icons";

import { DataTax } from "../../../redux/@types";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import Table from "../../../shared/components/ui/Table";
import { taxService } from "../../../shared/services";
import { useTableTax, useTax } from "../hooks";

export const TableTax = () => {
  const { changeTaxForUpdate } = useTableTax();
  const { setTableProps } = useTax();
  const columns: TableColumns<DataTax> = [
    {
      title: "ID",
      width: 5,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Nombre",
      width: 5,
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "Valor",
      width: 5,
      dataIndex: "value",
      key: "value",
      filterType: "text",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 1,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeTaxForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <Table<DataTax>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      scroll={{ x: 'max-content' }}
      service={taxService}
      onLoad={setTableProps}
    />
  );
};
