// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectProductRefreshTable } from "../../../redux";
import { productsService } from "../../../shared/services"
import { message } from "antd";

interface Props {
    handleQueryTransform:(val: string[] | []) => void
}

const helperObjQueryKey = {
    total_without_categories:         'category_id',
    total_without_images:             'images',
    total:                            null,
    total_active:                     'status',
    total_inactive:                   'status',
    total_with_out_description_short: 'description_short',
    total_with_out_weight:            'weight',
    total_with_out_height:            'heigh',
    total_with_out_width:             'width',
    total_with_out_long:              'long',
}

const helperObjQueryVal = {
    total_without_categories:         'true',
    total_without_images:             'false',
    total:                            null,
    total_active:                     'active',
    total_inactive:                   'inactive',
    total_with_out_description_short: 'null',
    total_with_out_weight:            'null',
    total_with_out_height:            'null',
    total_with_out_width:             'null',
    total_with_out_long:              'null',
}

interface PropsRes {
    total_without_categories:         number;
    total_without_images:             number;
    total:                            number;
    total_active:                     number;
    total_inactive:                   number;
    total_with_out_description_short: number;
    total_with_out_weight:            number;
    total_with_out_height:            number;
    total_with_out_width:             number;
    total_with_out_long:              number;
}

interface PropsArray {
    [k: string]: number;
}

const comparisonMatrix = [ 'total_without_categories','total_without_images','total','total_active','total_inactive','total_with_out_description_short','total_with_out_weight','total_with_out_height','total_with_out_width','total_with_out_long']

export const useCounterProductsTable = ({handleQueryTransform}:Props) => {
    const isRefreshTableProducts = useSelector(selectProductRefreshTable)
    const [counterArr, setCounterArr] = useState<PropsArray[] | []>([])
    const [querySelector, setQuerySelector] = useState< string[]>([])
    const [firstTime, setFirstTime] = useState(true)

    const getInventory = async()=>{
        productsService
        .find({ query: { $client: {
            totalReportProducts: "true"
        } } })
        .then((res: PropsRes)=> {
            const arrAux = []
            for(const keyVal in res){
                arrAux.push({ [keyVal]: res[keyVal as keyof PropsRes] })
            }
            setCounterArr(arrAux)
        })
        .catch((err)=> {
            setCounterArr([])
            console.log('error ===>', err)
        })
    }

    useEffect(() => {
        if(firstTime){
            setFirstTime(false)
            getInventory()
        }else if(!firstTime && isRefreshTableProducts){
            console.log('hola me vuelvo a llamar aquí')
            getInventory()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefreshTableProducts]);

    const getUpdateQuerySelector = (val:string) => {
        if(querySelector.includes(val)){
            const constAux = querySelector.filter((item)=> item !== val)
            setQuerySelector(constAux )
            return handleQueryTransform(constAux)
        } else {
            const constAux = [...querySelector, val]
            setQuerySelector(constAux)
            return handleQueryTransform(constAux)
        }
    }

    const getDownloadProductsWithFilters = async() => {

        const objAux: PropsArray= {}

        querySelector.forEach((item)=>{
          if (helperObjQueryKey.hasOwnProperty(item)) {
            return objAux[helperObjQueryKey[item as keyof typeof helperObjQueryKey]] = helperObjQueryVal[item as keyof typeof helperObjQueryVal]
          }
        })
        console.log({objAux})
        return  await productsService.find({ query: { $client: { exportToexcell: 'true' }, ...objAux } })
        .then((res) => {
            window.open(res.data[0].objectUrl, '_blank')
        })
        .catch((err: any) => {
            console.log(err);
            message.error(err.message);
        })
    }

  return {
    //state
    querySelector,
    counterArr,
    isRefreshTableProducts,
    //methods
    setQuerySelector,
    //functions
    getUpdateQuerySelector,
    getDownloadProductsWithFilters,
  }
}
