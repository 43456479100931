import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { AdminMenuLayout, ModalBanners } from "../../../shared/components";
import { WrapperCard } from "./styled";
import { TableBanners } from "./TableBanners";
import { useBanners } from "../hooks";
import { useSelector } from "react-redux";
import { selectModalBanners } from "../../../redux";
import { FormProvider } from "react-hook-form";

export const Banners = () => {
  const modalBanners = useSelector(selectModalBanners);
  const {
    isUpdateBanner,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalBannersState,
  } = useBanners();

  return (
    <AdminMenuLayout pageDescription="Banners" title="Banners">
      <WrapperCard>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalBannersState}
            >
              Agregar nuevo banner
            </Button>
          </Col>
        </Row>
        <TableBanners />

        {modalBanners && isUpdateBanner && (
          <FormProvider {...formMethodsUpdate}>
            <ModalBanners />
          </FormProvider>
        )}
        {modalBanners && !isUpdateBanner && (
          <FormProvider {...formMethodsCreate}>
            <ModalBanners />
          </FormProvider>
        )}
      </WrapperCard>
    </AdminMenuLayout>
  );
};
