// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Input, message } from 'antd';
import { useEffect } from "react";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import { InputError } from "./styled";

const { TextArea } = Input;

interface Props {
  width?: string;
  placeHolder: string;
  name: string;
  control: any;
  rows?: boolean;
}

interface PropsContents{
  errors: Partial<FieldErrorsImpl<{
    [x: string]: any;
  }>>;
  name: string;
  rows: boolean;
  width: string;
  onChange: (...event: any[]) => void;
  value: any;
  placeHolder:string;
}

const TextContent = ({errors, name, rows, onChange, value, width, placeHolder}:PropsContents) =>{
  useEffect(() => {
    if(errors?.[name]?.message){
      message.error(`${errors?.[name]?.message}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  return(
  <div
  style={{
    display: "flex",
    justifyContent: "center",
    width,
    height: "80%",
    flexDirection: "column",
  }}
>
  {!rows ? (
    <Input
      status={errors[name] ? "error" : ""}
      value={value}
      placeholder={placeHolder}
      onChange={onChange}
    />
  ) : (
    <TextArea
      status={errors[name] ? "error" : ""}
      value={value}
      placeholder={placeHolder}
      onChange={onChange}
      autoSize={{ minRows: 2, maxRows: 6 }}
    />
  )}
  {!!errors[name] && (
    <InputError>{errors?.[name]?.message}</InputError>
  )}
</div>)
}

export const InputText = ({
  width = "100%",
  placeHolder,
  name,
  control,
  rows = false,
}: Props) => {
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name}
      render={({ field: { onChange, value = "" }, formState: { errors } }) => {
        return (
          <TextContent placeHolder={placeHolder} errors={errors} name={name} onChange={onChange} rows={rows} value={value} width={width}/>
        );
      }}
    />
  );
};
