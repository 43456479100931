import { DataSystemVariables } from "../@types"

export const SYSTEM_VARIABLES_MODAL_STATE = '[ SYSTEM VARIABLES ] CMS Modal State'

export interface ISystemVariablesModalState {
    type: typeof SYSTEM_VARIABLES_MODAL_STATE
}

export const GET_SYSTEM_VARIABLES_FOR_UPDATE = '[ SYSTEM VARIABLES ] Get System Variables For Update'

export interface IGetSystemVariablesForUpdate {
    type: typeof GET_SYSTEM_VARIABLES_FOR_UPDATE
    payload: DataSystemVariables
}

export const IS_SYSTEM_VARIABLES_FOR_UPDATE ='[ SYSTEM VARIABLES ] Is System Variables For Update'

export interface IIsSystemVariablesForUpdate {
    type: typeof IS_SYSTEM_VARIABLES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_SYSTEM_VARIABLES_REFRESH_TABLE = '[ SYSTEM VARIABLES ] Switch System Variables Refresh Table'

export interface ISwitchSystemVariablesRefreshTable{
    type: typeof SWITCH_SYSTEM_VARIABLES_REFRESH_TABLE
    payload: boolean
}