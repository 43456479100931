"use client";

import React from "react";
import { Resolver, useForm } from "react-hook-form";
import { message } from "antd";
import { academyEquipmentsService } from "../../../../shared/services";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { EquipmentsAttributesType } from "../../../../types/dto/equipments";

export interface UseEditEquipmentProps {
  equipmentData: EquipmentsAttributesType
  setOpenModal: (val: boolean) => void
}

export default function useEditEquipment({equipmentData, setOpenModal}: UseEditEquipmentProps) {
  const [loading, setLoading] = React.useState(false);
 
  const { control, handleSubmit: onSubmit, reset } = useForm<EquipmentsAttributesType>({
    resolver: yupResolver(
      yup.object({
      stock: yup.string().required('Campo requerido.'),
      value_per_day: yup.string().required('Campo requerido.'),
      name: yup.string().required('Campo requerido.'),
      description: yup.string().required('Campo requerido.'),
      status: yup.string().required('Campo requerido.'),
      category: yup.string().required('Campo requerido.'),
      })
    ) as unknown as Resolver<EquipmentsAttributesType>,
  });
  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    await academyEquipmentsService.patch(equipmentData.id, {...data, stock: +data.stock! })
      .then((response) => {
        message.success("Se ha actualizado exitosamente el quipo");
        setOpenModal(false)
      })
      .catch((err: any) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  React.useEffect(()=>{
   if(equipmentData){ reset({
      name: equipmentData.name,
      description: equipmentData.description,
      stock: equipmentData.stock,
      value_per_day: equipmentData.value_per_day,
      status: equipmentData.status,
      category: equipmentData.category,
    })}
  },[equipmentData])
  return {
    loading,
    control,
    handleSubmit,
  };
}
