import { Button, Col, Typography } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { TableColumns } from "../../../../../shared/components/ui/Table/types";
import Table from "../../../../../shared/components/ui/Table";
import { EquipmentsAttributesType } from "../../../../../types/dto/equipments";
import { academyEquipmentsService } from "../../../../../shared/services";
import useDeleteEquipment from "../../../hooks/equipments/useDeleteEquipment";
import React from "react";
import { ModalEquipmentEdit } from "../../../../../shared/components/ui/modals/ModalEquipmentEdit";
import { useNavigate } from "react-router-dom";
const { Title, Text } = Typography;

export const TableAcademyEquipments = () => {
  const { showDeleteConfirm } = useDeleteEquipment();
  const [openModalEditEquipmet, setOpenModalEditEquipment] =
    React.useState<boolean>(false);
  const [equipmentById, setEquipmentById] = React.useState<number>();
  
  const navigate = useNavigate();

  const hanldeRedirectPersonalInfo = (equipment_id: number) => {
    navigate(`/gestion-academy/rents/${equipment_id}`);
  };


  const handleOpenMoodalEdit = (value: number) => {
    setEquipmentById(value);
    setOpenModalEditEquipment(true);
  };

  const columns: TableColumns<EquipmentsAttributesType> = [
    {
      title: "ID",
      dataIndex: "id",
      width: 1,
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      width: 2,

      key: "name",
      filterType: "text",
    },
    {
      title: "Valor por dia",
      dataIndex: "value_per_day",
      width: 2,
      key: "value_per_day",
      render: (item)=>{
        const amount =  item.toLocaleString('es-CO', {
          style: 'currency',
          currency: 'COP',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        return (
          <Col
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
          }}
        >
          <Text >
            {amount}
          </Text>
        </Col>
        )
      }
    },
    {
      title: "Cantidad disponible",
      width: 2,
      key: "quantity_available",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Text>
               {item.quantity_available} /  {item.stock}
            </Text>
          </Col>
        );
      },
    },
    {
      title: "Estado",
      dataIndex: "status",
      width: 2,
      key: "status",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title
              style={{
                fontSize: "0.74rem",
                fontWeight: "500",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
              level={5}
            >
              {item.status === "active" && "Activo"}
              {item.status === "inactive" && "Inactivo"}
            </Title>
          </Col>
        );
      },
      filters: [
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Inactivo",
          value: "inactive",
        },
      ],
    },
    {
      title: "Categoria",
      dataIndex: "category",
      width: 2,

      key: "category",
    },
    {
      title: "Acciones",
      key: "operation",
      width: 3, 
      fixed: 'right',
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
            }}
          >
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              size="large"
              onClick={() => handleOpenMoodalEdit(item.id)}
            />
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              size="large"
              onClick={()=> hanldeRedirectPersonalInfo(item.id)}
            />
            <Button
              type="primary"
              danger
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
              onClick={() => showDeleteConfirm(item.id, item.name!)}
            />
          </Col>
        );
      },
    },
  ];

  return (
    <>
      <ModalEquipmentEdit
        openModal={openModalEditEquipmet}
        setOpenModal={setOpenModalEditEquipment}
        equipmentById={equipmentById!}
      />
      <Table<EquipmentsAttributesType>
        columns={columns}
        scroll={{}}
        fetchQueryProps={{
          $sort: { id: -1 },
        }}
        service={academyEquipmentsService}
      />
    </>
  );
};
