import { Drawer } from "antd";
import CreateEquipmentRents from "../../../../modules/AcademyEquipmentRents/ui/equipmentRents/form/CreateEquipmentRents";

export const ModalEquipmentRents = ({openModal, setOpenModal}: {openModal:boolean, setOpenModal: (value: boolean)=>void}) => {

  return (
    <Drawer
      title={`Rentar un equipo`}
      open={openModal}
      width={"320px"}
      destroyOnClose
      onClose={()=>setOpenModal(false)}
    >
      <CreateEquipmentRents setOpenModal={setOpenModal}/>
    </Drawer>
  );
};
