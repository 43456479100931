import type {Resolver} from "react-hook-form";

import {yupResolver} from "@hookform/resolvers/yup";
import React from "react";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import { programsModuleTechniquesAcademy } from "../../../../shared/services";
import { ProgramTechniquesType } from "../../../../types/dto/program";

export interface UseCreateTechniquesProps {
  programModuleTopicId?: number,
  onSuccess?: (technique: ProgramTechniquesType) => void;
}

export default function useCreateProgramTechniques(props?: UseCreateTechniquesProps) {
  const [loading, setLoading] = React.useState(false);
  const {
    control,
    handleSubmit: onSubmit,
    reset,
  } = useForm<ProgramTechniquesType>({
    resolver: yupResolver(
      yup.object({
        name: yup.string().required(),
        description: yup.string().required(),
        status: yup.string().required(),
      }),
    ) as unknown as Resolver<ProgramTechniquesType>,

  });


  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);

    await programsModuleTechniquesAcademy
    .create({...data, program_module_topic_id: +props?.programModuleTopicId!
    })
    .then((res) => {
        reset({});
        props?.onSuccess?.(res);
      })
      .catch((err: Error) => {
      })
      .finally(() => {
        setLoading(false);
      });
  });

  React.useEffect(() => {
    reset({
    });
  }, [ reset]);

  return {
    control,
    loading,
    handleSubmit,
  };
}
