import { AcademyProgramsPersonalInfoTeacher } from "../modules/academyProgramPersonalInfo/ui/programTeacher/AcademyProgramsPersonalInfoTeacher";
import { AdminMenuLayout } from "../shared/components";

export default function AcademyProgramsPersonalInfoTeacherPage() {

  return (
    <AdminMenuLayout title="" pageDescription="">
     <AcademyProgramsPersonalInfoTeacher/>
    </AdminMenuLayout>
  );
}

