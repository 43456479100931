"use client";

import React from "react";
import { message, Modal } from "antd";

import { programsAcademy } from "../../../../shared/services";
import { ProgramType } from "../../../../types/dto/program";

const { confirm } = Modal;

export interface UseDeleteLevelProps {
  onSuccess?: (programId: ProgramType) => void;
}

export default function useDeletePrograms(
) {
  const [loading, setLoading] = React.useState(false);

  const showDeleteConfirm = (valId: number, val: string) => {
    if (!valId ) {
      message.error("Programa no válido");
      return;
    }

    confirm({
      title: `Estás seguro de borrar el programa con el id: ${valId}`,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar el programa" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar el programa",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        setLoading(true);
          await programsAcademy.remove(valId, {query:{
            $client: {'removeProgramInCascade': 'true'}
          }}).then((res)=>{
            if(res.remove_allow){
              message.success(`Se ha borrado con éxito el programa con id:${valId}`);
            }else{
              message.error(res.message);
            }
          }).catch(err=>{
            console.error(err);
            message.error(`Error: ${err.message}`);
          }).finally(()=>{
            setLoading(false);
          }) 
      },
      onCancel() {},
    });
  };

  return { showDeleteConfirm, loading };
}
