import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";

import { selectModalTax } from "../../../redux";
import { AdminMenuLayout, ModalTax } from "../../../shared/components";
import { WrapperCard } from "./styled";
import { TableTax } from "./TableTax";
import { useTax } from "../hooks";

export const Tax = () => {
  const modalTax = useSelector(selectModalTax);
  const { formMethodsUpdate, changeModalTaxState } = useTax();

  return (
    <AdminMenuLayout pageDescription="Tax" title="Tax">
      <WrapperCard>
        <TableTax />
        {modalTax && (
          <FormProvider {...formMethodsUpdate}>
            <ModalTax />
          </FormProvider>
        )}
      </WrapperCard>
    </AdminMenuLayout>
  );
};
