import React from "react";
import { programsAcademy } from "../../../../shared/services";
import { useLevelContext } from "../../context";
import { ProgramType } from "../../../../types/dto/program";

export default function useProgramList({duplicateProgram}: {duplicateProgram?: boolean}) {
  const [loading, setLoading] = React.useState(false);
  const [programs, setPrograms] = React.useState<ProgramType[]>([]);
  const {pagination, setPagination} = useLevelContext();

  const getPrograms = async () => {
    if (loading) return;
    setLoading(true);
    

    await programsAcademy
      .find({query: {
        $limit: 999
      }})
      .then((response) => {
        setPrograms(response.data);
      }).catch((err) => {
        console.log(err);
        
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
      getPrograms()
  }, [duplicateProgram]);

  return {
    loading,
    pagination,
    programs,
    setPrograms,
    refresh: getPrograms,
  };
}
