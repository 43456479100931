"use client";

import Button from "../../../../../shared/components/ui/Button";
import useCreateEquipment from "../../../hooks/equipments/useCreateEquipment";
import CreateEquipmentForm from "./createEquipmentForm";


export interface CreateProgramProps {
  setOpenModal: (val: boolean) => void;
}

export default function CreateEquipment({setOpenModal}: CreateProgramProps) {
  const {control, loading, handleSubmit} = useCreateEquipment({setOpenModal});

  return (
    <form onSubmit={handleSubmit}>
      <CreateEquipmentForm control={control} />
      <div className="mt-4 flex items-center justify-end">
        <Button primary className="bg-[#072082]" loading={loading} type="submit" variant="filled">
           Crear
        </Button>
      </div>
    </form>
  );
}
