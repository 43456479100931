import CreateProgramSchedule from "../../../../modules/academyPrograms/ui/programs/schedule/createProgramSchedule";
import { WrapperModalUsers } from "./styled";
import { useParams } from "react-router-dom";

export const ModalProgramsSchedule = ({
  openModal,
  setOpenModal,
}: {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
}) => {
  const { id } = useParams();
  return (
    <WrapperModalUsers
      title={`Crear cronograma de pagos`}
      open={openModal}
      width={"320px"}
      destroyOnClose
      onClose={() => {
        setOpenModal(false)
      }}
    >
        <CreateProgramSchedule id={+id!} setOpenModal={setOpenModal} />
    </WrapperModalUsers>
  );
};
