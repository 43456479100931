import {workOffersCategoriesService } from "../../shared/services"
import { DataWorkOffersCategories } from "../@types"
import { workOffersCategoriesActions } from "../action-types"

export const workOffersCategoriesChangeModalState = (): workOffersCategoriesActions.IWorkOffersCategoriesModalState =>({
    type: workOffersCategoriesActions.WORK_OFFERS_CATEGORIES_MODAL_STATE
})

export const setWorkOffersCategoriesForUpdate = (val: DataWorkOffersCategories): workOffersCategoriesActions.IGetWorkOffersCategoriesForUpdate =>({
    type: workOffersCategoriesActions.GET_WORK_OFFERS_CATEGORIES_FOR_UPDATE,
    payload: val
})

export const setIsWorkOffersCategoriesForUpdate = (val: boolean): workOffersCategoriesActions.IIsWorkOffersCategoriesForUpdate =>({
    type: workOffersCategoriesActions.IS_WORK_OFFERS_CATEGORIES_FOR_UPDATE,
    payload: val
})

export const createWorkOffersCategories = async(data: Partial<DataWorkOffersCategories>) =>{
    return await workOffersCategoriesService.create(data)
    .then((res: DataWorkOffersCategories) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateWorkOffersCategories =async(data: Partial<DataWorkOffersCategories>) =>{
    return await workOffersCategoriesService.patch(data?.id!, data)
    .then((res: DataWorkOffersCategories) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteWorkOffersCategories = async(id: number) => {
    return await workOffersCategoriesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableWorkOffersCategories = (val: boolean): workOffersCategoriesActions.ISwitchWorkOffersCategoriesRefreshTable =>({
    type: workOffersCategoriesActions.SWITCH_WORK_OFFERS_CATEGORIES_REFRESH_TABLE,
    payload: val
})