"use client";

import React from "react";
import { programsAcademy } from "../../../../shared/services";
import { ProgramType } from "../../../../types/dto/program";

export default function useProgram(programId?: number) {
  const [program, setProgram] = React.useState<ProgramType | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState(false);

  const getProgram = async () => {
    if (loading) return;
    setLoading(true);
    if (programId) {
      await programsAcademy
        .get(programId)
        .then((response) => {
          setProgram(response);
        })
        .catch((err: Error) => {
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (programId) {
      getProgram();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  return {
    program,
    loading,
    setProgram,
    getProgram
  };
}
