import { FormProvider } from "react-hook-form";
import { CMSById, useCMS } from "../modules";
import { useCMSById } from "../modules/cms/hook/cmsById";

export const CMSByIdPage = () => {
  const { isValidate } = useCMSById();
  const { isUpdateCMS, formMethodsCreate, formMethodsUpdate } = useCMS();
  return (
    <>
      {isValidate && !isUpdateCMS && (
        <FormProvider {...formMethodsCreate}>
          <CMSById />
        </FormProvider>
      )}
      {isValidate && isUpdateCMS && (
        <FormProvider {...formMethodsUpdate}>
          <CMSById />
        </FormProvider>
      )}
    </>
  );
};
