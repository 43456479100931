import { Button, Col, Typography } from "antd";
import {
  DollarOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { TableColumns } from "../../../../../shared/components/ui/Table/types";
import Table from "../../../../../shared/components/ui/Table";
import { RentEquipmentStudentsAttributesType } from "../../../../../types/dto/equipments";
import { academyEquipmentRentsService } from "../../../../../shared/services";
import React from "react";
import moment from "moment";
import { ModalEditEquipmentRents } from "../../../../../shared/components/ui/modals/ModalEditEquipmentRents";
import useDeleteEquipmentRents from "../../../hooks/equipmentRents/useDeleteEquipmentRents";
import { WrapperAcademyEquipmentsRentsDetail } from "../../../style";
import Modal from "../../../../../shared/components/ui/modal";
import CreateEquipmentRentPayment from "../form/CreateEquipmentRentPayment";
import { useParams } from "react-router-dom";
const {Text} = Typography
export const TableAcademyEquipmentRents = () => {
  const { showDeleteConfirm } = useDeleteEquipmentRents();
  const [openModalEditEquipmetRent, setOpenModalEditEquipmetRent] =
    React.useState<boolean>(false);
  const [
    openModaEquipmentRentPayment,
    setOpenModaEquipmentRentPayment,
  ] = React.useState<boolean>(false);
  const [equipmentRentId, setEquipmentRentId] = React.useState<number>();

  const { equipment_id } = useParams();

  const handleOpenModalEdit = (value: number) => {
    setEquipmentRentId(value);
    setOpenModalEditEquipmetRent(true);
  };

  const handleOpenModalPayment = (value: number) => {
    setEquipmentRentId(value);
    setOpenModaEquipmentRentPayment(true);
  };

  const columns: TableColumns<RentEquipmentStudentsAttributesType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 1,
      filterType: "filterByID",
    },
    {
      title: "Fecha de retiro",
      dataIndex: "rent_date",
      key: "rent_date",
      width: 3,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <p>{moment(item.rent_date).format("DD/MM/YYY")}</p>
          </Col>
        );
      },
    },
    {
      title: "Fecha de entrega real",
      dataIndex: "return_expected_date",
      key: "return_expected_date",
      width: 3,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            {item.real_return_date !== "0000-00-00 00:00:00" ? (
              <p>
                {item.real_return_date !== null &&
                  moment(item?.real_return_date).format("DD/MM/YYYY")}
              </p>
            ) : (
              <p>Pendiente por entregar</p>
            )}
          </Col>
        );
      },
    },
    {
      title: "Fecha estimada de entrega",
      dataIndex: "return_expected_date",
      key: "return_expected_date",
      width: 4,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <p>{moment(item.return_expected_date).format("DD/MM/YYY")}</p>
          </Col>
        );
      },
    },
    {
      title: "# Equipos rentados",
      dataIndex: "quantity",
      key: "quantity",
      width: 3,
    },
    {
      title: "Dias rentados",
      dataIndex: "rent_days",
      key: "stock",
      width: 3,
    },
    {
      title: "Estado",
      dataIndex: "rent_days",
      key: "stock",
      width: 3,
      render: (_, item) => {
        const bgText =
          (item.status === "pending_payment" && "#ff4d4f") ||
          (item.status === "successful_payment" && "#4bd542");
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                textAlign: 'center',
                background: String(bgText),
                padding: 8,
                borderRadius: 20,
              }}
            >
                {item.status === "pending_payment" && "Pendiente por pago"}
                {item.status === "successful_payment" && "Pago exitoso"}
            </Text>
          </Col>
        );
      },
    },
    {
      title: "Equipo",
      dataIndex: ["rent_equipment", "name"],
      key: "category",
      width: 2,
    },
    {
      title: "Categoria",
      dataIndex: ["rent_equipment", "category"],
      key: "category",
      width: 2,
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
            }}
          >
            {item.status !== "successful_payment" && (
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="large"
                onClick={() => handleOpenModalEdit(item.id)}
              />
            )}

            {item.real_return_date !== "0000-00-00 00:00:00" && (
              <Button
                type="primary"
                shape="circle"
                icon={<DollarOutlined />}
                size="large"
                onClick={() => handleOpenModalPayment(item.id)}
              />
            )}
            <Button
              type="primary"
              danger
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
              onClick={() => showDeleteConfirm(item.id, "alquiler")}
            />
          </Col>
        );
      },
    },
  ];

  return (
    <>
      <ModalEditEquipmentRents
        openModal={openModalEditEquipmetRent}
        setOpenModal={setOpenModalEditEquipmetRent}
        equipmentRentById={equipmentRentId!}
      />
      <Modal
        className="w-[60%] create-payment"
        title="Pagar alquiler"
        visible={openModaEquipmentRentPayment}
        onCancel={() => {
          setOpenModaEquipmentRentPayment(false);
        }}
      >
        <WrapperAcademyEquipmentsRentsDetail>
          <CreateEquipmentRentPayment setOpenModaEquipmentRentPayment={setOpenModaEquipmentRentPayment} equipmentRentId={equipmentRentId!} />
        </WrapperAcademyEquipmentsRentsDetail>
      </Modal>

      <Table<RentEquipmentStudentsAttributesType>
        columns={columns}
        fetchQueryProps={{
          rent_equipment_id: +equipment_id!,
          $sort: { id: -1 },
        }}
        service={academyEquipmentRentsService}
      />
    </>
  );
};
