import AssignedTeacherPrograms from "../../../../modules/academyProgramDetail/ui/programTeacher/assignedTeacherPrograms";
import { WrapperModalUsers } from "./styled";
import { useParams } from "react-router-dom";


export const ModalAssignedTeacherProgram = ({openModal, setOpenModal}: {openModal:boolean, setOpenModal: (value: boolean)=>void}) => {
const {id: programId} = useParams()
  return (
    <WrapperModalUsers
      title={`Asignar profesor al programa`}
      open={openModal}
      width={"320px"}
      destroyOnClose
      onClose={()=>setOpenModal(false)}
    >
      <AssignedTeacherPrograms programId={+programId!} setOpenModal={setOpenModal}/>
    </WrapperModalUsers>
  );
};
