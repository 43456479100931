import CreateProgramPaymentStudent from "../../../../modules/academyProgramDetail/ui/payment/CreateProgramModule";
import { WrapperModalPaymentProgramStuden } from "./styled";
import { useParams } from "react-router-dom";

export const ModalPaymentProgramStuden = ({
  openModal,
  setOpenModal,
  scheduleId,
  program_id,
  minAmount
}: {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  scheduleId: number;
  program_id: number;
  minAmount: number;
}) => {
  const { id } = useParams();
  return (
    <WrapperModalPaymentProgramStuden
      title={`Crear pagos del estudiante`}
      open={openModal}
      width={"320px"}
      destroyOnClose
      onClose={() => {
        setOpenModal(false);
      }}
    >
      <CreateProgramPaymentStudent
        scheduleId={scheduleId!}
        programId={program_id}
        setOpenModal={setOpenModal}
        minAmount={minAmount}
      />{" "}
    </WrapperModalPaymentProgramStuden>
  );
};
