import { Button, Col } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { DataCourseSection } from "../../../../redux/@types";
import { courseSectionsService } from "../../../../shared/services";
import Table from "../../../../shared/components/ui/Table";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import { selectModalCourseSections } from "../../../../redux";
import { useCourseSections } from "../../hook";
import { ModalCourseSections } from "../../../../shared/components";

export const TableCourseSections = () => {
  const { id } = useParams();
  const modalCourseSections = useSelector(selectModalCourseSections);

  const {
    isUpdateCourseSections,
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    changeModalCourseSectionsState,
    changeCourseSectionsForUpdate,
    showDeleteConfirm,
  } = useCourseSections();

  const columns: TableColumns<DataCourseSection> = [
    {
      title: "ID",
      width: 2,
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Nombre",
      width: 6,
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "ID del curso",
      width: 2,
      dataIndex: "course_id",
      key: "course_id",
    },
    {
      title: "Posición",
      width: 2,
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 4,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeCourseSectionsForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <>
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          size="large"
          onClick={changeModalCourseSectionsState}
        >
          Agregar nueva sección
        </Button>
      </Col>
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Table<DataCourseSection>
          columns={columns}
          fetchQueryProps={{
            $sort: { id: -1 },
            course_id: id,
          }}
          service={courseSectionsService}
          onLoad={setTableProps}
        />
      </Col>
      {modalCourseSections && isUpdateCourseSections && (
        <FormProvider {...formMethodsUpdate}>
          <ModalCourseSections />
        </FormProvider>
      )}
      {modalCourseSections && !isUpdateCourseSections && (
        <FormProvider {...formMethodsCreate}>
          <ModalCourseSections />
        </FormProvider>
      )}
    </>
  );
};
