import { message } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateCreateUsers, validateUpdateUsers } from '../../../helpers';
import { createUser, updateUser,selectIsUpdateUser, selectUserForUpdate, setIsUserForUpdate, userChangeModalState, selectUserRefreshTable, refreshTableUsers } from '../../../redux';
import { IUser, IUserResFind } from '../../../redux/@types';
import useAppDispatch from '../../../redux/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { TableProperties } from '../../../shared/components/ui/Table/types';
import { usersService } from '../../../shared/services';
import { CaUserAcademy, ProgramStudentType } from '../../../types/dto/program';

export const useUsers = () => {
  const dispatch = useAppDispatch();
  const isUpdateUser = useSelector(selectIsUpdateUser);
  const dataUpdateUser = useSelector(selectUserForUpdate);
  const isRefreshTableUsers = useSelector(selectUserRefreshTable)
  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();
  const [userStudents, setUserStudents] = useState<IUser[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [studentForQualification, setStudentForQualification] = useState<any[]>([]);
  const [studentWithoutPrograms, setStudentWithoutPrograms] = useState<IUser[]>([]);

  const changeModalUserState = () => {
    dispatch(setIsUserForUpdate(false))
    dispatch(userChangeModalState())
  }
  const formMethodsCreate = useForm<IUserResFind>({
    resolver: yupResolver(validateCreateUsers),
  })

  const formMethodsUpdate  = useForm<IUserResFind>({
    resolver: yupResolver(validateUpdateUsers),
  })

  const findUserStudents = async () => {
     await usersService
      .find({ query: { student_teacher: "student" } })
      .then((res) => {
        setUserStudents(res.data);
      });
  };
 
  const findUserUserActive = async () => {
     await usersService
      .find({ query: { status: "active" } })
      .then((res) => {
        setUsers(res.data);
      });
  };
 
  const studentWithoutProgramsQuery = async (program_id: number) => {
     await usersService
      .find({ query: { '$client[studentWithoutPrograms]': program_id } })
      .then((res) => {
        setStudentWithoutPrograms(res.data);
      });
  };
  
  const findStudentForQualification = async () => {
     await usersService
      .find({ query: { '$client[listUserQualification]': true } })
      .then((res) => {
        setStudentForQualification(res.data);
      });
  };
  

  const findUserById = async (id: number): Promise<CaUserAcademy> => {
   return await usersService.get(id)
  };
  
  useEffect(() => {
    if (isUpdateUser) {
        formMethodsCreate.reset()
        formMethodsUpdate.reset({ ...dataUpdateUser })
    }
    return () => {
        formMethodsUpdate.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [isUpdateUser, dataUpdateUser])

  const onSubmitCreateOrUpdate = async(data: IUserResFind) => {
    if (isLoading) return;
    setIsLoading(true);
    const record ={
      id: data.id,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      dni: data.dni,
      role: data.role,
      phone: data.phone,
      status: data.status,
      password: data?.password!,
      student_teacher: data?.student_teacher,
      city: data?.city,
      student_code: data?.student_code
    }
    if(isUpdateUser){


      await updateUser(record)
      .then((res:Partial<IUserResFind>)=>{
        setIsLoading(false)
        // dispatch(mappingUpdatedUser(res))
        if(res.id){
          message.success(`Se ha actualizado con éxito el usuario con id:${res.id}`)
          dispatch(refreshTableUsers(true))
          // dispatch(userChangeModalState())
        }
        else{
          message.error(res as string)
        }
      })
    }else{
      // console.log('Creando===>',data)
      
      await createUser(record)
      .then((res: IUserResFind)=>{
        setIsLoading(false)
        // dispatch(pushCreateUser(res))
        if(res.id){
          message.success(`Se ha creado con éxito el usuario con id:${res.id}`)
          dispatch(refreshTableUsers(true))
          dispatch(userChangeModalState())
        }
        else{
          message.error(res as any)
        }
      })
      .catch((err: any) =>{
        setIsLoading(false)
        console.log(err)
        message.error(err.message)
      })
    }
  }

  useEffect(() => {
    if (isRefreshTableUsers) {
      tableProps?.refresh()
      dispatch(refreshTableUsers(false))
    }
  }, [isRefreshTableUsers])


  return {
    //state
    isUpdateUser,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    userStudents,
    studentForQualification,
    studentWithoutPrograms,
    users,
    setTableProps,
    onSubmitCreateOrUpdate,
    changeModalUserState,
    findUserStudents,
    findUserById,
    findStudentForQualification,
    studentWithoutProgramsQuery,
    findUserUserActive
  }
}
