// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { productCharacteristicsService } from "../../shared/services";
import { dataProducsCharac } from "../@types";
import { productCharacteristicsActions } from "../action-types";

export const productCharacteristicsChangeModalState = ():productCharacteristicsActions.IProductCharacteristicsModalState =>({
    type: productCharacteristicsActions.PRODUCT_CHARACTERISTICS_MODAL_STATE
})

export const setProductCharacteristicsForUpdate = (val: dataProducsCharac):productCharacteristicsActions.IGetProductCharacteristicsForUpdate =>({
    type: productCharacteristicsActions.GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE,
    payload: val
})

export const setIsProductCharacteristicForUpdate = (val: boolean): productCharacteristicsActions.IIsProductCharacteristicsForUpdate =>({
    type: productCharacteristicsActions.IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE,
    payload: val
})

export const createProductCharacteristic = async(data: Partial<dataProducsCharac>) =>{
    return await productCharacteristicsService.create(data)
    .then((res: dataProducsCharac) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateProductCharacteristic = async(data:Partial<any>) =>{
    return  await productCharacteristicsService.patch(data?.id!, data)
    .then((res: any) => {
        // console.log({res});
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteProductCharacteristic = async (id: number ) =>{
    return  await productCharacteristicsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProductCharacteristics =( val: boolean ): productCharacteristicsActions.ISwitchProductCharacteristicsRefreshTable =>({
    type: productCharacteristicsActions.SWITCH_PRODUCT_CHARACTERISTICS_REFRESH_TABLE,
    payload: val
})