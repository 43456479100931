import { DataDiscountCode, IDiscountCodeState } from "../@types";
import { discountCodeActions } from "../action-types";

type discountCodeType =
| { type: typeof discountCodeActions.DISCOUNT_CODE_MODAL_STATE }
| { type: typeof discountCodeActions.GET_DISCOUNT_CODE_FOR_UPDATE; payload: DataDiscountCode }
| { type: typeof discountCodeActions.IS_DISCOUNT_CODE_FOR_UPDATE; payload: boolean }
| { type: typeof discountCodeActions.SWITCH_DISCOUNT_CODE_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: IDiscountCodeState ={
    discountCodeForUpdate: undefined,
    isUpdateDiscountCode: false,
    discountCodeModalIsOpen: false,
    refreshTableDiscountCode: false,
}

export default function discountCodeReducer(state: IDiscountCodeState = INITIAL_STATE, action: discountCodeType): IDiscountCodeState {
    switch(action.type){
        case discountCodeActions.DISCOUNT_CODE_MODAL_STATE:
            return{
                ...state,
                discountCodeModalIsOpen: !state.discountCodeModalIsOpen
            }
        case discountCodeActions.GET_DISCOUNT_CODE_FOR_UPDATE:
            return{
                ...state,
                discountCodeForUpdate: action.payload
            }
        case discountCodeActions.IS_DISCOUNT_CODE_FOR_UPDATE:
            return{
                ...state,
                isUpdateDiscountCode: action.payload
            }
        case discountCodeActions.SWITCH_DISCOUNT_CODE_REFRESH_TABLE:
            return{
                ...state,
                refreshTableDiscountCode: action.payload
            }
        default:
            return state
    }
}