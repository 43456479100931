import { IUserResFind, IUserState } from "../@types"
import { userActions } from "../action-types"

type userActionType =
| { type: typeof userActions.USER_CHANGE_MODAL_STATE }
| { type: typeof userActions.GET_USER_FOR_UPDATE; payload: IUserResFind}
| { type: typeof userActions.IS_USER_FOR_UPDATE; payload: boolean}
| { type: typeof userActions.SWITCH_USERS_REFRESH_TABLE; payload: boolean}


const INITIAL_STATE: IUserState = {
    userForUpdate: undefined,
    isUpdateUser: false,
    userModalIsOpen: false,
    refreshTableUsers: false,
}

export default function userReducer(state: IUserState = INITIAL_STATE, action: userActionType ): IUserState{
    switch ( action.type) {
        case userActions.USER_CHANGE_MODAL_STATE:
            return {
                ...state,
                userModalIsOpen: !state.userModalIsOpen,
            }
        //TODO: acciones para error acturar, msm para delete o update, acción para crear, acción para borrar
        case userActions.GET_USER_FOR_UPDATE:
            return {
                ...state,
                userForUpdate: action.payload
            }
        case userActions.IS_USER_FOR_UPDATE:
            return {
                ...state,
                isUpdateUser: action.payload
            }
        case userActions.SWITCH_USERS_REFRESH_TABLE:
            return {
                ...state,
                refreshTableUsers: action.payload
            }
        default:
            return state
    }
}