// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { brandsService, productCategoriesService, productCharacteristics2Service, productCharacteristicsService, productsService, taxRuleService } from "../../shared/services";

import { productsActions } from "../action-types";
import { IGenericArrProduct, IProductCharacteristics2, IProducts } from '../@types/products';

export const setProductsForUpdate = (val: IProducts): productsActions. IGetProductsForUpdate => ({
    type: productsActions.GET_PRODUCT_FOR_UPDATE,
    payload: val
})

export const setIsProductsForUpdate = (val: boolean): productsActions.IIsProductsForUpdate =>({
    type: productsActions.IS_PRODUCT_FOR_UPDATE,
    payload: val
})

export const createProduct = async(data: Partial<IProducts>) => {
    return productsService.create(data)
    .then((res: IProducts) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateProduct = async(data:Partial<IProducts>) => {
    return await productsService.patch(data?.id!, data)
    .then((res: IProducts) => {
        // console.log({res});
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteProduct = async (id: number ) =>{
    return  await productsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProducts =( val: boolean ): productsActions.ISwitchProductsRefreshTable=>({
    type: productsActions.SWITCH_PRODUCTS_REFRESH_TABLE,
    payload: val
})

export const getCategories = async () => {
    return await productCategoriesService.find({
        query:{
            $select: ["id","name","parent_id"],
            $client: {tree: true},
        },
    })
    .then((res)=>{ return res.data})
    .catch((err: any) => { return err.message })
}

export const setCategoriesArr = (val:IGenericArrProduct[]): productsActions.IGetCategoriesProducts =>({
    type: productsActions.GET_CATEGORIES_PRODUCTS,
    payload: val
})

export const getBrands = async () => {
    return await brandsService.find({
        query:{
            $select: ["id","name"],
            $limit: 9999999999999,
        },
    })
    .then((res)=>{ return res.data})
    .catch((err: any) => { return err.message })
}

export const setBrandsArr = (val:IGenericArrProduct[]): productsActions.IGetBrandsProducts =>({
    type: productsActions.GET_BRANDS_PRODUCTS,
    payload: val
})

export const getTaxArr = async () => {
    return await taxRuleService.find({
        query:{
            $select: ["id","name"],
            $limit: 9999999999999,
        },
    })
    .then((res)=>{ return res.data})
    .catch((err: any) => { return err.message })
}

export const setTaxArr = (val:IGenericArrProduct[]): productsActions.IGetTaxProducts =>({
    type: productsActions.GET_TAX_PRODUCTS,
    payload: val
})

//ProductCharacteristic

export const productsCharacteristicChangeModalState = (): productsActions.IProductsCharacteristicsChangeModalState => ({
    type: productsActions.PRODUCTS_CHARACTERISTICS_CHANGE_MODAL_STATE
})

export const setProductsCharacteristicForUpdate = (val: IProductCharacteristics2): productsActions.IGetProductsCharacteristicsForUpdate => ({
    type: productsActions.GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE,
    payload: val
})

export const setIsProductsCharacteristicForUpdate = (val: boolean): productsActions.IIsProductsCharacteristicsForUpdate =>({
    type: productsActions.IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE,
    payload: val
})

export const createProductCharacteristic2 = async(data: Partial<IProductCharacteristics2>) => {
    return productCharacteristics2Service.create(data)
    .then((res: IProductCharacteristics2) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateProductCharacteristic2 = async(data:Partial<IProductCharacteristics2>) => {
    return await productCharacteristics2Service.patch(data?.id!, data)
    .then((res: IProductCharacteristics2) => {
        // console.log({res});
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteProductCharacteristic2 = async (id: number ) =>{
    return  await productCharacteristics2Service.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProductsCharacteristics =( val: boolean ): productsActions.ISwitchProductsCharacteristicsRefreshTable=>({
    type: productsActions.SWITCH_PRODUCTS_CHARACTERISTICS_REFRESH_TABLE,
    payload: val
})

export const getCharacteristicArr = async () => {
    return await productCharacteristicsService.find({
        query:{
            $select: ["id","name"],
            $limit: 9999999,
        },
    })
    .then((res)=>{ return res.data})
    .catch((err: any) => { return err.message })
}

export const setCharacteristicArr = (val:IGenericArrProduct[]): productsActions.IGetCharacteristicProducts =>({
    type: productsActions.GET_CHARACTERISTIC_PRODUCTS,
    payload: val
})