import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { AdminMenuLayout, ModalRankings } from "../../../shared/components";
import { WrapperCard } from "./styled";
import { useSelector } from "react-redux";
import { selectModalRankings } from "../../../redux";
import { TableRankings } from "./TableRankings";
import { useRankings } from "../hooks";
import { FormProvider } from "react-hook-form";

export const Ratings = () => {
  const modalRankings = useSelector(selectModalRankings);
  const {
    isUpdateRanking,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalRankingsState,
  } = useRankings();

  return (
    <AdminMenuLayout pageDescription="Ratings" title="Ratings">
      <WrapperCard>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalRankingsState}
            >
              Agregar nueva calificación
            </Button>
          </Col>
        </Row>
        <TableRankings />

        {modalRankings && isUpdateRanking && (
          <FormProvider {...formMethodsUpdate}>
            <ModalRankings />
          </FormProvider>
        )}
        {modalRankings && !isUpdateRanking && (
          <FormProvider {...formMethodsCreate}>
            <ModalRankings />
          </FormProvider>
        )}
      </WrapperCard>
    </AdminMenuLayout>
  );
};
