"use client";

import React from "react";
import { programsTopicsAcademy } from "../../../../shared/services";
import { PaginatedResponse, ProgramTopicType } from "../../../../types/dto/program";

export default function useTopic(moduleId?: number) {
  const [loading, setLoading] = React.useState(false);
  const [programModuleTopic, setProgramModuleTopic] = React.useState<ProgramTopicType[]>([]);
  const [pagination, setPagination] = React.useState<Omit<PaginatedResponse, "data">>({
    limit: 50,
    skip: 0,
    total: 0,
  });

  const getTopic = async () => {
    if (loading || !moduleId) return;
    setLoading(true);

    await programsTopicsAcademy
      .find({
       query: {
        program_module_id: moduleId,
        $limit: pagination.limit,
        $skip: pagination.skip,
        $sort: {
          position: 1
        }
       }
      })
      .then((response) => {
        setProgramModuleTopic(response.data);
        setPagination({
          limit: response.limit,
          skip: response.skip,
          total: response.total,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    setProgramModuleTopic([]);
    getTopic().catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleId]);

  return {
    loading,
    pagination,
    programModuleTopic,
    refresh: getTopic,
    setProgramModuleTopic,
  };
}
