import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { TableProperties } from '../../../shared/components/ui/Table/types';
import { selectIsUpdateSystemVariables, selectSystemVariablesForUpdate, selectSystemVariablesRefreshTable } from "../../../redux";
import { refreshTableSystemVariables, setIsSystemVariablesForUpdate, systemVariablesChangeModalState, createSystemVariables, updateSystemVariables } from '../../../redux/actions/systemVariables.actions';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { DataSystemVariables } from "../../../redux/@types";
import { message } from "antd";
import { validateCreateSystemVariables, validateUpdateSystemVariables } from "../../../helpers";

export const useSystemVariables = () => {
  const dispatch = useAppDispatch();

  const isUpdateSystemVariables = useSelector(
    selectIsUpdateSystemVariables
  );
  const dataUpdateSystemVariables = useSelector(
    selectSystemVariablesForUpdate
  );
  const isRefreshTableSystemVariables = useSelector(
    selectSystemVariablesRefreshTable
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalSystemVariablesState = () => {
    formMethodsCreate.reset();
    dispatch(setIsSystemVariablesForUpdate(false));
    dispatch(systemVariablesChangeModalState());
  };

  const formMethodsCreate = useForm<DataSystemVariables>({
    resolver: yupResolver(validateCreateSystemVariables),
  });

  const formMethodsUpdate = useForm<DataSystemVariables>({
    resolver: yupResolver(validateUpdateSystemVariables),
  });

  useEffect(() => {
    if (isUpdateSystemVariables) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateSystemVariables });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSystemVariables, dataUpdateSystemVariables]);

  const onSubmitCreateOrUpdate = async (data: DataSystemVariables) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateSystemVariables) {
      //console.log("Actualiando===>", data);
      await updateSystemVariables({
        id: data.id,
        name: data.name,
        key: data.key,
        value: data.value,
      })
        .then((res: Partial<DataSystemVariables>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // dispatch(coursesCategoriesChangeModalState());
            message.success(
              `Se ha actualizado con éxito la variable de sistema con id:${res.id}`
            );
            dispatch(refreshTableSystemVariables(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createSystemVariables(data)
        .then((res: DataSystemVariables) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(systemVariablesChangeModalState());
            message.success(
              `Se ha creado con éxito la variable de sistema con id:${res.id}`
            );
            dispatch(refreshTableSystemVariables(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableSystemVariables) {
      tableProps?.refresh();
      dispatch(refreshTableSystemVariables(false));
    }
  }, [isRefreshTableSystemVariables]);
  return {
    //state
    isUpdateSystemVariables,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalSystemVariablesState,
  }
}
