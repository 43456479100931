import { Button, Col } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import Table from "../../../shared/components/ui/Table";
import { productCharacteristicsService } from "../../../shared/services";
import { dataProducsCharac } from "../../../redux/@types/productCharacteristics";
import { useTableProductCharacteristics } from "../hooks/useTableProductCharacteristics";

export const TableProductCharacteristics = () => {
  const { changeProductCharacteristicsForUpdate, showDeleteConfirm } =
    useTableProductCharacteristics();
  const columns: TableColumns<dataProducsCharac> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "Acciones",
      key: "operation",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeProductCharacteristicsForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <Table<dataProducsCharac>
      columns={columns}
      scroll={{ x: 'max-content' }}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={productCharacteristicsService}
      // onLoad={}
    />
  );
};
