import { Button, Col, Typography } from "antd";
import { useSelector } from "react-redux";
import { useTax, WrapperModalTax } from "../../../../modules/tax";
import { selectModalTax, selectTaxForUpdate } from "../../../../redux";
import { InputNumber, InputText } from "../inputs";
import { useFormContext } from "react-hook-form";
import { DataTax } from "../../../../redux/@types";

const { Title } = Typography;

export const ModalTax = () => {
  const modalTax = useSelector(selectModalTax);
  const updateTax = useSelector(selectTaxForUpdate);

  const { isLoading, changeModalTaxState, onSubmitUpdate } = useTax();

  const { control, handleSubmit: onSubmit } = useFormContext<DataTax>();
  return (
    <WrapperModalTax
      title={`Actualizando IVA: '${updateTax?.id!}'`}
      open={modalTax}
      width={"320px"}
      destroyOnClose
      onClose={changeModalTaxState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText placeHolder="IVA 29%" name="name" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Valor:
        </Title>
        <InputNumber
          //   stringMode={true}
          placeHolder="8"
          name="value"
          control={control}
          min
        />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitUpdate)}
        loading={isLoading}
      >
        Actualizar información
      </Button>
    </WrapperModalTax>
  );
};
