import { useState } from "react";
import { message } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validateLogin } from "../../../helpers";
import { authLogin, authSetToken, authSetUserCredentials } from "../../../redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
export interface FormData {
  email: string;
  password: string;
}

export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const formsMethods = useForm<FormData>({
    resolver: yupResolver(validateLogin),
  });

  const onLoginUser =({ email, password }: FormData) => {
    // console.log({ email, password })
    if (isLoading) return;
    setIsLoading(true);
    authLogin(email, password)
    .then((res )=>{
      if(res?.accessToken){
        console.log({res})
        dispatch(authSetToken(res?.accessToken))
        dispatch(authSetUserCredentials(res?.user))
        if(res.user.role === "seller"){
          navigate("/orders")
        }else if(res.user.role === "teacher"){
          navigate("/gestion-academy/programs")
        }else{
          navigate("/users")
        }
      }else{
        console.log({res})
        message.error('Invalid email or password.')
      }
    })
    .catch((err)=>{
      message.error(err.message)
    })
    setIsLoading(false);
  };

  return {
    //state
    isLoading,
    //methods
    formsMethods,
    //functions
    onLoginUser,
  };
};
