
import AcademyStudentPendingPayment from "../modules/AcademyStudentPendingPayment/AcademyStudentPendingPayment";
import { AdminMenuLayout } from "../shared/components";

export default function AcademyStudentPendingPaymentPage() {

  return (
    <AdminMenuLayout title="" pageDescription="">
     <AcademyStudentPendingPayment/>
    </AdminMenuLayout>
  );
}

