"use client";
import React from "react";
import { programsModuleAcademy } from "../../../../shared/services";
import { ProgramModuleType } from "../../../../types/dto/program";
import { message, Modal } from "antd";

const { confirm } = Modal;

export interface UseDeleteProgramModuleProps {
  onSuccess?: (programModule: ProgramModuleType) => void;
}

export default function useDeleteProgramModule(
) {
  const [loading, setLoading] = React.useState(false);

  const showDeleteConfirm = (valId: number, val: string) => {
    if (!valId ) {
      message.error("Programa del estudiante no válido");
      return;
    }

    confirm({
      title: `Estás seguro de borrar el modulo con el id: ${valId}`,
      // icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar modulo" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar modulo",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        setLoading(true);
        try {
          await programsModuleAcademy.remove(valId).then((res)=>{
            message.success(`Se ha borrado con éxito el modulo con id:${valId}`);
          })
        } catch (err: any) {
          message.error(`Error: ${err.message}`);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {},
    });
  };

  return { showDeleteConfirm, loading };
}
