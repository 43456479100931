import { IUserRes } from "../@types"

export const AUTH_USER_FROM_TOKEN_REQUEST = '[ AUTH ACTIONS ] Auth User From Token Request'
export interface IAuthUserFromTokenRequestAction {
  type: typeof AUTH_USER_FROM_TOKEN_REQUEST
  /**
   * Token JWT
   */
  payload: string
}

export const AUTH_USER_FROM_TOKEN_SUCCEEDED = '[ AUTH ACTIONS ] Auth User From Token Succeeded'
export interface IAuthUserFromTokenSucceededAction {
  type: typeof AUTH_USER_FROM_TOKEN_SUCCEEDED
  payload: IUserRes
}

export const AUTH_USER_FROM_TOKEN_FAILED = '[ AUTH ACTIONS ] Auth User From Token Failed'
export interface IAuthUserFromTokenFailedAction {
  type: typeof AUTH_USER_FROM_TOKEN_FAILED
}

//daniel dev logic

export const AUTH_USER_SET_TOKEN_CREDENTIAL = '[ AUTH ACTIONS ] Auth Set Token Credential'

export interface IAuthUserSetTokenCredential {
  type: typeof AUTH_USER_SET_TOKEN_CREDENTIAL
  payload: string
}

export const AUTH_USER_SET_CREDENTIALS = '[ AUTH ACTIONS ] Auth Set Credentials'

export interface IAuthUserSetCredentials {
  type: typeof AUTH_USER_SET_CREDENTIALS
  payload: IUserRes
}


export const AUTH_USER_LOGOUT = '[ AUTH ACTIONS ] Logout User'

export interface IAuthUserLogout {
  type: typeof AUTH_USER_LOGOUT
}