import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  deleteWorkOffers,
  refreshTableWorkOffers,
  setWorkOffersForUpdate,
  setIsWorkOffersForUpdate,
} from "../../../redux";
import { DataWorkOffers } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useWorkOffers } from "./useWorkOffers";

const { confirm } = Modal;

export const useTableWorkOffers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tableProps } = useWorkOffers();

  const changeWorkOffersForUpdate = async (val: DataWorkOffers) => {
    dispatch(setIsWorkOffersForUpdate(true));
    navigate(`/workOffers/${val.id}`);
    // dispatch(contactsDirectoryChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la Oferta de trabajo con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar oferta" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar oferta",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteWorkOffers(valId)
          .then(() => {
            dispatch(refreshTableWorkOffers(true));
            message.success(
              `Se ha borrado con éxito la Oferta de trabajo con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeWorkOffersForUpdate,
    showDeleteConfirm,
  };
};
