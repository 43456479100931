import * as yup from 'yup'

export const validateCreateBanners = yup.object().shape({
    // path_movile:yup
    // .string()
    // .required('Campo requerido.')
    // .min(1, 'La PATH MOBILE debe tener más de 1 caracteres'),
    // path_desktop:yup
    // .string()
    // .required('Campo requerido.')
    // .min(1, 'La PATH ESCRITORIO debe tener más de 1 caracteres'),
    priority:yup
    .number().typeError('Campo requerido.')
    .required('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    type:yup
    .string()
    .required('Campo requerido.')
    .oneOf(["HOME", "OFFERS", "POPUP", "ACADEMY", "FLASH_SALE", "HOME_MOBILE" ], 'Debes elegir entre estas opciones'),
    status:yup
    .string()
    .required('Campo requerido.')
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
    action_type:yup
    .string()
    .required('Campo requerido.')
    .oneOf(['BANNER_PRODUCTS', 'CATEGORY', 'PRODUCT', 'URL', 'COURSE' ], 'Debes elegir entre estas opciones'),
    // url:yup
    // .string()
    // .required('Campo requerido.')
    // .min(2, 'LA URL debe tener más de 1 caracteres'),
    // start_date:yup
    // .string()
    // .required('Campo requerido.')
    // .min(1, 'La FECHA DE INICIO debe tener más de 1 caracteres'),
    // end_date:yup
    // .string()
    // .required('Campo requerido.')
    // .min(1, 'La FECHA DE FINALIZACIÓN debe tener más de 1 caracteres'),
})