import { DataBrands } from "../@types"

export const BRANDS_MODAL_STATE = '[ BRANDS ] Brands Modal State'

export interface IBrandsModalState {
    type: typeof BRANDS_MODAL_STATE
}

export const GET_BRAND_FOR_UPDATE = '[ BRANDS ] Get Brand For Update'

export interface IGetBrandForUpdate {
    type: typeof GET_BRAND_FOR_UPDATE
    payload: DataBrands
}

export const IS_BRANDS_FOR_UPDATE ='[ BRANDS ] Is Brand For Update'

export interface IIsBrandForUpdate {
    type: typeof IS_BRANDS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_BRANDS_REFRESH_TABLE = '[ BRANDS ] Switch Brands Refresh Table'

export interface ISwitchBrandsRefreshTable {
    type: typeof SWITCH_BRANDS_REFRESH_TABLE
    payload: boolean
}
