import { OrderById } from "../modules/orders/ui/orderById";
import { WrapperOrderById } from "../modules/orders/ui/styled";
import { AdminMenuLayout } from "../shared/components";

export const OrdersByIdPage = () => {
  return (
    // <AdminMenuLayout pageDescription="OrderById" title="OrderById">
    //   <WrapperOrderById>
    <OrderById />
    //   </WrapperOrderById>
    // </AdminMenuLayout>
  );
};
