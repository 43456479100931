"use client";

import React from "react";
import { ProgramStudentModuleTechniqueType } from "../../../types/dto/program";
import { programsStudentModuleTechniqueAcademy } from "../../../shared/services";
import { StudentModuleTechniqueDetails } from "./qualifications/useEditQualificationStudent";

export default function useProgramModuleTechniques(techeniqueId: number) {
  const [loading, setLoading] = React.useState(false);
  const [programModuleTechniques, setProgramModuleTechniques] = React.useState<ProgramStudentModuleTechniqueType>();
  const [getStudentModuleTechniqueDetails, setGetStudentModuleTechniqueDetails] = React.useState<StudentModuleTechniqueDetails[]>([]);

  const getSectionProgramTechniquesById = async () => {
    if (loading || !techeniqueId) return;
    setLoading(true);

   await programsStudentModuleTechniqueAcademy
      .get(techeniqueId)
      .then((response) => {
        setProgramModuleTechniques(response);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSectionProgramTechniquesByQuery = async () => {
    if (loading || !techeniqueId) return;
    setLoading(true);

   await programsStudentModuleTechniqueAcademy
      .get(1,{query: {'$client[getStudentModuleTechniqueDetails]' : techeniqueId}})
      .then((response) => {
        setGetStudentModuleTechniqueDetails(response.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  React.useEffect(() => {
    getSectionProgramTechniquesById();
    getSectionProgramTechniquesByQuery();
  }, [techeniqueId]);

  return {
    loading,
    programModuleTechniques,
    getStudentModuleTechniqueDetails,
    getSectionProgramTechniquesById,
    getSectionProgramTechniquesByQuery
  };
}
