"use client";
import React from "react";
import { programsStudentAcademy } from "../../../../shared/services";
import { ProgramStudentType } from "../../../../types/dto/program";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import type { Resolver } from "react-hook-form";
import * as yup from "yup";
import { message } from "antd";

export interface UseUpdateProgramStudentProps {
  programStudent: ProgramStudentType;
  setOpenModal: (val: boolean) => void;
}

export default function useUpdateStudentProgram(
  props: UseUpdateProgramStudentProps
) {
  const [loading, setLoading] = React.useState(false);

  const {
    control,
    handleSubmit: onSubmit,
    reset,
  } = useForm<ProgramStudentType>({
    resolver: yupResolver(
      yup.object({ user_id: yup.number().required() })
    ) as unknown as Resolver<ProgramStudentType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    await programsStudentAcademy
      .patch(props?.programStudent?.id!, { status: data.status })
      .then((res) => {
        props?.setOpenModal(false);
        message.success(
          `Se ha actualizado correctamente el estudiante al programa`
        );
      })
      .catch((err: Error) => {
        message.error(`Ha ocurrido un error: ${err.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  React.useEffect(() => {
    if (props?.programStudent) {
      reset({
        user_id: +props?.programStudent?.user_id,
      });
    }
  }, [props?.programStudent, reset])

  return {
    control,
    loading,
    handleSubmit,
  };
}
