import { useEffect, useState } from "react";
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  courseChaptersChangeModalState,
  createCourseChapters,
  deleteCourseChapters,
  getSectionsCourseChapters,
  refreshTableCourseChapters,
  selectArrSectionsForCourses,
  selectCourseChaptersForUpdate,
  selectCourseChaptersRefreshTable,
  selectIsUpdateCourseChapters,
  setCourseChaptersForUpdate,
  setIsCourseChaptersForUpdate,
  updateCourseChapters,
} from "../../../../redux";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../../shared/components/ui/Table/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { DataCourseChapters } from "../../../../redux/@types";
import {
  validateCreateCourseChapters,
  validateUpdateCourseChapters,
} from "../../../../helpers";
import { courseSectionsService } from "../../../../shared/services";

const { confirm } = Modal;

export const useCourseChapters = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const arrSections = useSelector(selectArrSectionsForCourses);

  const isUpdateCourseChapters = useSelector(selectIsUpdateCourseChapters);
  const dataUpdateCourseChapters = useSelector(selectCourseChaptersForUpdate);
  const isRefreshTableCourseChapters = useSelector(
    selectCourseChaptersRefreshTable
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalCourseChaptersState = () => {
    dispatch(setIsCourseChaptersForUpdate(false));
    dispatch(courseChaptersChangeModalState());
  };

  const formMethodsCreate = useForm<DataCourseChapters>({
    resolver: yupResolver(validateCreateCourseChapters),
  });

  const formMethodsUpdate = useForm<DataCourseChapters>({
    resolver: yupResolver(validateUpdateCourseChapters),
  });

  useEffect(() => {
    if (isUpdateCourseChapters) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateCourseChapters });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCourseChapters, dataUpdateCourseChapters]);

  const onSubmitCreateOrUpdate = async (data: DataCourseChapters) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCourseChapters) {
      //console.log("Actualiando===>", data);
      await updateCourseChapters({
        id: data.id,
        name: data.name,
        path_video: data.path_video,
        position: data.position,
        course_section_id: Number(data.course_section_id),
        type: data.type,
        duration: data.duration,
      })
        .then((res: Partial<DataCourseChapters>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            dispatch(courseChaptersChangeModalState());
            message.success(
              `Se ha actualizado con éxito el capitulo de curso con id:${res.id}`
            );
            dispatch(refreshTableCourseChapters(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createCourseChapters({
        ...data,
        course_id: Number(pathname.split("/")[2]),
        course_section_id: Number(data.course_section_id),
      })
        .then((res: DataCourseChapters) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(courseChaptersChangeModalState());
            message.success(
              `Se ha creado con éxito el capitulo de curso con id:${res.id}`
            );
            dispatch(refreshTableCourseChapters(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCourseChapters) {
      tableProps?.refresh();
      dispatch(refreshTableCourseChapters(false));
    }
  }, [isRefreshTableCourseChapters]);

  const changeCourseChaptersForUpdate = (val: DataCourseChapters) => {
    dispatch(setIsCourseChaptersForUpdate(true));
    dispatch(setCourseChaptersForUpdate(val));
    dispatch(courseChaptersChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el capitulo con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar capitulo" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar capitulo",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteCourseChapters(valId)
          .then(() => {
            dispatch(refreshTableCourseChapters(true));
            message.success(
              `Se ha borrado con éxito el capitulo con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  const getArrOfServices = async () => {
    await courseSectionsService
      .find({
        query: {
          course_id: Number(pathname.split("/")[2]),
          $limit: 9999999999999,
        },
      })
      .then(({ data }) => {
        dispatch(getSectionsCourseChapters(data));
      });
  };

  useEffect(() => {
    if (!arrSections.length) {
      getArrOfServices();
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    //state
    isUpdateCourseChapters,
    isLoading,
    tableProps,
    arrSections,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalCourseChaptersState,
    changeCourseChaptersForUpdate,
    showDeleteConfirm,
  };
};
