import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useUsers } from "../../../users/hooks/useUsers";
import { CaUserAcademy } from "../../../../types/dto/program";
import { WrapperAcademyProgramsStudent } from "../styled";
import { WrapperCardUserOrder } from "../../../orders/ui/styled";
import { WrapperTabNav } from "../../../products";
import { Typography } from "antd";
import { TableProgramPersonalInfoTeacher } from "./TableProgramPersonalInfoTeacher";

const { Title } = Typography;

export const AcademyProgramsPersonalInfoTeacher = () => {
  const [studentInfo, setStudentInfo] = React.useState<CaUserAcademy>();
  const { findUserById } = useUsers();
  const { user_id, program_id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    const fecth = async () => {
      await findUserById(+user_id!).then((it) => setStudentInfo(it));
    };
    fecth();
  }, [user_id]);
  const goBack = () => {
    navigate(`/gestion-academy/programs/details/${program_id}`)
  };

  return (
    <WrapperAcademyProgramsStudent>
      <div>
        <span
          className="flex items-center underline text-gray-500 cursor-pointer"
          onClick={goBack}
        >
          <ArrowLeftOutlined className="mr-2" />
          Ir atras
        </span>
        <div>
          <Title style={{ textAlign: "left" }}>
            Información del profesor
          </Title>
        </div>
        <WrapperCardUserOrder>
          <div style={{ display: "flex", justifyContent: "space-around", width:'100%', flexWrap:'wrap'}}>
            <div>
              {" "}
              <label className="text-gray-500">Nombre:</label>
              <p>
                {studentInfo?.first_name}
              </p>
            </div>
            <div>
              {" "}
              <label className="text-gray-500">Apellido:</label>
              <p>
                {studentInfo?.last_name}
              </p>
            </div>
            <div>
              {" "}
              <label className="text-gray-500">Email:</label>
              <p>
                {studentInfo?.email}
              </p>
            </div>
            <div>
              {" "}
              <label className="text-gray-500">Telefono:</label>
              <p>
                {studentInfo?.phone}
              </p>
            </div>
          </div>
        </WrapperCardUserOrder>
      </div>
      <div>
        <div>
          <Title style={{ textAlign: "left" }}>Programa</Title>
          <WrapperTabNav
            items={[
              {
                label: `PROGRAMAS DEL PROFESOR`,
                key: "3",
                children: (
                  <TableProgramPersonalInfoTeacher
                    studentId={+user_id!}
                    programId={+program_id!}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </WrapperAcademyProgramsStudent>
  );
};
