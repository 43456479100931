import { AdminMenuLayout } from "../../../shared/components";
import { WrapperOrders } from "./styled";
import { TableOrders } from "./TableOrders";

export const Orders = () => {
  return (
    <AdminMenuLayout
      pageDescription="ProductCategories"
      title="ProductCategories"
    >
      <WrapperOrders>
        <TableOrders />
      </WrapperOrders>
    </AdminMenuLayout>
  );
};
