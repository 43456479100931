import { TRootState } from "../@types"

export const selectModalPriceList = ( state: TRootState ) =>state.priceList.isModalPriceListOpen

export const selectIsUpdatePriceList = ( state: TRootState ) =>state.priceList.isUpdatePriceList

export const selectPriceListForUpdate = ( state: TRootState ) =>state.priceList.priceListForUpdate

export const selectPriceListRefreshTable =  ( state: TRootState ) =>state.priceList.refreshTablePriceList

