import { WrapperAcademyStudentSchedulePayment, WrapperTabNav } from "./style";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { TablePaymentByStudent } from "./ui/table/TablePaymentByStudent";
import { Button, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { TableAcademyStudentSchedulePayment } from "./ui/table/TableAcademyStudentSchedulePayment";
import { ModalScheludePaymentStudent } from "../../shared/components/ui/modals/ModalScheludePaymentStudent";
import React from "react";
const { Title } = Typography;

export default function AcademyStudentSchedulePayment() {
  const  [openModalScheduleStudent, setOpenModalScheduleStudent] = React.useState<boolean>(false)
  const navigate = useNavigate();
  const { user_id, program_id } = useParams();
  const goBack = () => {
    navigate("/gestion-academy/programs/details/10");
  };
  return (
    <WrapperAcademyStudentSchedulePayment>
      <span
        className="flex items-center underline text-gray-500 cursor-pointer"
        onClick={goBack}
      >
        <ArrowLeftOutlined className="mr-2" />
        Ir atras
      </span>
      <div>
        <Title style={{ textAlign: "left", fontSize: 25 }}>Programa</Title>
        <h2 className="text-2xl"></h2>
        <WrapperTabNav
          items={[
            {
              label: `HISTORIAL DE PAGOS`,
              key: "3",
              children: (
                <>
                  <TablePaymentByStudent />
                </>
              ),
            },
            {
              label: `CRONOGRAMA DE PAGOS DE ESTUDIANTES `,
              key: "4",
              children: (<TableAcademyStudentSchedulePayment />),
            },
          ]}
        />
      </div>
    </WrapperAcademyStudentSchedulePayment>
  );
}
