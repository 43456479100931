import { useEffect, useState } from "react";
import { Button, Col, Typography } from "antd";
import moment from "moment";
import { EyeOutlined } from "@ant-design/icons";

import { IOrder } from "../../../redux/@types";
import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { ordersService, orderStatusService } from "../../../shared/services";
import { decimal } from "../../../shared/utils/Helper";
import { useTableOrders } from "../hooks/useTableOrders";

const { Title } = Typography;

interface DataStatus {
  id: number;
  name: string;
  description: string;
  status_code: string;
  color: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
}

export const TableOrders = () => {
  // const [tableProps, setTableProps] = useState<TableProperties>()
  // const tes = ()=>{
  //   tableProps?.refresh()
  // }

  const [orderStatus, setOrderStatus] = useState<DataStatus[]>([]);

  const getOrderStatus = async () => {
    await orderStatusService
      .find({ query: { $limit: 9999999999999 } })
      .then((res) => {
        setOrderStatus(res.data);
      });
  };

  useEffect(() => {
    getOrderStatus();
  }, []);

  const { changeOrderForUpdate } = useTableOrders();
  const columns: TableColumns<IOrder> = [
    {
      title: "ID",
      width: 5,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    // {
    //   title: "Email",
    //   width: 5,
    //   dataIndex: "first_name",
    //   // key: "data_value",
    //   render: (_, item) => {
    //     return (
    //       item.user.first_name
    //     );
    //   },
    //   filterType: "text",
    // },
    // {
    //   title: "Email",
    //   width: 5,
    //   dataIndex: "last_name",
    //   // key: "data_value",
    //   render: (_, item) => {
    //     return (
    //       item.user.last_name
    //     );
    //   },
    //   filterType: "text",
    // },
    {
      title: "Usuario",
      width: 11,
      render: (_, item) => {
        return (
          <Col
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
          }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {`${item.user.first_name} ${item.user.last_name}`}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Email",
      width: 12,
      dataIndex: "data_value",
      // key: "data_value",
      render: (_, item) => {
        return (
          item.user.email
        );
      },
      filterType: "text",
    },
    {
      title: "Estado",
      width: 15,
      dataIndex: "order_status_id",
      render: (_, item) => {
        // console.log('c===>',item.id,item.status.name)
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {orderStatus && (
              <Col
                style={{
                  display: "flex",
                  padding: "5px 27px",
                  borderRadius: "20px",
                  backgroundColor: orderStatus?.find(
                    (order) => order.id === item.order_status_id
                  )?.color,
                }}
              >
                <Title
                  style={{
                    marginBottom: "1.5px",
                    color: "white",
                  }}
                  level={5}
                >
                  {item.status.name}
                </Title>
              </Col>
            )}
          </Col>
        );
      },
      filters: orderStatus.map((item) => {
        return {
          text: `${item.name}`,
          value: item.id,
        };
      })
    },
    {
      title: "Método de pago",
      width: 6,
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: "Total",
      width: 7,
      dataIndex: "total_price",
      key: "total_price",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              $ {decimal(item.total_price)}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Pago recibido",
      width: 7,
      dataIndex: "total_price",
      key: "total_price",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              $ {decimal(item.total_payment_received)}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Pago pendiente",
      width: 7,
      dataIndex: "total_price",
      key: "total_price",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              $ {decimal(item.total_payment_pending)}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Fuentes",
      width: 6,
      dataIndex: "sources",
      key: "sources",
      filters: [
        {
          text: "WebApp",
          value: "webApp",
        },
        {
          text: "App",
          value: "app",
        },
      ],
    },
    {
      title: "Fecha",
      width: 7,
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {moment(item.createdAt).add("h",5).format("DD/MM/YYYY")}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 5,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeOrderForUpdate(item)}
              icon={<EyeOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<IOrder>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={ordersService}
      //onLoad={setTableProps}
    />
  );
};
