import { ITaxState } from "../@types";
import { taxActions } from "../action-types";

type taxActionType =
| { type: typeof taxActions.TAX_CHANGE_MODAL_STATE }
| { type: typeof taxActions.GET_TAX_FOR_UPDATE; payload: any }
| { type: typeof taxActions.SWITCH_TAX_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: ITaxState = {
    taxForUpdate: undefined,
    taxModalIsOpen: false,
    refreshTableTax: false,
}

export default function taxReducer(state: ITaxState = INITIAL_STATE, action: taxActionType): ITaxState {
    switch ( action.type ){
        case taxActions.TAX_CHANGE_MODAL_STATE:
            return{
                ...state,
                taxModalIsOpen: !state.taxModalIsOpen,
            }
        case taxActions.GET_TAX_FOR_UPDATE:
            return{
                ...state,
                taxForUpdate: action.payload,
            }
        case taxActions.SWITCH_TAX_REFRESH_TABLE:
            return{
                ...state,
                refreshTableTax: action.payload,
            }
        default:
            return state
    }
}