import { AdminMenuLayout } from "../../../shared/components";
import { WrapperAcademyProgramsStudent } from "./styled";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { ModalPrograms } from "../../../shared/components/ui/modals/ModalPrograms";
import React from "react";
import { TableProgram } from "./programs/TableProgram";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux";
const { Title } = Typography;

export const AcademyPrograms = () => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [duplicateProgram, setDuplicateProgram] =
    React.useState<boolean>(false);
  const user = useSelector(selectUser);

  const handleDuplicateProgram = () => {
    setOpenModal(true);
    setDuplicateProgram(true);
  };
  return (
    <AdminMenuLayout
      pageDescription="ProductCategories"
      title="ProductCategories"
    >
      <div>
        <div>
          <WrapperAcademyProgramsStudent>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Title style={{ textAlign: "left", fontSize: 25 }}>
                Visualización de programas
              </Title>
              {user?.role !== "teacher" && (
                <div style={{ display: "flex", gap: 10 }}>
                  <Button
                    type="primary"
                    shape="round"
                    icon={<PlusOutlined />}
                    size="large"
                    onClick={() => setOpenModal(true)}
                  >
                    Agregar programa
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    icon={<PlusOutlined />}
                    size="large"
                    onClick={handleDuplicateProgram}
                  >
                    Duplicar programa
                  </Button>
                </div>
              )}
            </div>
            <TableProgram />
          </WrapperAcademyProgramsStudent>
        </div>
      </div>
      <ModalPrograms
        onClose={() => {
          setDuplicateProgram(false);
        }}
        openModal={openModal}
        setOpenModal={setOpenModal}
        duplicateProgram={duplicateProgram}
      />
    </AdminMenuLayout>
  );
};
