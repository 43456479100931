import { Button, Col, Typography } from "antd";
import { Dispatch, SetStateAction } from "react";
import { useBannersProducts } from "../../../../modules";
import { WrapperModalBanners } from "../../../../modules/banners/ui/styled";
import { SimpleInputSelect } from "../inputs";

const { Title } = Typography;

interface Props {
    modalIsOpen: boolean
    setModalIsOpen: Dispatch<SetStateAction<boolean>>
}
export const ModalBannersProducts = ({modalIsOpen,setModalIsOpen}:Props) => {
    const { arrOptionsProducts, changeSelectValue,onSubmitCreateProductForBanner, onInputKeyDown, selectedValue, isLoading } = useBannersProducts()
  return (
    <WrapperModalBanners
      title={`Agregar un nuevo producto al banner`}
      open={modalIsOpen}
      width={"320px"}
      destroyOnClose
      onClose={()=>setModalIsOpen(false)}
    >
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Producto:
          </Title>
          <SimpleInputSelect
              showSearch
              valueSelect={selectedValue}
              onChange={changeSelectValue}
              label={"Seleccione producto"}
              onInputKeyDown={onInputKeyDown}
              dataOptions={
                arrOptionsProducts || []
              }
              maxWidth="100%"
            />
        </Col>

      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={()=>{
            onSubmitCreateProductForBanner()
            .then((res)=>{
                setModalIsOpen(false)
            })
        }}
        loading={isLoading}
      >
        Agregar producto al banner
      </Button>
    </WrapperModalBanners>
  )
}
