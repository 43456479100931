import {Card, Tabs} from "antd";
import styled from "styled-components";

export const WrapperAcademyStudentPendingPayment = styled(Card)`
  width: 100%;
  height: auto;
  min-height: 100%;

  font-weight: 400;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  border: 1px solid #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: #fff;
  border-radius: 1rem !important;

  .ant-card-body {
  }
`;

export const WrapperTabNav = styled(Tabs)`
  width: 100%;
`;
