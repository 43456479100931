import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createListCustomerGroups, listCustomerGroupsChangeModalState, refreshTableListCustomerGroups, selectIsUpdateListCustomerGroups, selectListCustomerGroupsForUpdate, selectListCustomerGroupsRefreshTable,  setIsListCustomerGroupsForUpdate, setListCustomerGroupsForUpdate, updateListCustomerGroups, } from "../../../redux";
import { DataListCustomerGroups } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateCreateListCustomerGroups, validateUpdateListCustomerGroups } from "../../../helpers";
import { useParams, useNavigate } from 'react-router-dom';
import { useIsMounted } from "../../fulfillmentCompany";
import { listCustomerGroupsService } from "../../../shared/services";

export interface Props {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
  customers: CustomerElement[];
  price_lists: any[];
}

export interface CustomerElement {
  id: number;
  customer_group_id: number;
  user_id: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
  customer: CustomerCustomer;
}

export interface CustomerCustomer {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  profile_picture: null;
}

export const useListCustomerGroups = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isMounted = useIsMounted();
    const isUpdateListCustomerGroups = useSelector(
        selectIsUpdateListCustomerGroups
      );
      const dataUpdateListCustomerGroups = useSelector(
        selectListCustomerGroupsForUpdate
      );
      const isRefreshTableListCustomerGroups = useSelector(
        selectListCustomerGroupsRefreshTable
      );

      const [isLoading, setIsLoading] = useState(false);
      const [tableProps, setTableProps] = useState<TableProperties>();
      const [singleListCustomerGroups, setSingleListCustomerGroups] =
      useState<Props>();

      const getSingleListCustomerGroups = async () => {
        await listCustomerGroupsService
          .get(Number(id))
          .then((res) => {
            setSingleListCustomerGroups(res);
            dispatch(setListCustomerGroupsForUpdate(res));
            dispatch(setIsListCustomerGroupsForUpdate(true));
          })
          .catch((err: any) => {
            console.log(err);
            navigate(`/listCustomerGroups`);
            message.error(err.message);
          });
      };

      useEffect(() => {
        if(!isNaN(Number(id)) && isMounted){
          getSingleListCustomerGroups();
        }
      }, [ isMounted,id, isUpdateListCustomerGroups]);

      const changeModalListCustomerGroupsState = () => {
        dispatch(setIsListCustomerGroupsForUpdate(false));
        dispatch(listCustomerGroupsChangeModalState());
      };

      // const changeListCustomerGroupsForUpdate = (item: DataListCustomerGroups) => {
      //   dispatch(setIsListCustomerGroupsForUpdate(true));
      //   dispatch(setListCustomerGroupsForUpdate(item));
      // };

      const formMethodsCreate = useForm<DataListCustomerGroups>({
        resolver: yupResolver(validateCreateListCustomerGroups),
      });

      const formMethodsUpdate = useForm<DataListCustomerGroups>({
        resolver: yupResolver(validateUpdateListCustomerGroups),
      });

    useEffect(() => {
        if (isUpdateListCustomerGroups) {
          formMethodsCreate.reset();
          formMethodsUpdate.reset({ ...dataUpdateListCustomerGroups });
        }

        return () => {
          formMethodsUpdate.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isUpdateListCustomerGroups, dataUpdateListCustomerGroups]);

      const onSubmitCreateOrUpdate = async (data: DataListCustomerGroups) => {
        // console.log({ data });
        if (isLoading) return;
        setIsLoading(true);
        if (isUpdateListCustomerGroups) {
          //console.log("Actualiando===>", data);
          await updateListCustomerGroups({
            id: data.id,
            name: data.name,
          })
            .then((res: Partial<DataListCustomerGroups>) => {
              setIsLoading(false);
              // dispatch(mappingUpdatedUser(res))
              if (res.id) {
                navigate(`/listCustomerGroups`);
                // dispatch(listCustomerGroupsChangeModalState());
                message.success(
                  `Se ha actualizado con éxito la list. grup. clientes con id:${res.id}`
                );
                dispatch(refreshTableListCustomerGroups(true));
              } else {
                message.error(res as string);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        } else {
          // console.log('Creando===>',data)
          await createListCustomerGroups(data)
            .then((res: DataListCustomerGroups) => {
              setIsLoading(false);
              if (res.id) {
                dispatch(listCustomerGroupsChangeModalState());
                message.success(
                  `Se ha creado con éxito la list. grup. clientes con id:${res.id}`
                );
                dispatch(refreshTableListCustomerGroups(true));
              } else {
                message.error(res as any);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        }
      };

      useEffect(() => {
        if (isRefreshTableListCustomerGroups) {
          tableProps?.refresh();
          dispatch(refreshTableListCustomerGroups(false));
        }
      }, [isRefreshTableListCustomerGroups]);
  return {
    //state
    isUpdateListCustomerGroups,
    isLoading,
    tableProps,
    singleListCustomerGroups,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    changeModalListCustomerGroupsState,
    onSubmitCreateOrUpdate,
  }
}
