import { Button, Col, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { priceListService } from "../../../shared/services";
import { DataPriceList } from "../../../redux/@types/";
import { usePriceList, useTablePriceList } from "../hooks";
import moment from "moment";

const { Title } = Typography;

export const TablePriceList = () => {
  const { showDeleteConfirm, changePriceListForUpdate } = useTablePriceList();
  const { setTableProps, updateStatus, isLoading } = usePriceList();
  const columns: TableColumns<DataPriceList> = [
    {
      title: "ID",
      width: 4,
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Nombre",
      key: "nombre",
      dataIndex: "name",
      width: 12,
      filterType: "text",
    },
    {
      title: "Estado",
      width: 6,
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <button
            disabled={isLoading}
              onClick={()=>{
                if(item.status === "active"){
                  updateStatus("inactive", item.id)
                }else if( item.status === "inactive"){
                  updateStatus("active", item.id)
                }
              }}
              style={{
                display: "flex",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "white",
                }}
                level={5}
              >
                {item.status}
              </Title>
            </button>
          </Col>
        );
      },
      filters: [
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Inactivo",
          value: "inactive",
        },
      ],
    },
    {
      title: "Prioridad",
      key: "priority",
      dataIndex: "priority",
      width: 3,
      filterType: "text",
    },
    {
      title: "Fecha de inicio",
      dataIndex: "start_at",
      key: "start_at",
      width: 8,
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {moment(item.start_at).utcOffset(0).format("DD/MM/YYYY HH:mm:ss")}
          </Title>
        </Col>
      ),
    },
    {
      title: "Fecha de finalización",
      dataIndex: "ends_at",
      key: "ends_at",
      width: 8,
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {moment(item.ends_at).utcOffset(0).format("DD/MM/YYYY HH:mm:ss")}
          </Title>
        </Col>
      ),
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 4,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changePriceListForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<DataPriceList>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={priceListService}
      onLoad={setTableProps}
    />
  );
};
