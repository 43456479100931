import { contactsDirectoryCategories } from "../../shared/services"
import { DataDirectoryCategories } from "../@types"
import { directoryCategoriesActions } from "../action-types"

export const directoryCategoriesChangeModalState = (): directoryCategoriesActions.IDirectoryCategoriesModalState =>({
    type: directoryCategoriesActions.DIRECTORY_CATEGORIES_MODAL_STATE
})

export const setDirectoryCategoriesForUpdate = (val: DataDirectoryCategories): directoryCategoriesActions.IGetDirectoryCategoriesForUpdate =>({
    type: directoryCategoriesActions.GET_DIRECTORY_CATEGORIES_FOR_UPDATE,
    payload: val
})

export const setIsDirectoryCategoriesForUpdate = (val: boolean): directoryCategoriesActions.IIsDirectoryCategoriesForUpdate =>({
    type: directoryCategoriesActions.IS_DIRECTORY_CATEGORIES_FOR_UPDATE,
    payload: val
})

export const createDirectoryCategories = async(data: Partial<DataDirectoryCategories>) =>{
    return await contactsDirectoryCategories.create(data)
    .then((res: DataDirectoryCategories) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateDirectoryCategories =async(data: Partial<DataDirectoryCategories>) =>{
    return await contactsDirectoryCategories.patch(data?.id!, data)
    .then((res: DataDirectoryCategories) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteDirectoryCategories = async(id: number) => {
    return await contactsDirectoryCategories.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableDirectoryCategories = (val: boolean): directoryCategoriesActions.ISwitchDirectoryCategoriesRefreshTable =>({
    type: directoryCategoriesActions.SWITCH_DIRECTORY_CATEGORIES_REFRESH_TABLE,
    payload: val
})