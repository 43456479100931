import * as yup from 'yup'

export const validateCreateDiscountCode = yup.object().shape({
    status: yup
    .string()
    .required('Campo requerido.')
    .oneOf(['active', 'inactive', 'pending security verification', 'pending user data', 'pending validation'], 'Debes elegir entre estas opciones'),
    name: yup
    .string()
    .required('Campo requerido.')
    .min(3, 'El NOMBRE debe tener más de 2 caracteres'),
    code:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El CÓDIGO debe tener más de 2 caracteres'),
    quantity:yup
    .number().typeError('Campo requerido.')
    .required('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    value_percentage:yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    value_amount:yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    uses_by_user:yup
    .number().typeError('Campo requerido.')
    .required('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    order_min_amount:yup
    .number().typeError('Campo requerido.')
    .required('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    date_start: yup
    .date()
    .required('Campo requerido.'),
    date_end: yup
    .date()
    .required('Campo requerido.'),
})
