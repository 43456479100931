import { Button, Col, Typography } from "antd";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";

import {
  useDiscountCode,
  WrapperModalDiscountCode,
} from "../../../../modules/discountCode";
import { DataDiscountCode } from "../../../../redux/@types";

import {
  selectDiscountCodeForUpdate,
  selectIsUpdateDiscountCode,
  selectModalDiscountCode,
} from "../../../../redux/selectors/discountCode.selectors";
import { InputData, InputNumber, InputSelect, InputText } from "../inputs";

const { Title } = Typography;

export const ModalDiscountCode = () => {
  const modalDiscountCode = useSelector(selectModalDiscountCode);
  const isUpdateDiscountCode = useSelector(selectIsUpdateDiscountCode);
  const updateDiscountCode = useSelector(selectDiscountCodeForUpdate);

  const { isLoading, changeModalDiscountCodeState, onSubmitCreateOrUpdate } =
    useDiscountCode();

  const { control, handleSubmit: onSubmit } =
    useFormContext<DataDiscountCode>();
  return (
    <WrapperModalDiscountCode
      title={
        isUpdateDiscountCode
          ? `Actualizando código de descuento: '${updateDiscountCode?.id!}'`
          : `Creando código de descuento`
      }
      open={modalDiscountCode}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalDiscountCodeState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Estado:
        </Title>
        <InputSelect
          name="status"
          control={control}
          dataOptions={[
            { title: "Activo", value: "active" },
            { title: "Inactivo", value: "inactive" },
          ]}
          label="Estado"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre del descuento:
        </Title>
        <InputText
          placeHolder="CÓDIGO DE BIENVENIDA"
          name="name"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Código a utilizar:
        </Title>
        <InputText placeHolder="ABC123" name="code" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Cantidad:
        </Title>
        <InputNumber
          //   stringMode={true}
          placeHolder="8"
          name="quantity"
          control={control}
          min
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Porcentaje de descuento:
        </Title>
        <InputNumber
          //   stringMode={true}
          placeHolder="30"
          name="value_percentage"
          control={control}
          min
        />
        <Title style={{ marginBottom: "1.5px", fontSize: 13 }} level={5}>
          Llene este campo si desea que el descuento sea en base a un
          porcentaje. Ej: 20
        </Title>
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Descuento fijo:
        </Title>
        <InputNumber
          //   stringMode={true}
          placeHolder="1500"
          name="value_amount"
          control={control}
          min
        />
        <Title style={{ marginBottom: "1.5px", fontSize: 13 }} level={5}>
          Llene este campo si desea que el descuento sea fijo. Ej: 50000
        </Title>
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={4}>
          Condiciones:
        </Title>
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Usos por usuario:
        </Title>
        <InputNumber
          //   stringMode={true}
          placeHolder="6"
          name="uses_by_user"
          control={control}
          min
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Fecha de inicio:
        </Title>
        <InputData
          placeHolder="23/10/2022"
          name="date_start"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Fecha de finalización:
        </Title>
        <InputData placeHolder="29/10/2022" name="date_end" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Valor de compra minima:
        </Title>
        <InputNumber
          //   stringMode={true}
          placeHolder="1"
          name="order_min_amount"
          control={control}
          min
        />
        <Title style={{ marginBottom: "1.5px", fontSize: 13 }} level={5}>
          Ingrese 0 si no requiere compra mínima
        </Title>
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateDiscountCode
          ? "Actualizar información"
          : "Crear código de descuento"}
      </Button>
    </WrapperModalDiscountCode>
  );
};
