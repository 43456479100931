// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
import { Controller } from "react-hook-form";
import { InputError } from "./styled";

interface Props {
  name: string;
  control: any;
}

export const InputPassword = ({ name, control }: Props) => {
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name}
      render={({ field: { onChange, value = "" }, formState: { errors } }) => {
        return (
          <>
            <Input.Password
              placeholder="Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              status={errors[name] ? "error" : ""}
              value={value}
              onChange={onChange}
            />
            {!!errors[name] && (
              <InputError>{errors?.[name]?.message}</InputError>
            )}
          </>
        );
      }}
    />
  );
};
