import * as yup from 'yup'

export const validateCreateProductCharacteristics2 = yup.object().shape({
    characteristic_id:yup
    .string()
    .required('Campo requerido.')
    .min(1, 'LA CARACTERÍSTICA debe tener más de 1 caracteres'),
    characteristic_value:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El VALOR DE LA CARACTERÍSTICA debe tener más de 2 caracteres'),
})


