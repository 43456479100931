import { message, Modal } from "antd";
import {
  deleteProductCharacteristic,
  productCharacteristicsChangeModalState,
  refreshTableProductCharacteristics,
  setIsProductCharacteristicForUpdate,
  setProductCharacteristicsForUpdate,
} from "../../../redux";
import { dataProducsCharac } from "../../../redux/@types";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useProductCharacteristics } from "./useProductCharacteristics";

const { confirm } = Modal;

export const useTableProductCharacteristics = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useProductCharacteristics();

  const changeProductCharacteristicsForUpdate = (user: dataProducsCharac) => {
    dispatch(setIsProductCharacteristicForUpdate(true));
    dispatch(setProductCharacteristicsForUpdate(user));
    dispatch(productCharacteristicsChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la Característica de producto con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar característica de producto" en caso tal solo oprime en "
          Cancelar".`,
      okText: "Borrar característica de producto",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteProductCharacteristic(valId)
          .then(() => {
            dispatch(refreshTableProductCharacteristics(true));
            message.success(
              `Se ha borrado con éxito la característica de producto con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeProductCharacteristicsForUpdate,
    showDeleteConfirm,
  };
};
