import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Typography } from "antd";

import { PATH_S3_BUCKET } from "../../../shared/utils/constants";
import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { useContactsDirectory, useTableContactsDirectory } from "../hook";
import { DataContactsDirectory } from "../../../redux/@types";
import { contactsDirectoryService } from "../../../shared/services";

const { Title } = Typography;

export const TableContactsDirectory = () => {
  const { changeContactsDirectoryForUpdate, showDeleteConfirm } =
    useTableContactsDirectory();
  const { setTableProps, updateStatus, isLoading } = useContactsDirectory();

  const columns: TableColumns<DataContactsDirectory> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Nombre",
      width: 9,
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ciudad",
      width: 8,
      dataIndex: ["city", "name"],
      key: "city_id",
      // filters: [
      //   {
      //     text: "Activo",
      //     value: "active",
      //   },
      //   {
      //     text: "Inactivo",
      //     value: "inactive",
      //   },
      // ],
    },
    {
      title: "Dirección",
      width: 9,
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Calificación",
      width: 6,
      dataIndex: "score",
      key: "score",
    },
    // {
    //   title: "Descripción corta",
    //   width: 6,
    //   dataIndex: "short_description",
    //   key: "short_description",
    // },
    // {
    //   title: "Descripción",
    //   width: 8,
    //   dataIndex: "description",
    //   key: "description",
    // },
    {
      title: "Número de whatsApp",
      width: 7,
      dataIndex: "whatsapp_phone",
      key: "whatsapp_phone",
    },
    {
      title: "teléfono",
      width: 9,
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Imagen",
      width: 7,
      dataIndex: "path_cover",
      key: "path_cover",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.path_cover}`} />
          </Col>
        );
      },
    },
    {
      title: "Estado",
      width: 9,
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <button
              disabled={isLoading}
              onClick={() => {
                if (item.status === "active") {
                  updateStatus("inactive", item.id);
                } else if (item.status === "inactive") {
                  updateStatus("active", item.id);
                } else if (item.status === "pending_validation") {
                  updateStatus("active", item.id);
                }
              }}
              style={{
                display: "flex",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active"
                    ? "#4bd542"
                    : item.status === "pending_validation"
                    ? "#cccccc"
                    : "#ff4d4f",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "white",
                }}
                level={5}
              >
                {item.status === "active"
                  ? "Activo"
                  : item.status === "pending_validation"
                  ? "Pendiente"
                  : "Inactivo"}
              </Title>
            </button>
          </Col>
        );
      },
      filters: [
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Inactivo",
          value: "inactive",
        },
        {
          text: "Pendiente",
          value: "pending_validation",
        },
      ],
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 7,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeContactsDirectoryForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<DataContactsDirectory>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={contactsDirectoryService}
      onLoad={setTableProps}
    />
  );
};
