import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Typography } from "antd";
import { DataWorkOffers } from "../../../redux/@types";

import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { workOffersService } from "../../../shared/services";
import { useWorkOffers, useTableWorkOffers } from "../hook";
import { decimal } from "../../../shared/utils/Helper";

const { Title } = Typography;

export const TableWorkOffers = () => {
  const { changeWorkOffersForUpdate, showDeleteConfirm } = useTableWorkOffers();
  const { setTableProps, updateStatus, isLoading } = useWorkOffers();

  const columns: TableColumns<DataWorkOffers> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Trabajo",
      width: 6,
      dataIndex: "job",
      key: "job",
      filterType: "text",
    },
    {
      title: "Categoría",
      width: 4,
      dataIndex: "category_id",
      key: "category_id",
      filterType: "text",
    },
    {
      title: "Ciudad",
      width: 4,
      dataIndex: "city_id",
      key: "city_id",
      filterType: "text",
    },
    {
      title: "Dirección",
      width: 4,
      dataIndex: "address",
      key: "address",
      filterType: "text",
    },
    {
      title: "Nombre de compañía",
      width: 4,
      dataIndex: "company_name",
      key: "company_name",
      filterType: "text",
    },
    {
      title: "Salario",
      width: 3,
      dataIndex: "total_price",
      key: "total_price",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              $ {decimal(item.salary)}
            </Title>
          </Col>
        );
      },
    },
    // {
    //   title: "Descripción",
    //   width: 4,
    //   dataIndex: "description",
    //   key: "description",
    //   filterType: "text",
    // },
    // {
    //   title: "Requerimientos",
    //   width: 4,
    //   dataIndex: "requirements",
    //   key: "requirements",
    //   filterType: "text",
    // },
    // {
    //   title: "Responsabilidades",
    //   width: 4,
    //   dataIndex: "responsibilities",
    //   key: "responsibilities",
    //   filterType: "text",
    // },
    {
      title: "Estado",
      width: 4,
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <button
            disabled={isLoading}
              onClick={()=>{
                if(item.status === "active"){
                  updateStatus("inactive", item.id)
                }else if( item.status === "inactive"){
                  updateStatus("active", item.id)
                }
              }}
              style={{
                display: "flex",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "white",
                }}
                level={5}
              >
                {item.status}
              </Title>
            </button>
          </Col>
        );
      },
      filters: [
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Inactivo",
          value: "inactive",
        },
      ],
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 4,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeWorkOffersForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.job}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<DataWorkOffers>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={workOffersService}
      onLoad={setTableProps}
    />
  );
};
