import { TRootState } from '../@types/redux';

export const selectIsUpdateProduct= ( state: TRootState) =>state.products.isUpdateProducts

export const selectProductForUpdate= ( state: TRootState) =>state.products.productsForUpdate

export const selectProductRefreshTable= ( state: TRootState) =>state.products.refreshTableProducts

export const selectProductCategoriesArr= ( state: TRootState) =>state.products.arrCategories

export const selectProductBrandsArr= ( state: TRootState) =>state.products.arrBrands

export const selectProductTaxArr= ( state: TRootState) =>state.products.arrTax
//productCharacteristic
export const selectModalProductCharacteristic2= ( state: TRootState) =>state.products.productsCharacteristicModalIsOpen

export const selectIsUpdateProductCharacteristic2= ( state: TRootState) =>state.products.isUpdateProductCharacteristics

export const selectProductCharacteristicForUpdate2= ( state: TRootState) =>state.products.productCharacteristicsForUpdate

export const selectProductCharacteristicRefreshTable2= ( state: TRootState) =>state.products.refreshTableProductCharacteristics

export const selectProductCharacteristicsArr= ( state: TRootState) =>state.products.arrCharacteristic