import { ChangeEvent } from "react";
import TextArea from "antd/lib/input/TextArea";

interface Props {
  placeHolder: string;
  rows?: boolean;
  value: string | undefined;
  onChange: (val: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const SimpleInputTextArea = ({
  placeHolder,
  value,
  onChange,
}: Props) => {
  return (
    <TextArea
      value={value}
      placeholder={placeHolder}
      onChange={onChange}
      autoSize={{ minRows: 2, maxRows: 6 }}
    />
  );
};
