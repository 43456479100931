import type {Resolver} from "react-hook-form";

import {yupResolver} from "@hookform/resolvers/yup";
import React from "react";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import { programsModuleTechniquesAcademy } from "../../../../shared/services";
import { ProgramTechniquesType } from "../../../../types/dto/program";


export interface UseUpdateProgramModuleTechniquesProps {
  programTechniques: Pick<ProgramTechniquesType, "name" | "description" | "status" | 'id' | "program_module_topic_id" | "position">
  onSuccess?: (programTechniques: Pick<ProgramTechniquesType, "name" | "description" | "status" | 'id' | "program_module_topic_id" | "position">) => void;
}

export default function useUpdateProgramModuleTechniques(props?: UseUpdateProgramModuleTechniquesProps) {
  const [loading, setLoading] = React.useState(false);
  const {
    control,
    handleSubmit: onSubmit,
    reset,
  } = useForm<any>({
    resolver: yupResolver(
      yup.object({
      }),
    ) as unknown as Resolver<any>,
  });


  const handleSubmit = onSubmit(async (data) => {
    if (loading || !props?.programTechniques) return;
    setLoading(true);

    await programsModuleTechniquesAcademy
      .patch(props?.programTechniques.id, {
        ...data,
        position: +data.position
      })
      .then((res) => {
        reset({});
        props?.onSuccess?.(res);
      })
      .catch((err: Error) => {
      })
      .finally(() => {
        setLoading(false);
      });
  });

  React.useEffect(() => {
    if (props?.programTechniques) {
      reset({
        name: props?.programTechniques.name,
        description: props?.programTechniques.description,
        position: props?.programTechniques.position,
        status: props?.programTechniques.status,
      });
    }
  }, [props?.programTechniques]);

  return {
    control,
    loading,
    handleSubmit,
  };
}
