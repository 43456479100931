import { IGenericArrProduct, IProductCharacteristics2, IProducts } from "../@types"

export const GET_PRODUCT_FOR_UPDATE = '[ PRODUCTS ] Get Product For Update'

export interface IGetProductsForUpdate {
    type: typeof GET_PRODUCT_FOR_UPDATE
    payload: IProducts
    // DataIProducts
}

export const IS_PRODUCT_FOR_UPDATE = '[ PRODUCTS ] Is Product For Update'

export interface IIsProductsForUpdate {
    type: typeof IS_PRODUCT_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PRODUCTS_REFRESH_TABLE = '[ PRODUCTS ] Switch Products Refresh Table'

export interface ISwitchProductsRefreshTable {
    type: typeof SWITCH_PRODUCTS_REFRESH_TABLE
    payload: boolean
}

export const GET_CATEGORIES_PRODUCTS = '[ PRODUCTS ] Get Categories Products'

export interface IGetCategoriesProducts {
    type: typeof GET_CATEGORIES_PRODUCTS
    payload: IGenericArrProduct[]
}

export const GET_BRANDS_PRODUCTS = '[ PRODUCTS ] Get Brands Products'

export interface IGetBrandsProducts {
    type: typeof GET_BRANDS_PRODUCTS
    payload: IGenericArrProduct[]
}

export const GET_TAX_PRODUCTS = '[ PRODUCTS ] Get Tax Products'

export interface IGetTaxProducts {
    type: typeof GET_TAX_PRODUCTS
    payload: IGenericArrProduct[]
}

//productCharacteristic

export const PRODUCTS_CHARACTERISTICS_CHANGE_MODAL_STATE = '[ PRODUCTS ] Products Characteristics Change Modal State'

export interface IProductsCharacteristicsChangeModalState {
    type: typeof PRODUCTS_CHARACTERISTICS_CHANGE_MODAL_STATE
}

export const GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE = '[ PRODUCTS ] Get Product Characteristics For Update'

export interface IGetProductsCharacteristicsForUpdate {
    type: typeof GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE
    payload: IProductCharacteristics2
    // DataIProducts
}

export const IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE = '[ PRODUCTS ] Is Product Characteristics For Update'

export interface IIsProductsCharacteristicsForUpdate {
    type: typeof IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PRODUCTS_CHARACTERISTICS_REFRESH_TABLE = '[ PRODUCTS ] Switch Products Characteristics Refresh Table'

export interface ISwitchProductsCharacteristicsRefreshTable {
    type: typeof SWITCH_PRODUCTS_CHARACTERISTICS_REFRESH_TABLE
    payload: boolean
}

export const GET_CHARACTERISTIC_PRODUCTS = '[ PRODUCTS ] Get Characteristic Products'

export interface IGetCharacteristicProducts {
    type: typeof GET_CHARACTERISTIC_PRODUCTS
    payload: IGenericArrProduct[]
}