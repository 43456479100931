"use client";
import type { Resolver } from "react-hook-form";

import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { programsAcademy } from "../../../../shared/services";
import { ProgramType } from "../../../../types/dto/program";
import { message } from "antd";

export interface UseCreateLevelProps {
  setOpenModal: (val: boolean) => void;
}

export default function useCreateDuplicatePrograms({ setOpenModal }: UseCreateLevelProps) {
  const [loading, setLoading] = React.useState(false);
  
  const { control, handleSubmit: onSubmit, watch, setValue } = useForm<ProgramType>({
    resolver: yupResolver(
      yup.object({
       
      })
    ) as unknown as Resolver<ProgramType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);

    await programsAcademy
      .create({
        id: data.program_id
      },
    {
      query: {
        $client: { duplicateProgram: true },
      }
    })
      .then((response) => {
        setOpenModal(false)
        message.success("Programa duplicado correctamente")
      })
      .catch((err: Error) => {
        // Manejo de errores
        message.error(err.message)
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    setValue,
    watch,
    control,
    loading,
    handleSubmit,
  };
}
