import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useWalletBonus } from "./useWalletBonus";
import { DataWalletBonus } from "../../../redux/@types";
import {
  deleteWalletBonus,
  refreshTableWalletBonus,
  setIsWalletBonusForUpdate,
  setWalletBonusForUpdate,
  walletBonusChangeModalState,
} from "../../../redux";
const { confirm } = Modal;

export const useTableWalletBonus = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useWalletBonus();

  const changeWalletBonusForUpdate = (val: DataWalletBonus) => {
    dispatch(setIsWalletBonusForUpdate(true));
    dispatch(setWalletBonusForUpdate(val));
    dispatch(walletBonusChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el bonus con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar bonus" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar bonus",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteWalletBonus(valId)
          .then(() => {
            dispatch(refreshTableWalletBonus(true));
            message.success(`Se ha borrado con éxito el bonus con id:${valId}`);
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeWalletBonusForUpdate,
    showDeleteConfirm,
  };
};
