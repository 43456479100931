"use client";

import React from "react";
import { UseFormHandleSubmit, UseFormReset } from "react-hook-form";
import { message } from "antd";
import { ProgramStudentModuleTechniqueType } from "../../../../types/dto/program";
import { programsStudentModuleTechniqueAcademy } from "../../../../shared/services";

export interface UseEditQualificationStudentProps {
  getStudentModuleTechniqueDetails: StudentModuleTechniqueDetails[];
  onSubmit: UseFormHandleSubmit<ProgramStudentModuleTechniqueType>;
  setOpenModal: (val: boolean) => void
  reset: UseFormReset<ProgramStudentModuleTechniqueType>
}
export interface StudentModuleTechniqueDetails {
  module_id: number;
  module_name: string;
  program_id: number;
  program_name: string;
  qualification: 'approved' | 'failed';
  qualification_check: 'approved' | 'disapproved';
  student_module_technique_id: number;
  technique_id: number;
  technique_name: string;
  topic_id: number;
  topic_name: string;
}


export default function useEditQualificationStudent({
  getStudentModuleTechniqueDetails,
  onSubmit,
  setOpenModal,
  reset
}: UseEditQualificationStudentProps) {
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    // await getStudentsByUserIdAndProgramId(userId, data.program_id);
    await programsStudentModuleTechniqueAcademy
      .patch(getStudentModuleTechniqueDetails[0].student_module_technique_id, {
        ...(data.qualification && {qualification: data.qualification}),
        ...(data.qualification_check && {qualification_check: data.qualification_check})
      })
      .then((response) => {
        message.success("Se ha actualizado exitosamente la calificion");
        setOpenModal(false)
      })
      .catch((err: any) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  React.useEffect(() => {

    if (getStudentModuleTechniqueDetails[0] && getStudentModuleTechniqueDetails.length > 0) {
      console.log(getStudentModuleTechniqueDetails[0])
      reset({
        qualification: getStudentModuleTechniqueDetails[0].qualification,
        qualification_check: getStudentModuleTechniqueDetails[0].qualification_check,
      });
    }
  }, [getStudentModuleTechniqueDetails, reset]);

  return {
    loading,
    handleSubmit,
  };
}
