import { Button, Card, Modal, Row } from "antd";
import styled from "styled-components";
export const WrapperOrders = styled(Card)`
  display: flex;
  width: 100%;
  height: auto;
  text-align: left;
  font-weight: 400;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  border: 1px solid #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: #fff;
  border-radius: 1rem !important;
  min-height: 100%;

  .ant-card-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const WrapperOrderById = styled(Card)`
  display: flex;
  width: 100%;
  height: auto;
  text-align: left;
  font-weight: 400;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  border: 1px solid #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: #fff;
  border-radius: 1rem !important;
  min-height: 100%;

  .ant-card-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;
export const WrapperOrderByIdSpin = styled(Card)`
  display: flex;
  width: 100%;
  height: auto;
  text-align: left;
  font-weight: 400;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  border: 1px solid #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: #fff;
  border-radius: 1rem !important;
  min-height: 100%;

  .ant-card-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
`;

export const WrapperCardUserOrder = styled(Card)`
  display: flex;
  width: 100%;
  height: auto;
  text-align: left;
  font-weight: 400;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  border: 1px solid #f0f0f0;
  box-shadow: 3px 3px 3px #ccc;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: #f0f2f5;
  border-radius: 1rem !important;
  min-height: 100%;

  .ant-card-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const WrapperModalGenerate = styled(Modal)`
  & .ant-modal-content {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 25px !important;
    border-radius: 10px 10px 8px 8px !important;
  }

  & .ant-modal-header {
    background: var(--color-primary) !important;
    border-radius: 8px 8px 0 0 !important;
    height: 100px !important;
  }
  & .ant-modal-body {
    box-sizing: border-box !important;
    padding-bottom: 0px !important;
  }

  & .ant-modal-title {
    color: var(--color-white) !important;
    font-style: italic !important;
    font-size: 1.125rem !important;
    font-weight: 600 !important;
  }
  & .modal-content.headline {
    transform: translate(0px, -60px);
    margin: 0px auto;
    text-align: center;
    overflow: hidden;
  }

  & .modal-content {
    /* width: calc(100% - 50px); */

    border-radius: 3px !important;
    margin-bottom: 0px !important;

    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
    min-height: 80px;
    overflow: hidden;

    /*  position: absolute; */
    display: block;

    background: var(--color-white) !important;
    padding: 20px 5% 0px 5%;
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
    box-sizing: border-box !important;
  }

  & .modal-content .ant-form {
    padding: 0px !important;
    margin: 0px !important;
    border: 0px !important;
    box-shadow: none !important;
    width: 100% !important;
  }
  & .ant-modal-close-x {
    color: #fff !important;
  }
`;

export const Wrapper = styled.div`
  margin: 10px;
  & .ant-table {
    /*  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.03) !important; */
    box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
    border: 1px solid #e8e8e85e !important;
    border-radius: 8px !important;
  }
  & .box-head {
    background: var(--color-gray-ligth);
    /* box-shadow: 0px 2px 0px #0000002e; */
    border-radius: 0px 0px 4px 4px;
  }
  & .box-head h2,
  .box-head i {
    color: #fff !important;
  }
  ${(box) => {
    if (box)
      return `
            & .box-head {
              margin-bottom:0px!important;
              border-radius: 0px!important;
            }
            & .ant-table {
              border-radius: 0px 0px 8px 8px !important;
            }
        `;
  }}
`;

export const RoundedButton = styled(Button)`
  border-radius: 20px !important;
`;

export const WrapperFilters = styled(Row)`
  margin: 0px 4px;
`;

export const WrapperCost = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 2px 2px 2px #ccc;
  margin: 4px 9px;
  max-width: 20%;

  cursor: pointer;

  background: "#e6f7ff" !important;
  & h2 {
    margin-bottom: 0px;
    color: "rgba(0, 0, 0, 0.7)" !important;
  }
  & h3 {
    font-size: 24px;
    font-weight: bold;
    color: "rgba(0, 0, 0, 0.7)" !important;
  }
`;
