"use client";
import Button from "../../../../shared/components/ui/Button";
import useUpdateStudentProgram from "../../hooks/students/useUpdateStudentProgram";
import useProgramStudent from "../../hooks/students/useProgramStudent";
import React from "react";
import AssignedStudentProgramForm from "./AssignedStudentProgramForm";

export default function EditAssignedStudentPrograms({
  programStudentId,
  setOpenModal,
}: {
  programStudentId: number;
  setOpenModal: (val: boolean)=> void
}) {
const {programStudentData, getProgramStudentById} = useProgramStudent({})

React.useEffect(() => {
  getProgramStudentById(programStudentId!);
}, []);

const { control, loading, handleSubmit } = useUpdateStudentProgram({
  programStudent: programStudentData!,
  setOpenModal
});

  return (
    <form onSubmit={handleSubmit}>
      <AssignedStudentProgramForm control={control} programId={programStudentData?.program_id}/>
      <div className="mt-4 flex items-center justify-end">
        <Button primary className="bg-[#072082]" loading={loading} type="submit" variant="filled">
          <i className="fa-regular fa-floppy-disk" /> Actualizar
        </Button>
      </div>
    </form>
  );
}
