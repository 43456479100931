import { TRootState } from '../@types/redux'

export const selectModalAddressesUsers = (state: TRootState) => state.addressesUsers.addressesUsersModalIsOpen

export const selectIsUpdateAddressesUsers = (state: TRootState) => state.addressesUsers.isUpdateAddressesUsers

export const selectAddressesUsersForUpdate = (state: TRootState) => state.addressesUsers.addressesUsersForUpdate

export const selectAddressesUsersRefreshTable = (state: TRootState) => state.addressesUsers.refreshTableAddressesUsers

export const selectStateAddressesUsers = (state: TRootState) => state.addressesUsers.stateAddressesUsers

export const selectCitiesAddressesUsers = (state: TRootState) => state.addressesUsers.citiesAddressesUsers