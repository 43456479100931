import React from "react";
import { LoadScript, GoogleMap, Polygon } from "@react-google-maps/api";
import { Wrapper } from "./Styles";
import { getService } from "../../../../../clients/feathers.clients";
import { message } from "antd";

function Map(props) {
  const [shippingCosts, setShippingCosts] = React.useState([]);
  const [center, setCenter] = React.useState();

  const polygonRef = React.useRef({});
  const listenersRef = React.useRef([]);
  const loadingRef = React.useRef(false);

  const getData = async () => {
    if (loadingRef.current) return;
    loadingRef.current = true;

    await getService("shipping-costs")
      .find({
        query: {
          fulfillment_company_id: props.fulfillmentCompany?.id,
        },
      })
      .then((res) => {
        setShippingCosts(res.data);
      })
      .finally(() => {
        loadingRef.current = false;
      });
  };

  const onEdit = React.useCallback((shippingCost) => {
    const polygon = polygonRef.current[shippingCost.id];
    if (polygon) {
      const nextPath = polygon
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });

      setShippingCosts((prev) => {
        const index = prev.findIndex((item) => item.id === shippingCost.id);
        prev[index].polygon = JSON.stringify(nextPath);
        return [...prev];
      });

      getService("shipping-costs")
        .patch(shippingCost.id, {
          polygon: JSON.stringify(nextPath),
        })
        .then(() => {
          message.success("Se ha actualizado el polígono correctamente");
        });
    }
  }, []);

  const onLoad = React.useCallback(
    (polygon, shippingCost) => {
      const path = polygon.getPath();

      polygonRef.current[shippingCost.id] = polygon;
      listenersRef.current[shippingCost.id] = [
        path.addListener("set_at", () => onEdit(shippingCost)),
        path.addListener("insert_at", () => onEdit(shippingCost)),
        path.addListener("remove_at", () => onEdit(shippingCost)),
      ];
    },
    [onEdit]
  );

  const onUnmount = React.useCallback((shippingCost) => {
    listenersRef.current = Object.fromEntries(
      Object.entries(listenersRef.current).filter(
        ([key]) => key !== shippingCost.id
      )
    );
    polygonRef.current = Object.fromEntries(
      Object.entries(polygonRef.current).filter(
        ([key]) => key !== shippingCost.id
      )
    );
  }, []);

  React.useEffect(() => {
    if (props.fulfillmentCompany) {
      setCenter({
        lat: props.fulfillmentCompany?.default_lat || 11.0079481,
        lng: props.fulfillmentCompany?.default_lng || -74.813056,
      });
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fulfillmentCompany]);

  React.useEffect(() => {
    if (props.refresh) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  return (
    <Wrapper className="App" height={props.height}>
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyApL_WYvJC4BmsYL84bd3XNP65aFJ5scJo"
        language="en"
        region="us"
      >
        <GoogleMap
          mapContainerClassName="app-map"
          center={center}
          zoom={12}
          version="weekly"
        >
          {!!shippingCosts.length &&
            shippingCosts.map((shippingCost) => (
              <Polygon
                key={shippingCost.id}
                editable={props?.selectedItem?.id === shippingCost.id}
                draggable={props?.selectedItem?.id === shippingCost.id}
                path={JSON.parse(shippingCost.polygon)}
                onDragEnd={() => onEdit(shippingCost)}
                onMouseUp={() => {
                  if (props?.selectedItem?.id === shippingCost.id)
                    onEdit(shippingCost);
                }}
                onLoad={(polygon) => onLoad(polygon, shippingCost)}
                onUnmount={onUnmount}
                onClick={() => {
                  props?.onSelectItem?.(shippingCost);
                }}
                options={{
                  strokeColor: shippingCost.color || "#FF0000",
                  fillColor: shippingCost.color || "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillOpacity: 0.3,
                }}
              />
            ))}
        </GoogleMap>
      </LoadScript>
    </Wrapper>
  );
}

export default React.memo(Map);
