// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { cMSService } from "../../../../shared/services";
import { setCMSForUpdate, setIsCMSForUpdate } from "../../../../redux";

export const useCMSById = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const [isValidate, setIsValidate] = useState(false);

  const getSingleCourse = async (id: number) => {
    return await cMSService
      .get(id)
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        navigate(`/cms`);
        message.error(err.message);
      });
  };
  useEffect(() => {
    if (pathname !== "/cms/new") {
      if (isNaN(pathname.split("/")[2])) return navigate(`/cms`);
      getSingleCourse(Number(pathname.split("/")[2])).then((res) => {
        dispatch(setCMSForUpdate(res));
        dispatch(setIsCMSForUpdate(true));
      });
      setIsValidate(true);
    } else if (pathname === "/cms/new") {
      dispatch(setIsCMSForUpdate(false));
      setIsValidate(true);
    } else {
      navigate(`/cms`);
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    //states
    isValidate,
    //methods
    //functions
  };
};
