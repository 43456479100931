import * as yup from 'yup'

export const validateCreateAddressesUsers = yup.object().shape({
    name: yup
    .string()
    .required('Campo requerido.')
    .min(4, 'El NOMBRE debe tener más de 3 caracteres'),
    address: yup
    .string()
    .required('Campo requerido.')
    .min(7, 'El DIRECCIÓN debe tener más de 6 caracteres'),
    state_id: yup
    .number().typeError('Campo requerido.')
    .required('Campo requerido.')
    .positive(),
    city_id: yup
    .number().typeError('Campo requerido.')
    .required('Campo requerido.')
    .positive(),
    lat: yup
    .number().typeError('Campo requerido.')
    .required('Campo requerido.')
    .min(-999, 'LA LATITUD debe tener mínimo -999'),
    lng: yup
    .number().typeError('Campo requerido.')
    .required('Campo requerido.')
    .min(-999, 'LA LONGITUD debe tener mínimo -999'),
    details: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'LOS DETALLES debe tener más de 1 caracteres'),
})