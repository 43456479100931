import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";

import { AdminMenuLayout, ModalStates } from "../../../shared/components";
import { WrapperCardStates } from "./styled";
import { statesService } from "../../../shared/services";
import { selectModalState } from "../../../redux";
import { useStates } from "../hook";
import { TableStates } from "./TableStates";

export const States = () => {
  const modalStates = useSelector(selectModalState);
  const {
    isUpdateState,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalStates,
  } = useStates();

  return (
    <AdminMenuLayout pageDescription="States" title="States">
      <WrapperCardStates>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalStates}
            >
              Agregar nuevo estado
            </Button>
          </Col>
        </Row>
        <TableStates />
        {modalStates && isUpdateState && (
          <FormProvider {...formMethodsUpdate}>
            <ModalStates />
          </FormProvider>
        )}
        {modalStates && !isUpdateState && (
          <FormProvider {...formMethodsCreate}>
            <ModalStates />
          </FormProvider>
        )}
      </WrapperCardStates>
    </AdminMenuLayout>
  );
};
