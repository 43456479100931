import { DataWalletBonus } from "../@types"

export const WALLET_BONUS_MODAL_STATE = '[ WALLET BONUS ] Wallet Bonus Modal State'

export interface IWalletBonusModalState {
    type: typeof WALLET_BONUS_MODAL_STATE
}

export const GET_WALLET_BONUS_FOR_UPDATE = '[ WALLET BONUS ] Get Wallet Bonus For Update'

export interface IGetWalletBonusForUpdate {
    type: typeof GET_WALLET_BONUS_FOR_UPDATE
    payload: DataWalletBonus
}

export const IS_WALLET_BONUS_FOR_UPDATE ='[ WALLET BONUS ] Is Wallet Bonus For Update'

export interface IIsWalletBonusForUpdate {
    type: typeof IS_WALLET_BONUS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_WALLET_BONUS_REFRESH_TABLE = '[ WALLET BONUS ] Switch Wallet Bonus Refresh Table'

export interface ISwitchWalletBonusRefreshTable{
    type: typeof SWITCH_WALLET_BONUS_REFRESH_TABLE
    payload: boolean
}
