import { configurationCashbackService } from "../../shared/services"
import { DataConfigurationCashback } from "../@types"
import { configurationCashbackActions } from "../action-types"


export const configurationCashbackChangeModalState = (): configurationCashbackActions.IConfigurationCashbackModalState =>({
    type: configurationCashbackActions.CONFIGURATION_CASHBACK_MODAL_STATE
})

export const setConfigurationCashbackForUpdate = (val: DataConfigurationCashback): configurationCashbackActions.IGetConfigurationCashbackForUpdate =>({
    type: configurationCashbackActions.GET_CONFIGURATION_CASHBACK_FOR_UPDATE,
    payload: val
})

export const setIsConfigurationCashbackForUpdate = (val: boolean): configurationCashbackActions.IIsConfigurationCashbackForUpdate =>({
    type: configurationCashbackActions.IS_CONFIGURATION_CASHBACK_FOR_UPDATE,
    payload: val
})

export const createConfigurationCashback = async(data: Partial<DataConfigurationCashback>) =>{
    return await configurationCashbackService.create(data)
    .then((res: DataConfigurationCashback) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateConfigurationCashback =async(data: Partial<DataConfigurationCashback>) =>{
    return await configurationCashbackService.patch(data?.id!, data)
    .then((res: DataConfigurationCashback) => {return res})
    .catch((err: any) => { return err.message })
}


export const refreshTableConfigurationCashback = (val: boolean): configurationCashbackActions.ISwitchConfigurationCashbackRefreshTable =>({
    type: configurationCashbackActions.SWITCH_CONFIGURATION_CASHBACK_REFRESH_TABLE,
    payload: val
})