// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button, Col, Row, Spin, Tooltip } from "antd";
import {
  CheckOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { citiesService } from "../../../../shared/services";
import { DataCities } from "../../../../redux/@types/cities";
import Table from "../../../../shared/components/ui/Table";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import { useTableFulfillmentCompanyById } from "../../hooks";
import { InputTextSimple, SimpleInputNumber } from "../../../../shared/components";
import { useParams } from "react-router-dom";

export const TableFulfillmentCompanyById = () => {
  const { id } = useParams();
  const {
    minDeliveryDaysState,
    minDeliveryDaysId,
    isEditMinDeliveryDays,
    textValueMinDeliveryDays,
    fulfillmentCities,
    isLoading,
    textIsLoading,
    setMinDeliveryDaysState,
    changeStateEditIntegrationState,
    exitIntegrationState,
    addFulfillmentCity,
    removeFulfillmentCity,
    updateIntegrationId,
  } = useTableFulfillmentCompanyById();
  const columns: TableColumns<DataCities> = [
    {
      title: "ID",
      width: 10,
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Nombre",
      width: 17,
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "Estado",
      width: 14,
      dataIndex: ["state", "name"],
      key: "stateName",
      filterType: "text",
    },
    {
      title: "Días mínimos de entrega",
      width: 10,
      dataIndex: "min_delivery_days",
      key: "min_delivery_days",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {(!isEditMinDeliveryDays || minDeliveryDaysId !== item.id) && (
              <>
                {!textIsLoading ? (
                  <div>
                    {
                      fulfillmentCities.find(
                        (element) => element.location_city_id === item.id
                      )?.min_delivery_days
                    }
                  </div>
                ) : (
                  <Spin size="small" />
                )}
                {
                  fulfillmentCities.find(
                    (element) => element.location_city_id === item.id
                  ) &&(
                    <Button
                      type="dashed"
                      shape="circle"
                      onClick={() =>
                        changeStateEditIntegrationState(
                          item.id,
                          fulfillmentCities.find(
                            (element) => element.location_city_id === item.id
                          )?.min_delivery_days
                        )
                      }
                      icon={<EditOutlined />}
                      size="large"
                    />
                  )
                }
              </>
            )}
            {isEditMinDeliveryDays && minDeliveryDaysId === item.id && (
              <>
                <SimpleInputNumber
                  placeHolder="3"
                  value={textValueMinDeliveryDays}
                  onChange={(e) => {
                    setMinDeliveryDaysState({
                      ...minDeliveryDaysState,
                      textValueMinDeliveryDays: e,
                    });
                  }}
                  max={999999999999}
                  isDisable={false}
                />
                <Button
                  type="dashed"
                  shape="circle"
                  disabled={isLoading}
                  onClick={() =>
                    updateIntegrationId(
                      fulfillmentCities.find(
                        (element) => element.location_city_id === item.id
                      )?.id,
                      item.id,
                      Number(id)
                    )
                  }
                  icon={<SaveOutlined />}
                  size="large"
                />
                <Button
                  type="dashed"
                  danger
                  shape="circle"
                  onClick={exitIntegrationState}
                  icon={<CloseOutlined />}
                  size="large"
                />
              </>
            )}
          </Col>
        );
      },
    },
    {
      title: "Agregar/Quitar",
      key: "operation",
      fixed: "right",
      width: 5,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {fulfillmentCities.find(
              (element) => element.location_city_id === item.id
            ) ? (
              <Tooltip placement="top" title="Quitar">
                <Button
                  disabled={isLoading}
                  type="primary"
                  danger
                  onClick={() =>
                    removeFulfillmentCity(
                      fulfillmentCities.find(
                        (element) => element.location_city_id === item.id
                      )?.id
                    )
                  }
                  shape="circle"
                  icon={<CloseOutlined />}
                  size="small"
                />
              </Tooltip>
            ) : (
              <Tooltip placement="top" title="Agregar">
                <Button
                  disabled={isLoading}
                  type="primary"
                  shape="circle"
                  style={{
                    background: "var(--color2)",
                    borderColor: "var(--color2)",
                  }}
                  onClick={() => addFulfillmentCity(item.id)}
                  icon={<CheckOutlined />}
                  size="small"
                />
              </Tooltip>
            )}
          </Col>
        );
      },
    },
  ];
  return (
    <Row
      gutter={[8, 8]}
      style={{
        padding: "10px",
        width: "100%",
      }}
    >
      <Table<DataCities>
        columns={columns}
        fetchQueryProps={{
          $sort: { id: -1 },
        }}
        service={citiesService}
        // onLoad={setTableProps}
      />
    </Row>
  );
};
