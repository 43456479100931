import { Button, Col, Typography } from "antd";
import { useSelector } from "react-redux";
import { useRankings, WrapperModalRankings } from "../../../../modules/ratings";
import {
  selectIsUpdateRankings,
  selectModalRankings,
  selectRankingsForUpdate,
} from "../../../../redux";
import { useFormContext } from "react-hook-form";
import { DataIRankings } from "../../../../redux/@types";
import { InputSelect, InputText } from "../inputs";
import { useRankingModal } from "../../../../modules/ratings/hooks/useRankingModal";

const { Title } = Typography;
export const ModalRankings = () => {
  const modalAddressesUser = useSelector(selectModalRankings);
  const isUpdateRankings = useSelector(selectIsUpdateRankings);
  const updateRankings = useSelector(selectRankingsForUpdate);

  const { isLoading, changeModalRankingsState, onSubmitCreateOrUpdate } =
    useRankings();
  const { usersRankings, ordersRankings, productsRankings } = useRankingModal();
  let dataUsers = usersRankings?.map((item) => ({
    value: item.id.toString(),
    title: `${item.first_name}${item.last_name}`,
  }));
  let dataOrders = ordersRankings?.map((item) => ({
    value: item.id.toString(),
    title: `${item.id}`,
  }));
  let dataProducts = productsRankings?.map((item) => ({
    value: item.id.toString(),
    title: item.name,
  }));

  const { control, handleSubmit: onSubmit } = useFormContext<DataIRankings>();

  return (
    <WrapperModalRankings
      title={
        isUpdateRankings
          ? `Actualizando calificación: '${updateRankings?.id!}'`
          : `Creando calificación`
      }
      open={modalAddressesUser}
      width={"320px"}
      destroyOnClose
      onClose={changeModalRankingsState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Usuario:
        </Title>
        <InputSelect
          name="user_id"
          control={control}
          dataOptions={dataUsers || []}
          label="Nombre de usuario"
          showSearch
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Orden:
        </Title>
        <InputSelect
          name="order_id"
          control={control}
          dataOptions={dataOrders || []}
          label="Estado"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Producto:
        </Title>
        <InputSelect
          name="product_id"
          control={control}
          dataOptions={dataProducts || []}
          label="Estado"
          showSearch
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Calificación:
        </Title>
        <InputSelect
          name="score"
          control={control}
          dataOptions={[
            { title: "1", value: 1 },
            { title: "2", value: 2 },
            { title: "3", value: 3 },
            { title: "4", value: 4 },
            { title: "5", value: 5 },
          ]}
          label="Calificación"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Mensaje:
        </Title>
        <InputText
          placeHolder="Es un producto que cumple con mis expectativas por su..."
          name="message"
          control={control}
          rows
        />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateRankings ? "Actualizar información" : "Crear calificación"}
      </Button>
    </WrapperModalRankings>
  );
};
