"use client";

import React from "react";
import { ProgramScheduleType } from "../../../../types/dto/program";
import { programPaymentScheduleAcademy } from "../../../../shared/services";
import moment from "moment";

export default function useProgramSchedule({
  programScheduleId,
  programId,
}: {
  programScheduleId?: number;
  programId?: number;
}) {
  const [programSchedule, setProgramSchedule] =
    React.useState<ProgramScheduleType>();
  const [programScheduleByProgram, setprogramScheduleByProgram] =
    React.useState<ProgramScheduleType[]>([]);
  const [
    programScheduleByProgramAndCurrentMonth,
    setprogramScheduleByProgramAndCurrentMonth,
  ] = React.useState<ProgramScheduleType[]>([]);
  const [loading, setLoading] = React.useState(false);

  const getProgramSchedule = async () => {
    if (loading) return;
    setLoading(true);
    if (programScheduleId) {
      await programPaymentScheduleAcademy
        .get(programScheduleId)
        .then((response) => {
          setProgramSchedule(response);
        })
        .catch((err: Error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getprogramScheduleByProgram = async () => {
    if (loading) return;
    setLoading(true);
    await programPaymentScheduleAcademy
      .find({ query: { program_id: programId } })
      .then((response) => {
        setprogramScheduleByProgram(response.data);
      })
      .catch((err: Error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getprogramScheduleByProgramAndCurrentMonth = async () => {
    if (loading) return;
    setLoading(true);
    const currentYear = moment().format("YYYY");

    await programPaymentScheduleAcademy
      .find({
        query: {
          program_id: programId,
          month: { $gte: `${currentYear}-01-01`, $lt: `${currentYear}-12-31` },
        },
      })
      .then((response) => {
        setprogramScheduleByProgramAndCurrentMonth(response.data);
      })
      .catch((err: Error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (programScheduleId) {
      getProgramSchedule();
    }
    if (programId) {
      getprogramScheduleByProgram();
      getprogramScheduleByProgramAndCurrentMonth();
    }
  }, [programScheduleId, programId]);

  return {
    programSchedule,
    loading,
    programScheduleByProgram,
    programScheduleByProgramAndCurrentMonth,
    setProgramSchedule,
    getProgramSchedule,
    getprogramScheduleByProgram,
  };
}
