import { Button, Col } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined, EyeOutlined } from "@ant-design/icons";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";

import { DataCourseChapters } from "../../../../redux/@types";
import { courseChaptersService } from "../../../../shared/services";
import Table from "../../../../shared/components/ui/Table";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import useAppSelector from "../../../../redux/hooks/useAppSelector";
import { selectModalCourseChapters } from "../../../../redux";
import { useCourseChapters } from "../../hook";
import { ModalCourseChapters } from "../../../../shared/components";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";

export const TableCourseChapters = () => {
  const { id } = useParams();
  const modalCourseChapters = useAppSelector(selectModalCourseChapters);

  const {
    isUpdateCourseChapters,
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    changeModalCourseChaptersState,
    changeCourseChaptersForUpdate,
    showDeleteConfirm,
  } = useCourseChapters();
  const columns: TableColumns<DataCourseChapters> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Nombre",
      width: 7,
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    // {
    //   title: "Video",
    //   width: 9,
    //   dataIndex: "path_video",
    //   key: "path_video",
    // },
    {
      title: "Posición",
      width: 4,
      dataIndex: "position",
      key: "position",
    },
    {
      title: "ID Sección de curso",
      width: 4,
      dataIndex: "course_section_id",
      key: "course_section_id",
    },
    {
      title: "ID de curso",
      width: 4,
      dataIndex: "course_id",
      key: "course_id",
    },
    {
      title: "Tipo",
      width: 4,
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Duración",
      width: 4,
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 4,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => window.open(`${PATH_S3_BUCKET}/${_.path_video}`)}
              icon={<EyeOutlined />}
              size="large"
            />
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeCourseChaptersForUpdate(item as any)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <>
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          size="large"
          onClick={changeModalCourseChaptersState}
        >
          Agregar Nuevo Capitulo
        </Button>
      </Col>
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Table<DataCourseChapters>
          columns={columns}
          fetchQueryProps={{
            $sort: { id: -1 },
            course_id: id,
          }}
          service={courseChaptersService}
          onLoad={setTableProps}
        />
      </Col>
      {modalCourseChapters && isUpdateCourseChapters && (
        <FormProvider {...formMethodsUpdate}>
          <ModalCourseChapters />
        </FormProvider>
      )}
      {modalCourseChapters && !isUpdateCourseChapters && (
        <FormProvider {...formMethodsCreate}>
          <ModalCourseChapters />
        </FormProvider>
      )}
    </>
  );
};
