// import _ from "lodash";

import  currency from "currency-formatter";
// export const money = (value: any, decimalSeparator: any) => {
//   return currency.format(value, {
//     code: "USD",
//     decimalDigits: 0,
//     precision: 0,
//     decimalSeparator: "."
//     //typeof decimalSeparator !== "undefined" ? decimalSeparator : ","
//   });
// };
export const decimal = (value: any, decimal = ",") => {
  return currency.format(value, {
    symbol: "",
    decimal,
    precision: 0,
    format: "%v %s" // %s is the symbol and %v is the value
  });
};

// export const sortBy = (field: any, array: any) => {
//   return _.sortBy(array, [
//     function(it) {
//       return it[field];
//     }
//   ]);
// };

export const slugify = (text: string) => {
  return text
      .toString() // Cast to string (optional)
      .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string (optional)
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/\-\-+/g, '-')

  // const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
  // const to = 'aaaaaeeeeeiiiiooooouuuunc------'

  // const newText = text.split('').map((letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

  // return (
  //     newText
  //         .toString() // Cast to string
  //         .toLowerCase() // Convert the string to lowercase letters
  //         .trim() // Remove whitespace from both sides of a string
  //         .replace(/\s+/g, '-') // Replace spaces with -
  //         .replace(/&/g, '-y-') // Replace & with 'and'
  //         // .replace(/[^\w\-]+/g, '')  Remove all non-word chars
  //         .replace(/\-\-+/g, '-')
  // ) // Replace multiple - with single -
}

export const formatNumberPrice = (value: number) =>{
  const price = Number(value);
  const amount = !isNaN(price) ? price.toLocaleString('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,

  }) : 'N/A'; 
  return amount
}