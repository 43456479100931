import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import {
  selectIsUpdateConfigurationCashback,
  selectConfigurationCashbackForUpdate,
  selectConfigurationCashbackRefreshTable,
} from "../../../redux";
import {
  refreshTableConfigurationCashback,
  setIsConfigurationCashbackForUpdate,
  configurationCashbackChangeModalState,
  // createConfigurationCashback,
  updateConfigurationCashback,
} from "../../../redux/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { DataConfigurationCashback } from "../../../redux/@types";
import { message } from "antd";
import { validateUpdateConfigurationCashback } from "../../../helpers";

export const useConfigurationCashback = () => {
  const dispatch = useAppDispatch();

  const isUpdateConfigurationCashback = useSelector(
    selectIsUpdateConfigurationCashback
  );
  const dataUpdateConfigurationCashback = useSelector(
    selectConfigurationCashbackForUpdate
  );
  const isRefreshTableConfigurationCashback = useSelector(
    selectConfigurationCashbackRefreshTable
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalConfigurationCashbackState = () => {
    // formMethodsCreate.reset();
    dispatch(setIsConfigurationCashbackForUpdate(false));
    dispatch(configurationCashbackChangeModalState());
  };

  // const formMethodsCreate = useForm<DataSystemVariables>({
  //   resolver: yupResolver(validateCreateSystemVariables),
  // });

  const formMethodsUpdate = useForm<DataConfigurationCashback>({
    resolver: yupResolver(validateUpdateConfigurationCashback),
  });

  useEffect(() => {
    if (isUpdateConfigurationCashback) {
      // formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateConfigurationCashback });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateConfigurationCashback, dataUpdateConfigurationCashback]);

  const onSubmitCreateOrUpdate = async (data: DataConfigurationCashback) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateConfigurationCashback) {
      //console.log("Actualiando===>", data);
      await updateConfigurationCashback({
        id: data.id,
        cashback_status: data.cashback_status,
        cashback_type: data.cashback_type,
        cashback_value: data.cashback_value,
        cashback_max_amount: data.cashback_max_amount,
        cashback_expiration_days: data.cashback_expiration_days,
        order_min_amount: data.order_min_amount,
        cashback_apply_to_offer_products: data.cashback_apply_to_offer_products,
      })
        .then((res: Partial<DataConfigurationCashback>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // dispatch(coursesCategoriesChangeModalState());
            message.success(
              `Se ha actualizado con éxito la variable de sistema con id:${res.id}`
            );
            dispatch(refreshTableConfigurationCashback(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      // await createConfigurationCashback(data)
      //   .then((res: DataConfigurationCashback) => {
      //     setIsLoading(false);
      //     if (res.id) {
      //       dispatch(configurationCashbackChangeModalState());
      //       message.success(
      //         `Se ha creado con éxito la variable de sistema con id:${res.id}`
      //       );
      //       dispatch(refreshTableConfigurationCashback(true));
      //     } else {
      //       message.error(res as any);
      //     }
      //   })
      //   .catch((err: any) => {
      //     setIsLoading(false);
      //     console.log(err);
      //     message.error(err.message);
      //   });
    }
  };

  useEffect(() => {
    if (isRefreshTableConfigurationCashback) {
      tableProps?.refresh();
      dispatch(refreshTableConfigurationCashback(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTableConfigurationCashback]);

  return {
    //state
    isUpdateConfigurationCashback,
    isLoading,
    tableProps,
    //methods
    // formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalConfigurationCashbackState,
  };
};
