import { TRootState } from "../@types"

export const selectModalFulfillmentCompany = ( state: TRootState ) =>state.fulfillmentCompany.fulfillmentCompanyModalIsOpen

export const selectIsUpdateFulfillmentCompany = ( state: TRootState ) =>state.fulfillmentCompany.isUpdateFulfillmentCompany

export const selectFulfillmentCompanyForUpdate = ( state: TRootState ) =>state.fulfillmentCompany.fulfillmentCompanyForUpdate

export const selectFulfillmentCompanyRefreshTable =  ( state: TRootState ) =>state.fulfillmentCompany.refreshTableFulfillmentCompany

export const selectActiveKeyOfFulfillmentCompany =  ( state: TRootState ) =>state.fulfillmentCompany.activeKey
