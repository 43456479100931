import * as React from 'react';
import useDebounce from './useDebounce';

type UseFilterProps<T> = {
  service: any; // Reemplaza con el tipo adecuado para tu servicio
  initialData: T[];
  queryKey: string;
  debounceDelay?: number;
  student_teacher: string;
};

export default function useFilter<T>({
  service,
  initialData,
  queryKey,
  debounceDelay = 300,
  student_teacher
}: UseFilterProps<T>) {
  const [data, setData] = React.useState<T[]>(initialData);
  const [query, setQuery] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<T[]>(initialData);
  const debouncedQuery = useDebounce(query, debounceDelay);

  React.useEffect(() => {
    // Cargar data inicial
    setFilteredData(initialData);
  }, [initialData]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (debouncedQuery) {
        try {
          const response = await service.find({ query: { [queryKey]: debouncedQuery, skipJoin: true, student_teacher  } });
          setData(response?.data || []);
        } catch (err) {
          console.error('Error fetching data:', err);
        }
      } else {
        setData(initialData);
      }
    };

    fetchData();
  }, [debouncedQuery, initialData, queryKey, service]);

  React.useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return {
    query,
    setQuery,
    filteredData
  };
}
