"use client";
import React from "react";
import { programsStudentAcademy } from "../../../../shared/services";
import { ProgramStudentType } from "../../../../types/dto/program";
import { message } from "antd";

export interface UseProgramStudentProps {
  program_id?: number;
  user_id?: number;
}

export default function useProgramStudent({
  program_id,
  user_id,
}: UseProgramStudentProps) {
  const [loading, setLoading] = React.useState(false);
  const [studentsByProgram, setstudentsByProgram] = React.useState<
    ProgramStudentType[]
  >([]);
  const [
    programStudentsByUserIdAndProgramId,
    setProgramStudentsByUserIdAndProgramId,
  ] = React.useState<ProgramStudentType[]>([]);
  const [programStudentData, setProgramStudentData] =
    React.useState<ProgramStudentType>();
  const [programStudentByUserIdData, setProgramStudentByUserIdData] =
    React.useState<ProgramStudentType[]>([]);

  const fetchProgramstudentsByProgram = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const response = await programsStudentAcademy.find({
        query: { program_id: program_id },
      });
      setstudentsByProgram(response.data);
      return response.data;
    } catch (err) {
      console.error("Error al obtener estudiantes del programa:", err);
    } finally {
      setLoading(false);
    }
  };

  const getProgramStudentsByUserId = async (user_id: number) => {
    if (loading) return;
    setLoading(true);

    try {
      const response = await programsStudentAcademy.find({
        query: { user_id: user_id },
      });
      setProgramStudentByUserIdData(response.data);
    } catch (err) {
      console.error("Error al obtener estudiantes del programa:", err);
    } finally {
      setLoading(false);
    }
  };

  const getStudentsByUserIdAndProgramId = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const response = await programsStudentAcademy.find({
        query: { program_id, user_id },
      });

      setProgramStudentsByUserIdAndProgramId(response.data);
      return response.data;
    } catch (err: any) {
      message.error(err.message);
      console.error("Error al obtener estudiantes por ID de usuario:", err);
    } finally {
      setLoading(false);
    }
  };

  const getProgramStudentById = async (programStudentId: number) => {
    if (loading) return;
    setLoading(true);

    try {
      const response = await programsStudentAcademy.get(programStudentId);
      setProgramStudentData(response);
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  React.useEffect(() => {
    if (program_id) {
      fetchProgramstudentsByProgram();
    }
    if (program_id || user_id) {
      const fecht = async ()=>{
        await getStudentsByUserIdAndProgramId();
      }
      fecht()
    }
  }, [program_id, user_id]);

  return {
    studentsByProgram,
    programStudentsByUserIdAndProgramId,
    programStudentData,
    programStudentByUserIdData,
    fetchProgramstudentsByProgram,
    getStudentsByUserIdAndProgramId,
    getProgramStudentById,
    getProgramStudentsByUserId,
  };
}
