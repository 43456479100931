import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { message } from "antd";

import { discountCodeChangeModalState, setIsDiscountCodeForUpdate, refreshTableDiscountCode, updateDiscountCode, createDiscountCode } from '../../../redux/actions/discountCode.actions';
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { selectDiscountCodeForUpdate, selectDiscountCodeRefreshTable, selectIsUpdateDiscountCode } from "../../../redux/selectors/discountCode.selectors";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { DataDiscountCode } from "../../../redux/@types";
import { validateCreateDiscountCode, validateUpdateDiscountCode } from "../../../helpers";

export const useDiscountCode = () => {
  const dispatch = useAppDispatch();

  const isUpdateDiscountCode = useSelector(selectIsUpdateDiscountCode)
  const dataUpdateDiscountCode = useSelector(selectDiscountCodeForUpdate)
  const isRefreshTableDiscountCode = useSelector(selectDiscountCodeRefreshTable)

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalDiscountCodeState = () => {
    dispatch(setIsDiscountCodeForUpdate(false))
    dispatch(discountCodeChangeModalState())
  }

  const formMethodsCreate = useForm<DataDiscountCode>({
    resolver: yupResolver(validateCreateDiscountCode),
  });

  const formMethodsUpdate = useForm<DataDiscountCode>({
    resolver: yupResolver(validateUpdateDiscountCode),
  });

  useEffect(() => {
    if (isUpdateDiscountCode) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateDiscountCode });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateDiscountCode, dataUpdateDiscountCode]);

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await updateDiscountCode({
      id,
      status: value,
    })
    .then((res: Partial<DataDiscountCode>) => {
      setIsLoading(false);
      if (res.id) {
        // dispatch(bannersChangeModalState());
        message.success(
          `Se ha actualizado con éxito el estado del código con id:${res.id}`
        );
        dispatch(refreshTableDiscountCode(true));
      } else {
        message.error(res as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }

  const onSubmitCreateOrUpdate = async (data: DataDiscountCode) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateDiscountCode) {
      //console.log("Actualiando===>", data);
      await updateDiscountCode({
        id: data.id,
        status: data.status,
        name: data.name,
        code: data.code,
        quantity: data.quantity,
        value_percentage: data.value_percentage,
        value_amount: data.value_amount,
        uses_by_user: data.uses_by_user,
        date_start: data.date_start,
        date_end: data.date_end,
      })
        .then((res: Partial<DataDiscountCode>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // dispatch(discountCodeChangeModalState());
            message.success(
              `Se ha actualizado con éxito el código de descuento con id:${res.id}`
            );
            dispatch(refreshTableDiscountCode(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createDiscountCode(data)
        .then((res: DataDiscountCode) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(discountCodeChangeModalState());
            message.success(
              `Se ha creado con éxito el código de descuento con id:${res.id}`
            );
            dispatch(refreshTableDiscountCode(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableDiscountCode) {
      tableProps?.refresh();
      dispatch(refreshTableDiscountCode(false));
    }
  }, [isRefreshTableDiscountCode]);
  return {
    //state
    isUpdateDiscountCode,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalDiscountCodeState,
    updateStatus,
  }
}
