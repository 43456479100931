/* eslint-disable @typescript-eslint/no-explicit-any */

import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Button, Input, Space, Table as AntTable } from "antd";
import { Id, Paginated, Params } from "@feathersjs/feathers";
import {
  SorterResult,
  TablePaginationConfig,
  FilterValue,
  TableCurrentDataSource,
} from "antd/lib/table/interface";
import { SearchOutlined } from "@ant-design/icons";

// import { InputSearch } from "core/common/input-search";

import { TableProps } from "./types";
import { useFetch, useDebounce } from "../hooks";
import { useSearchParams } from "react-router-dom";

export interface FindMethod<T> {
  params: Params[];
  result: Paginated<T>;
}
interface ObjParams {
    page?: string
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Table = <T extends object & { id: number } = any>({
  service,
  columns,
  limit,
  pagination = true,
  fetch$ClientProps,
  fetchQueryProps,
  className,
  onChange,
  onFetch,
  onLoad,
  ...restProps
}: TableProps<T>) => {
    let [searchParams, setSearchParams] = useSearchParams();
  // States
  const [isMounted, setIsMounted] = useState(false);
  let params:ObjParams = {}
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [paginationData, setPaginationData] = useState({
    total: 0,
    skip: 0,
    limit: limit || 10,
    currentPage: Number(params?.page!) || 1 ,
  });
  // Hooks
  const {
    fetch: fetchData,
    data: dataFetch,
    loading: loadingFetch,
  } = useFetch<FindMethod<T>>({ service: service.find });

  const { value: searchValue, loading: loadingSearch } = useDebounce(
    inputValue,
    1000
  );

  const getColumnInputSearchProps = useCallback(
    (dataIndex: string, type: "text" | "date") => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder="Buscar..."
            style={{ marginBottom: 8, display: "block" }}
            type={type}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm({ closeDropdown: true })}
          />
          <Space>
            <Button
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
              type="primary"
              onClick={() => confirm({ closeDropdown: true })}
            >
              Buscar
            </Button>
            <Button
              size="small"
              style={{ width: 90 }}
              onClick={() => {
                clearFilters();
                confirm({ closeDropdown: false });
              }}
            >
              Reiniciar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value: any, record: any) => {
        return record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "";
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => /*  this.searchInput.select() */ undefined, 100);
        }
      },
    }),
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _fetch = (skip?: number, params?: any) => {
    const query = _.merge(
      {},
      {
        $limit: paginationData.limit,
        $skip: skip || ((Number(params?.page!) || 1) - 1) * ( paginationData.limit),
        ...(fetchQueryProps || {}),
        $client: {
          ...(searchValue ? { search: searchValue } : {}),
          ...(fetch$ClientProps || {}),
        },
      },
      params || {}
    );

    fetchData({ query }).then((res) => {
      onFetch && onFetch(res);
    });
  };

  const getSortData = useCallback(
    (sorter: SorterResult<T>[] | SorterResult<T>) => {
      return (!Array.isArray(sorter) ? [sorter] : sorter)
        .map((it) =>
          it.field
            ? { [it.field as string]: it.order === "ascend" ? 1 : -1 }
            : {}
        )
        .reduce((acc, item) => {
          const key = Object.keys(item)[0];

          if (key) acc[key] = item[key];

          return acc;
        }, {});
    },
    []
  );

  const getFilterData = useCallback(
    (filters: Record<string, FilterValue | null>) => {
      const $clientFiltersKeys: string[] = [];
      const queryFilters = Object.keys(filters)
        .map((key) => {
          const colConfig = columns?.find((it) => it.dataIndex === key);
          let val: any;

          if (filters[key] !== null) {
            if (colConfig?.filterWay === "$client") {
              val = { [key]: filters[key] };
              $clientFiltersKeys.push(key);
            } else if (colConfig?.filterType === "date") {
              val = {
                [key]: {
                  $gte: `${filters[key]} 00:00:00`,
                  $lte: `${filters[key]} 23:59:59`,
                },
              };
            } else if (colConfig?.filterType === "text")
              val = { [key]: { $like: `%${filters[key]}%` } };
            else val = { [key]: { $in: filters[key] } };
          } else val = {};

          return val;
        })
        .reduce((acc, item) => {
          const key = Object.keys(item)[0];

          if (key) acc[key] = item[key];

          return acc;
        }, {});

      return {
        queryFilters: Object.keys(queryFilters).reduce((acc: any, item) => {
          if (!$clientFiltersKeys.includes(item))
            acc[item] = queryFilters[item];

          return acc;
        }, {}),
        $clientFilters: Object.keys(queryFilters).reduce((acc: any, item) => {
          if ($clientFiltersKeys.includes(item)) acc[item] = queryFilters[item];

          return acc;
        }, {}),
      };
    },
    [columns]
  );

  const handleChange = (
    pag: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T>[] | SorterResult<T>,
    extra: TableCurrentDataSource<T>
  ) => {
    const a = new URLSearchParams()
    if(pag.current){
        a.set('page', pag.current.toString())
        setSearchParams(a.toString())
        setPaginationData((prev) => ({ ...prev, currentPage: pag.current || 1 }));
    }

    const { queryFilters, $clientFilters } = getFilterData(filters);

    _fetch(
      ((pag?.current || 1) - 1) * (pag?.pageSize || paginationData.limit),
      {
        ...queryFilters,
        $client: {
          filterBy: $clientFilters,
        },
        $sort: getSortData(sorter),
      }
    );

    if (onChange) onChange(pag, filters, sorter, extra);
  };

  // Update pagination
  useEffect(() => {
    if (dataFetch) {
      setPaginationData((prev) => ({
        ...prev,
        total: dataFetch.total,
        skip: dataFetch.skip,
        limit: dataFetch.limit,
      }));
    }
  }, [dataFetch]);
  //console.log({ dataFetch });
  // Fetch data
  useEffect(() => {
    if (isMounted) {
        params = Array.from(searchParams.keys()).reduce(
            (acc, val) => ({ ...acc, [val]: searchParams.get(val) }),
            {}
          )
      setPaginationData((prev) => ({ ...prev, currentPage: Number(params?.page!)|| 1 }));
      if (!loadingFetch && !loadingSearch){
        window.document.body.scrollTo(0,0)
        _fetch(((Number(params?.page!) || 1) - 1) * ( paginationData.limit))
      };
    }
    setIsMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, service, fetch$ClientProps, fetchQueryProps, isMounted]);

  useEffect(() => {
    if (onLoad) {
      onLoad({
        refresh: _fetch,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {/* <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                <InputSearch placeholder="Buscar..." value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
            </Space> */}
      <div style={{ margin: "15px 0" }}>
        <AntTable<T>
          className={`${className || ""} tableStyleOne`}
          columns={columns?.map((col: any) => ({
            ...col,
            ...(col.filterType && col.filterType !== "base"
              ? { ...getColumnInputSearchProps(col.dataIndex, col.filterType) }
              : {}),
          }))}
          dataSource={dataFetch?.data || []}
          loading={loadingFetch || loadingSearch}
          pagination={
            pagination && {
              defaultCurrent: 1,
              current: paginationData.currentPage,
              showSizeChanger: false,
              pageSize: paginationData.limit,
              total: paginationData.total,
            }
          }
          rowKey={(record) =>
            `row_table_key_${
              record.id || Math.random() * (paginationData.limit - 0) + 0
            }`
          }
          sticky
          scroll={{ x: 1500 }}
          onChange={handleChange}
          {...restProps}
        />
      </div>
    </div>
  );
};

export default Table;