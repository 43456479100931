import CreateProgram from "../../../../modules/academyPrograms/ui/programs/createProgram";
import { WrapperModalUsers } from "./styled";

export const ModalPrograms = ({
  openModal,
  onClose,
  setOpenModal,
  duplicateProgram,
}: {
  openModal: boolean;
  onClose: () => void;
  setOpenModal: (value: boolean) => void;
  duplicateProgram: boolean;
}) => {
  return (
    <WrapperModalUsers
      title={`Crear programa`}
      open={openModal}
      width={"320px"}
      destroyOnClose
      onClose={() => {
        setOpenModal(false);
        onClose();
      }}
    >
      <CreateProgram
        setOpenModal={setOpenModal}
        duplicateProgram={duplicateProgram}
      />
    </WrapperModalUsers>
  );
};
