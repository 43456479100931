import * as yup from 'yup'

export const validateUpdateCMS = yup.object().shape({
    title:yup
    .string()
    .min(2, 'El TITULO debe tener más de 2 caracteres'),
    body:yup
    .string()
    .min(2, 'El TITULO debe tener más de 2 caracteres'),
    status: yup
    .string()
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
})