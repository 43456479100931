
import AcademyStudentSchedulePayment from "../modules/AcademyStudentSchedulePayment/AcademyStudentSchedulePayment";
import { AdminMenuLayout } from "../shared/components";

export default function AcademyStudentSchedulePaymentPage() {

  return (
    <AdminMenuLayout title="" pageDescription="">
     <AcademyStudentSchedulePayment/>
    </AdminMenuLayout>
  );
}

