import { DataStates } from "../@types/states"

export const STATES_MODAL_STATE = '[ STATES ] States Modal State'

export interface IStatesModalState {
    type: typeof STATES_MODAL_STATE
}

export const GET_STATE_FOR_UPDATE = '[ STATES ] Get State For Update'

export interface IGetStateForUpdate {
    type: typeof GET_STATE_FOR_UPDATE
    payload: DataStates
}

export const IS_STATES_FOR_UPDATE ='[ STATES ] Is State For Update'

export interface IIsStateForUpdate {
    type: typeof IS_STATES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_STATES_REFRESH_TABLE = '[ STATES ] Switch States Refresh Table'

export interface ISwitchStatesRefreshTable {
    type: typeof SWITCH_STATES_REFRESH_TABLE
    payload: boolean
}