import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  coursesCategoriesChangeModalState,
  deleteCoursesCategories,
  refreshTableCoursesCategories,
  setCoursesCategoriesForUpdate,
  setIsCoursesCategoriesForUpdate,
} from "../../../redux";
import { DataCoursesCategories } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useCoursesCategories } from "./useCoursesCategories";

const { confirm } = Modal;

export const useTableCoursesCategories = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useCoursesCategories();

  const changeCoursesCategoriesForUpdate = (val: DataCoursesCategories) => {
    dispatch(setIsCoursesCategoriesForUpdate(true));
    dispatch(setCoursesCategoriesForUpdate(val));
    dispatch(coursesCategoriesChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la Categoría de curso con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar categoría" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar categoría",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteCoursesCategories(valId)
          .then(() => {
            dispatch(refreshTableCoursesCategories(true));
            message.success(
              `Se ha borrado con éxito la Categoría de curso con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeCoursesCategoriesForUpdate,
    showDeleteConfirm,
  };
};
