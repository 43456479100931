"use client";

import React from "react";
import { PaginatedResponse } from "../../types/dto/program";

export interface LevelContextProps {
  selectedProgramId?: number;
  selectedProgramModuleId?: number;
  selectedProgramModuleTopicId?: number;
  selectedProgramModuleTechniqueId?: number;

  pagination: Omit<PaginatedResponse, "data">;

  onSelectedProgramId: (id?: number) => void;
  onSelectedProgramModuleId: (id?: number) => void;
  onSelectedProgramModuleTopicId: (id?: number) => void;
  onSelectedProgramModuleTechniqueId: (id?: number) => void;

  setPagination: React.Dispatch<React.SetStateAction<Omit<PaginatedResponse, "data">>>;
}

export const LevelContext = React.createContext({} as LevelContextProps);

export const useLevelContext = () => React.useContext(LevelContext);

export function LevelContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // const {program, setProgram} = usePrograms(levelId);
  const [selectedProgramId, setSelectedProgramId] = React.useState<number | undefined>();
  const [selectedProgramModuleId, setSelectedProgramModuleId] = React.useState<number | undefined>();
  const [selectedProgramModuleTopicId, setSelectedProgramModuleTopicId] = React.useState<number | undefined>();
  const [selectedProgramModuleTechniqueId, setSelectedProgramModuleTechniqueId] = React.useState<number | undefined>();

  const [pagination, setPagination] = React.useState<Omit<PaginatedResponse, "data">>({
    limit: 50,
    skip: 0,
    total: 0,
  });

  return (
    <LevelContext.Provider
      value={{
        selectedProgramId,
        selectedProgramModuleId,
        selectedProgramModuleTopicId,
        selectedProgramModuleTechniqueId,
        pagination,
        setPagination,
        onSelectedProgramId(id) {
          setSelectedProgramId(id);
          setSelectedProgramModuleId(undefined);
          setSelectedProgramModuleTopicId(undefined);
          setSelectedProgramModuleTechniqueId(undefined);
        },
        onSelectedProgramModuleId(id) {
          setSelectedProgramModuleId(id);
          setSelectedProgramModuleTopicId(undefined);
          setSelectedProgramModuleTechniqueId(undefined);
        },
        onSelectedProgramModuleTopicId(id) {
          setSelectedProgramModuleTopicId(id);
          setSelectedProgramModuleTechniqueId(undefined);
        },
        onSelectedProgramModuleTechniqueId(id?: number) {
          setSelectedProgramModuleTechniqueId(id);
        },
      }}
    >
      {children}
    </LevelContext.Provider>
  );
}
