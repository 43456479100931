import { useState, useEffect } from 'react';

export const useIsMounted = (t?: number) => {
    const [isMounted, setIsMounted] = useState(false)
    useEffect(() => {
        setIsMounted(true)
      setTimeout(() => {
        setIsMounted(false)
      }, t || 400);
      return () => {
        setIsMounted(false)
      }
    }, [])

  return isMounted
}

