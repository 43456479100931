"use client";

import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";

import clsx from "clsx";
import React from "react";

import { useLevelContext } from "../../../../academyPrograms/context";
import {
  DeleteOutlined,
  EditOutlined,
  FullscreenOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import CreateProgramModuleTechniques from "./CreateTechnique";
import useProgramModuleTechniques from "../../../../academyPrograms/hooks/techniques/useProgramTechniques";
import useSaveSortedProgramTecnics from "../../../../academyPrograms/hooks/techniques/useSaveSortedProgramTechniques";
import UpdateProgramModuleTechniques from "./UpdateProgramTechnique";
import { ProgramTechniquesType } from "../../../../../types/dto/program";
import Button from "../../../../../shared/components/ui/Button";
import Modal from "../../../../../shared/components/ui/modal";
import SortableList from "../../../../../shared/components/ui/Sortable";
import useDeleteProgramTechniques from "../../../../academyPrograms/hooks/techniques/useDeleteProgramTechniques";

export function ProgramModuleTecniqueItem({
  pgmModTechniques,
  selected,
  dragListeners,
  opacity,

  onClick,
  onUpdate,
}: {
  pgmModTechniques: any;
  selected?: boolean;
  dragListeners?: SyntheticListenerMap;
  opacity?: number;
  onClick?: () => void;
  onUpdate?: (
    promTechniques: Pick<
      ProgramTechniquesType,
      | "name"
      | "description"
      | "status"
      | "id"
      | "program_module_topic_id"
      | "position"
    >
  ) => void;
}) {
  const [
    showModalPrgramModuleTechniquesUpdate,
    setShowModalPrgramModuleTechniquesUpdate,
  ] = React.useState(false);
  const [
    showModalPrgramModuleTechniquesRemove,
    setShowModalPrgramModuleTechniquesRemove,
  ] = React.useState(false);

  const {showDeleteConfirm} = useDeleteProgramTechniques()
  return (
    <>
      <Modal
        className="w-[90%]"
        title="Editar tecnica"
        visible={showModalPrgramModuleTechniquesUpdate}
        onCancel={() => {
          setShowModalPrgramModuleTechniquesUpdate(false);
        }}
      >
        <UpdateProgramModuleTechniques
          programTechniques={pgmModTechniques}
          onSuccess={(res) => {
            setShowModalPrgramModuleTechniquesUpdate(false);
            onUpdate?.({
              ...pgmModTechniques,
              res,
            });
          }}
        />
      </Modal>
      <div
        aria-hidden="true"
        className={clsx(
          "mb-2 flex w-full cursor-pointer items-center gap-2  rounded-lg px-4 py-2 pr-1 shadow-md",
          "hover:ring-2 hover:ring-primary-500 hover:ring-offset-2 hover:ring-offset-white",
          "hover:transition-all hover:duration-200",
          {
            "bg-white text-black/50": !selected,
            "bg-blue-600 text-white": selected,
          }
        )}
        role="button"
        style={{ opacity }}
        onClick={onClick}
      >
        <div className="flex-1">
          <p className={clsx("text-sm font-semibold ")}>
            {pgmModTechniques.name}
          </p>
        </div>
        <div className="flex items-center text-sm">
          <>
            <Button
              primary={!selected}
              size="small"
              type="button"
              variant="text"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setShowModalPrgramModuleTechniquesUpdate(true);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              danger
              size="small"
              type="button"
              variant="text"
              onClick={(event) => {
                showDeleteConfirm(pgmModTechniques.id, pgmModTechniques.name)              }}
            >
              <DeleteOutlined />
            </Button>
            <Button
              className="cursor-move"
              size="small"
              type="button"
              variant="text"
              {...dragListeners}
            >
              <FullscreenOutlined />
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default function ProgramModuleTecnique({
  programModuleTopicId,
}: {
  programModuleTopicId?: number;
}) {
  const [
    showModalCreateProgramModuleTechnique,
    setShowModalCreateProgramModuleTechnique,
  ] = React.useState(false);
  const {
    programTechniques,
    loading,
    refresh: refreshTechniques,
    setProgramTechniques,
  } = useProgramModuleTechniques(programModuleTopicId);
  const {
    selectedProgramModuleTechniqueId,
    onSelectedProgramModuleTechniqueId,
  } = useLevelContext();

  const saveSorted = useSaveSortedProgramTecnics();
  return (
    <div style={{ margin: 20 }}>
      <Modal
        className="w-[90%]"
        title="Crear una tecnica"
        visible={showModalCreateProgramModuleTechnique}
        onCancel={() => {
          setShowModalCreateProgramModuleTechnique(false);
        }}
      >
        <div className="flex flex-col gap-2">
          {!!programModuleTopicId ? (
            <CreateProgramModuleTechniques
              programModuleTopicId={programModuleTopicId}
              onSuccess={() => {
                refreshTechniques()
                  .then(() => {
                    // TODO
                  })
                  .catch(console.error);

                setShowModalCreateProgramModuleTechnique(false);
              }}
            />
          ) : null}
        </div>
      </Modal>
      <div className="w-72 flex-none rounded-lg bg-white p-3 shadow-lg">
        <div className="flex items-center gap-2">
          <h3 className="font-semibold">Tecnicas</h3>
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-1">
              <Button
                primary
                size="small"
                type="button"
                variant="text"
                onClick={() => setShowModalCreateProgramModuleTechnique(true)}
              >
                <PlusOutlined />
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-2">
          {loading ? (
            <div className="py-2 text-center">
              <i className="fa-solid fa-spinner animate-spin text-black/50" />
            </div>
          ) : null}
          {programTechniques.length === 0 && !loading && (
            <p className="py-2 text-center text-sm text-black/30">
              <i className="fa-solid fa-info-circle" /> No hay temas creados
            </p>
          )}
          <SortableList
            key="id"
            customActivator
            id="id"
            items={programTechniques}
            renderItem={(it, props) => (
              <ProgramModuleTecniqueItem
                pgmModTechniques={it}
                selected={it.id === selectedProgramModuleTechniqueId}
                opacity={props?.activeId === it.id ? 0.5 : 1}
                onClick={() => {
                  onSelectedProgramModuleTechniqueId(it.id);
                }}
                onUpdate={() => {
                  refreshTechniques().catch(console.error);
                }}
              />
            )}
            onSort={(sortedProgramModule) => {
              const sortedPosition = sortedProgramModule.map(
                (level, position) => ({
                  ...level,
                  position: position + 1,
                })
              );

              setProgramTechniques(sortedPosition);
              saveSorted(
                sortedPosition.map(({ id, position }) => ({
                  id: id.toString(),
                  position,
                }))
              ).catch(console.error);
            }}
          />
        </div>
      </div>
    </div>
  );
}
