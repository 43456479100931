import { getService } from "../../../../clients/feathers.clients";
import { PaginatedResponse, ProgramTechniquesType } from "../../../../types/dto/program";

export default function useSaveSortedProgramTecnics() {
  const service = getService("ca-program-module-techniques");

  return (sortedPrograms: { id: string; position: number }[]) =>
    service.patch(null, sortedPrograms, {
      query: {
        skipJoin: 'true',
        $client: {
          saveSort: "true"
        }
      }
    })
    .then((res: PaginatedResponse) => res.data as Pick<ProgramTechniquesType, "id" | "position">[]);
}
