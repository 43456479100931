import Button from "../../../../../shared/components/ui/Button";
import { ProgramModuleType } from "../../../../../types/dto/program";
import useUpdateProgramModule from "../../../../academyPrograms/hooks/module/useUpdateProgramsModule";
import ProgramModuleForm from "./ProgramModuleForm";

export interface UpdateProgramModuleProps {
  programModule: ProgramModuleType;
  onSuccess?: (programModule: ProgramModuleType) => void;
}

export default function UpdateProgramModule({programModule, onSuccess}: UpdateProgramModuleProps) {
  const {control, loading, handleSubmit} = useUpdateProgramModule(programModule, {
    onSuccess(data) {
      onSuccess?.(data);
    },
  });

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ProgramModuleForm control={control} />
        <div className="mt-2 flex items-center justify-end">
          <Button primary  className="bg-[#072082]" loading={loading} type="submit" variant="filled">
            <i className="fa-solid fa-save" /> Actualizar
          </Button>
        </div>
      </form>
    </div>
  );
}
