import { type Control } from "react-hook-form";
import { Input, TextArea, Select } from "../../../../../shared/components/ui/form/inputs";
import { EquipmentsAttributesType } from "../../../../../types/dto/equipments";
 
export default function CreateEquipmentForm({
  control,
}: {
  control?: Control<EquipmentsAttributesType>;
}) {

  return (
    <div className="flex flex-col gap-3">
      <Input
      style={{background:'none'}}
        control={control}
        label="Nombre"
        name="name"
        placeholder="Ejemplo"
        type="text"
      />
        <TextArea style={{background:'none'}}  control={control} label="Descripción" name="description" placeholder="description" />
      <Input
      style={{background:'none'}}
        control={control}
        label="Categoria"
        name="category"
        placeholder="Tecnologia"
        type="text"
      />
        <Input
      style={{background:'none'}}
        control={control}
        label="Valor por dia"
        name="value_per_day"
        placeholder="100000"
        type="number"
      />
      <Input
      style={{background:'none'}}
        control={control}
        label="Stock"
        name="stock"
        placeholder="100000"
        type="number"
      />
      <Select style={{background:'none'}} control={control} label="Estado" name="status" >
        <option value="">Seleccionar...</option>
        <option value="active">Activo</option>
        <option value="inactive">Inactivo</option>
      </Select>

    </div>
  );
}
