import { priceListsService } from "../../shared/services"
import { ISinglePriceList } from "../@types"
import { priceListActions } from "../action-types"

export const priceListChangeModalState = (): priceListActions.IPriceListModalState =>({
    type: priceListActions.PRICE_LIST_MODAL_STATE
})

export const setPriceListForUpdate = (val: ISinglePriceList): priceListActions.IGetPriceListForUpdate =>({
    type: priceListActions.GET_PRICE_LISTS_FOR_UPDATE,
    payload: val
})

export const setIsPriceListForUpdate = (val: boolean): priceListActions.IIsPriceListForUpdate =>({
    type: priceListActions.IS_PRICE_LISTS_FOR_UPDATE,
    payload: val
})

export const createPriceList = async(data: Partial<ISinglePriceList>, params?: Record<string, any>) =>{
    return await priceListsService.create(data, params)
    .then((res: ISinglePriceList) => {return res})
    .catch((err: any) => { return err.message })
}

export const updatePriceList =async(data: Partial<ISinglePriceList>, params?: Record<string, any>) =>{
    return await priceListsService.patch(data?.id!, data, params)
    .then((res: ISinglePriceList) => {return res})
    .catch((err: any) => { return err.message })
}

export const deletePriceList = async(id: number) => {
    return await priceListsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTablePriceList = (val: boolean): priceListActions.ISwitchPriceListRefreshTable =>({
    type: priceListActions.SWITCH_PRICE_LISTS_REFRESH_TABLE,
    payload: val
})