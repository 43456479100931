import { DataWalletBonus, IWalletBonusState } from "../@types";
import { walletBonusActions } from "../action-types";

type walletBonusType =
| { type: typeof walletBonusActions.WALLET_BONUS_MODAL_STATE }
| { type: typeof walletBonusActions.GET_WALLET_BONUS_FOR_UPDATE; payload: DataWalletBonus }
| { type: typeof walletBonusActions.IS_WALLET_BONUS_FOR_UPDATE; payload: boolean }
| { type: typeof walletBonusActions.SWITCH_WALLET_BONUS_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IWalletBonusState ={
    walletBonusForUpdate: undefined,
    isUpdateWalletBonus: false,
    walletBonusModalIsOpen: false,
    refreshTableWalletBonus: false,
}

export default function walletBonusReducer(state: IWalletBonusState = INITIAL_STATE, action: walletBonusType): IWalletBonusState {
    switch(action.type){
        case walletBonusActions.WALLET_BONUS_MODAL_STATE:
            return{
                ...state,
                walletBonusModalIsOpen: !state.walletBonusModalIsOpen
            }
        case walletBonusActions.GET_WALLET_BONUS_FOR_UPDATE:
            return{
                ...state,
                walletBonusForUpdate: action.payload
            }
        case walletBonusActions.IS_WALLET_BONUS_FOR_UPDATE:
            return{
                ...state,
                isUpdateWalletBonus: action.payload
            }
        case walletBonusActions.SWITCH_WALLET_BONUS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableWalletBonus: action.payload
            }
        default:
            return state
    }
}