import { ISinglePriceList, IPriceListState } from "../@types";
import { priceListActions } from "../action-types";

type priceListType =
| { type: typeof priceListActions.PRICE_LIST_MODAL_STATE }
| { type: typeof priceListActions.GET_PRICE_LISTS_FOR_UPDATE; payload: ISinglePriceList }
| { type: typeof priceListActions.IS_PRICE_LISTS_FOR_UPDATE; payload: boolean }
| { type: typeof priceListActions.SWITCH_PRICE_LISTS_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: IPriceListState ={
    priceListForUpdate: undefined,
    isModalPriceListOpen: false,
    isUpdatePriceList: false,
    refreshTablePriceList: false,
}

export default function priceListReducer(state: IPriceListState = INITIAL_STATE, action: priceListType): IPriceListState {
    switch(action.type){
        case priceListActions.PRICE_LIST_MODAL_STATE:
            return {
                ...state,
                isModalPriceListOpen: !state.isModalPriceListOpen
            }
        case priceListActions.GET_PRICE_LISTS_FOR_UPDATE:
            return{
                ...state,
                priceListForUpdate: action.payload
            }
        case priceListActions.IS_PRICE_LISTS_FOR_UPDATE:
            return{
                ...state,
                isUpdatePriceList: action.payload
            }
        case priceListActions.SWITCH_PRICE_LISTS_REFRESH_TABLE:
            return{
                ...state,
                refreshTablePriceList: action.payload
            }
        default:
            return state
    }
}