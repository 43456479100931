import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import EditStudentSchedulePaymentForm from "./EditStudentSchedulePaymentForm";
import useEditPaymentScheduleStudent from "../hooks/useEditPaymentScheduleStudent";

export default function EditStudentSchedulePayment({
  setOpenModal,
  program_student_id
}: {
  setOpenModal: (val: boolean) => void;
  program_student_id: number
}) {

  const { control, loading, handleSubmit } = useEditPaymentScheduleStudent({
    program_student_id: +program_student_id!,
    setOpenModal,
  });
  
  return (
    <form onSubmit={handleSubmit}>
      <EditStudentSchedulePaymentForm
        control={control}
      />
      <div className="mt-4 flex items-center justify-end">
        <Button
          htmlType="submit"
          style={{background: '#072082', color: 'white', borderRadius: 10, border: 'none', fontWeight: 'bold', padding: '0px 20px', height: 36}}
          loading={loading}
          disabled={loading}
        >
          <i className="fa-regular fa-floppy-disk" /> Crear
        </Button>
      </div>
    </form>
  );
}
