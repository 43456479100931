import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useListCustomerGroups,
  WrapperModalListCustomerGroups,
} from "../../../../modules";
import {
  selectListCustomerGroupsForUpdate,
  selectIsUpdateListCustomerGroups,
  selectModalListCustomerGroups,
} from "../../../../redux";
import { DataListCustomerGroups } from "../../../../redux/@types";
import { InputText } from "../inputs";

const { Title } = Typography;

export const ModalListCustomerGroups = () => {
  const modalListCustomerGroups = useSelector(selectModalListCustomerGroups);
  const isUpdateListCustomerGroups = useSelector(
    selectIsUpdateListCustomerGroups
  );
  const updateListCustomerGroups = useSelector(
    selectListCustomerGroupsForUpdate
  );

  const {
    isLoading,
    changeModalListCustomerGroupsState,
    onSubmitCreateOrUpdate,
  } = useListCustomerGroups();

  const { control, handleSubmit: onSubmit } =
    useFormContext<DataListCustomerGroups>();
  return (
    <WrapperModalListCustomerGroups
      title={
        isUpdateListCustomerGroups
          ? `Actualizando grupo: '${updateListCustomerGroups?.id!}'`
          : `Creando grupo`
      }
      open={modalListCustomerGroups}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalListCustomerGroupsState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText placeHolder="grupo numero 1" name="name" control={control} />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateListCustomerGroups ? "Actualizar información" : "Crear grupo"}
      </Button>
    </WrapperModalListCustomerGroups>
  );
};
