import { DataFulfillmentCompany, IFulfillmentCompanyState } from "../@types";
import { fulfillmentCompanyActions } from "../action-types";

type fulfillmentCompanyType =
| { type: typeof fulfillmentCompanyActions.FULFILLMENT_COMPANY_MODAL_STATE }
| { type: typeof fulfillmentCompanyActions.GET_FULFILLMENT_COMPANY_FOR_UPDATE; payload: DataFulfillmentCompany }
| { type: typeof fulfillmentCompanyActions.IS_FULFILLMENT_COMPANY_FOR_UPDATE; payload: boolean }
| { type: typeof fulfillmentCompanyActions.SWITCH_FULFILLMENT_COMPANY_REFRESH_TABLE; payload: boolean }
| { type: typeof fulfillmentCompanyActions.CHANGE_KEY_OF_FULFILLMENT_COMPANY; payload: number }


const INITIAL_STATE: IFulfillmentCompanyState ={
    fulfillmentCompanyForUpdate: undefined,
    isUpdateFulfillmentCompany: false,
    fulfillmentCompanyModalIsOpen: false,
    refreshTableFulfillmentCompany: false,
    activeKey: 0,
}

export default function fulfillmentCompanyReducer(state: IFulfillmentCompanyState = INITIAL_STATE, action: fulfillmentCompanyType): IFulfillmentCompanyState {
    switch(action.type){
        case fulfillmentCompanyActions.FULFILLMENT_COMPANY_MODAL_STATE:
            return{
                ...state,
                fulfillmentCompanyModalIsOpen: !state.fulfillmentCompanyModalIsOpen
            }
        case fulfillmentCompanyActions.GET_FULFILLMENT_COMPANY_FOR_UPDATE:
            return{
                ...state,
                fulfillmentCompanyForUpdate: action.payload
            }
        case fulfillmentCompanyActions.IS_FULFILLMENT_COMPANY_FOR_UPDATE:
            return{
                ...state,
                isUpdateFulfillmentCompany: action.payload
            }
        case fulfillmentCompanyActions.SWITCH_FULFILLMENT_COMPANY_REFRESH_TABLE:
            return{
                ...state,
                refreshTableFulfillmentCompany: action.payload
            }
        case fulfillmentCompanyActions.CHANGE_KEY_OF_FULFILLMENT_COMPANY:
            return{
                ...state,
                activeKey: action.payload,
            }
        default:
            return state
    }
}