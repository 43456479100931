import { Avatar, Col, Row, Typography } from "antd";
import { MailOutlined, PhoneOutlined ,IdcardOutlined } from "@ant-design/icons";

import { UserByOrder } from "../../../../redux/@types";
import { WrapperCardUserOrder } from "../styled";

const { Title } = Typography;

export interface ShippingProps {
  id: number;
  name: string;
  address: string;
  user_id: number;
  city_id: number;
  state_id: number;
  lat: null;
  lng: null;
  details: string;
  main: string;
  integration_nit: null;
  integration_codigo_direccion: null;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
  address_name: string;
  address_id: number;
  state_name: string;
  state_integration_id: string;
  city_name: string;
  city_integration_id: string;
  dane_code: string;
  fulfillment_company_id: number;
}
interface Props {
  dataUser: UserByOrder;
  shipping: ShippingProps;
}

export const InfoUserCard = ({ shipping, dataUser }: Props) => {
  return (
    <WrapperCardUserOrder>
      <Row justify="start" style={{ width: "100%" }}>
        <Col
          style={{
            display: "flex",
            // padding: 2.5,
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Avatar
            size={74}
            style={{
              background: "var(--text-color-blue-dark)",
              // padding: 9,
            }}
            src={`../../../../../public/avatar.png`}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            marginLeft: "5px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ margin: 0, color: "var(--primary)" }} level={4}>
            {`${dataUser.first_name} ${dataUser.last_name}`}
          </Title>
          <Title
            style={{
              margin: 0,
              color: "var(--text-color-grey)",
            }}
            level={5}
          >
            <MailOutlined /> {`${dataUser.email}`}
          </Title>
          <Title
            style={{
              margin: 0,
              color: "var(--text-color-grey)",
            }}
            level={5}
          >
            <PhoneOutlined /> {`${dataUser.phone}`}
          </Title>
          <Title
            style={{
              margin: 0,
              color: "var(--text-color-grey)",
            }}
            level={5}
          >
            <IdcardOutlined /> {`${dataUser.dni_type === "cedula" ? "cédula" : dataUser.dni_type} #${dataUser.dni}${dataUser.dni_type === "nit"? ` - ${dataUser.company_legal_name}`: ""}`}
          </Title>
        </Col>
      </Row>
      <Row justify="start" style={{ width: "100%" }}>
        <Col
          span={24}
          style={{
            display: "flex",
            marginLeft: "5px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ margin: "7px 0", color: "var(--primary)" }} level={4}>
            Datos de envío
          </Title>
        </Col>
        <Col
          style={{
            display: "flex",
            marginLeft: "5px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title
            style={{
              margin: 0,
              color: "var(--text-color-grey)",
            }}
            level={5}
          >{`${shipping.address}`}</Title>
           <Title
            style={{
              margin: 0,
              color: "var(--text-color-grey)",
            }}
            level={5}
          >{`Detalles: ${shipping.details}`}</Title>
          <Title
            style={{
              margin: 0,
              color: "var(--text-color-grey)",
            }}
            level={5}
          >
            {`${shipping.city_name}, ${shipping.state_name}`}
          </Title>
        </Col>
      </Row>
    </WrapperCardUserOrder>
  );
};
