
import { DataConfigurationCashback, IConfigurationCashbackState } from "../@types";
import { configurationCashbackActions } from "../action-types";


type configurationCashbackType =
| { type: typeof configurationCashbackActions.CONFIGURATION_CASHBACK_MODAL_STATE }
| { type: typeof configurationCashbackActions.GET_CONFIGURATION_CASHBACK_FOR_UPDATE; payload: DataConfigurationCashback }
| { type: typeof configurationCashbackActions.IS_CONFIGURATION_CASHBACK_FOR_UPDATE; payload: boolean }
| { type: typeof configurationCashbackActions.SWITCH_CONFIGURATION_CASHBACK_REFRESH_TABLE; payload: boolean }


const INITIAL_STATE: IConfigurationCashbackState ={
    configurationCashbackForUpdate: undefined,
    isUpdateConfigurationCashback: false,
    configurationCashbackModalIsOpen: false,
    refreshTableConfigurationCashback: false,
}

export default function configurationCashbackReducer(state: IConfigurationCashbackState = INITIAL_STATE, action: configurationCashbackType): IConfigurationCashbackState {
    switch(action.type){
        case configurationCashbackActions.CONFIGURATION_CASHBACK_MODAL_STATE:
            return{
                ...state,
                configurationCashbackModalIsOpen: !state.configurationCashbackModalIsOpen
            }
        case configurationCashbackActions.GET_CONFIGURATION_CASHBACK_FOR_UPDATE:
            return{
                ...state,
                configurationCashbackForUpdate: action.payload
            }
        case configurationCashbackActions.IS_CONFIGURATION_CASHBACK_FOR_UPDATE:
            return{
                ...state,
                isUpdateConfigurationCashback: action.payload
            }
        case configurationCashbackActions.SWITCH_CONFIGURATION_CASHBACK_REFRESH_TABLE:
            return{
                ...state,
                refreshTableConfigurationCashback: action.payload
            }
        default:
            return state
    }
}