import styled from 'styled-components'

export const WrappercolorFields = styled.div`
  & .color{
    background:${({ color }) => `${color || "#FFF"}`};
    height:40px;
    border:1px solid #ccc;
    width: "36px",
    height: "14px",
    borderRadius: "2px"
  }
  & .swatch{
    padding: "5px",
    background: "#fff",
    border-Radius: "1px",
    box-Shadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer"
  }
  & .popover: {
    position: "absolute",
    zIndex: "2"
  },
  & .cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  }
`;