// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button, Col, message, Typography } from "antd";
import { useSelector } from "react-redux";
import {
  useModalPriceLis,
  usePriceList,
  WrapperModalPriceList,
} from "../../../../modules";

import { selectModalPriceList } from "../../../../redux";
import { SimpleInputNumber } from "../inputs";
import { SimpleInputSelect } from "../inputs/SimpleInputSelect";

const { Title } = Typography;

interface Props {
  resFunction: (item: any) => void;
  dataFilter: Number[];
}

export const ModalPricesList = ({ resFunction, dataFilter }: Props) => {
  const modalPriceList = useSelector(selectModalPriceList);
  const { changeModalPriceListState } = usePriceList();
  const {
    products,
    valueSelector,
    valueNumber,
    valueSelectorName,
    setValueNumber,
    changeSelectValue,
  } = useModalPriceLis({ dataFilter });

  // console.log({ dataFilter });

  const addingProductList = () => {
    if (valueSelector === undefined)
      return message.error("Debe de seleccionar un producto");
    if (valueNumber === undefined)
      return message.error("Debe de escribir un precio para producto");
    changeModalPriceListState();
    return resFunction({ valueSelectorName, valueSelector, valueNumber });
  };

  return (
    <WrapperModalPriceList
      title={`Crea Precio`}
      open={modalPriceList}
      width={"320px"}
      destroyOnClose
      onClose={changeModalPriceListState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Producto a listar precio:
        </Title>
        <SimpleInputSelect
          showSearch
          valueSelect={valueSelector}
          onChange={changeSelectValue}
          label={"Seleccione un producto"}
          dataOptions={products}
          maxWidth="100%"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Precio:
        </Title>
        <SimpleInputNumber
          placeHolder="Precio"
          onChange={(val) => setValueNumber(val)}
          value={valueNumber}
          max={999999999999}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Button
          style={{
            width: "100%",
            marginTop: 10,
          }}
          type="primary"
          shape="round"
          size="large"
          onClick={addingProductList}
        >
          Agregar Producto
        </Button>
      </Col>
    </WrapperModalPriceList>
  );
};
