import UpdateProgram from "../../../../modules/academyPrograms/ui/programs/UpdateProgram";
import { WrapperModalUsers } from "./styled";


export const ModalEditProgram = ({openModal, setOpenModal, id}: {openModal: boolean, setOpenModal: (value: boolean)=>void, id: number}) => {

  return (
    <WrapperModalUsers
      title={`Editar programa`}
      open={openModal}
      width={"320px"}
      destroyOnClose
      onClose={()=>setOpenModal(false)}
    >
      <UpdateProgram programId={+id} setOpenModal={setOpenModal}/>
    </WrapperModalUsers>
  );
};
