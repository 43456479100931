import React, { useEffect, useState } from "react"; // Asegúrate de importar useState
import { Checkbox, Col, Select, Typography } from "antd";
import moment from "moment";
import { Control, Controller } from "react-hook-form";
import useFilter from "../../hooks/students/useFilterStudents";
import { CaUserAcademy, ProgramScheduleType, ProgramStudentType } from "../../../../types/dto/program";
import { useUsers } from "../../../users/hooks/useUsers";
import { usersService } from "../../../../shared/services";
import { formatNumberPrice } from "../../../../shared/utils/Helper";

const { Text } = Typography;
export default function AssignedStudentProgramForm({
  control,
  programId,
  programScheduleByProgram,
  setSelectedSchedules,
}: {
  control: Control<ProgramStudentType>;
  programId?: number;
  programScheduleByProgram?: ProgramScheduleType[];
  setSelectedSchedules?: React.Dispatch<
    React.SetStateAction<{ id: number; price: number }[]>
  >;
}) {
  const { studentWithoutPrograms, studentWithoutProgramsQuery } = useUsers();
  
  // Estado local para manejar los checkboxes
  const [checkedSchedules, setCheckedSchedules] = useState<{ id: number; price: number }[]>([]);

  useEffect(() => {
    studentWithoutProgramsQuery(+programId!);
  }, [programId]);

  const { query, setQuery, filteredData } = useFilter<CaUserAcademy>({
    service: usersService,
    initialData: studentWithoutPrograms,
    queryKey: "q",
    student_teacher: "student",
  });

  useEffect(() => {
    if (programScheduleByProgram) {
      // Inicializa el estado de los checkboxes marcándolos por defecto
      const initialCheckedSchedules = programScheduleByProgram.map(item => ({
        id: item.id,
        price: +item.price_with_prompt_payment_discount
      }));
      setCheckedSchedules(initialCheckedSchedules);
      if(setSelectedSchedules) {
        setSelectedSchedules(initialCheckedSchedules); // También actualiza el estado externo si es necesario
      }
    }
  }, [programScheduleByProgram, setSelectedSchedules]);

  const handleCheckboxChange = (
    scheduleId: number,
    price: number,
    isChecked: boolean
  ) => {
    if (setSelectedSchedules) {
      setSelectedSchedules((prev) =>
        isChecked
          ? [...prev, { id: scheduleId, price }]
          : prev.filter((schedule) => schedule.id !== scheduleId)
      );
    }
    // Actualiza el estado local de los checkboxes
    setCheckedSchedules((prev) => 
      isChecked
        ? [...prev, { id: scheduleId, price }]
        : prev.filter((schedule) => schedule.id !== scheduleId)
    );
  };

  return (
    <>
      {programScheduleByProgram && programScheduleByProgram.length > 0 ? (
        <div className="flex flex-col gap-2">
          <div>
            <label>
              <strong>Selecciona un estudiante</strong>
            </label>
            <Controller
              control={control}
              name="user_id"
              render={({ field, fieldState }) => (
                <div>
                  <Select
                    {...field}
                    showSearch
                    style={{ width: "100%", height: 30 }}
                    placeholder="Selecciona un estudiante"
                    optionFilterProp="label"
                    onSearch={(value) => setQuery(value)}
                    filterOption={false}
                    options={
                      filteredData.length > 0
                        ? filteredData.map((it) => ({
                            key: `${it.id}`,
                            value: `${it.id}`,
                            label: `${it.first_name} ${it.last_name}`,
                          }))
                        : [
                            {
                              key: "",
                              value: "",
                              label: "No hay estudiantes disponibles",
                            },
                          ]
                    }
                  />
                  {fieldState.error ? (
                    <span className="mt-1 block text-xs text-red-500">
                      {fieldState.error.message}
                    </span>
                  ) : null}
                </div>
              )}
            />
          </div>
          {programScheduleByProgram && (
            <div className="mt-3">
              <label>
                <strong>Asignación del cronograma de pago al estudiante</strong>
              </label>
              {programScheduleByProgram.map((item) => (
                <Col
                  key={item.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 15,
                  }}
                >
                  <Checkbox
                    checked={checkedSchedules.some(schedule => schedule.id === item.id)} // Marca el checkbox si está en el estado
                    onChange={(e) =>
                      handleCheckboxChange(
                        item.id,
                        +item.price_with_prompt_payment_discount,
                        e.target.checked
                      )
                    }
                  >
                    <strong>
                      {moment(item.month).format("MMMM YYYY").toUpperCase()}
                    </strong>
                    : {formatNumberPrice(+item.price_with_prompt_payment_discount)}
                  </Checkbox>
                </Col>
              ))}
            </div>
          )}
        </div>
      ) : (
        <Text>Primero debes crear el cronograma de pago del programa</Text>
      )}
    </>
  );
}
