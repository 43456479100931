import { Button, Col } from "antd";
import {  EditOutlined } from "@ant-design/icons";

import Table from "../../../shared/components/ui/Table"
import { systemVariablesService } from "../../../shared/services";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { DataSystemVariables } from "../../../redux/@types";
import { useTableSystemVariables } from '../hook/useTableSystemVariables';
import { useSystemVariables } from "../hook";

export const TableSystemVariables = () => {
  const { changeSystemVariablesForUpdate } =
    useTableSystemVariables();
  const { setTableProps } = useSystemVariables();
  const columns: TableColumns<DataSystemVariables> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Nombre",
      width: 9,
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "Clave",
      width: 9,
      dataIndex: "key",
      key: "key",
      filterType: "text",
    },
    {
      title: "Valor",
      width: 9,
      dataIndex: "value",
      key: "value",
      filterType: "text",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 4,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeSystemVariablesForUpdate(item as any)}
              icon={<EditOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <Table<DataSystemVariables>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={systemVariablesService}
      onLoad={setTableProps}
    />
  )
}
