import { Select } from "antd";

const { Option } = Select;

interface Props {
  dataOptions: { value: string | number; title: string }[];
  width?: string;
  maxWidth?: string;
  label: string;
  valuesSelect: string[];
  onChange: (val: string[]) => void;
  showSearch?: boolean;
  clearValue: (val: string) => void;
}

export const SimpleInputSelectMultiple = ({
  width = "100%",
  maxWidth = "100%",
  dataOptions,
  label,
  valuesSelect,
  showSearch = false,
  clearValue,
  onChange,
}: Props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Select
        mode="multiple"
        value={valuesSelect}
        showSearch={showSearch ? true : false}
        style={{ width, maxWidth }}
        placeholder={label}
        onChange={onChange}
        optionLabelProp="label"
        onDeselect={clearValue}
      >
        {dataOptions.map((item, idx) => (
          <Option key={idx + ""} value={item.value} label={item.title}>
            <div className="demo-option-label-item">
              {`${item.title} (${item.value})`}
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
};
