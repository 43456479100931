import { Login } from "../modules";
import { FormProvider } from "react-hook-form";
import { useLogin } from "../modules/login/hooks/useLogin";

export const LoginPage = () => {
  const { formsMethods } = useLogin();

  return (
    <FormProvider {...formsMethods}>
      <Login />
    </FormProvider>
  );
};
