import { TRootState } from "../@types"

export const selectModalListCustomerGroups = ( state: TRootState ) =>state.listCustomerGroups.listCustomerGroupsModalIsOpen

export const selectIsUpdateListCustomerGroups = ( state: TRootState ) =>state.listCustomerGroups.isUpdateListCustomerGroups

export const selectListCustomerGroupsForUpdate = ( state: TRootState ) =>state.listCustomerGroups.listCustomerGroupsForUpdate

export const selectListCustomerGroupsRefreshTable =  ( state: TRootState ) =>state.listCustomerGroups.refreshTableListCustomerGroups

