import { ICoursesCategoriesState } from "../@types";
import { coursesCategoriesActions } from "../action-types";
import { DataCoursesCategories } from '../@types/coursesCategories';

type coursesCategoriesType =
| { type: typeof coursesCategoriesActions.COURSES_CATEGORIES_MODAL_STATE }
| { type: typeof coursesCategoriesActions.GET_COURSES_CATEGORIES_FOR_UPDATE; payload: DataCoursesCategories }
| { type: typeof coursesCategoriesActions.IS_COURSES_CATEGORIES_FOR_UPDATE; payload: boolean }
| { type: typeof coursesCategoriesActions.SWITCH_COURSES_CATEGORIES_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: ICoursesCategoriesState ={
    coursesCategoriesForUpdate: undefined,
    isUpdateCoursesCategories: false,
    coursesCategoriesModalIsOpen: false,
    refreshTableCoursesCategories: false,
}

export default function coursesCategoriesReducer(state: ICoursesCategoriesState = INITIAL_STATE, action: coursesCategoriesType): ICoursesCategoriesState {
    switch(action.type){
        case coursesCategoriesActions.COURSES_CATEGORIES_MODAL_STATE:
            return{
                ...state,
                coursesCategoriesModalIsOpen: !state.coursesCategoriesModalIsOpen
            }
        case coursesCategoriesActions.GET_COURSES_CATEGORIES_FOR_UPDATE:
            return{
                ...state,
                coursesCategoriesForUpdate: action.payload
            }
        case coursesCategoriesActions.IS_COURSES_CATEGORIES_FOR_UPDATE:
            return{
                ...state,
                isUpdateCoursesCategories: action.payload
            }
        case coursesCategoriesActions.SWITCH_COURSES_CATEGORIES_REFRESH_TABLE:
            return{
                ...state,
                refreshTableCoursesCategories: action.payload
            }
        default:
            return state
    }
}