import { DataCities, ICitiesState } from "../@types";
import { citiesActions } from "../action-types";


type citiesActionType =
| { type: typeof citiesActions.CITIES_MODAL_STATE }
| { type: typeof citiesActions.GET_CITY_FOR_UPDATE; payload: DataCities }
| { type: typeof citiesActions.IS_CITIES_FOR_UPDATE; payload: boolean }
| { type: typeof citiesActions.SWITCH_CITIES_REFRESH_TABLE; payload: boolean }
| { type: typeof citiesActions.GET_STATES_CITIES; payload: DataCities[] }

const INITIAL_STATE: ICitiesState = {
    citiesForUpdate: undefined,
    isUpdateCity: false,
    citiesModalIsOpen: false,
    refreshTableCities: false,
    arrState: [],
}

export default function citiesReducer(state: ICitiesState = INITIAL_STATE, action: citiesActionType): ICitiesState {
    switch(action.type){
        case citiesActions.CITIES_MODAL_STATE:
            return{
                ...state,
                citiesModalIsOpen: !state.citiesModalIsOpen
            }
        case citiesActions.GET_CITY_FOR_UPDATE:
            return{
                ...state,
                citiesForUpdate: action.payload
            }
        case citiesActions.IS_CITIES_FOR_UPDATE:
            return{
                ...state,
                isUpdateCity: action.payload
            }
        case citiesActions.SWITCH_CITIES_REFRESH_TABLE:
            return{
                ...state,
                refreshTableCities: action.payload
            }
        case citiesActions.GET_STATES_CITIES:
            return{
                ...state,
                arrState: action.payload
            }
        default:
            return state
    }
}
