"use client";

import { Spin } from "antd";
import Button from "../../../../shared/components/ui/Button";
import { ProgramType } from "../../../../types/dto/program";
import useCreateDuplicatePrograms from "../../hooks/programs/useCreateDuplicatePrograms";
import useCreatePrograms from "../../hooks/programs/useCreatePrograms";
import useProgram from "../../hooks/programs/usePrograms";
import useProgramList from "../../hooks/programs/useProgrmaList";
import DuplicateProgramForm from "./DuplicateProgramForm";
import ProgramForm from "./ProgramForm";

export interface CreateProgramProps {
  setOpenModal: (val: boolean) => void;
  duplicateProgram: boolean;
}

export default function CreateProgram({
  setOpenModal,
  duplicateProgram,
}: CreateProgramProps) {
  const {programs} = useProgramList({duplicateProgram})
  const { control, loading, handleSubmit, watch, setValue } = useCreatePrograms(
    {
      setOpenModal,
    }
  );

  const { control: controlDuplicate, loading: loadingDuplicate, handleSubmit: handleSubmitDuplicate } = useCreateDuplicatePrograms(
    {
      setOpenModal,
    }
  );

  return (
    <form onSubmit={duplicateProgram ? handleSubmitDuplicate : handleSubmit}>
      {duplicateProgram ? (<>
        <DuplicateProgramForm
          control={controlDuplicate}
          programs={programs}
        />
        {loadingDuplicate && <Spin style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}/>}
          </>
      ) : (
        <ProgramForm control={control} watch={watch} setValue={setValue} />
      )}
      <div className="mt-4 flex items-center justify-end">
        <Button
          primary
          className="bg-[#072082]"
          loading={loading}
          type="submit"
          variant="filled"
        >
          {duplicateProgram ? "Duplicar" : "Crear"}
        </Button>
      </div>
    </form>
  );
}
