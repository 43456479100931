import {  contactsDirectoryMedia, contactsDirectoryService } from "../../shared/services"
import { DataCities, DataContactsDirectory, DataDirectoryCategories, IDataForUpdateContactsDirectory } from "../@types"
import { contactsDirectoryActions } from "../action-types"

export const contactsDirectoryChangeModalState = (): contactsDirectoryActions.IContactsDirectoryModalState =>({
    type: contactsDirectoryActions.CONTACTS_DIRECTORY_MODAL_STATE
})

export const setContactsDirectoryForUpdate = (val: IDataForUpdateContactsDirectory): contactsDirectoryActions.IGetContactsDirectoryForUpdate =>({
    type: contactsDirectoryActions.GET_CONTACTS_DIRECTORY_FOR_UPDATE,
    payload: val
})

export const setIsContactsDirectoryForUpdate = (val: boolean): contactsDirectoryActions.IIsContactsDirectoryForUpdate =>({
    type: contactsDirectoryActions.IS_CONTACTS_DIRECTORY_FOR_UPDATE,
    payload: val
})

export const createContactsDirectory = async(data: Partial<DataContactsDirectory>) =>{
    return await contactsDirectoryService.create(data)
    .then((res: DataContactsDirectory) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateContactsDirectory =async(data: Partial<DataContactsDirectory>) =>{
    return await contactsDirectoryService.patch(data?.id!, data)
    .then((res: DataContactsDirectory) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteContactsDirectory = async(id: number) => {
    return await contactsDirectoryService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

// export const updateOrCreateAttributes = async(val: { name: string, id?: number, contact_directory_id: number }) =>{
//     if(val.id){
//         return await contactsDirectoryAttributes.patch(val?.id!, {name: val.name, contact_directory_id: val.contact_directory_id})
//         .then((res: any) => {return res})
//         .catch((err: any) => { return err.message })
//     }else{
//         console.log(val.contact_directory_id, val.name);
//         return await contactsDirectoryAttributes.create({name: val.name, contact_directory_id: val.contact_directory_id})
//         .then((res: any) => {return res})
//         .catch((err: any) => { return err.message })
//     }
// }

export const updateOrCreateMedia = async(val: { path: string, id?: number, contact_directory_id: number }) =>{
    if(val.id){
        return await contactsDirectoryMedia.patch(val?.id!, {path: val.path, contact_directory_id: val.contact_directory_id})
        .then((res: any) => {return res})
        .catch((err: any) => { return err.message })
    }else{
        return await contactsDirectoryMedia.create({path: val.path, contact_directory_id: val.contact_directory_id})
        .then((res: any) => {return res})
        .catch((err: any) => { return err.message })
    }
}

export const refreshTableContactsDirectory = (val: boolean): contactsDirectoryActions.ISwitchContactsDirectoryRefreshTable =>({
    type: contactsDirectoryActions.SWITCH_CONTACTS_DIRECTORY_REFRESH_TABLE,
    payload: val
})

export const getCitiesForContactsDirectory = (val: DataCities[]): contactsDirectoryActions.IGetCitiesContactsDirectory =>({
    type: contactsDirectoryActions.GET_CITIES_CONTACTS_DIRECTORY,
    payload: val
})

export const getCategoriesContactsDirectory = (val: DataDirectoryCategories[]): contactsDirectoryActions.IGetCategoriesContactsDirectory =>({
    type: contactsDirectoryActions.GET_CATEGORIES_CONTACTS_DIRECTORY,
    payload: val
})