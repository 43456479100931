// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { usersService } from "../../shared/services";
import { IUserResFind } from "../@types";
import { userActions } from "../action-types";


export const userChangeModalState = (): userActions.IUserChangeModalState => ({
    type: userActions.USER_CHANGE_MODAL_STATE
})

export const setUserForUpdate = ( val: IUserResFind ): userActions.IGetUserForUpdate =>({
    type: userActions.GET_USER_FOR_UPDATE,
    payload: val
})

export const setIsUserForUpdate = (val: boolean ): userActions.IIsUserForUpdate => ({
    type: userActions.IS_USER_FOR_UPDATE,
    payload: val
})

export const createUser =async( data: Partial<IUserResFind> ) => {
    return await usersService.create(data)
    .then((res: IUserResFind) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateUser = async(data:Partial<IUserResFind>) => {
    return  await usersService.patch(data?.id!, data)
    .then((res: any) => {
        // console.log({res});
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteUser = async (id: number ) =>{
    return  await usersService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableUsers =( val: boolean ): userActions.ISwitchUsersRefreshTable =>({
    type: userActions.SWITCH_USERS_REFRESH_TABLE,
    payload: val
})