import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { AdminMenuLayout } from "../../../shared/components";
import { WrapperCardPriceList } from "./styled";
import { TablePriceList } from "./TablePriceList";
import { usePriceList } from "../hooks";

export const PriceList = () => {
  const { changeCreatePriceListState } = usePriceList();
  return (
    <AdminMenuLayout pageDescription="PriceList" title="PriceList">
      <WrapperCardPriceList>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeCreatePriceListState}
            >
              Agregar nueva lista de precio
            </Button>
          </Col>
        </Row>
        <TablePriceList />
      </WrapperCardPriceList>
    </AdminMenuLayout>
  );
};
