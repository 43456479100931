import * as yup from 'yup'

export const validateUpdateCoursesCategories = yup.object().shape({
    name:yup
    .string()
    .min(2, 'El NOMBRE debe tener más de 2 caracteres'),
    status:yup
    .string()
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
    position:yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    path_imagen:yup
    .string()
    .min(2, 'La IMAGEN debe tener más de 2 caracteres'),
})