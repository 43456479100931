// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { InputNumber as InputNumberAnt, message } from "antd";
import { useEffect } from "react";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import { InputError } from "./styled";

interface Props {
  width?: string;
  placeHolder: string;
  name: string;
  control: any;
  stringMode?: boolean;
  min?: boolean;
  max?: number
  isDisable?: boolean;
}

interface PropsContent {
  errors: Partial<FieldErrorsImpl<{
    [x: string]: any;
  }>>;
  name: string;
}

const ErrorComponent = ({errors,name}:PropsContent) =>{
  useEffect(() => {
    if(errors?.[name]?.message){
      message.error(`${errors?.[name]?.message}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])
  return(
    <>
    {!!errors[name] && (
      <InputError>{errors?.[name]?.message}</InputError>
      )}
      </>
  )
}

export const InputNumber = ({
  width = "100%",
  placeHolder,
  name,
  control,
  stringMode = false,
  min = false,
  max,
  isDisable = false,
}: Props) => {
  return (
    <Controller
      shouldUnregister
      control={control}
      name={name}
      render={({ field: { onChange, value = "" }, formState: { errors } }) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width,
              height: "80%",
              flexDirection: "column",
            }}
          >
            <InputNumberAnt
              disabled={isDisable}
              style={{ width: "100%" }}
              min={min ? -99999999 : 0}
              max={max ? max : 99999999999999999}
              status={errors[name] ? "error" : ""}
              value={value}
              placeholder={placeHolder}
              onChange={onChange}
              stringMode={stringMode}
            />
            <ErrorComponent errors={errors} name={name}/>
          </div>
        );
      }}
    />
  );
};
