import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";

import {
  ContactsDirectoryForUpdate,
  useContactsDirectory,
} from "../modules/contactsDirectory";
import { selectModalContactsDirectory } from "../redux";

export const DirectoryCategoriesByIdPage = () => {
  const { isUpdateContactsDirectory, formMethodsUpdate } =
    useContactsDirectory();
  return (
    <>
      <FormProvider {...formMethodsUpdate}>
        <ContactsDirectoryForUpdate />
      </FormProvider>
    </>
  );
};
