import { getService } from "../../../../clients/feathers.clients";
import { PaginatedResponse, ProgramModuleType } from "../../../../types/dto/program";

export default function useSaveSortedProgramModule() {
  const service = getService("ca-program-modules");

  return (sortedPrograms: { id: string; position: number }[]) =>
    service.patch(null, sortedPrograms, {
      query: {
        skipJoin: true,
        $client: {
          saveSort: "true"
        }
      }
    })
    .then((res: PaginatedResponse) => res.data as Pick<ProgramModuleType, "id" | "position">[]);
}
