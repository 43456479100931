import * as React from 'react';
import useDebounce from '../../../academyProgramDetail/hooks/students/useDebounce';

type UseFilterProps<T> = {
  service: any; // Reemplaza con el tipo adecuado para tu servicio
  initialData: T[];
  queryKey: string;
  debounceDelay?: number;
  params: string;
  parasValue: number | string;
  skip: boolean;
};

export default function useFilterProgramStudent<T>({
  service,
  initialData,
  queryKey,
  debounceDelay = 300,
  params,
  parasValue,
  skip
}: UseFilterProps<T>) {
  const [data, setData] = React.useState<T[]>(initialData);
  const [query, setQuery] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<T[]>(initialData);
  const debouncedQuery = useDebounce(query, debounceDelay);

  React.useEffect(() => {
    // Cargar data inicial
    setFilteredData(initialData);
  }, [initialData]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (debouncedQuery || parasValue) {
        try {
          const queryObj: any = {
            [queryKey]: debouncedQuery,
            [params]: parasValue
          };

          if (skip) {
            queryObj.skipJoin = true;
          }

          const response = await service.find({ query: queryObj });
          setData(response?.data || []);
        } catch (err) {
          console.error('Error fetching data:', err);
        }
      } else {
        setData(initialData);
      }
    };

    fetchData();
  }, [debouncedQuery, initialData, queryKey, service, params, parasValue, skip]);

  React.useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return {
    query,
    setQuery,
    filteredData
  };
}
