import { useEffect, useState } from "react";
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  courseBenefitsChangeModalState,
  createCourseBenefits,
  deleteCourseBenefits,
  refreshTableCourseBenefits,
  selectCourseBenefitsForUpdate,
  selectCourseBenefitsRefreshTable,
  selectIsUpdateCourseBenefits,
  setCourseBenefitsForUpdate,
  setIsCourseBenefitsForUpdate,
  updateCourseBenefits,
} from "../../../../redux";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../../shared/components/ui/Table/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { DataCourseBenefits } from "../../../../redux/@types";
import {
  validateCreateCourseBenefits,
  validateUpdateCourseBenefits,
} from "../../../../helpers";

const { confirm } = Modal;

export const useCourseBenefits = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const isUpdateCourseBenefits = useSelector(selectIsUpdateCourseBenefits);
  const dataUpdateCourseBenefits = useSelector(selectCourseBenefitsForUpdate);
  const isRefreshTableCourseBenefits = useSelector(
    selectCourseBenefitsRefreshTable
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalCourseBenefitsState = () => {
    dispatch(setIsCourseBenefitsForUpdate(false));
    dispatch(courseBenefitsChangeModalState());
  };

  const formMethodsCreate = useForm<DataCourseBenefits>({
    resolver: yupResolver(validateCreateCourseBenefits),
  });

  const formMethodsUpdate = useForm<DataCourseBenefits>({
    resolver: yupResolver(validateUpdateCourseBenefits),
  });

  useEffect(() => {
    if (isUpdateCourseBenefits) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateCourseBenefits });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCourseBenefits, dataUpdateCourseBenefits]);

  const onSubmitCreateOrUpdate = async (data: DataCourseBenefits) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCourseBenefits) {
      //console.log("Actualiando===>", data);
      await updateCourseBenefits({
        id: data.id,
        name: data.name,
        position: data.position,
      })
        .then((res: Partial<DataCourseBenefits>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            dispatch(courseBenefitsChangeModalState());
            message.success(
              `Se ha actualizado con éxito el beneficio de curso con id:${res.id}`
            );
            dispatch(refreshTableCourseBenefits(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createCourseBenefits({
        ...data,
        course_id: Number(pathname.split("/")[2]),
      })
        .then((res: DataCourseBenefits) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(courseBenefitsChangeModalState());
            message.success(
              `Se ha creado con éxito el beneficio de curso con id:${res.id}`
            );
            dispatch(refreshTableCourseBenefits(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCourseBenefits) {
      tableProps?.refresh();
      dispatch(refreshTableCourseBenefits(false));
    }
  }, [isRefreshTableCourseBenefits]);

  const changeCourseBenefitsForUpdate = (val: DataCourseBenefits) => {
    dispatch(setIsCourseBenefitsForUpdate(true));
    dispatch(setCourseBenefitsForUpdate(val));
    dispatch(courseBenefitsChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el beneficio con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar beneficio" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar beneficio",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteCourseBenefits(valId)
          .then(() => {
            dispatch(refreshTableCourseBenefits(true));
            message.success(
              `Se ha borrado con éxito el beneficio con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    isUpdateCourseBenefits,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalCourseBenefitsState,
    changeCourseBenefitsForUpdate,
    showDeleteConfirm,
  };
};
