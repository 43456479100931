import { DataDirectoryCategories } from "../@types"

export const DIRECTORY_CATEGORIES_MODAL_STATE = '[ DIRECTORY CATEGORIES ] Directory Categories Modal State'

export interface IDirectoryCategoriesModalState {
    type: typeof DIRECTORY_CATEGORIES_MODAL_STATE
}

export const GET_DIRECTORY_CATEGORIES_FOR_UPDATE = '[ DIRECTORY CATEGORIES ] Get Directory Categories For Update'

export interface IGetDirectoryCategoriesForUpdate {
    type: typeof GET_DIRECTORY_CATEGORIES_FOR_UPDATE
    payload: DataDirectoryCategories
}

export const IS_DIRECTORY_CATEGORIES_FOR_UPDATE ='[ DIRECTORY CATEGORIES ] Is Directory Categories For Update'

export interface IIsDirectoryCategoriesForUpdate {
    type: typeof IS_DIRECTORY_CATEGORIES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_DIRECTORY_CATEGORIES_REFRESH_TABLE = '[ DIRECTORY CATEGORIES ] Switch Directory Categories Refresh Table'

export interface ISwitchDirectoryCategoriesRefreshTable{
    type: typeof SWITCH_DIRECTORY_CATEGORIES_REFRESH_TABLE
    payload: boolean
}
