// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { productCategoriesService } from "../../shared/services";
import { IParentId, IProductCategory } from "../@types";
import { productCategoriesActions } from "../action-types";

export const productCategoriesChangeModalState = (): productCategoriesActions.IProductCategoriesChangeModalState => ({
    type: productCategoriesActions.PRODUCT_CATEGORIES_CHANGE_MODAL_STATE
})

export const setProductCategoriesForParent = (val: IProductCategory): productCategoriesActions. IGetProductCategoriesForParent => ({
    type: productCategoriesActions.GET_PRODUCT_CATEGORIES_FOR_PARENT,
    payload: val
})

export const setProductCategoriesForUpdate = (val: IProductCategory): productCategoriesActions. IGetProductCategoriesForUpdate => ({
    type: productCategoriesActions.GET_PRODUCT_CATEGORIES_FOR_UPDATE,
    payload: val
})

export const setIsProductCategoriesForUpdate = (val: boolean): productCategoriesActions.IIsProductCategoriesForUpdate =>({
    type: productCategoriesActions.IS_PRODUCT_CATEGORIES_FOR_UPDATE,
    payload: val
})

export const createProductCategories = async(data: Partial<IProductCategory>) => {
    return productCategoriesService.create(data)
    .then((res: IProductCategory) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateProductCategories = async(data:Partial<IProductCategory>) => {
    return await productCategoriesService.patch(data?.id!, data)
    .then((res: IProductCategory) => {
        // console.log({res});
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteProductCategories = async (id: number ) =>{
    return  await productCategoriesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProductCategories =( val: boolean ): productCategoriesActions.ISwitchProductCategoriesRefreshTable=>({
    type: productCategoriesActions.SWITCH_PRODUCT_CATEGORIES_REFRESH_TABLE,
    payload: val
})


export const getParentsId = async () => {
    return await productCategoriesService.find({
      query: {
        $select: ["id", "name"],
        $limit: 9999999999999,
      },
    })
    .then((res)=>{ return res.data})
    .catch((err: any) => { return err.message })
};

export const setParentIdArray =(val:IParentId[]):productCategoriesActions.IGetParentsIdProductCategories =>({
    type: productCategoriesActions.GET_PARENTS_ID_PRODUCT_CATEGORIES,
    payload: val
})

export const getRelationSon = async(val: number)=>{
    return await productCategoriesService.find({
        query: {
            $limit: 9999999999999,
            parent_id: val
        },
    })
    .then((res)=>{ return res.data})
    .catch((err: any) => { return err.message })
}