import { TRootState } from "../@types"

export const selectModalCourses = ( state: TRootState ) =>state.courses.coursesModalIsOpen

export const selectIsUpdateCourses = ( state: TRootState ) =>state.courses.isUpdateCourses

export const selectCoursesForUpdate = ( state: TRootState ) =>state.courses.coursesForUpdate

export const selectCoursesRefreshTable =  ( state: TRootState ) =>state.courses.refreshTableCourses

export const selectArrProductsForCourses = ( state: TRootState ) =>state.courses.arrProducts

// export const selectObjCitiesForCourses = ( state: TRootState ) =>state.courses.objCities

export const selectArrCategoriesForCourses = ( state: TRootState ) =>state.courses.arrCategories

//courseSections

export const selectModalCourseSections = ( state: TRootState ) =>state.courses.courseSectionsModalIsOpen

export const selectIsUpdateCourseSections = ( state: TRootState ) =>state.courses.isUpdateCourseSections

export const selectCourseSectionsForUpdate = ( state: TRootState ) =>state.courses.courseSectionsForUpdate

export const selectCourseSectionsRefreshTable =  ( state: TRootState ) =>state.courses.refreshTableCourseSections

//courseChapters

export const selectModalCourseChapters = ( state: TRootState ) =>state.courses.courseChaptersModalIsOpen

export const selectIsUpdateCourseChapters = ( state: TRootState ) =>state.courses.isUpdateCourseChapters

export const selectCourseChaptersForUpdate = ( state: TRootState ) =>state.courses.courseChaptersForUpdate

export const selectCourseChaptersRefreshTable =  ( state: TRootState ) =>state.courses.refreshTableCourseChapters

export const selectArrSectionsForCourses = ( state: TRootState ) =>state.courses.arrSections

//courseBenefits

export const selectModalCourseBenefits = ( state: TRootState ) =>state.courses.courseBenefitsModalIsOpen

export const selectIsUpdateCourseBenefits = ( state: TRootState ) =>state.courses.isUpdateCourseBenefits

export const selectCourseBenefitsForUpdate = ( state: TRootState ) =>state.courses.courseBenefitsForUpdate

export const selectCourseBenefitsRefreshTable =  ( state: TRootState ) =>state.courses.refreshTableCourseBenefits