import { DataTax } from '../../../redux/@types';
import { setTaxForUpdate, taxChangeModalState } from '../../../redux/actions/tax.actions';
import useAppDispatch from '../../../redux/hooks/useAppDispatch'

export const useTableTax = () => {
    const dispatch = useAppDispatch();
    const changeTaxForUpdate = ( item: DataTax) => {
        dispatch(setTaxForUpdate(item))
        dispatch(taxChangeModalState())
    }

    return {
        //state
        //methods
        //functions
        changeTaxForUpdate,
  }
}
