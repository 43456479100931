/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";
import useProgramModule from "../../../../academyPrograms/hooks/module/useCreateProgramsModule";

import ProgramModuleForm from "./ProgramModuleForm";
import { ProgramModuleType } from "../../../../../types/dto/program";
import Button from "../../../../../shared/components/ui/Button";

export interface CreateProgramModuleProps {
  programId: number;
  onSuccess?: (programModule: ProgramModuleType) => void;
}

export default function CreateProgramModule({programId, onSuccess}: CreateProgramModuleProps) {
  const initialData = React.useMemo(() => {
    return {
      program_id: programId,
    };
  }, [programId]);

  const {control, loading, handleSubmit} = useProgramModule({
    initialData,
    onSuccess(data) {
      onSuccess?.(data);
    },
  });

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ProgramModuleForm control={control} />
        <div className="mt-2 flex items-center justify-end">
          <Button primary  className="bg-[#072082]" loading={loading} type="submit" variant="filled">
            <i className="fa-solid fa-save" /> Crear
          </Button>
        </div>
      </form>
    </div>
  );
}
