import type {UniqueIdentifier} from "@dnd-kit/core";
import type {ReactNode} from "react";

import React from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";

import Item from "./Item";

export default function SortableItem(props: {
  id: UniqueIdentifier;
  children: ReactNode;
  customActivator?: boolean;
}) {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id: props.id,
    transition: {
      duration: 150, // milliseconds
      easing: "cubic-bezier(0.25, 1, 0.5, 1)",
    },
  });

  const style: React.CSSProperties = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    transform: transform ? CSS.Translate.toString(transform) : undefined,
    transition,
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...(!props.customActivator ? listeners : {})}
    >
      {props.customActivator
        ? React.Children.map(props.children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                ...child.props,
                dragListeners: listeners,
              } as React.HTMLAttributes<HTMLElement>);
            }

            return child;
          })
        : props.children}
    </Item>
  );
}
