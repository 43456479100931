import { listCustomerGroupsService } from "../../shared/services"
import { DataListCustomerGroups } from "../@types"
import { listCustomerGroupsActions } from "../action-types"

export const listCustomerGroupsChangeModalState = (): listCustomerGroupsActions.IListCustomerGroupsModalState =>({
    type: listCustomerGroupsActions.LIST_CUSTOMER_GROUPS_MODAL_STATE
})

export const setListCustomerGroupsForUpdate = (val: DataListCustomerGroups): listCustomerGroupsActions.IGetListCustomerGroupsForUpdate =>({
    type: listCustomerGroupsActions.GET_LIST_CUSTOMER_GROUPS_FOR_UPDATE,
    payload: val
})

export const setIsListCustomerGroupsForUpdate = (val: boolean): listCustomerGroupsActions.IIsListCustomerGroupsForUpdate =>({
    type: listCustomerGroupsActions.IS_LIST_CUSTOMER_GROUPS_FOR_UPDATE,
    payload: val
})

export const createListCustomerGroups = async(data: Partial<DataListCustomerGroups>) =>{
    return await listCustomerGroupsService.create(data)
    .then((res: DataListCustomerGroups) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateListCustomerGroups =async(data: Partial<DataListCustomerGroups>) =>{
    return await listCustomerGroupsService.patch(data?.id!, data)
    .then((res: DataListCustomerGroups) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteListCustomerGroups = async(id: number) => {
    return await listCustomerGroupsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableListCustomerGroups = (val: boolean): listCustomerGroupsActions.ISwitchListCustomerGroupsRefreshTable =>({
    type: listCustomerGroupsActions.SWITCH_LIST_CUSTOMER_GROUPS_REFRESH_TABLE,
    payload: val
})