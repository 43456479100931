import { Controller, type Control } from "react-hook-form";
import {
  ProgramPaymentType,
  ProgramScheduleType,
  ProgramStudentType,
} from "../../../../types/dto/program";
import { Input } from "../../../../shared/components/ui/form/inputs";
import moment from "moment";
import { Select } from "antd";
import { programsStudentAcademy } from "../../../../shared/services";
import useFilterProgramStudent from "../../hooks/programStudent/useFilterProgramStudent";

export default function PayStudentProgramFromStudentViewForm({
  control,
  programScheduleByProgram,
  programStudentByUserIdData,
  userId
}: {
  control?: Control<ProgramPaymentType>;
  programScheduleByProgram: ProgramScheduleType[];
  programStudentByUserIdData: ProgramStudentType[];
  userId: number
}) {
 
  const { query, setQuery, filteredData } = useFilterProgramStudent<ProgramStudentType>({
    service: programsStudentAcademy,
    initialData: programStudentByUserIdData,
    queryKey: 'q',
    params: 'user_id',
    parasValue: userId,
    skip: true
  });
  
  return (
    <div className="flex flex-col gap-3">
       <Controller
        control={control}
        name="program_id"
        render={({ field, fieldState }) => (
          <div>
            <label>Selecciona un programa</label>
            <Select
              {...field}
              showSearch
              style={{ width: 270 }}
              placeholder="Selecciona el programa"
              optionFilterProp="label"
              onSearch={(value) => setQuery(value)}
              options={
                filteredData?.length > 0
                  ? filteredData.map((it) => ({
                      key: `${it.program_id}`,
                      value: `${it.program_id}`,
                      label: it.program.name,
                    }))
                  : [
                      {
                        key: "",
                        value: "",
                        label: "No hay programas disponibles",
                      },
                    ]
              }
            />
            {fieldState.error ? (
              <span className="mt-1 block text-xs text-red-500">
                {fieldState.error.message}
              </span>
            ) : null}
          </div>
        )}
      />
    
    <Controller
        control={control}
        name="program_payment_schedule_id"
        render={({ field, fieldState }) => (
          <div>
            <label>Selecciona la fecha de pago</label>
            <Select
              {...field}
              showSearch
              style={{ width: 270 }}
              placeholder="Selecciona la fecha de pago"
              optionFilterProp="label"
              options={
                programScheduleByProgram?.length > 0
                  ? programScheduleByProgram.map((it) => ({
                      key: `${it.id}`,
                      value: `${it.id}`,
                      label: `${moment(it.month).format('YYYY / MMM')}`,
                    }))
                  : [
                      {
                        key: "",
                        value: "",
                        label: "",
                      },
                    ]
              }
            />
            {fieldState.error ? (
              <span className="mt-1 block text-xs text-red-500">
                {fieldState.error.message}
              </span>
            ) : null}
          </div>
        )}
      />
      <Input
        style={{ background: "none" }}
        control={control}
        label="Monto"
        name="amount"
        placeholder="100000"
        type="number"
      />
      <Input
        style={{ background: "none" }}
        control={control}
        label="Fecha de pago"
        name="payment_date"
        placeholder="100000"
        type="date"
      />
    </div>
  );
}
