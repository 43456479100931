import { Controller, type Control } from 'react-hook-form';
import { Select, Input, DatePicker } from 'antd';
import { CaUserAcademy, ProgramPaymentType, ProgramStudentType } from '../../../../types/dto/program';
import { usersService } from '../../../../shared/services'; // Ajusta según la ubicación de tu servicio
import useFilter from '../../hooks/students/useFilterStudents';
import moment from 'moment';

export default function ProgramPaymentsForm({
  control,
  dataUser,
  minAmount
}: {
  control: Control<ProgramPaymentType>;
  programStudent?: ProgramStudentType[];
  dataUser: CaUserAcademy[];
  minAmount: number
}) {

  const { query, setQuery, filteredData } = useFilter<CaUserAcademy>({
    service: usersService,
    initialData: dataUser,
    queryKey: 'q',
    student_teacher: 'student'
  });

  const disabledDate = (current: moment.Moment) => {
    return current && current < moment().subtract(1, 'month').startOf('month');
  }; 

  return (
    <div>
      <div>
      <label>
          <strong>Selecciona un estudiante</strong>
        </label>
        <Controller
          control={control}
          name="user_id"
          render={({ field, fieldState }) => (
            <div>
              <Select
                {...field}
                showSearch
                style={{ width: '100%', height: 30 }}
                placeholder="Selecciona un estudiante"
                optionFilterProp="label"
                onSearch={(value) => setQuery(value)}
                filterOption={false}
                options={
                  filteredData.length > 0
                    ? filteredData.map((it) => ({
                        key: `${it.id}`,
                        value: `${it.id}`,
                        label: `${it.first_name} ${it.last_name}`
                      }))
                    : [{ key: "", value: "", label: "No hay estudiantes disponibles" }]
                }
              />
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>

      <div className='mt-3'>
        <label><strong>Fecha de pago</strong></label>
        <Controller
          control={control}
          name="payment_date"
          render={({ field, fieldState}) => (
            <div>
                <DatePicker disabledDate={disabledDate} style={{width: '100%', borderRadius: 5, height:36}} onChange={field.onChange}/>
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>

      <div className='mt-2'>
        <label>
          <strong>Monto</strong>
        </label>
        <Controller
          control={control}
          name="amount"
          render={({ field, fieldState }) => (
            <div>
              <Input
                {...field}
                style={{ borderRadius: 5, height: 36}}
                placeholder="100000"
                type="number"
              />
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
    </div>
  );
}
