import Button from "../../../../shared/components/ui/Button";
import AssignedTeacherProgramForm from "./assignedTeacherProgramForm";
import useAssignedTeacherProgram from "../../hooks/teacher/useAssignedTeacherProgram";
import useProgramTeacher from "../../hooks/teacher/useTeacher";

export interface AssignedTeacherProgramsProps {
  programId: number,
  setOpenModal: (val: boolean)=> void
}

export default function AssignedTeacherPrograms({programId, setOpenModal}: AssignedTeacherProgramsProps) {
  const {control, loading, handleSubmit} = useAssignedTeacherProgram({programId, setOpenModal});
  const {teacher} = useProgramTeacher({programId: +programId})
  return (
      <form onSubmit={handleSubmit} >
        <AssignedTeacherProgramForm control={control} teacher={teacher} />
        <div className="mt-9 flex items-center justify-end">
          <Button primary className="bg-[#072082]" loading={loading} type="submit" variant="filled">
            <i className="fa-solid fa-save" /> Crear
          </Button>
        </div>
      </form>
  );
}
