import * as yup from 'yup'

export const validateUpdateBanners = yup.object().shape({
    // path_movile:yup
    // .string()
    // .min(1, 'La PATH MOBILE debe tener más de 1 caracteres'),
    // path_desktop:yup
    // .string()
    // .min(1, 'La PATH ESCRITORIO debe tener más de 1 caracteres'),
    // priority:yup
    // .number().typeError('Campo requerido.')
    // .positive()
    // .min(0, 'Debe de utilizar un numero que no sea negativo'),
    type:yup
    .string()
    .oneOf(["HOME", "OFFERS", "POPUP", "ACADEMY", "FLASH_SALE", "HOME_MOBILE" ], 'Debes elegir entre estas opciones'),
    // type:yup
    // .string()
    // .oneOf(["HOME", "OFFERS", "POPUP", "ACADEMY", "FLASH_SALE" ], 'Debes elegir entre estas opciones'),
    // status:yup
    // .string()
    // .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
    // action_type:yup
    // .string()
    // .oneOf(['BANNER_PRODUCTS', 'CATEGORY', 'PRODUCT', 'URL', 'COURSE' ], 'Debes elegir entre estas opciones'),
    // action_id:
    // yup.string().when('action_type', {
    //     is: (val: string) => ['BANNER_PRODUCTS', 'CATEGORY', 'PRODUCT', 'COURSE' ].includes(val),
    //     then: yup.string().min(1, 'debe tener más de 1 caracteres'),
    // }),
    // url: yup.string().when('action_type', {
    //     is: 'URL',
    //     then: yup.string().min(2, 'LA URL debe tener más de 1 caracteres'),
    // }),
    start_date: yup
    .string()
    .nullable()
    .required('Campo requerido.')
    .min(1, 'La FECHA DE INICIO debe tener más de 1 caracteres')
    .when('type', {
      is: 'FLASH_SALE',
      then: yup
        .string()
        .test(
          'start_date',
          'La diferencia entre fechas debe ser igual o inferior a 31 días',
          function (value) {
            const endDate = this.parent.end_date;
            if (!endDate || !value) {
              return true; // Si end_date o start_date no están definidos, la validación pasa
            }

            const startDate = new Date(value);
            const endDateObj = new Date(endDate);
            const differenceInTime = endDateObj.getTime() - startDate.getTime();
            const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Diferencia en días

            return differenceInDays <= 31;
          }
        ),
    }),
    end_date: yup
        .string()
        .required('Campo requerido.')
        .min(1, 'La FECHA DE FINALIZACIÓN debe tener más de 1 caracteres'),
})