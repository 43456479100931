import { discountsService } from "../../shared/services"
import { DataDiscountCode } from "../@types"
import { discountCodeActions } from "../action-types"

export const discountCodeChangeModalState = (): discountCodeActions.IDiscountCodeModalState =>({
    type: discountCodeActions.DISCOUNT_CODE_MODAL_STATE
})

export const setDiscountCodeForUpdate = (val: DataDiscountCode): discountCodeActions.IGetDiscountCodeForUpdate =>({
    type: discountCodeActions.GET_DISCOUNT_CODE_FOR_UPDATE,
    payload: val
})

export const setIsDiscountCodeForUpdate = (val: boolean): discountCodeActions.IIsDiscountCodeForUpdate =>({
    type: discountCodeActions.IS_DISCOUNT_CODE_FOR_UPDATE,
    payload: val
})

export const createDiscountCode = async(data: Partial<DataDiscountCode>) =>{
    return await discountsService.create(data)
    .then((res: DataDiscountCode) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateDiscountCode =async(data: Partial<DataDiscountCode>) =>{
    return await discountsService.patch(data?.id!, data)
    .then((res: DataDiscountCode) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteDiscountCode = async(id: number) => {
    return await discountsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableDiscountCode = (val: boolean): discountCodeActions.ISwitchDiscountCodeRefreshTable =>({
    type: discountCodeActions.SWITCH_DISCOUNT_CODE_REFRESH_TABLE,
    payload: val
})