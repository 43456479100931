import { Button, Col, Typography } from "antd";
import { EyeOutlined, DeleteOutlined, DollarOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/es";
import React from "react";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import {
  ProgramPaymentType,
  ProgramScheduleType,
} from "../../../../types/dto/program";
import Table from "../../../../shared/components/ui/Table";
import {
  programPaymenAcademy,
  programPaymentScheduleAcademy,
} from "../../../../shared/services";
import Modal from "../../../../shared/components/ui/modal";
import useDeletePayments from "../../hooks/payments/useDeletePayments";
import { ModalPaymentProgramStuden } from "../../../../shared/components/ui/modals/ModalPaymentProgramStuden";
import { WrapperAcademyEquipments } from "../../../AcademyEquipments/style";
import { useNavigate } from "react-router-dom";
import { formatNumberPrice } from "../../../../shared/utils/Helper";
const { Title } = Typography;

interface TableProgramProps {
  program_id: number;
}

export const TableProgramPayments = ({ program_id }: TableProgramProps) => {
  const [openModalPaymentProgramStudent, setModalPaymentProgramStudent] =
    React.useState<boolean>(false);

  const [scheduleId, setScheduleId] = React.useState<number | null>(null);
  const [minAmount, setMinAmount] = React.useState<number>();

  const { showDeleteConfirm } = useDeletePayments();

  const handleModaCreatePayment = (scheduleId: number, minAmount: number) => {
    setScheduleId(scheduleId);
    setMinAmount(minAmount)
    setModalPaymentProgramStudent(true);
  };
  const navigate = useNavigate();

  const hanldeRedirectPersonalInfo = (schedule_id: number, program_id: number) => {
    navigate(`/gestion-academy/student-schedule/${schedule_id}/${program_id}`);
  }
  const columns: TableColumns<ProgramScheduleType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 2,
    },
    {
      title: "Mes de cobro",
      dataIndex: "month",
      key: "month",
      width: 2,
      render: (_, item: ProgramScheduleType) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <span className="capitalize">
              {moment(item.month).format("MMM / YYYY")}
            </span>
          </Col>
        );
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
    },
    {
      title: "Fecha limite de pago",
      dataIndex: "day_of_month_payment_limit",
      key: "day_of_month_payment_limit",
      width: 2,
      render: (_, item: ProgramScheduleType) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <span className="capitalize">
              {moment(item.day_of_month_payment_limit).format("YYYY/MM/DD")}
            </span>
          </Col>
        );
      },
    },
    {
      title: "Monto",
      key: "price",
      width: 2,
      render: (_, item: ProgramScheduleType) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <span className="capitalize">{formatNumberPrice(+item.price)}</span>
          </Col>
        );
      },
    },
    {
      title: "Monto pronto pago",
      key: "price_with_prompt_payment_discount",
      width: 2,
      render: (_, item: ProgramScheduleType) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <span className="capitalize">{formatNumberPrice(+item.price_with_prompt_payment_discount)}</span>
          </Col>
        );
      },
    },
    {
      title: "Acciones",
      key: "operation",
      width: 2,
      fixed: "right",
      render: (_, item: ProgramScheduleType) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              size="large"
              onClick={() => hanldeRedirectPersonalInfo(item.id, item.program_id)}
            />
            <Button
              type="primary"
              shape="circle"
              icon={<DollarOutlined />}
              size="large"
              onClick={() => handleModaCreatePayment(item.id, +item.price_with_prompt_payment_discount)}
            />
            <Button
              type="primary"
              shape="circle"
              danger
              onClick={() => {
                showDeleteConfirm(item.id, "");
              }}
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

 
  return (
    <>
      <ModalPaymentProgramStuden
        openModal={openModalPaymentProgramStudent}
        setOpenModal={setModalPaymentProgramStudent}
        program_id={program_id}
        scheduleId={scheduleId!}
        minAmount={minAmount!}
      />
      <Table<ProgramScheduleType>
        columns={columns}
        scroll={{}}
        fetchQueryProps={{
          program_id,
        }}
        service={programPaymentScheduleAcademy}
      />
    </>
  );
};
