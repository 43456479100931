import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  deleteState,
  refreshTableStates,
  setIsStateForUpdate,
  setStateForUpdate,
  statesChangeModalState,
} from "../../../redux";
import { DataStates } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useStates } from "./useStates";

const { confirm } = Modal;

export const useTableStates = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useStates();

  const changeStateForUpdate = (val: DataStates) => {
    dispatch(setIsStateForUpdate(true));
    dispatch(setStateForUpdate(val));
    dispatch(statesChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el estado con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar estado" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar estado",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteState(valId)
          .then(() => {
            dispatch(refreshTableStates(true));
            message.success(
              `Se ha borrado con éxito el estado con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeStateForUpdate,
    showDeleteConfirm,
  };
};
