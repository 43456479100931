import { WrapperAcademyStudentPendingPayment } from "./style";
import { Typography } from "antd";
import { TableAcademyStudentPendingPayment } from "./ui/table/TableAcademyStudentPendingPayment";
const { Title } = Typography;

export default function AcademyStudentPendingPayment() {
  return (
    <WrapperAcademyStudentPendingPayment>
      <div>
        <Title style={{ textAlign: "left", fontSize: 25 }}>Cronograma de Pagos Estudiantes</Title>
      </div>
      <TableAcademyStudentPendingPayment />
    </WrapperAcademyStudentPendingPayment>
  );
}
