"use client";
import type { Resolver } from "react-hook-form";

import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { programsAcademy } from "../../../../shared/services";
import { ProgramType } from "../../../../types/dto/program";
import { message } from "antd";

export interface UseUpdateLevelProps {
  onSuccess?: (level: ProgramType) => void;
  setOpenModal: (val: boolean) => void;
}

export default function useUpdateProgram(
  program?: ProgramType,
  props?: UseUpdateLevelProps
) {
  const [loading, setLoading] = React.useState(false);

  const {
    control,
    handleSubmit: onSubmit,
    reset,
    watch,
    setValue,
  } = useForm<ProgramType>({
    resolver: yupResolver(yup.object({})) as unknown as Resolver<ProgramType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading || !program) return;
    setLoading(true);
    const { department, ...programData } = data;
    await programsAcademy
      .patch(program.id, {
        ...programData,
        price: +data.price,
        position: +data.position,
      })
      .then((response) => {
        message.success(
          `Se ha actualizado con éxito el programa con id:${response.id}`
        );
        props?.setOpenModal(false);
        props?.onSuccess?.(response);
      })
      .catch((err: Error) => {})
      .finally(() => {
        setLoading(false);
      });
  });

  React.useEffect(() => {
    if (program) {
      reset({
        name: program.name,
        description: program.description,
        price: program.price,
        position: program.position,
        status: program.status,
      });
    }
  }, [program]);

  return {
    control,
    loading,
    handleSubmit,
    watch,
    setValue,
  };
}
