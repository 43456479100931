// Modules
import { useEffect, useState } from 'react'

/**
 * Hook Debounce
 * @param {T} value valor
 * @param {number} ms milisegundos
 * @returns {Object} valor y estado de carga
 */
export const useDebounce = <T>(value: T, ms: number) => {
    // States
    const [debounceValue, setDebounceValue] = useState<T>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (value) {
            setLoading(true)
        }
        const timeout = setTimeout(() => {
            setLoading(false)
            setDebounceValue(value)
        }, ms)

        return () => {
            clearTimeout(timeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return { value: debounceValue, loading }
}

