import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { DataSystemVariables } from '../../../redux/@types/systemVariables';
import { setIsSystemVariablesForUpdate, setSystemVariablesForUpdate, systemVariablesChangeModalState } from '../../../redux/actions/systemVariables.actions';

export const useTableSystemVariables = () => {
    const dispatch = useAppDispatch();

    const changeSystemVariablesForUpdate = (val: DataSystemVariables) => {
        dispatch(setIsSystemVariablesForUpdate(true));
        dispatch(setSystemVariablesForUpdate(val));
        dispatch(systemVariablesChangeModalState());
      };

    return {
        //state
        //methods
        //functions
        changeSystemVariablesForUpdate,
    }
}
