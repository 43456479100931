import { DataCities, DataDirectoryCategories, IDataForUpdateContactsDirectory } from "../@types"


export const CONTACTS_DIRECTORY_MODAL_STATE = '[ CONTACTS DIRECTORY ] Contacts Directory Modal State'

export interface IContactsDirectoryModalState {
    type: typeof CONTACTS_DIRECTORY_MODAL_STATE
}

export const GET_CONTACTS_DIRECTORY_FOR_UPDATE = '[ CONTACTS DIRECTORY ] Get Contacts Directory For Update'

export interface IGetContactsDirectoryForUpdate {
    type: typeof GET_CONTACTS_DIRECTORY_FOR_UPDATE
    payload: IDataForUpdateContactsDirectory
}

export const IS_CONTACTS_DIRECTORY_FOR_UPDATE ='[ CONTACTS DIRECTORY ] Is Contacts Directory For Update'

export interface IIsContactsDirectoryForUpdate {
    type: typeof IS_CONTACTS_DIRECTORY_FOR_UPDATE
    payload: boolean
}

export const SWITCH_CONTACTS_DIRECTORY_REFRESH_TABLE = '[ CONTACTS DIRECTORY ] Switch Contacts Directory Refresh Table'

export interface ISwitchContactsDirectoryRefreshTable {
    type: typeof SWITCH_CONTACTS_DIRECTORY_REFRESH_TABLE
    payload: boolean
}

export const GET_CITIES_CONTACTS_DIRECTORY = '[ CONTACTS DIRECTORY ] Get Cities Contacts Directory'

export interface IGetCitiesContactsDirectory {
    type: typeof GET_CITIES_CONTACTS_DIRECTORY
    payload: DataCities[]
}

export const GET_CATEGORIES_CONTACTS_DIRECTORY = '[ CONTACTS DIRECTORY ] Get Categories Contacts Directory'

export interface IGetCategoriesContactsDirectory {
    type: typeof GET_CATEGORIES_CONTACTS_DIRECTORY
    payload: DataDirectoryCategories[]
}
