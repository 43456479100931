"use client";

import React from "react";
import { programsModuleAcademy } from "../../../shared/services";


export default function useProgramModule() {
  const [loading, setLoading] = React.useState(false);
  const [programModule, setProgramModule] = React.useState<(any)[]>([]);
  const [programModuleByProgramId, ByProgramModuleByProgramI] = React.useState<(any)[]>([]);

  const getProgramModule = async () => {
    if (loading ) return;
    setLoading(true);

    await programsModuleAcademy
      .find({ query: {
        $sort: {
          position: 1,
        },
      }})
      .then((response) => {
        setProgramModule(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
 
  const getProgramModuleByProgramId = async (program_id: number) => {
    if (loading ) return;
    setLoading(true);

    await programsModuleAcademy
      .find({ query: {
        program_id,
        $sort: {
          position: 1,
        },
      }})
      .then((response) => {
        ByProgramModuleByProgramI(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    setProgramModule([])
      getProgramModule().catch((error) => {
        console.error(error);
      });
  }, []);

  return {
    loading,
    programModule,
    programModuleByProgramId,
    refresh: getProgramModule,
    setProgramModule,
    getProgramModuleByProgramId
  };
}
