import { fulfillmentCompanyService } from "../../shared/services"
import { DataFulfillmentCompany } from "../@types"
import { fulfillmentCompanyActions } from "../action-types"

export const fulfillmentCompanyChangeModalState = (): fulfillmentCompanyActions.IFulfillmentCompanyModalState =>({
    type: fulfillmentCompanyActions.FULFILLMENT_COMPANY_MODAL_STATE
})

export const setFulfillmentCompanyForUpdate = (val: DataFulfillmentCompany): fulfillmentCompanyActions.IGetFulfillmentCompanyForUpdate =>({
    type: fulfillmentCompanyActions.GET_FULFILLMENT_COMPANY_FOR_UPDATE,
    payload: val
})

export const setIsFulfillmentCompanyForUpdate = (val: boolean): fulfillmentCompanyActions.IIsFulfillmentCompanyForUpdate =>({
    type: fulfillmentCompanyActions.IS_FULFILLMENT_COMPANY_FOR_UPDATE,
    payload: val
})

export const createFulfillmentCompany = async(data: Partial<DataFulfillmentCompany>) =>{
    return await fulfillmentCompanyService.create(data)
    .then((res: DataFulfillmentCompany) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateFulfillmentCompany =async(data: Partial<DataFulfillmentCompany>) =>{
    return await fulfillmentCompanyService.patch(data?.id!, data)
    .then((res: DataFulfillmentCompany) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableFulfillmentCompany = (val: boolean): fulfillmentCompanyActions.ISwitchFulfillmentCompanyRefreshTable =>({
    type: fulfillmentCompanyActions.SWITCH_FULFILLMENT_COMPANY_REFRESH_TABLE,
    payload: val
})

export const changeActiveKeyOfFulfillmentCompany = (val: number): fulfillmentCompanyActions.IChangeKeyOfFulfillmentCompany =>({
    type: fulfillmentCompanyActions.CHANGE_KEY_OF_FULFILLMENT_COMPANY,
    payload: val
})