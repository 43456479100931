import { DataPaymentConfirmations, DataShipping, IOrder, IOrdersState } from "../@types";
import { ordersActions } from "../action-types";

type ordersActionType =
| { type: typeof ordersActions.GET_ORDER_FOR_UPDATE; payload: IOrder }
| { type: typeof ordersActions.ORDER_CHANGE_MODAL_STATE }
| { type: typeof ordersActions.GET_ORDER_SHIPPED_FOR_UPDATE; payload: any }
| { type: typeof ordersActions.ORDER_CHANGE_MODAL_STATE_SHIPPED }
| { type: typeof ordersActions.ORDER_CHANGE_PAYMENT_CONFIRMATIONS; payload: DataPaymentConfirmations[]}
| { type: typeof ordersActions.ORDER_CHANGE_SHIPPING; payload: DataShipping[]}
| { type: typeof ordersActions.ORDER_CHANGE_SHIPPING_ID; payload: number}

const INITIAL_STATE: IOrdersState = {
    orderForUpdate: undefined,
    orderModalIsOpen: false,
    orderShippedForUpdate: undefined,
    orderShippedModalIsOpen: false,
    orderPaymentConfirmations: undefined,
    orderShipping: undefined,
    shippingIDForAdd: undefined,
}

export default function ordersReducer(state: IOrdersState = INITIAL_STATE, action: ordersActionType ): IOrdersState {
    switch (action.type){
        case ordersActions.GET_ORDER_FOR_UPDATE:
            return{
                ...state,
                orderForUpdate: action.payload
            }
        case ordersActions.ORDER_CHANGE_MODAL_STATE:
            return{
                ...state,
                orderModalIsOpen: !state.orderModalIsOpen
            }
        case ordersActions.GET_ORDER_SHIPPED_FOR_UPDATE:
            return{
                ...state,
                orderShippedForUpdate: action.payload
            }
        case ordersActions.ORDER_CHANGE_MODAL_STATE_SHIPPED:
            return{
                ...state,
                orderShippedModalIsOpen: !state.orderShippedModalIsOpen
            }
        case ordersActions.ORDER_CHANGE_PAYMENT_CONFIRMATIONS:
            return{
                ...state,
                orderPaymentConfirmations: action.payload
            }
        case ordersActions.ORDER_CHANGE_SHIPPING:
            return{
                ...state,
                orderShipping: action.payload
            }
        case ordersActions.ORDER_CHANGE_SHIPPING_ID:
            return{
                ...state,
                shippingIDForAdd: action.payload
            }
        default:
            return state
    }
}