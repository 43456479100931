//taxService

export const  TAX_CHANGE_MODAL_STATE = '[ TAX ] Tax Change Modal State'

export interface ITaxChangeModalState {
    type: typeof TAX_CHANGE_MODAL_STATE
}

export const GET_TAX_FOR_UPDATE = '[ TAX ] Get Tax For Update'

export interface IGetTaxForUpdate {
    type: typeof GET_TAX_FOR_UPDATE
    payload: any
}

export const SWITCH_TAX_REFRESH_TABLE = '[ TAX ] Switch Tax Refresh Table'

export interface ISwitchTaxRefreshTable {
    type: typeof SWITCH_TAX_REFRESH_TABLE
    payload: boolean
}