"use client";
import type { Resolver } from "react-hook-form";

import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProgramSchedulePaymentStudentType } from "../../../types/dto/scheduleStudent";
import {
  programPaymentScheduleStudentAcademy,
} from "../../../shared/services";
import { message } from "antd";

export interface UseEditPaymentScheduleStudentProps {
  program_student_id: number
  setOpenModal: (val: boolean) => void;
}

export default function useEditPaymentScheduleStudent({program_student_id, setOpenModal}: UseEditPaymentScheduleStudentProps) {
  const [loading, setLoading] = React.useState(false);

  const { control, handleSubmit: onSubmit } = useForm<any>({
    resolver: yupResolver(
      yup.object({})
    ) as unknown as Resolver<ProgramSchedulePaymentStudentType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);

    try {
      
      await programPaymentScheduleStudentAcademy.patch(program_student_id ,{
        payment_agreement: data.payment_agreement,
      });
      message.success("Se ha actualizado con éxito el cronograma del estudiante");
      setOpenModal(false)
    } catch (err: any) {
      message.error("Algo salió mal: " + err.message);
    } finally {
      setLoading(false);
    }
  });


  return {
    control,
    loading,
    handleSubmit,
  };
}
