import { TRootState } from "../@types"

export const selectModalContactsDirectory = ( state: TRootState ) =>state.contactsDirectory.contactsDirectoryModalIsOpen

export const selectIsUpdateContactsDirectory = ( state: TRootState ) =>state.contactsDirectory.isUpdateContactsDirectory

export const selectContactsDirectoryForUpdate = ( state: TRootState ) =>state.contactsDirectory.contactsDirectoryForUpdate

export const selectContactsDirectoryRefreshTable =  ( state: TRootState ) =>state.contactsDirectory.refreshTableContactsDirectory

export const selectArrCitiesForContactsDirectory = ( state: TRootState ) =>state.contactsDirectory.arrCities

export const selectArrCategoriesForContactsDirectory = ( state: TRootState ) =>state.contactsDirectory.arrCategories