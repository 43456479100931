import { Drawer } from "antd";
import CreateEquipment from "../../../../modules/AcademyEquipments/ui/equipments/form/createEquipment";

export const ModalEquipment = ({openModal, setOpenModal}: {openModal:boolean, setOpenModal: (value: boolean)=>void}) => {

  return (
    <Drawer
      title={`Crear un equipo`}
      open={openModal}
      width={"320px"}
      destroyOnClose
      onClose={()=>setOpenModal(false)}
    >
      <CreateEquipment setOpenModal={setOpenModal}/>
    </Drawer>
  );
};
