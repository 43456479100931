import { WrapperAcademyEquipments } from "./style";
import { Typography, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { ModalEquipment } from "../../shared/components/ui/modals/ModalEquipment";
import { TableAcademyEquipments } from "./ui/equipments/table/TableAcademyEquipments";

const { Title } = Typography;

export default function AcademyEquipments() {
  const [openModalCreateEquipmet, setOpenModalCreateEquipment] =  React.useState<boolean>(false);

  return (
    <WrapperAcademyEquipments>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Title style={{ textAlign: "left", fontSize: 25 }}>
          Listado de equipos
        </Title>
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          size="large"
          onClick={() => setOpenModalCreateEquipment(true)}
        >
          Agregar un equipo
        </Button>{" "}
      </div>
      <TableAcademyEquipments />
      <ModalEquipment
        openModal={openModalCreateEquipmet}
        setOpenModal={setOpenModalCreateEquipment}
      />
    </WrapperAcademyEquipments>
    
  );
}