import { FC, ReactNode } from "react";
import { Layout } from "antd";

import { WrapperLayout } from "./styled";
const { Content } = Layout;

interface Props {
  children: ReactNode;
  title: string;
  pageDescription: string;
}

export const LoginLayout: FC<Props> = ({
  children,
  title,
  pageDescription,
}) => {
  return (
    <WrapperLayout>
      <Content>{children}</Content>
    </WrapperLayout>
  );
};
