import * as yup from 'yup'

export const validateUpdateFulfillmentCompany = yup.object().shape({
    name:yup
    .string()
    .min(2, 'El NOMBRE debe tener más de 2 caracteres'),
    description:yup
    .string()
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    path:yup
    .string()
    .typeError('Campo requerido.')
    .min(2, 'EL PATH debe tener más de 2 caracteres'),
    type: yup
    .string()
    .oneOf(['weight', 'units', 'volume', 'price', 'polygon'], 'Debes elegir entre estas opciones'),
    status: yup
    .string()
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
    default_lat: yup.number().when('type', {
      is: 'polygon',
      then: yup.number().required('Campo requerido.').typeError('Debe ser un número.'),
      otherwise: yup.number().nullable().typeError('Debe ser un número o nulo'),
    }),
    default_lng: yup.number().when('type', {
      is: 'polygon',
      then: yup.number().required('Campo requerido.').typeError('Debe ser un número.'),
      otherwise: yup.number().nullable().typeError('Debe ser un número o nulo'),
    })    
    // integration: yup
    // .string()
    // .min(1, 'LA INTEGRACIÓN debe tener más de 1 caracteres'),
})