"use client";
import useUpdateProgram from "../../hooks/programs/useUpdatePrograms";
import usePrograms from "../../hooks/programs/usePrograms";
import ProgramForm from "./ProgramForm";
import { ProgramType } from "../../../../types/dto/program";
import Button from "../../../../shared/components/ui/Button";

export default function UpdateProgram({
  programId,
  onSuccess,
  setOpenModal
}: {
  programId: number;
  onSuccess?: (level: ProgramType) => void;
  setOpenModal: (val: boolean)=> void
}) {
  const {program, setProgram} = usePrograms(programId);

  const {control, loading, handleSubmit, watch, setValue} = useUpdateProgram(program, {
    setOpenModal,
    onSuccess(updatedLevel) {
      setProgram(updatedLevel);
      onSuccess?.(updatedLevel);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <ProgramForm control={control}  watch={watch}  setValue={setValue} img_path={program?.image_path || ""} />
      <div className="mt-4 flex items-center justify-end">
        <Button primary className="bg-[#072082]" loading={loading} type="submit" variant="filled">
          <i className="fa-regular fa-floppy-disk" /> Actualizar
        </Button>
      </div>
    </form>
  );
}
