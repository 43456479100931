// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { message } from 'antd';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';

import { getCategoriesForWorkOffers, getCitiesForWorkOffers, selectArrCategoriesForWorkOffers, selectArrCitiesForWorkOffers, setIsWorkOffersForUpdate, setWorkOffersForUpdate } from "../../../../redux";
import { ICitiesFin, IWorkOffersCategoriesFin } from "../../../../redux/@types";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { citiesService, workOffersCategoriesService, workOffersService } from "../../../../shared/services";

export const useWorkOffersById = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const arrCities = useSelector(selectArrCitiesForWorkOffers);
    const arrCategories = useSelector(selectArrCategoriesForWorkOffers);
    const { pathname } = useLocation();
    const [isValidate, setIsValidate] = useState(false);

    const servicesCitiesForWorkOffers = async () => {
        await citiesService.find({ query: { $limit: 999999999 } }).then(({ data }: ICitiesFin) => {
          dispatch(getCitiesForWorkOffers(data));
        });
        await workOffersCategoriesService.find({ query: {  $limit: 9999999999999  } })
          .then(({ data }: IWorkOffersCategoriesFin) => {
            dispatch(getCategoriesForWorkOffers(data));
          });
      };

      const getSingleWorkOffers = async (id: number) => {
        return await workOffersService
          .get(id)
          .then((res) => {
            return res;
          })
          .catch((err: any) => {
            console.log(err);
            navigate(`/workOffers`);
            message.error(err.message);
          });
      };

      useEffect(() => {
        if (pathname !== "/workOffers/new") {
          dispatch(setIsWorkOffersForUpdate(true));
          if (isNaN(pathname.split("/")[2])) return navigate(`/workOffers`);
          getSingleWorkOffers(Number(pathname.split("/")[2])).then((res) =>
            dispatch(setWorkOffersForUpdate(res))
          );
          setIsValidate(true);
        } else if (pathname === "/workOffers/new") {
          dispatch(setIsWorkOffersForUpdate(false));
          setIsValidate(true);
        } else {
          navigate(`/workOffers`);
        }
      }, []);

      useEffect(() => {
        if (!arrCities.length && !arrCategories.length) {
            servicesCitiesForWorkOffers();
        }
      }, []);
  return {
    //state
    arrCategories,
    arrCities,
    isValidate,
    //methods
    //functions
  }
}
