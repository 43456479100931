import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Typography } from "antd";
import { DataCoursesCategories } from "../../../redux/@types";

import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { coursesCategoriesService } from "../../../shared/services";
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";
import { useCoursesCategories, useTableCoursesCategories } from "../hook";

const { Title } = Typography;

export const TableCoursesCategories = () => {
  const { changeCoursesCategoriesForUpdate, showDeleteConfirm } =
    useTableCoursesCategories();
  const { setTableProps, updateStatus, isLoading } = useCoursesCategories();

  const columns: TableColumns<DataCoursesCategories> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Nombre",
      width: 9,
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "Estado",
      width: 7,
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <button
            disabled={isLoading}
              onClick={()=>{
                if(item.status === "active"){
                  updateStatus("inactive", item.id)
                }else if( item.status === "inactive"){
                  updateStatus("active", item.id)
                }
              }}
              style={{
                display: "flex",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "white",
                }}
                level={5}
              >
                {item.status}
              </Title>
            </button>
          </Col>
        );
      },
      filters: [
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Inactivo",
          value: "inactive",
        },
      ],
    },
    {
      title: "Posición",
      width: 6,
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Imagen",
      width: 7,
      dataIndex: "path_image",
      key: "path_image",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.path_image}`} />
          </Col>
        );
      },
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 4,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeCoursesCategoriesForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<DataCoursesCategories>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={coursesCategoriesService}
      onLoad={setTableProps}
    />
  );
};
