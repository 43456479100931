import {type Control} from "react-hook-form";
import { ProgramTechniquesType } from "../../../../../types/dto/program";
import { Input, Select, TextArea } from "../../../../../shared/components/ui/form/inputs";

export default function ProgramModuleTechniquesForm({control}: {control: Control<ProgramTechniquesType>}) {
  return (
    <div className="flex flex-col gap-2">
      <Input style={{background:'none'}} control={control} label="Nombre" name="name" />
      <TextArea style={{background:'none'}} control={control} label="Descripción" name="description" />
      <Select style={{background:'none'}} control={control} label="Estado" name="status" >
        <option value="">Seleccionar...</option>
        <option value="active">Activo</option>
        <option value="inactive">Inactivo</option>
      </Select>
    </div>
  );
}
