import { TRootState } from '../@types/redux';

export const selectModalRankings= ( state: TRootState) =>state.rankings.rankingsModalIsOpen

export const selectIsUpdateRankings= ( state: TRootState) =>state.rankings.isUpdateRankings

export const selectRankingsForUpdate= ( state: TRootState) =>state.rankings.rankingsForUpdate

export const selectRankingsRefreshTable= ( state: TRootState) =>state.rankings.refreshTableRankings

export const selectRankingsUsers = ( state: TRootState) =>state.rankings.arrUsers

export const selectRankingsOrders = ( state: TRootState) =>state.rankings.arrOrders

export const selectRankingsProducts = ( state: TRootState) =>state.rankings.arrProducts