import { DataPaymentConfirmations, DataShipping, IOrder } from "../@types";
import { ordersActions } from "../action-types";

export const setOrdersForUpdate = (val: IOrder): ordersActions. IGetOrderForUpdate => ({
    type: ordersActions.GET_ORDER_FOR_UPDATE,
    payload: val
})

export const changeOrderModalIsOpen = (): ordersActions.IOrderChangeModalState => ({
    type: ordersActions.ORDER_CHANGE_MODAL_STATE
})

export const setOrdersShippedForUpdate = (val: IOrder): ordersActions. IGetOrderShippedForUpdate => ({
    type: ordersActions.GET_ORDER_SHIPPED_FOR_UPDATE,
    payload: val
})

export const changeOrderModalShippedIsOpen = (): ordersActions.IOrderChangeModalStateShipped => ({
    type: ordersActions.ORDER_CHANGE_MODAL_STATE_SHIPPED
})

export const changeOrderPaymentConfirmations = (val:DataPaymentConfirmations[]): ordersActions.IOrderChangePaymentConfirmations =>({
    type: ordersActions.ORDER_CHANGE_PAYMENT_CONFIRMATIONS,
    payload: val
})

export const changeOrderChangeShipping = (val:DataShipping[]): ordersActions.IOrderChangeShipping =>({
    type: ordersActions.ORDER_CHANGE_SHIPPING,
    payload: val
})

export const changeOrderChangeShippingID = (val:number): ordersActions.IOrderChangeShippingID =>({
    type: ordersActions.ORDER_CHANGE_SHIPPING_ID,
    payload: val
})