import { IUserResFind } from "../@types"

export const  USER_CHANGE_MODAL_STATE = '[ USER ACTIONS ] User Change Modal State'

export interface IUserChangeModalState {
    type: typeof USER_CHANGE_MODAL_STATE
}

export const GET_USER_FOR_UPDATE = '[ USER ACTIONS ] Get User For Update'

export interface IGetUserForUpdate {
    type: typeof GET_USER_FOR_UPDATE
    payload: IUserResFind
}

export const IS_USER_FOR_UPDATE = '[ USER ACTIONS ] Is User For Update'

export interface IIsUserForUpdate {
    type: typeof IS_USER_FOR_UPDATE
    payload: boolean
}

export const SWITCH_USERS_REFRESH_TABLE = '[ USER ACTIONS ] Switch Users Refresh Table'

export interface ISwitchUsersRefreshTable {
    type: typeof SWITCH_USERS_REFRESH_TABLE
    payload: boolean
}