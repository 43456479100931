import { DataCoursesCategories } from "../@types"


export const COURSES_CATEGORIES_MODAL_STATE = '[ COURSES CATEGORIES ] Courses Categories Modal State'

export interface ICoursesCategoriesModalState {
    type: typeof COURSES_CATEGORIES_MODAL_STATE
}

export const GET_COURSES_CATEGORIES_FOR_UPDATE = '[ COURSES CATEGORIES ] Get Courses Categories For Update'

export interface IGetCoursesCategoriesForUpdate {
    type: typeof GET_COURSES_CATEGORIES_FOR_UPDATE
    payload: DataCoursesCategories
}

export const IS_COURSES_CATEGORIES_FOR_UPDATE ='[ COURSES CATEGORIES ] Is Courses Categories For Update'

export interface IIsCoursesCategoriesForUpdate {
    type: typeof IS_COURSES_CATEGORIES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_COURSES_CATEGORIES_REFRESH_TABLE = '[ COURSES CATEGORIES ] Switch Courses Categories Refresh Table'

export interface ISwitchCoursesCategoriesRefreshTable {
    type: typeof SWITCH_COURSES_CATEGORIES_REFRESH_TABLE
    payload: boolean
}
