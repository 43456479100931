// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectPriceListForUpdate,
  setIsPriceListForUpdate,
  setPriceListForUpdate,
} from "../../../../redux";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { priceListsService } from "../../../../shared/services";

export const usePriceListById = () => {
  const [isValidate, setIsValidate] = useState(false);
  const { pathname } = useLocation();
  const dataUpdatePriceList = useSelector(selectPriceListForUpdate);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getSinglePriceList = async (id: number) => {
    return await priceListsService
      .get(id)
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        navigate(`/priceList`);
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (pathname !== "/priceList/new") {
      if (isNaN(pathname.split("/")[2])) return navigate(`/priceList`);
      dispatch(setIsPriceListForUpdate(true));
      getSinglePriceList(Number(pathname.split("/")[2])).then((res) =>
        dispatch(setPriceListForUpdate(res as any))
      );
      setIsValidate(true);
    } else if (pathname === "/priceList/new") {
      dispatch(setIsPriceListForUpdate(false));
      setIsValidate(true);
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    //state
    isValidate,
    dataUpdatePriceList,
    //methods
    //functions
  };
};
