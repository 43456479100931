// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Spin } from "antd";
import { FormProvider } from "react-hook-form";
import { AdminMenuLayout } from "../../../../shared/components";

import { usePriceList, usePriceListById } from "../../hooks";
import { WrapperCardPriceList } from "../styled";
import { FormPriceList } from "./FormPriceList";

export const PriceListById = () => {
  const { isUpdatePriceList, formMethodsCreate, formMethodsUpdate } =
    usePriceList();
  const { isValidate, dataUpdatePriceList } = usePriceListById();

  return (
    <AdminMenuLayout pageDescription="PriceListsById" title="PriceListsById">
      <WrapperCardPriceList>
        {!isValidate ? (
          <Spin size="large" />
        ) : (
          <>
            {!isUpdatePriceList && (
              <FormProvider {...formMethodsCreate}>
                <FormPriceList dataUpdatePriceList={dataUpdatePriceList} />
              </FormProvider>
            )}
            {isUpdatePriceList && (
              <FormProvider {...formMethodsUpdate}>
                {!dataUpdatePriceList ? (
                  <Spin size="large" />
                ) : (
                  <FormPriceList dataUpdatePriceList={dataUpdatePriceList} />
                )}
              </FormProvider>
            )}
          </>
        )}
      </WrapperCardPriceList>
    </AdminMenuLayout>
  );
};
