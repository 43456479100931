import { DatePicker } from "antd";
import moment from "moment";
import { Control, Controller } from "react-hook-form";

export default function EditStudentSchedulePaymentForm({
  control,
}: {
  control: Control<any>;
}) {
  const disabledDate = (current: moment.Moment) => {
    return current && current < moment().subtract(1, 'month').startOf('month');
  };
  return (
    <>
    <div>
      <label>
          <strong>Asignar acuerdo de pago</strong>
        </label>
        <Controller
          control={control}
          name="payment_agreement"
          render={({ field, fieldState}) => (
            <div>
                <DatePicker disabledDate={disabledDate} style={{width: '100%', borderRadius: 5, height:36}} onChange={field.onChange}/>
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
    </>
  );
}
