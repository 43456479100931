import { Datum, IAddressesUsersFind, ICitiesRes, IStatesRes } from "../@types"

export const ADDRESSES_USERS_CHANGE_MODAL_STATE = '[ ADDRESSES USERS ACTIONS ] Addresses Users Change Modal State'

export interface IAddressesUsersChangeModalState {
    type: typeof ADDRESSES_USERS_CHANGE_MODAL_STATE
}

export const GET_ADDRESSES_USERS_FOR_UPDATE = '[ ADDRESSES USERS ACTIONS ] Get Addresses Users For Update'

export interface IGetAddressesUsersForUpdate {
    type: typeof GET_ADDRESSES_USERS_FOR_UPDATE
    payload: IAddressesUsersFind
}

export const IS_ADDRESSES_USERS_FOR_UPDATE = '[ ADDRESSES USERS ACTIONS ] Is Addresses Users For Update'

export interface IIsAddressesUsersForUpdate {
    type: typeof IS_ADDRESSES_USERS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_ADDRESSES_USERS_REFRESH_TABLE = '[ ADDRESSES USERS ACTIONS ] Switch Addresses Users Refresh Table'

export interface ISwitchAddressesUsersRefreshTable {
    type: typeof SWITCH_ADDRESSES_USERS_REFRESH_TABLE
    payload: boolean
}

export const GET_STATES_ADDRESSES_USERS = '[ ADDRESSES USERS ACTIONS ] Get States Addresses Users'

export interface IGetStatesAddressesUsers {
    type: typeof GET_STATES_ADDRESSES_USERS
    payload: Datum[]
}

export const GET_CITIES_ADDRESSES_USERS = '[ ADDRESSES USERS ACTIONS ] Get Cities Addresses Users'

export interface IGetCitiesAddressesUsers {
    type: typeof GET_CITIES_ADDRESSES_USERS
    payload: ICitiesRes[]
}