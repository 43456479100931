// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { message } from 'antd';

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { statesService } from "../../../shared/services";
import { DataCities, ICitiesFin } from "../../../redux/@types";
import { getStatesForCities, selectArrState, selectIsUpdateCity,selectCityForUpdate, selectCitiesRefreshTable } from "../../../redux";
import { TableProperties } from '../../../shared/components/ui/Table/types';
import { setIsCityForUpdate, citiesChangeModalState, updateCity, refreshTableCities, createCity } from '../../../redux/actions/cities.actions';
import { validateCreateCities, validateUpdateCities } from '../../../helpers';

export const useCities = () => {
  const dispatch = useAppDispatch();

  const isUpdateCity = useSelector(selectIsUpdateCity)
  const dataUpdateCity = useSelector(selectCityForUpdate)
  const isRefreshTableCities = useSelector(selectCitiesRefreshTable)
  const arrState = useSelector(selectArrState)

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalCitiesState = () => {
    dispatch(setIsCityForUpdate(false))
    dispatch(citiesChangeModalState())
  }

  const formMethodsCreate = useForm<DataCities>({
    resolver: yupResolver(validateCreateCities),
  });

  const formMethodsUpdate = useForm<DataCities>({
    resolver: yupResolver(validateUpdateCities),
  });

  useEffect(() => {
    if (isUpdateCity) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateCity });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCity, dataUpdateCity]);

  const onSubmitCreateOrUpdate = async (data: DataCities) => {
    console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCity) {
      //console.log("Actualiando===>", data);
      await updateCity({
        id: data.id,
        name: data.name,
        state_id: Number(data.state_id)
      })
        .then((res: Partial<DataCities>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // dispatch(citiesChangeModalState());
            message.success(
              `Se ha actualizado con éxito la ciudad con id:${res.id}`
            );
            dispatch(refreshTableCities(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createCity({
        id: data.id,
        name: data.name,
        state_id: Number(data.state_id)
      })
        .then((res: DataCities) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(citiesChangeModalState());
            message.success(
              `Se ha creado con éxito la ciudad con id:${res.id}`
            );
            dispatch(refreshTableCities(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCities) {
      tableProps?.refresh();
      dispatch(refreshTableCities(false));
    }
  }, [isRefreshTableCities]);

  const servicesStatesForCities = async() =>{
    await statesService.find({ query: { $limit: 999999999 }})
    .then(({data}: ICitiesFin) =>{
      dispatch(getStatesForCities(data))
    })
  }

  useEffect(() => {
    if(!arrState.length){
      servicesStatesForCities()
    }
  }, [])

  return {
    //state
    isUpdateCity,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    changeModalCitiesState,
    onSubmitCreateOrUpdate,
  }
}
