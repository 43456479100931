import * as yup from 'yup'

export const validateCreateCMS = yup.object().shape({
    title:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El TITULO debe tener más de 2 caracteres'),
    body:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El TITULO debe tener más de 2 caracteres'),
    // status: yup
    // .string()
    // .required('Campo requerido.')
    // .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
})