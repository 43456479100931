import React, { useEffect, useState } from "react";
import { SketchPicker, CirclePicker } from "react-color";
import { Popover, Avatar, Button } from 'antd';
import { WrappercolorFields } from "./Styles";
import reactCSS from "reactcss";
import _ from "lodash"

const ColorField = ({ onChange, name, value, placeholder }) => {
  const [state, setState] = useState({
      color: {
          r: "241",
          g: "112",
          b: "19",
          a: "1"
      },
      displayColorPicker: false
  });
  const handleChange = (color) => {
      setState({ ...state, color: color.hex });
      _handleOnChange(color.hex)
  };
  const handleChangeComplete = color => {
      setState({ ...state, color: color.rgb });
  }
  const handleOnChange = color => {
      if (onChange)
          onChange(color);
  }
  const _handleOnChange = _.debounce(handleOnChange, 1000, { maxWait: 1000 })
  

  useEffect(() => {
      if (value)
          setState({
              ...state,
              color: value
          });
  }, [value])

  return (
      <WrappercolorFields color={state.color} >
          <Popover
              content={
                  <div>
                      <SketchPicker
                          color={state.color}
                          onChange={handleChange}
                          onChangeComplete={handleChangeComplete}
                          name={name}
                      />
                  </div>

              }
          >

              <Button
                  shape="square"
                  style={{
                      minWidth: 50,
                      minHeight: 50,
                      backgroundColor: state.color && state.color || '#fff'
                  }}
              >&nbsp;</Button>
          </Popover>
      </WrappercolorFields>
  );
}
export default ColorField;