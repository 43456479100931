import { Datum, IAddressesUsersFind, IAddressesUsersState, ICitiesRes, IStatesRes } from "../@types";
import { addressesUsersActions } from "../action-types";


type addressesUsersActionType =
| { type: typeof addressesUsersActions.ADDRESSES_USERS_CHANGE_MODAL_STATE }
| { type: typeof addressesUsersActions.GET_ADDRESSES_USERS_FOR_UPDATE; payload: IAddressesUsersFind}
| { type: typeof addressesUsersActions.IS_ADDRESSES_USERS_FOR_UPDATE; payload: boolean }
| { type: typeof addressesUsersActions.SWITCH_ADDRESSES_USERS_REFRESH_TABLE; payload: boolean }
| { type: typeof addressesUsersActions.GET_STATES_ADDRESSES_USERS; payload: Datum[] }
| { type: typeof addressesUsersActions.GET_CITIES_ADDRESSES_USERS; payload: ICitiesRes[] }

const INITIAL_STATE: IAddressesUsersState = {
    addressesUsersForUpdate: undefined,
    isUpdateAddressesUsers: false,
    addressesUsersModalIsOpen: false,
    refreshTableAddressesUsers: false,
    stateAddressesUsers: undefined,
    citiesAddressesUsers: undefined,
}

export default function addressesUsersReducer(state: IAddressesUsersState = INITIAL_STATE, action: addressesUsersActionType): IAddressesUsersState{
    switch ( action.type ) {
        case addressesUsersActions.ADDRESSES_USERS_CHANGE_MODAL_STATE:
            return {
                ...state,
                addressesUsersModalIsOpen: !state.addressesUsersModalIsOpen
            }
        case addressesUsersActions.GET_ADDRESSES_USERS_FOR_UPDATE:
            return {
                ...state,
                addressesUsersForUpdate: action.payload
            }
        case addressesUsersActions.IS_ADDRESSES_USERS_FOR_UPDATE:
            return {
                ...state,
                isUpdateAddressesUsers: action.payload
            }
        case addressesUsersActions.SWITCH_ADDRESSES_USERS_REFRESH_TABLE:
            return {
                ...state,
                refreshTableAddressesUsers: action.payload
            }
        case addressesUsersActions.GET_STATES_ADDRESSES_USERS:
            return {
                ...state,
                stateAddressesUsers: action.payload
            }
        case addressesUsersActions.GET_CITIES_ADDRESSES_USERS:
            return {
                ...state,
                citiesAddressesUsers: action.payload
            }
        default:
            return state
    }
}