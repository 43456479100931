import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRelationSon, selectProductCategoryParent, setProductCategoriesForParent } from "../../../redux";
import { IProductCategory } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";

export const useProductCategoryById = () => {
    const dispatch = useAppDispatch();
    const dataProductCategoryById = useSelector(
      selectProductCategoryParent
    );
    let singleCategory = localStorage.getItem('singleCategory')
    const [arrOfSonCategory, setArrOfSonCategory] = useState<IProductCategory[]>([])
    const [isLoadingData, setIsLoadingData] = useState(true)

    useEffect(() => {
        if( singleCategory ){
            dispatch(setProductCategoriesForParent(JSON.parse(singleCategory)));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if( dataProductCategoryById?.id ){
          getRelationSon(dataProductCategoryById?.id)
          .then((res)=>{
            if(res.length > 0){
              setArrOfSonCategory(res)
            }
            // else {
            //   message.error(res)
            // }
          })
          .finally(()=>setIsLoadingData(false))
      }
  }, [dataProductCategoryById])

  return {
    //state
    dataProductCategoryById,
    arrOfSonCategory,
    isLoadingData,
    //methods
    //function
  }
}
