import { Button, Col, Row } from "antd"
import { PlusOutlined } from "@ant-design/icons";

import { AdminMenuLayout, ModalSystemVariables } from "../../../shared/components"
import { WrapperCardSystemVariables } from "./styled"
import { FormProvider } from "react-hook-form";
import { TableSystemVariables } from "./TableSystemVariables";
import { useSelector } from "react-redux";
import { selectModalSystemVariables } from '../../../redux/selectors/systemVariables.selectors';
import { useSystemVariables } from "../hook";

export const SystemVariables = () => {

  const modalSystemVariables = useSelector(selectModalSystemVariables);
  const {
    isUpdateSystemVariables,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalSystemVariablesState,
  } = useSystemVariables();

  return (
    <AdminMenuLayout pageDescription="SystemVariables" title="SystemVariables">
      <WrapperCardSystemVariables>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalSystemVariablesState}
            >
              Agregar variable del sistema
            </Button>
          </Col>
        </Row>
        <TableSystemVariables />
        {modalSystemVariables && isUpdateSystemVariables && (
          <FormProvider {...formMethodsUpdate}>
            <ModalSystemVariables />
          </FormProvider>
        )}
        {modalSystemVariables && !isUpdateSystemVariables && (
          <FormProvider {...formMethodsCreate}>
            <ModalSystemVariables />
          </FormProvider>
        )}
      </WrapperCardSystemVariables>
    </AdminMenuLayout>
  )
}
