import { DataIRankings, IOrder, IProducts, IRankingsState, IUserResFind } from "../@types";
import { rankingsActions } from "../action-types";

type rankingsActionType =
| { type: typeof rankingsActions.RANKINGS_CHANGE_MODAL_STATE }
| { type: typeof rankingsActions.GET_RANKING_FOR_UPDATE; payload: DataIRankings }
| { type: typeof rankingsActions.IS_RANKING_FOR_UPDATE; payload: boolean }
| { type: typeof rankingsActions.SWITCH_RANKINGS_REFRESH_TABLE; payload: boolean }
| { type: typeof rankingsActions.GET_USERS_FOR_RANKING_SELECT; payload: IUserResFind[] }
| { type: typeof rankingsActions.GET_ORDERS_FOR_RANKING_SELECT; payload: IOrder[] }
| { type: typeof rankingsActions.GET_PRODUCTS_FOR_RANKING_SELECT; payload: IProducts[] }


const INITIAL_STATE: IRankingsState = {
    rankingsForUpdate: undefined,
    isUpdateRankings: false,
    rankingsModalIsOpen: false,
    refreshTableRankings: false,
    arrUsers: undefined,
    arrOrders: undefined,
    arrProducts: undefined,
}

export default function rankingsReducer(state: IRankingsState = INITIAL_STATE, action: rankingsActionType ): IRankingsState {
    switch ( action.type ) {
        case rankingsActions.RANKINGS_CHANGE_MODAL_STATE:
            return{
                ...state,
                rankingsModalIsOpen: !state.rankingsModalIsOpen
            }
        case rankingsActions.GET_RANKING_FOR_UPDATE:
            return{
                ...state,
                rankingsForUpdate: action.payload
            }
        case rankingsActions.IS_RANKING_FOR_UPDATE:
            return{
                ...state,
                isUpdateRankings: action.payload
            }
        case rankingsActions.SWITCH_RANKINGS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableRankings: action.payload
            }
        case rankingsActions.GET_USERS_FOR_RANKING_SELECT:
            return {
                ...state,
                arrUsers: action.payload
            }
        case rankingsActions.GET_ORDERS_FOR_RANKING_SELECT:
            return {
                ...state,
                arrOrders: action.payload
            }
        case rankingsActions.GET_PRODUCTS_FOR_RANKING_SELECT:
            return {
                ...state,
                arrProducts: action.payload
            }
        default:
            return state
    }
}