import { getService } from "../../../../clients/feathers.clients";
import { PaginatedResponse, ProgramTopicType } from "../../../../types/dto/program";

export default function useSaveSortedProgramTopic() {
  const service = getService("ca-program-module-topics");

  return (sortedPrograms: { id: number; position: number }[]) =>
    service.patch(null, sortedPrograms, {
      query: {
        skipJoin: 'true',
        $client: {
          saveSort: "true"
        }
      }
    })
    .then((res: PaginatedResponse) => res.data as Pick<ProgramTopicType, "id" | "position">[]);

}
