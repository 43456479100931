/* eslint-disable @typescript-eslint/no-misused-promises */

import Button from "../../../../../shared/components/ui/Button";
import { ProgramTechniquesType } from "../../../../../types/dto/program";
import useCreateProgramTechniques from "../../../../academyPrograms/hooks/techniques/useCreateProgramTechniques";
import ProgramModuleTechniquesForm from "./ProgramTechniquesForm";

export interface CreateProgramModuleTechniquesProps {
  programModuleTopicId: number,
  onSuccess?: (programTopic: ProgramTechniquesType) => void;
}

export default function CreateProgramModuleTechniques({programModuleTopicId, onSuccess}: CreateProgramModuleTechniquesProps) {

  const {control, loading, handleSubmit} = useCreateProgramTechniques({
    programModuleTopicId,
    onSuccess(data) {
      onSuccess?.(data);
    },
  });
  

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ProgramModuleTechniquesForm control={control} />
        <div className="mt-2 flex items-center justify-end">
          <Button primary  className="bg-[#072082]" loading={loading} type="submit" variant="filled">
            <i className="fa-solid fa-save" /> Crear
          </Button>
        </div>
      </form>
    </div>
  );
}
