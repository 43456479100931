import { DataConfigurationCashback } from "../@types"

export const CONFIGURATION_CASHBACK_MODAL_STATE = '[ CONFIGURATION CASHBACK ] Configuration Cashback Modal State'

export interface IConfigurationCashbackModalState {
    type: typeof CONFIGURATION_CASHBACK_MODAL_STATE
}

export const GET_CONFIGURATION_CASHBACK_FOR_UPDATE = '[ CONFIGURATION CASHBACK ] Get Configuration Cashback For Update'

export interface IGetConfigurationCashbackForUpdate {
    type: typeof GET_CONFIGURATION_CASHBACK_FOR_UPDATE
    payload: DataConfigurationCashback
}

export const IS_CONFIGURATION_CASHBACK_FOR_UPDATE ='[ CONFIGURATION CASHBACK ] Is Configuration Cashback For Update'

export interface IIsConfigurationCashbackForUpdate {
    type: typeof IS_CONFIGURATION_CASHBACK_FOR_UPDATE
    payload: boolean
}

export const SWITCH_CONFIGURATION_CASHBACK_REFRESH_TABLE = '[ CONFIGURATION CASHBACK ] Switch Configuration Cashback Refresh Table'

export interface ISwitchConfigurationCashbackRefreshTable{
    type: typeof SWITCH_CONFIGURATION_CASHBACK_REFRESH_TABLE
    payload: boolean
}