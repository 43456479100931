import EditStudentSchedulePayment from "../../../../modules/AcademyStudentSchedulePayment/ui/EditStudentSchedulePayment";
import { WrapperModalAssignedStudentProgram } from "./styled";

export const ModalEditPaymentScheduleStudent = ({openModal, setOpenModal, program_student_id}: {openModal: boolean, setOpenModal: (value: boolean)=>void, program_student_id: number}) => {

  return (
    <WrapperModalAssignedStudentProgram
      title={`Editar acuerdo de pago`}
      open={openModal}
      width={"320px"}
      destroyOnClose
      onClose={()=>setOpenModal(false)}
    >
      <EditStudentSchedulePayment setOpenModal={setOpenModal} program_student_id={program_student_id}/>
    </WrapperModalAssignedStudentProgram>
  );
};
