"use client";

import React from "react";
import { programsModuleAcademy } from "../../../../shared/services";
import { PaginatedResponse, ProgramModuleType } from "../../../../types/dto/program";


export default function useProgramModule(programId?: number) {
  const [loading, setLoading] = React.useState(false);
  const [programModule, setProgramModule] = React.useState<(ProgramModuleType)[]>([]);
  const [pagination, setPagination] = React.useState<Omit<PaginatedResponse, "data">>({
    limit: 50,
    skip: 0,
    total: 0,
  });

  const getProgramModule = async () => {
    if (loading || !programId) return;
    setLoading(true);

    await programsModuleAcademy
      .find({ query: {
        program_id: programId,
        $limit: pagination.limit,
        $skip: pagination.skip,
        $sort: {
          position: 1,
        },
      }})
      .then((response) => {
        setProgramModule(response.data);
        setPagination({
          limit: response.limit,
          skip: response.skip,
          total: response.total,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    setProgramModule([])
      getProgramModule().catch((error) => {
        console.error(error);
      });
  }, [programId]);

  return {
    loading,
    pagination,
    programModule,
    refresh: getProgramModule,
    setProgramModule,
  };
}
