"use client";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import clsx from "clsx";
import React from "react";
import { useLevelContext } from "../../../../academyPrograms/context";
import useTopic from "../../../../academyPrograms/hooks/topics/useTopic";
import CreateProgramTopics from "./CreateProgramTopic";
import UpdateProgramTopics from "./UpdateProgramTopics";
import {
  DeleteOutlined,
  EditOutlined,
  FullscreenOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import useSaveSortedProgramTopic from "../../../../academyPrograms/hooks/topics/useSaveSortedTopic";
import Button from "../../../../../shared/components/ui/Button";
import { ProgramTopicType } from "../../../../../types/dto/program";
import Modal from "../../../../../shared/components/ui/modal";
import SortableList from "../../../../../shared/components/ui/Sortable";
import useDeleteTopic from "../../../../academyPrograms/hooks/topics/useDeleteTopic";

export function ProgramTopicsItem({
  progmTopic,
  selected,
  dragListeners,
  opacity,
  onClick,
  onUpdate,
}: {
  progmTopic: any;
  selected?: boolean;
  dragListeners?: SyntheticListenerMap;
  opacity?: number;
  onClick?: () => void;
  onUpdate?: (
    promTopic: Pick<ProgramTopicType, "name" | "description" | "status" | 'id' | "program_module_id" | "position" >
  ) => void;

}) {
  const [showModalPrgramTopicUpdate, setShowModalPrgramTopicUpdate] =
    React.useState(false);
  const [showModalPrgramTopicRemove, setShowModalPrgramTopicRemove] =
    React.useState(false);
    const {showDeleteConfirm} = useDeleteTopic()

  return (
    <>
      <Modal
        className="w-[90%]"
        title="Editar tema"
        visible={showModalPrgramTopicUpdate}
        onCancel={() => {
          setShowModalPrgramTopicUpdate(false);
        }}
      >
        <UpdateProgramTopics
          programTopic={progmTopic}
          onSuccess={(promTopic) => {
            setShowModalPrgramTopicUpdate(false);
            onUpdate?.({
              ...progmTopic,
              promTopic,
            });
          }}
        />
      </Modal>
      <div
        aria-hidden="true"
        className={clsx(
          "mb-2 flex w-full cursor-pointer items-center gap-2  rounded-lg px-4 py-2 pr-1 shadow-md",
          "hover:ring-2 hover:ring-primary-500 hover:ring-offset-2 hover:ring-offset-white",
          "hover:transition-all hover:duration-200",
          {
            "bg-white text-black/50": !selected,
            "bg-blue-600 text-white": selected,
          }
        )}
        role="button"
        style={{ opacity }}
        onClick={onClick}
      >
        <div className="flex-1">
          <p className={clsx("text-sm font-semibold ")}>{progmTopic.name}</p>
        </div>
        <div className="flex items-center text-sm">
          <>
            <Button
              primary={!selected}
              size="small"
              type="button"
              variant="text"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setShowModalPrgramTopicUpdate(true);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              danger
              size="small"
              type="button"
              variant="text"
              onClick={(event) => {
                showDeleteConfirm(progmTopic.id, progmTopic.name)
              }}
            >
              <DeleteOutlined />
            </Button>
            <Button
              className="cursor-move"
              size="small"
              type="button"
              variant="text"
              {...dragListeners}
            >
              <FullscreenOutlined />
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default function ProgramsTopics({ moduleId }: { moduleId?: number }) {
  const [showModalCreateProgramModule, setShowModalCreateProgramModule] =
    React.useState(false);
  const {
    programModuleTopic,
    loading,
    refresh: refreshTopics,
    setProgramModuleTopic,
  } = useTopic(moduleId);

  const { selectedProgramModuleTopicId, onSelectedProgramModuleTopicId } =
    useLevelContext();

  React.useEffect(() => {
    refreshTopics();
  }, [moduleId]);

  const saveSorted = useSaveSortedProgramTopic();
  return (
    <div style={{ margin: 20 }}>
      <Modal
        className="w-[90%]"
        title="Crear un tema"
        visible={showModalCreateProgramModule}
        onCancel={() => {
          setShowModalCreateProgramModule(false);
        }}
      >
        <div className="flex flex-col gap-2">
          {!!moduleId ? (
            <CreateProgramTopics
              moduleId={moduleId}
              onSuccess={(section) => {
                refreshTopics()
                  .then(() => {
                    // TODO
                  })
                  .catch(console.error);
                setShowModalCreateProgramModule(false);
              }}
            />
          ) : null}
        </div>
      </Modal>
      <div className="w-72 flex-none rounded-lg bg-white p-3 shadow-lg">
        <div className="flex items-center gap-2">
          <h3 className="font-semibold">Temas</h3>
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-1">
              <Button
                primary
                size="small"
                type="button"
                variant="text"
                onClick={() => setShowModalCreateProgramModule(true)}
              >
                <PlusOutlined />
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-2">
          {programModuleTopic.length === 0 && !loading && (
            <p className="py-2 text-center text-sm text-black/30">
              <i className="fa-solid fa-info-circle" /> No hay temas creados
            </p>
          )}
          <SortableList
            key="id"
            customActivator
            id="id"
            items={programModuleTopic}
            renderItem={(programTopic, props) => (
              <ProgramTopicsItem
                progmTopic={programTopic}
                opacity={props?.activeId === programTopic.id ? 0.5 : 1}
                selected={programTopic.id === selectedProgramModuleTopicId}
                onClick={() => {
                  onSelectedProgramModuleTopicId(programTopic.id);
                }}
                onUpdate={() => {
                  refreshTopics().catch(console.error);
                }}
              />
            )}
            onSort={(sortedProgramTopic) => {
              const sortedPosition = sortedProgramTopic.map(
                (level, position) => ({
                  ...level,
                  position: position + 1,
                })
              );

              setProgramModuleTopic(sortedPosition);
              saveSorted(
                sortedPosition.map(({ id, position }) => ({
                  id: +id,
                  position,
                }))
              ).catch(console.error);
            }}
          />
        </div>
      </div>
    </div>
  );
}
