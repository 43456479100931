// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button, Col, Row, Spin, Typography } from "antd";

import {
  AdminMenuLayout,
  InputText,
  SimpleInputSelectMultiple,
} from "../../../../shared/components";
import { useListCustomerGroups } from "../../hooks";
import { WrapperCardListCustomerGroups } from "../styled";
import { useFormContext } from "react-hook-form";
import { DataListCustomerGroups } from "../../../../redux/@types";
import { useListCustomerGroupsById } from "../../hooks/listCustomerGroupsById/useListCustomerGroupsById";

const { Title } = Typography;

export const ListCustomerGroupsById = () => {
  const { isLoading, onSubmitCreateOrUpdate, singleListCustomerGroups } =
    useListCustomerGroups();

  const {
    addCustomers,
    deleteCustomers,
    setValuesSelected,
    userList,
    valuesSelected,
    isLoadingValuesSelected,
  } = useListCustomerGroupsById();

  const { control, handleSubmit: onSubmit } =
    useFormContext<DataListCustomerGroups>();
  //   console.log({ singleListCustomerGroups });
  return (
    <AdminMenuLayout
      pageDescription="ListCustomerGroups"
      title="ListCustomerGroups"
    >
      <WrapperCardListCustomerGroups>
        {!singleListCustomerGroups && <Spin size="large" />}
        {singleListCustomerGroups && (
          <Row
            gutter={[8, 8]}
            style={{
              padding: "10px",
              width: "100%",
            }}
          >
            <Col
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
                flexDirection: "column",
              }}
            >
              <Title
                style={{ marginBottom: "12.5px", color: "var(--primary)" }}
                level={3}
              >
                {`Actualizando: ${singleListCustomerGroups?.id}`}
              </Title>
            </Col>
            <Col
              span={8}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Nombre:
              </Title>
              <InputText
                placeHolder="grupo numero 1"
                name="name"
                control={control}
              />
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Grupo de usuarios:
              </Title>
              <SimpleInputSelectMultiple
                showSearch
                valuesSelect={valuesSelected}
                onChange={setValuesSelected}
                label={"Seleccione usuario"}
                dataOptions={userList || []}
                clearValue={deleteCustomers}
              />
              <Button
                style={{
                  maxWidth: "30rem",
                  marginTop: 10,
                }}
                type="primary"
                shape="round"
                size="large"
                onClick={addCustomers}
                loading={isLoadingValuesSelected}
              >
                Guardar usuarios
              </Button>
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Button
                style={{
                  width: "100%",
                  marginTop: 10,
                }}
                type="primary"
                shape="round"
                size="large"
                onClick={onSubmit(onSubmitCreateOrUpdate)}
                loading={isLoading}
              >
                Actualizar información
              </Button>
            </Col>
          </Row>
        )}
      </WrapperCardListCustomerGroups>
    </AdminMenuLayout>
  );
};
