import { IProductsState } from "../@types";
import { productsActions } from "../action-types";
import { IGenericArrProduct, IProductCharacteristics2, IProducts } from '../@types/products';

type productsActionType =
| { type: typeof productsActions.GET_PRODUCT_FOR_UPDATE; payload: IProducts }
| { type: typeof productsActions.IS_PRODUCT_FOR_UPDATE; payload: boolean }
| { type: typeof productsActions.SWITCH_PRODUCTS_REFRESH_TABLE; payload: boolean }
| { type: typeof productsActions.GET_CATEGORIES_PRODUCTS; payload: IGenericArrProduct[] }
| { type: typeof productsActions.GET_BRANDS_PRODUCTS; payload: IGenericArrProduct[] }
| { type: typeof productsActions.GET_TAX_PRODUCTS; payload: IGenericArrProduct[] }
//productCharacteristic
| { type: typeof productsActions.PRODUCTS_CHARACTERISTICS_CHANGE_MODAL_STATE }
| { type: typeof productsActions.GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE; payload: IProductCharacteristics2 }
| { type: typeof productsActions.IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE; payload: boolean }
| { type: typeof productsActions.SWITCH_PRODUCTS_CHARACTERISTICS_REFRESH_TABLE; payload: boolean }
| { type: typeof productsActions.GET_CHARACTERISTIC_PRODUCTS; payload: IGenericArrProduct[] }

const INITIAL_STATE: IProductsState = {
    productsForUpdate: undefined,
    isUpdateProducts: false,
    refreshTableProducts: false,
    productCharacteristicsForUpdate: undefined,
    isUpdateProductCharacteristics:false,
    refreshTableProductCharacteristics:false,
    productsCharacteristicModalIsOpen: false,
    arrCategories: [],
    arrBrands: [],
    arrTax: [],
    arrCharacteristic: [],
}

export default function productsReducer(state: IProductsState = INITIAL_STATE, action: productsActionType ): IProductsState {
    switch ( action.type ) {
        case productsActions.GET_PRODUCT_FOR_UPDATE:
            return{
                ...state,
                productsForUpdate: action.payload
            }
        case productsActions.IS_PRODUCT_FOR_UPDATE:
            return{
                ...state,
                isUpdateProducts: action.payload
            }
        case productsActions.SWITCH_PRODUCTS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableProducts: action.payload
            }
        case productsActions.GET_CATEGORIES_PRODUCTS:
            return{
                ...state,
                arrCategories: action.payload
            }
        case productsActions.GET_BRANDS_PRODUCTS:
            return{
                ...state,
                arrBrands: action.payload
            }
        case productsActions.GET_TAX_PRODUCTS:
            return{
                ...state,
                arrTax: action.payload
            }
        //productCharacteristics
        case productsActions.PRODUCTS_CHARACTERISTICS_CHANGE_MODAL_STATE:
            return{
                ...state,
                productsCharacteristicModalIsOpen: !state.productsCharacteristicModalIsOpen
            }
        case productsActions.GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE:
            return{
                ...state,
                productCharacteristicsForUpdate: action.payload
            }
        case productsActions.IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE:
            return{
                ...state,
                isUpdateProductCharacteristics: action.payload
            }
        case productsActions.SWITCH_PRODUCTS_CHARACTERISTICS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableProducts: action.payload
            }
        case productsActions.GET_CHARACTERISTIC_PRODUCTS:
            return{
                ...state,
                arrCharacteristic: action.payload
            }
        default:
            return state
    }
}