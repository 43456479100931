import type { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import {
  Input,
  Select,
  TextArea,
} from "../../../../shared/components/ui/form/inputs";
import { ProgramType } from "../../../../types/dto/program";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { Col, message, Typography } from "antd";
import Upload from "../../../../shared/components/ui/inputs/Upload";
import { citiesService, statesService } from "../../../../shared/services";
import React from "react";
import { InputSelect } from "../../../../shared/components";
const { Title } = Typography;

export default function ProgramForm({
  control,
  setValue,
  img_path,
  watch
}: {
  control: Control<ProgramType>;
  watch: UseFormWatch<ProgramType>;
  setValue: UseFormSetValue<ProgramType>;
  img_path?: string;
}) {
  const image_path_previus = watch("image_path")
  const [dataStatesOptions, setDataStatesOptions] = React.useState<{value: string, title: string}[]>([]);
  const [dataCitiesOptions, setDataCitiesOptions] = React.useState<any>([]);
  const selectedDepartment = watch("department");

  React.useEffect(() => {
    const fetchStates = async () => {
      const { data: dataDepartments } = await statesService.find();
      
      setDataStatesOptions(
        dataDepartments.map((item: any) => ({
          value: String(item.id),
          title: item.name,
        }))
      );
    };
    fetchStates();
  }, []);
  React.useEffect(() => {

    if (selectedDepartment) {
      const fetchCities = async () => {
        const { data: dataCities } = await citiesService.find({
          query: { state_id: +selectedDepartment, $limit: 1000 },
        });
        setDataCitiesOptions(
          dataCities.map((item: any) => ({
            value: String(item.name),
            title: item.name,
            state_id: item.state_id,
          }))
        );
      };
      fetchCities();
    }
  }, [selectedDepartment]);


  return (
    <div className="flex flex-col gap-3">
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Banner desktop:
        </Title>
        {img_path ? (
          <img
            src={`${PATH_S3_BUCKET}/${image_path_previus !== undefined ? image_path_previus : img_path }`}
            alt="path_movile"
            style={{
              marginBottom: "7px",
              width: "100%",
              height: "100%",
              maxWidth: "250px",
              maxHeight: "250px",
            }}
          />
        ) : (
          <div
            style={{
              marginBottom: "7px",
              width: "100%",
              height: "100%",
              maxWidth: "250px",
              maxHeight: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px dashed #ccc",
            }}
          >
            No hay imagen disponible
          </div>
        )}
        <Upload
          filePath="bannerDesktop"
          onUploadedFile={(files) => {
            const file = files[0];
            setValue("image_path", file.fileKey);
            message.success("Se ha actualizado el path Desktop");
          }}
        />
      </Col>
      <Input
        style={{ background: "none" }}
        control={control}
        label="Nombre del programa"
        name="name"
        placeholder="Nombre del programa"
      />
      <TextArea
        style={{ background: "none" }}
        control={control}
        label="Descripción"
        name="description"
        placeholder="description"
      />
      <Input
        style={{ background: "none" }}
        control={control}
        label="Valor"
        name="price"
        placeholder="100000"
        type="number"
      />
      <Input
        style={{ background: "none" }}
        control={control}
        label="Posición"
        name="position"
        placeholder="100000"
        type="number"
      />
      <Select
        style={{ background: "none" }}
        control={control}
        label="Estado"
        name="status"
      >
        <option value="">Seleccionar...</option>
        <option value="active">Activo</option>
        <option value="inactive">Inactivo</option>
      </Select>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Departamento:
        </Title>
        <InputSelect
          name="department"
          control={control}
          dataOptions={dataStatesOptions}
          label="Ciudad"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Ciudad:
        </Title>
        <InputSelect
          name="city"
          control={control}
          dataOptions={dataCitiesOptions}
          label="Ciudad"
        />
      </Col>
    </div>
  );
}
