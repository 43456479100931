import { citiesService } from "../../shared/services";
import { DataCities } from "../@types";
import { citiesActions } from "../action-types";

export const citiesChangeModalState = (): citiesActions.ICitiesModalState =>({
    type: citiesActions.CITIES_MODAL_STATE
})

export const setCityForUpdate = (val: DataCities): citiesActions.IGetCityForUpdate =>({
    type: citiesActions.GET_CITY_FOR_UPDATE,
    payload: val
})

export const setIsCityForUpdate = (val: boolean): citiesActions.IIsCityForUpdate =>({
    type: citiesActions.IS_CITIES_FOR_UPDATE,
    payload: val
})

export const createCity = async(data: Partial<DataCities>) =>{
    return await citiesService.create(data)
    .then((res: DataCities) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateCity =async(data: Partial<DataCities>) =>{
    return await citiesService.patch(data?.id!, data)
    .then((res: DataCities) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteCity = async(id: number) => {
    return await citiesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCities = (val: boolean): citiesActions.ISwitchCitiesRefreshTable =>({
    type: citiesActions.SWITCH_CITIES_REFRESH_TABLE,
    payload: val
})

export const getStatesForCities = (val: DataCities[]): citiesActions.IGetStatesCities =>({
    type: citiesActions.GET_STATES_CITIES,
    payload: val
})
