import { TRootState } from "../@types"

export const selectModalWalletMovements = ( state: TRootState ) =>state.walletMovements.walletMovementsModalIsOpen

export const selectIsUpdateWalletMovements = ( state: TRootState ) =>state.walletMovements.isUpdateWalletMovements

export const selectWalletMovementsForUpdate = ( state: TRootState ) =>state.walletMovements.walletMovementsForUpdate

export const selectWalletMovementsRefreshTable =  ( state: TRootState ) =>state.walletMovements.refreshTableWalletMovements

export const selectArrUsersForWalletMovements = ( state: TRootState ) =>state.walletMovements.arrUsers

export const selectObjUsersForWalletMovements = ( state: TRootState ) =>state.walletMovements.objUsers