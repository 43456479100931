"use client";
import type {Resolver} from "react-hook-form";

import React from "react";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {  programsTopicsAcademy } from "../../../../shared/services";
import { ProgramTopicType } from "../../../../types/dto/program";

export interface UseUpdateSectionProps {
  programTopic: Pick<ProgramTopicType, "name" | "description" | "status" | 'id' | "program_module_id" | "position">;
  onSuccess?: (level: ProgramTopicType) => void;
}

export default function useUpdateTopic({programTopic, onSuccess}: UseUpdateSectionProps) {
  const [loading, setLoading] = React.useState(false);

  const {
    control,
    handleSubmit: onSubmit,
    reset,
  } = useForm<ProgramTopicType>({
    resolver: yupResolver(
      yup.object({
      }),
    ) as unknown as Resolver<ProgramTopicType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading || !programTopic) return;
    setLoading(true);

    await programsTopicsAcademy
    .patch(programTopic.id, {...data, position: +data.position})
    .then((response) => {
        onSuccess?.(response);
      })
      .catch((err: Error) => {
      })
      .finally(() => {
        setLoading(false);
      });
  });

  React.useEffect(() => {
    if (programTopic) {
      reset({
        name: programTopic.name,
        description: programTopic.description,
        program_module_id: programTopic.program_module_id,
        position: programTopic.position,
        status: programTopic.status,
      });
    }
  }, [programTopic]);

  return {
    control,
    loading,
    handleSubmit,
  };
}
