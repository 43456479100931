"use client";
import React from "react";
import { programsTeacherAcademy, usersService } from "../../../../shared/services";
import { CaUserAcademy, ProgramTeacherType } from "../../../../types/dto/program";
import { userActions } from "../../../../redux";

export default function useProgramTeacher({programId}:{programId:number}) {
  const [loading, setLoading] = React.useState(false);
  const [programTeacher, setProgramTeacher] = React.useState<ProgramTeacherType[]>([]);
  const [teacher, setTeacher] = React.useState<CaUserAcademy[]>([]);

  const getProgramTeacher = async () => {
    if (loading) return;
    setLoading(true);

    await programsTeacherAcademy
      .find({query:{program_id: programId}})
      .then((response) => {
        setProgramTeacher(response.data);
      })
      .catch((err: Error) => {
        // Manejo de errores
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const getTeacher = async () => {
    if (loading) return;
    setLoading(true);

    await usersService
      .find({query:{ '$client[teacherWithoutPrograms]': programId}})
      .then((response) => {
        setTeacher(response.data);
      })
      .catch((err: Error) => {
        // Manejo de errores
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getProgramTeacher();
    getTeacher()
  }, [programId]);

  return {
    programTeacher,
    teacher,
    getProgramTeacher,
  };
}
