import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  AdminMenuLayout,
  ModalProductCharacteristics,
} from "../../../shared/components";
import { FormProvider } from "react-hook-form";
import { WrapperCardCharacteristics } from "./styled";
import { TableProductCharacteristics } from "./TableProductCharacteristics";
import { useProductCharacteristics } from "../hooks/useProductCharacteristics";
import { useSelector } from "react-redux";
import { selectModalProductCharacteristic } from "../../../redux/selectors/productCharacteristics.selectors";

export const ProductCharacteristics = () => {
  const modalProductCharacteristics = useSelector(
    selectModalProductCharacteristic
  );
  const {
    isUpdateProductCharacteristic,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalProductCharacteristicsState,
  } = useProductCharacteristics();
  return (
    <AdminMenuLayout
      pageDescription="ProductCharacteristics"
      title="ProductCharacteristics"
    >
      <WrapperCardCharacteristics>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalProductCharacteristicsState}
            >
              Agregar nueva característica de producto
            </Button>
          </Col>
        </Row>
        <TableProductCharacteristics />
        {modalProductCharacteristics && isUpdateProductCharacteristic && (
          <FormProvider {...formMethodsUpdate}>
            <ModalProductCharacteristics />
          </FormProvider>
        )}
        {modalProductCharacteristics && !isUpdateProductCharacteristic && (
          <FormProvider {...formMethodsCreate}>
            <ModalProductCharacteristics />
          </FormProvider>
        )}
      </WrapperCardCharacteristics>
    </AdminMenuLayout>
  );
};
