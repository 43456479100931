"use client";

import React from "react";
import { message } from "antd";
import { academyEquipmentsService } from "../../../../shared/services";
import { EquipmentsAttributesType } from "../../../../types/dto/equipments";

export default function useEquipment() {
  const [equipmentsData, setEquipmentsData] = React.useState<EquipmentsAttributesType[]>([]);
  const [loading, setLoading] = React.useState(false);

  const findEquipment = async () => {
    
    if (loading) return;
    setLoading(true);
    
    await academyEquipmentsService.find()
      .then((response) => {
        setEquipmentsData(response.data)
      })
      .catch((err: any) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(()=>{
    findEquipment()},[])

  return {
    loading,
    equipmentsData,
    findEquipment,
  };
}
