import Button from "../../../../../shared/components/ui/Button";
import { ProgramTechniquesType } from "../../../../../types/dto/program";
import useUpdateProgramTecnics from "../../../../academyPrograms/hooks/techniques/useUpdateProgramTechniques";
import ProgramModuleTechniquesForm from "./ProgramTechniquesForm";

export interface UpdateProgramModuleTechniquesProps {
  programTechniques: Pick<ProgramTechniquesType, "name" | "description" | "status" | 'id' | "program_module_topic_id" | "position">;
  onSuccess?: (programTopic: Pick<ProgramTechniquesType, "name" | "description" | "status" | 'id' | "program_module_topic_id" | "position">) => void;
}

export default function UpdateProgramModuleTechniques({programTechniques, onSuccess}: UpdateProgramModuleTechniquesProps) {
  const {control, loading, handleSubmit} = useUpdateProgramTecnics({
    programTechniques,
    onSuccess(data) {
      onSuccess?.(data);
    },
  });

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ProgramModuleTechniquesForm control={control} />
        <div className="mt-2 flex items-center justify-end">
          <Button primary  className="bg-[#072082]" loading={loading} type="submit" variant="filled">
            <i className="fa-solid fa-save" /> Actualizar
          </Button>
        </div>
      </form>
    </div>
  );
}
