import { useEffect } from "react"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authSetToken, authSetUserCredentials, authUserLogout, selectIsAuth } from "../../redux";
import useAppDispatch from "../../redux/hooks/useAppDispatch";
import { authenticationService } from "../../shared/services";

export const useAppRouter = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const auth = useSelector(selectIsAuth);
    let token = localStorage.getItem('jwt')
    let user = localStorage.getItem('user')
    const refreshToken = async () => {
        await authenticationService.retryLogin()
        .then((res: any)=>{
            dispatch(authSetToken(res.accessToken))
            dispatch(authSetUserCredentials(res.user))
            localStorage.setItem('jwt', res.accessToken)
            localStorage.setItem('user', JSON.stringify(res.user))
        })
        .catch((res)=>{
            console.log("error inesperado",res)
            localStorage.removeItem('jwt')
            localStorage.removeItem('user')
            dispatch(authUserLogout())
            navigate('/login')
        })
    }
    useEffect(() => {
        if( token && user ){
            refreshToken()
        }
    }, [])

    return {
        //state
        auth,
        //methods
        //functions
    }
}
