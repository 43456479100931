import { cMSService } from "../../shared/services"
import { DataCMS } from "../@types/cms"
import { cMSActions } from "../action-types"


export const cMSChangeModalState = (): cMSActions.ICMSModalState =>({
    type: cMSActions.CMS_MODAL_STATE
})

export const setCMSForUpdate = (val: DataCMS): cMSActions.IGetCMSForUpdate =>({
    type: cMSActions.GET_CMS_FOR_UPDATE,
    payload: val
})

export const setIsCMSForUpdate = (val: boolean): cMSActions.IIsCMSForUpdate =>({
    type: cMSActions.IS_CMS_FOR_UPDATE,
    payload: val
})

export const createCMS = async(data: Partial<DataCMS>) =>{
    return await cMSService.create(data)
    .then((res: DataCMS) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateCMS =async(data: Partial<DataCMS>) =>{
    return await cMSService.patch(data?.id!, data)
    .then((res: DataCMS) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteCMS = async(id: number) => {
    return await cMSService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCMS = (val: boolean): cMSActions.ISwitchCMSRefreshTable =>({
    type: cMSActions.SWITCH_CMS_REFRESH_TABLE,
    payload: val
})