import type { Control } from "react-hook-form";
import { Select } from "../../../../shared/components/ui/form/inputs";
import { ProgramType } from "../../../../types/dto/program";
import { Col, Typography } from "antd";
const { Title } = Typography;

export default function DuplicateProgramForm({
  control,
  programs,
}: {
  control: Control<ProgramType>;
  programs: ProgramType[];
}) {
  return (
    <div className="flex flex-col gap-3">
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Selecciona el programa a duplicar:
        </Title>
        <Select
          style={{ background: "none" }}
          control={control}
          label="Programa"
          name="program_id"
        >
          <option>Seleccionar...</option>
          {programs.map((program) => (
            <option value={program.id}>
              {program.id} - {program.name}
            </option>
          ))}
        </Select>
      </Col>
    </div>
  );
}
