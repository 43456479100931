import * as yup from 'yup'

export const validateUpdateCourseBenefits = yup.object().shape({
    name:yup
    .string()
    .min(2, 'El NOMBRE debe tener más de 2 caracteres'),
    position:yup
    .number()
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
})