"use client";
import type { Resolver } from "react-hook-form";

import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { programsAcademy } from "../../../../shared/services";
import { ProgramType } from "../../../../types/dto/program";
import { message } from "antd";

export interface UseCreateLevelProps {
  setOpenModal: (val: boolean) => void;
}

export default function useCreatePrograms({ setOpenModal }: UseCreateLevelProps) {
  const [loading, setLoading] = React.useState(false);
  const { control, handleSubmit: onSubmit, watch, setValue } = useForm<ProgramType>({
    resolver: yupResolver(
      yup.object({
        // Aquí puedes agregar validaciones si es necesario
      })
    ) as unknown as Resolver<ProgramType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);

    // Eliminamos el departamento de los datos a enviar

    await programsAcademy
      .create({ 
        city: data.city,
        description: data.description,
        name: data.name,
        position: +data.position,
        price: +data.price,
        status: data.status,
      })
      .then((response) => {
        setOpenModal(false);
        message.success("Programa creado correctamente");
      })
      .catch((err: Error) => {
        // Manejo de errores
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    setValue,
    watch,
    control,
    loading,
    handleSubmit,
  };
}
