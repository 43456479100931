// import {  contactsDirectoryMedia, coursesService } from "../../shared/services"
import { courseBenefitsService, courseChaptersService, courseSectionsService, coursesService } from "../../shared/services"
import { DataCourseBenefits, DataCourseChapters, DataCourses, DataCoursesCategories, DataCourseSection, DataCoursesForUpdate, IProducts } from "../@types"
import { coursesActions } from "../action-types"

export const coursesChangeModalState = (): coursesActions.ICoursesModalState =>({
    type: coursesActions.COURSES_MODAL_STATE
})

export const setCoursesForUpdate = (val: DataCoursesForUpdate): coursesActions.IGetCoursesForUpdate =>({
    type: coursesActions.GET_COURSES_FOR_UPDATE,
    payload: val
})

export const setIsCoursesForUpdate = (val: boolean): coursesActions.IIsCoursesForUpdate =>({
    type: coursesActions.IS_COURSES_FOR_UPDATE,
    payload: val
})

export const createCourses = async(data: Partial<DataCourses>) =>{
    return await coursesService.create(data)
    .then((res: DataCourses) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateCourses =async(data: Partial<DataCourses>) =>{
    return await coursesService.patch(data?.id!, data)
    .then((res: DataCourses) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteCourses = async(id: number) => {
    return await coursesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

// export const updateOrCreateMedia = async(val: { path: string, id?: number, contact_directory_id: number }) =>{
//     if(val.id){
//         return await contactsDirectoryMedia.patch(val?.id!, {path: val.path, contact_directory_id: val.contact_directory_id})
//         .then((res: any) => {return res})
//         .catch((err: any) => { return err.message })
//     }else{
//         return await contactsDirectoryMedia.create({path: val.path, contact_directory_id: val.contact_directory_id})
//         .then((res: any) => {return res})
//         .catch((err: any) => { return err.message })
//     }
// }

export const refreshTableCourses = (val: boolean): coursesActions.ISwitchCoursesRefreshTable =>({
    type: coursesActions.SWITCH_COURSES_REFRESH_TABLE,
    payload: val
})

export const getProductsCourses = (val: IProducts[]): coursesActions.IGetProductsCourses =>({
    type: coursesActions.GET_PRODUCTS_COURSES,
    payload: val
})

// export const getObjCitiesForCourses = (val: { [key: string]: string; }): coursesActions.IGetCitiesObjCourses =>({
//     type: coursesActions.GET_CITIES_OBJ_COURSES,
//     payload: val
// })

export const getCategoriesCourses = (val: DataCoursesCategories[]): coursesActions.IGetCategoriesCourses =>({
    type: coursesActions.GET_CATEGORY_COURSES,
    payload: val
})

//course-sections

export const courseSectionsChangeModalState = (): coursesActions.ICourseSectionsModalState =>({
    type: coursesActions.COURSE_SECTIONS_MODAL_STATE
})

export const setCourseSectionsForUpdate = (val: DataCourseSection): coursesActions.IGetCourseSectionsForUpdate =>({
    type: coursesActions.GET_COURSE_SECTIONS_FOR_UPDATE,
    payload: val
})

export const setIsCourseSectionsForUpdate = (val: boolean): coursesActions.IIsCourseSectionsForUpdate =>({
    type: coursesActions.IS_COURSE_SECTIONS_FOR_UPDATE,
    payload: val
})

export const createCourseSection = async(data: Partial<DataCourseSection>) =>{
    return await courseSectionsService.create(data)
    .then((res: DataCourseSection) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateCourseSection =async(data: Partial<DataCourseSection>) =>{
    return await courseSectionsService.patch(data?.id!, data)
    .then((res: DataCourseSection) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteCourseSection = async(id: number) => {
    return await courseSectionsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCourseSection = (val: boolean): coursesActions.ISwitchCourseSectionsRefreshTable =>({
    type: coursesActions.SWITCH_COURSE_SECTIONS_REFRESH_TABLE,
    payload: val
})

//course-chapters

export const courseChaptersChangeModalState = (): coursesActions.ICourseChaptersModalState =>({
    type: coursesActions.COURSE_CHAPTERS_MODAL_STATE
})

export const setCourseChaptersForUpdate = (val: DataCourseChapters): coursesActions.IGetCourseChaptersForUpdate =>({
    type: coursesActions.GET_COURSE_CHAPTERS_FOR_UPDATE,
    payload: val
})

export const setIsCourseChaptersForUpdate = (val: boolean): coursesActions.IIsCourseChaptersForUpdate =>({
    type: coursesActions.IS_COURSE_CHAPTERS_FOR_UPDATE,
    payload: val
})

export const createCourseChapters = async(data: Partial<DataCourseChapters>) =>{
    return await courseChaptersService.create(data)
    .then((res: DataCourseChapters) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateCourseChapters =async(data: Partial<DataCourseChapters>) =>{
    return await courseChaptersService.patch(data?.id!, data)
    .then((res: DataCourseChapters) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteCourseChapters = async(id: number) => {
    return await courseChaptersService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCourseChapters = (val: boolean): coursesActions.ISwitchCourseChaptersRefreshTable =>({
    type: coursesActions.SWITCH_COURSE_CHAPTERS_REFRESH_TABLE,
    payload: val
})

export const getSectionsCourseChapters = (val: DataCourseSection[]): coursesActions.IGetSectionsCourseChapters =>({
    type: coursesActions.GET_SECTIONS__COURSE_CHAPTERS,
    payload: val
})


//course-benefits

export const courseBenefitsChangeModalState = (): coursesActions.ICourseBenefitsModalState =>({
    type: coursesActions.COURSE_BENEFITS_MODAL_STATE
})

export const setCourseBenefitsForUpdate = (val: DataCourseBenefits): coursesActions.IGetCourseBenefitsForUpdate =>({
    type: coursesActions.GET_COURSE_BENEFITS_FOR_UPDATE,
    payload: val
})

export const setIsCourseBenefitsForUpdate = (val: boolean): coursesActions.IIsCourseBenefitsForUpdate =>({
    type: coursesActions.IS_COURSE_BENEFITS_FOR_UPDATE,
    payload: val
})

export const createCourseBenefits = async(data: Partial<DataCourseBenefits>) =>{
    return await courseBenefitsService.create(data)
    .then((res: DataCourseBenefits) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateCourseBenefits =async(data: Partial<DataCourseBenefits>) =>{
    return await courseBenefitsService.patch(data?.id!, data)
    .then((res: DataCourseBenefits) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteCourseBenefits = async(id: number) => {
    return await courseBenefitsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCourseBenefits = (val: boolean): coursesActions.ISwitchCourseBenefitsRefreshTable =>({
    type: coursesActions.SWITCH_COURSE_BENEFITS_REFRESH_TABLE,
    payload: val
})

