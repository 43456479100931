import * as yup from 'yup'

export const validateUpdateContactsDirectory = yup.object().shape({
    name:yup
    .string()
    .min(2, 'El NOMBRE debe tener más de 2 caracteres'),
    city_id: yup
    .number().typeError('Campo requerido.')
    .positive(),
    user_id: yup
    .string().typeError('Campo requerido.'),
    address: yup
    .string()
    .min(7, 'El DIRECCIÓN debe tener más de 6 caracteres'),
    category_id:yup
    .number().typeError('Campo requerido.')
    .positive(),
    // attributes: yup
    // .array()
    // .of(yup.string()),
    // score: yup
    // .number().typeError('Campo requerido.')
    // .positive()
    // .min(0, 'Debe de utilizar un numero que no sea negativo'),
    // short_description:yup
    // .string()
    // .min(2, 'LA DESCRIPCIÓN CORTA debe tener más de 2 caracteres'),
    // description:yup
    // .string()
    // .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    whatsapp_phone:yup
    .string()
    .typeError('Campo requerido.')
    .min(4, 'El WHATSAPP debe tener más de 3 caracteres numéricos'),
    phone:yup
    .string()
    .typeError('Campo requerido.')
    .min(4, 'El TELÉFONO debe tener más de 3 caracteres numéricos'),
    path_cover:yup
    .string()
    .min(2, 'La IMAGEN debe tener más de 2 caracteres'),
    status:yup
    .string()
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
    years_of_experience: yup
    .number().typeError('Campo requerido.')
    .positive()
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    job_availability:yup
    .string()
    .oneOf(['tiempo_completo', 'medio_tiempo', 'trabajo_independiente'], 'Debes elegir entre estas opciones'),
})