import * as yup from 'yup'

export const validateCreateSystemVariables = yup.object().shape({
    name:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El NOMBRE debe tener más de 2 caracteres'),
    key:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'LA CLAVE debe tener más de 2 caracteres'),
    value:yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El VALOR debe tener más de 2 caracteres'),
})