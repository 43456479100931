import { Input, Space } from 'antd';

const { Search } = Input;

interface Props {
  width?: string;
  height?: string;
  placeHolder: string;
  size?: 'small' | 'middle' | 'large' | undefined;
}

export const InputSearch = ({ width="100%", height="100%", placeHolder, size="large"}:Props) => {

    const onSearch = (value: string) => console.log(value);

  return (
    <div style={{ display: "flex", justifyContent: "center",width, height}}>
      <Search
        placeholder={placeHolder}
        allowClear
        enterButton
        size={size}
        onSearch={onSearch}
      />
    </div>
  )
}