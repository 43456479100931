"use client";
import Button from "../../../../../shared/components/ui/Button";
import useEditEquipmentRents from "../../../hooks/equipmentRents/useEditEquipmentRents";
import useEquipmentRentsById from "../../../hooks/equipmentRents/useEquipmentRentsById";
import EditEquipmentRentsForm from "./EditEquipmentRentsForm";

export interface EditEquipmentProps {
  equipmentRentById: number;
  setOpenModal: (value: boolean) => void;
}

export default function EditEquipmentRents({
  equipmentRentById,
  setOpenModal,
}: EditEquipmentProps) {
  const { equipmentRentData } = useEquipmentRentsById(equipmentRentById);

  const { control, loading, handleSubmit } = useEditEquipmentRents({
    equipmentRentData: equipmentRentData!,
    setOpenModal,
  });

  return (
    <form onSubmit={handleSubmit}>
      <EditEquipmentRentsForm control={control} />
      <div className="mt-4 flex items-center justify-end">
        <Button
          primary
          className="bg-[#072082]"
          loading={loading}
          type="submit"
          variant="filled"
        >
          Actualizar
        </Button>
      </div>
    </form>
  );
}
