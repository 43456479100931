import { DataCMS } from "../@types/cms"

export const CMS_MODAL_STATE = '[ CMS ] CMS Modal State'

export interface ICMSModalState {
    type: typeof CMS_MODAL_STATE
}

export const GET_CMS_FOR_UPDATE = '[ CMS ] Get CMS For Update'

export interface IGetCMSForUpdate {
    type: typeof GET_CMS_FOR_UPDATE
    payload: DataCMS
}

export const IS_CMS_FOR_UPDATE ='[ CMS ] Is CMS For Update'

export interface IIsCMSForUpdate {
    type: typeof IS_CMS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_CMS_REFRESH_TABLE = '[ CMS ] Switch CMS Refresh Table'

export interface ISwitchCMSRefreshTable{
    type: typeof SWITCH_CMS_REFRESH_TABLE
    payload: boolean
}