import { Drawer } from "antd";
import EditEquipment from "../../../../modules/AcademyEquipments/ui/equipments/form/editEquipment";

export const ModalEquipmentEdit = ({openModal, setOpenModal, equipmentById}: {openModal:boolean, setOpenModal: (value: boolean)=>void, equipmentById: number}) => {
  return (
    <Drawer
      title={`Actualiza un euqipo`}
      open={openModal}
      width={"320px"}
      destroyOnClose
      onClose={()=>setOpenModal(false)}
    >
      <EditEquipment setOpenModal={setOpenModal} equipmentById={equipmentById} />
    </Drawer>
  );
};
