import { AcademyProgramDetail } from "../modules/academyProgramDetail/ui/AcademyProgramDetail";
import { LevelContextProvider } from "../modules/academyPrograms/context";
import { AdminMenuLayout } from "../shared/components";

export default function AcademyProgramsDetailsPage() {
  return (
    <AdminMenuLayout title="" pageDescription="">
      <LevelContextProvider>
        <AcademyProgramDetail/>
      </LevelContextProvider>
    </AdminMenuLayout>
  );
}
