import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  useProductCharacteristics2,
  useTableProductCharacteristics2,
  WrapperModalProducts,
} from "../../../../modules/products";
import {
  selectIsUpdateProductCharacteristic2,
  selectProductCharacteristicForUpdate2,
} from "../../../../redux";

import { IProductCharacteristics2 } from "../../../../redux/@types";
import { InputSelect, InputText } from "../inputs";
import { selectModalProductCharacteristic2 } from "../../../../redux/selectors/products.selectors";

const { Title } = Typography;

export const ProductCharacteristics2 = () => {
  const modalProductCharacteristic2 = useSelector(
    selectModalProductCharacteristic2
  );
  const isUpdateProductCharacteristic = useSelector(
    selectIsUpdateProductCharacteristic2
  );
  const updateProductCharacteristic = useSelector(
    selectProductCharacteristicForUpdate2
  );

  const {
    isLoading,
    onSubmitCreateOrUpdate,
    navigateProductCharacteristicCreate,
  } = useProductCharacteristics2();
  const { arrCharacteristics } = useTableProductCharacteristics2();

  const { control, handleSubmit: onSubmit } =
    useFormContext<IProductCharacteristics2>();

  return (
    <WrapperModalProducts
      title={
        isUpdateProductCharacteristic
          ? `Actualizando característica de producto: '${updateProductCharacteristic?.id!}'`
          : `Creando característica de producto`
      }
      open={modalProductCharacteristic2}
      width={"320px"}
      destroyOnClose
      onClose={navigateProductCharacteristicCreate}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Caracteristica:
        </Title>
        <InputSelect
          name="characteristic_id"
          control={control}
          dataOptions={arrCharacteristics.map((item) => ({
            title: item.name,
            value: item.id.toString(),
          }))}
          label="Categoría principal"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Valor de la característica:
        </Title>
        <InputText
          placeHolder="Valor de la característica"
          name="characteristic_value"
          control={control}
        />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateProductCharacteristic
          ? "Actualizar información"
          : "Crear caract. de producto"}
      </Button>
    </WrapperModalProducts>
  );
};
