"use client";

import React from "react";
import { message } from "antd";
import { academyEquipmentsService } from "../../../../shared/services";
import { EquipmentsAttributesType } from "../../../../types/dto/equipments";

export default function useEquipmentById() {
  const [equipmentData, setEquipmentData] = React.useState<EquipmentsAttributesType>();
  const [loading, setLoading] = React.useState(false);

  const getEquipmentById = async (id: number) => {
    
    if (loading) return;
    setLoading(true);
    
    await academyEquipmentsService.get(id)
      .then((response) => {
        setEquipmentData(response)
      })
      .catch((err: any) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    equipmentData,
    getEquipmentById
  };
}
