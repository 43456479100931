import { DataFulfillmentCompany } from "../@types"

export const FULFILLMENT_COMPANY_MODAL_STATE = '[ FULFILLMENT COMPANY ] Fulfillment Company Modal State'

export interface IFulfillmentCompanyModalState {
    type: typeof FULFILLMENT_COMPANY_MODAL_STATE
}

export const GET_FULFILLMENT_COMPANY_FOR_UPDATE = '[ FULFILLMENT COMPANY ] Get Fulfillment Company For Update'

export interface IGetFulfillmentCompanyForUpdate {
    type: typeof GET_FULFILLMENT_COMPANY_FOR_UPDATE
    payload: DataFulfillmentCompany
}

export const IS_FULFILLMENT_COMPANY_FOR_UPDATE ='[ FULFILLMENT COMPANY ] Is Fulfillment Company For Update'

export interface IIsFulfillmentCompanyForUpdate {
    type: typeof IS_FULFILLMENT_COMPANY_FOR_UPDATE
    payload: boolean
}

export const SWITCH_FULFILLMENT_COMPANY_REFRESH_TABLE = '[ FULFILLMENT COMPANY ] Switch Fulfillment Company Refresh Table'

export interface ISwitchFulfillmentCompanyRefreshTable{
    type: typeof SWITCH_FULFILLMENT_COMPANY_REFRESH_TABLE
    payload: boolean
}

export const CHANGE_KEY_OF_FULFILLMENT_COMPANY = '[ FULFILLMENT COMPANY ] Change Key Of Fulfillment Company'

export interface IChangeKeyOfFulfillmentCompany{
    type: typeof CHANGE_KEY_OF_FULFILLMENT_COMPANY
    payload: number
}
