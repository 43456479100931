"use client";

import React from "react";
import { Resolver, useForm } from "react-hook-form";
import { message } from "antd";
import { academyEquipmentRentsService, academyEquipmentsService } from "../../../../shared/services";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RentEquipmentStudentsAttributesType } from "../../../../types/dto/equipments";

export interface UseCreateEquipmentRentsProps {
  setOpenModal: (val: boolean) => void;
  equipment_id: number
}

export default function useCreateEquipmentRents({
  setOpenModal,
  equipment_id
}: UseCreateEquipmentRentsProps) {
  const [loading, setLoading] = React.useState(false);

  const { control, handleSubmit: onSubmit } = useForm<RentEquipmentStudentsAttributesType>({
    resolver: yupResolver(
      yup.object({
        user_id: yup.string().required("Campo requerido"),
        rent_date: yup.string().required("Campo requerido"),
        return_expected_date: yup.string().required("Campo requerido"),
        quantity: yup.string().required("Campo requerido"),
      })
    ) as unknown as Resolver<RentEquipmentStudentsAttributesType>,
  });
  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    await academyEquipmentRentsService.create({...data, quantity: +data.quantity!, rent_equipment_id: equipment_id}, {query: {skipJoin: 'true'}})
      .then((response) => {
        message.success(response.message);
        setOpenModal(false);
      })
      .catch((err: any) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    loading,
    control,
    handleSubmit,
  };
}
