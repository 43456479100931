import AssignedStudentPrograms from "../../../../modules/academyProgramDetail/ui/programStudents/AssignedStudentPrograms";
import { WrapperModalAssignedStudentProgram } from "./styled";

export const ModalAssignedStudentProgram = ({openModal, setOpenModal}: {openModal: boolean, setOpenModal: (value: boolean)=>void}) => {

  return (
    <WrapperModalAssignedStudentProgram
      title={`Asignar estudiante al programa`}
      open={openModal}
      width={"320px"}
      destroyOnClose
      onClose={()=>setOpenModal(false)}
    >
      <AssignedStudentPrograms setOpenModal={setOpenModal}/>
    </WrapperModalAssignedStudentProgram>
  );
};
