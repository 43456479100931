import { Button, Col, Typography } from "antd";
import {
  EyeOutlined,
} from "@ant-design/icons";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import { ProgramStudentType } from "../../../../types/dto/program";
import Table from "../../../../shared/components/ui/Table/prueba";
import { programsTeacherAcademy } from "../../../../shared/services";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

interface TableProgramProps {
  studentId: number;
  programId: number;
}

export const TableProgramPersonalInfoTeacher = ({ studentId, programId }: TableProgramProps) => {
const navigate = useNavigate()
  
  const columns: TableColumns<ProgramStudentType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 1,
      filterType: "filterByID",
    },
    {
      title: "Nombre del programadd",
      width: 2,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
              {item?.program?.name}
            </Title>
          </Col>
        );
      },
    },
    {
      title: "Acciones",
      key: "operation",
      width: 1,
      fixed: "right",
      render: (_, item: ProgramStudentType) => {
        return (
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              size="large"
              onClick={() => navigate(`/gestion-academy/programs/details/${item.program_id}`)}
            />
          </Col>
        );
      },
    },
  ];

  return (
    <>
      <Table<ProgramStudentType>
        columns={columns}
        scroll={{}}
        fetchQueryProps={{
          user_id: studentId,
          program_id: programId,
          $sort: { id: -1 },
        }}
        service={programsTeacherAcademy}
      />
    </>
  );
};
