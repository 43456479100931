import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { createDirectoryCategories, directoryCategoriesChangeModalState, refreshTableDirectoryCategories, selectDirectoryCategoriesForUpdate, selectDirectoryCategoriesRefreshTable, selectIsUpdateDirectoryCategories, setIsDirectoryCategoriesForUpdate, updateDirectoryCategories } from "../../../redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { DataDirectoryCategories } from "../../../redux/@types";
import { message } from "antd";
import { validateCreateDirectoryCategories, validateUpdateDirectoryCategories } from "../../../helpers";

export const useDirectoryCategories = () => {
    const dispatch = useAppDispatch();

    const isUpdateDirectoryCategories = useSelector(
      selectIsUpdateDirectoryCategories
    );
    const dataUpdateDirectoryCategories = useSelector(
      selectDirectoryCategoriesForUpdate
    );
    const isRefreshTableDirectoryCategories = useSelector(
      selectDirectoryCategoriesRefreshTable
    );

    const [isLoading, setIsLoading] = useState(false);
    const [tableProps, setTableProps] = useState<TableProperties>();

    const changeModalDirectoryCategoriesState = () => {
      dispatch(setIsDirectoryCategoriesForUpdate(false));
      dispatch(directoryCategoriesChangeModalState());
    };

    const formMethodsCreate = useForm<DataDirectoryCategories>({
      resolver: yupResolver(validateCreateDirectoryCategories),
    });

    const formMethodsUpdate = useForm<DataDirectoryCategories>({
      resolver: yupResolver(validateUpdateDirectoryCategories),
    });

    useEffect(() => {
      if (isUpdateDirectoryCategories) {
        formMethodsCreate.reset();
        formMethodsUpdate.reset({ ...dataUpdateDirectoryCategories });
      }

      return () => {
        formMethodsUpdate.reset();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateDirectoryCategories, dataUpdateDirectoryCategories]);

    const onSubmitCreateOrUpdate = async (data: DataDirectoryCategories) => {
      // console.log({ data });
      if (isLoading) return;
      setIsLoading(true);
      if (isUpdateDirectoryCategories) {
        //console.log("Actualiando===>", data);
        await updateDirectoryCategories({
          id: data.id,
          name: data.name,
        })
          .then(async(res: Partial<DataDirectoryCategories>) => {
            setIsLoading(false);
            // dispatch(mappingUpdatedUser(res))
            if (res.id) {
              // dispatch(directoryCategoriesChangeModalState());
              message.success(
                `Se ha actualizado con éxito la categoría con id:${res.id}`
              );
              dispatch(refreshTableDirectoryCategories(true));
            } else {
              message.error(res as string);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            console.log(err);
            message.error(err.message);
          });
      } else {
        // console.log('Creando===>',data)
        await createDirectoryCategories(data)
          .then((res: DataDirectoryCategories) => {
            setIsLoading(false);
            if (res.id) {
              dispatch(directoryCategoriesChangeModalState());
              message.success(
                `Se ha creado con éxito la categoría con id:${res.id}`
              );
              dispatch(refreshTableDirectoryCategories(true));
            } else {
              message.error(res as any);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            console.log(err);
            message.error(err.message);
          });
      }
    };

    useEffect(() => {
      if (isRefreshTableDirectoryCategories) {
        tableProps?.refresh();
        dispatch(refreshTableDirectoryCategories(false));
      }
    }, [isRefreshTableDirectoryCategories]);

  return {
    //state
    isUpdateDirectoryCategories,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalDirectoryCategoriesState,
  }
}
