import { Button, Col, Row } from "antd";
import { PlusOutlined, FilterOutlined, DownloadOutlined } from "@ant-design/icons";

import { AdminMenuLayout } from "../../../shared/components";
import { CounterProductsTable } from "./CounterProductsTable";
import { WrapperCard } from "./styled";
import { useCounterProductsTable, useProducts } from "../hooks";
import { TableProducts } from "./TableProducts";

export const Products = () => {
  const { querySelectorTransform,navigateProductsCreate, handleQueryTransform } = useProducts();
  const { querySelector,counterArr, getUpdateQuerySelector, setQuerySelector, getDownloadProductsWithFilters } = useCounterProductsTable({handleQueryTransform})
  return (
    <AdminMenuLayout pageDescription="Products" title="Products">
      <WrapperCard>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="large"
              style={{
                marginRight: "5px",
              }}
              onClick={getDownloadProductsWithFilters}
            >
              Descargar lista de productos
            </Button>
            <Button
              type="primary"
              shape="round"
              style={{
                marginRight: "5px",
              }}
              icon={<FilterOutlined />}
              size="large"
              onClick={()=>{
                setQuerySelector([])
                handleQueryTransform([])
              }}
            >
              Limpiar filtros
            </Button>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={navigateProductsCreate}
            >
              Agregar nuevo producto
            </Button>
          </Col>
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <CounterProductsTable getUpdateQuerySelector={getUpdateQuerySelector} querySelector={querySelector} counterArr={counterArr} />
          </Col>
        </Row>
        <TableProducts fetchQuery={querySelectorTransform}/>
      </WrapperCard>
    </AdminMenuLayout>
  );
};
