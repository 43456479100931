import EditAssignedStudentPrograms from "../../../../modules/academyProgramDetail/ui/programStudents/EditAssignedStudentPrograms";
import { WrapperModalUsers } from "./styled";


export const ModalEditStudentProgram = ({openModal, setOpenModal, programStudentId}: {openModal: boolean, setOpenModal: (value: boolean)=>void, programStudentId: number}) => {

  return (
    <WrapperModalUsers
      title={`Editar programa del estudiante`}
      open={openModal}
      width={"320px"}
      destroyOnClose
      onClose={()=>setOpenModal(false)}
    >
      <EditAssignedStudentPrograms programStudentId={+programStudentId} setOpenModal={setOpenModal}/>
    </WrapperModalUsers>
  );
};
