"use client";
import Button from "../../../../shared/components/ui/Button";
import { ProgramStudentModuleTechniqueType } from "../../../../types/dto/program";
import { Resolver, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useProgramModuleTechniques from "../../hooks/useProgramModuleTechniques";
import EditStudentQualificationForm from "./EditStudentQualificationForm";
import useEditQualificationStudent from "../../hooks/qualifications/useEditQualificationStudent";

export default function EditStudentQualification({
  setOpenModal,
  techeniqueId,
}: {
  setOpenModal: (val: boolean) => void;
  techeniqueId: number
}) {

  const {
    control,
    handleSubmit: onSubmit,
    reset
  } = useForm<ProgramStudentModuleTechniqueType>({
    resolver: yupResolver(
      yup.object({})
    ) as unknown as Resolver<ProgramStudentModuleTechniqueType>,
  });


  const { getStudentModuleTechniqueDetails} = useProgramModuleTechniques(techeniqueId);

  const { handleSubmit } = useEditQualificationStudent({
    reset,
    getStudentModuleTechniqueDetails: getStudentModuleTechniqueDetails!,
    onSubmit,
    setOpenModal
  });

  
  return (
    <form onSubmit={handleSubmit}>
      <EditStudentQualificationForm control={control}/>
      <div className="mt-4 flex items-center justify-end">
        <Button primary className="bg-[#072082]" type="submit" variant="filled">
          <i className="fa-regular fa-floppy-disk" /> Actualizar
        </Button>
      </div>
    </form>
  );
}
