import { useEffect, useState } from "react";
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  courseSectionsChangeModalState,
  createCourseSection,
  deleteCourseSection,
  refreshTableCourseSection,
  selectCourseSectionsForUpdate,
  selectCourseSectionsRefreshTable,
  selectIsUpdateCourseSections,
  setCourseSectionsForUpdate,
  setIsCourseSectionsForUpdate,
  updateCourseSection,
} from "../../../../redux";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../../shared/components/ui/Table/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { DataCourseSection } from "../../../../redux/@types";
import {
  validateCreateCourseSections,
  validateUpdateCourseSections,
} from "../../../../helpers";

const { confirm } = Modal;

export const useCourseSections = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const isUpdateCourseSections = useSelector(selectIsUpdateCourseSections);
  const dataUpdateCourseSections = useSelector(selectCourseSectionsForUpdate);
  const isRefreshTableCourseSections = useSelector(
    selectCourseSectionsRefreshTable
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalCourseSectionsState = () => {
    dispatch(setIsCourseSectionsForUpdate(false));
    dispatch(courseSectionsChangeModalState());
  };

  const formMethodsCreate = useForm<DataCourseSection>({
    resolver: yupResolver(validateCreateCourseSections),
  });

  const formMethodsUpdate = useForm<DataCourseSection>({
    resolver: yupResolver(validateUpdateCourseSections),
  });

  useEffect(() => {
    if (isUpdateCourseSections) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateCourseSections });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateCourseSections, dataUpdateCourseSections]);

  const onSubmitCreateOrUpdate = async (data: DataCourseSection) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateCourseSections) {
      //console.log("Actualiando===>", data);
      await updateCourseSection({
        id: data.id,
        name: data.name,
        position: data.position,
      })
        .then((res: Partial<DataCourseSection>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            dispatch(courseSectionsChangeModalState());
            message.success(
              `Se ha actualizado con éxito la sección de curso con id:${res.id}`
            );
            dispatch(refreshTableCourseSection(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createCourseSection({
        ...data,
        course_id: Number(pathname.split("/")[2]),
      })
        .then((res: DataCourseSection) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(courseSectionsChangeModalState());
            message.success(
              `Se ha creado con éxito la sección de curso con id:${res.id}`
            );
            dispatch(refreshTableCourseSection(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableCourseSections) {
      tableProps?.refresh();
      dispatch(refreshTableCourseSection(false));
    }
  }, [isRefreshTableCourseSections]);

  const changeCourseSectionsForUpdate = (val: DataCourseSection) => {
    dispatch(setIsCourseSectionsForUpdate(true));
    dispatch(setCourseSectionsForUpdate(val));
    dispatch(courseSectionsChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la sección con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar sección" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar sección",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteCourseSection(valId)
          .then(() => {
            dispatch(refreshTableCourseSection(true));
            message.success(
              `Se ha borrado con éxito la sección con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    isUpdateCourseSections,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalCourseSectionsState,
    changeCourseSectionsForUpdate,
    showDeleteConfirm,
  };
};
