import { IGenericArrStores, IStore } from "../@types/stores"

export const STORES_CHANGE_MODAL_STATE = '[ STORES ] Stores Change Modal State'

export interface IStoresChangeModalState {
    type: typeof STORES_CHANGE_MODAL_STATE
}

export const GET_STORE_FOR_UPDATE = '[ STORES ] Get Stores For Update'

export interface IGetStoresForUpdate {
    type: typeof GET_STORE_FOR_UPDATE
    payload: IStore
    // DataIProducts
}

export const IS_STORE_FOR_UPDATE = '[ STORES ] Is Stores For Update'

export interface IIsStoresForUpdate {
    type: typeof IS_STORE_FOR_UPDATE
    payload: boolean
}

export const SWITCH_STORES_REFRESH_TABLE = '[ STORES ] Switch Stores Refresh Table'

export interface ISwitchStoresRefreshTable {
    type: typeof SWITCH_STORES_REFRESH_TABLE
    payload: boolean
}

export const GET_CITIES_FOR_STORES = '[ STORES ] Get Cities For Stores'

export interface IGetCitiesForStores {
    type: typeof GET_CITIES_FOR_STORES
    payload: IGenericArrStores[]
}