import { Controller, type Control } from "react-hook-form";
import { DatePicker, Input } from "antd";
import { CopyRentEquipmentStudentsAttributesType } from "../../../../../types/dto/equipments";

export default function EditEquipmentRentsForm({
  control,
}: {
  control?: any;
}) {
  return (
    <div className="flex flex-col gap-3">
      <div>
        <label>
          <strong>Fecha de retiro</strong>
        </label>
        <Controller
          control={control}
          name="rent_date"
          render={({ field, fieldState }) => (
            <div>
              <DatePicker
                style={{ width: "100%", borderRadius: 5, height: 36 }}
                onChange={field.onChange}
              />
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
      <div>
        <label>
          <strong>Fecha estimada de entrega</strong>
        </label>
        <Controller
          control={control}
          name="return_expected_date"
          render={({ field, fieldState }) => (
            <div>
              <DatePicker
                style={{ width: "100%", borderRadius: 5, height: 36 }}
                onChange={field.onChange}
              />
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
      <div>
        <label>
          <strong>Cantidad de equipos</strong>
        </label>
        <Controller
          control={control}
          name="quantity"
          render={({ field, fieldState }) => (
            <div>
              <Input
                style={{ width: "100%", borderRadius: 5, height: 36 }}
                onChange={field.onChange}
              />
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
      <div>
        <label>
          <strong>Fecha de devolución del equipo</strong>
        </label>
        <Controller
          control={control}
          name="real_return_date"
          render={({ field, fieldState }) => (
            <div>
              <DatePicker
                style={{ width: "100%", borderRadius: 5, height: 36 }}
                onChange={field.onChange}
              />
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
    </div>
  );
}
