import { useState, useEffect, ReactNode } from "react";
import { Modal } from "antd";

import { WrapperModalGallery } from "../../../../modules/products";

interface PropsModal {
  children: ReactNode;
  title: string;
  maskClosable?: boolean;
  destroyOnClose?: boolean;
  closable?: boolean;
  onCancel?: any;
  footer?: ReactNode;
  width?: number | string;
  visibleState: boolean;
  height?: number | string;
}

export const ModalGalleryMedia = ({
  children,
  title,
  maskClosable = true,
  destroyOnClose = true,
  closable = true,
  onCancel = null,
  footer = null,
  width = 650,
  height = 600,
  visibleState,
  ...props
}: PropsModal) => {
  const [visible, setVisible] = useState(visibleState);

  useEffect(() => {
    setVisible(visibleState);
    return () => {};
  }, [visibleState]);
  return (
    <WrapperModalGallery
      centered
      width={width}
      destroyOnClose={destroyOnClose}
      maskClosable={maskClosable}
      closable={closable}
      title={title}
      style={{ top: 20, height: height }}
      open={visible}
      onCancel={() => {
        Modal.destroyAll();
        setVisible(false);
        if (onCancel) onCancel();
      }}
      footer={footer}
    >
      <div className={`modal-content ${title ? "headline" : ""}`}>
        {children}
      </div>
    </WrapperModalGallery>
  );
};
