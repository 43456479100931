import { DataDiscountCode } from "../@types"

export const DISCOUNT_CODE_MODAL_STATE = '[ DISCOUNT CODE ] Discount Code Modal State'

export interface IDiscountCodeModalState {
    type: typeof DISCOUNT_CODE_MODAL_STATE
}

export const GET_DISCOUNT_CODE_FOR_UPDATE = '[ DISCOUNT CODE ] Get Discount Code For Update'

export interface IGetDiscountCodeForUpdate {
    type: typeof GET_DISCOUNT_CODE_FOR_UPDATE
    payload: DataDiscountCode
}

export const IS_DISCOUNT_CODE_FOR_UPDATE ='[ DISCOUNT CODE ] Is Discount Code For Update'

export interface IIsDiscountCodeForUpdate {
    type: typeof IS_DISCOUNT_CODE_FOR_UPDATE
    payload: boolean
}

export const SWITCH_DISCOUNT_CODE_REFRESH_TABLE = '[ DISCOUNT CODE ] Switch Discount Code Refresh Table'

export interface ISwitchDiscountCodeRefreshTable {
    type: typeof SWITCH_DISCOUNT_CODE_REFRESH_TABLE
    payload: boolean
}
