import { IUIState } from "../@types";
import { uiActions } from "../action-types";


type uiActionType =
| { type: typeof uiActions.UI_CHANGE_COLLAPSED_STATE }

const INITIAL_STATE:IUIState = {
    collapsed: false,
}

export default function uiReducer(state: IUIState = INITIAL_STATE, action: uiActionType): IUIState {
    switch (action.type) {
        case uiActions.UI_CHANGE_COLLAPSED_STATE:
            return{
                ...state,
                collapsed: !state.collapsed
            }
        default:
            return state
    }
}