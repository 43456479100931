import { TRootState } from '../@types/redux';

export const selectModalBanners= ( state: TRootState) =>state.banners.bannersModalIsOpen

export const selectIsUpdateBanners= ( state: TRootState) =>state.banners.isUpdateBanners

export const selectBannersForUpdate= ( state: TRootState) =>state.banners.bannersForUpdate

export const selectBannersRefreshTable= ( state: TRootState) =>state.banners.refreshTableBanners

export const selectBannersProductsRefreshTable= ( state: TRootState) =>state.banners.refreshTableBannersProducts
