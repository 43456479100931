import AcademyEquipments from "../modules/AcademyEquipments/AcademyEquipments";
import { AdminMenuLayout } from "../shared/components";

export default function AcademyEquipmentsPage () {
  return ( 
    <AdminMenuLayout title="" pageDescription="">
        <AcademyEquipments />
    </AdminMenuLayout>
  );
}
