import Button from "../../../../../shared/components/ui/Button";
import { ProgramTopicType } from "../../../../../types/dto/program";
import useUpdateTopic from "../../../../academyPrograms/hooks/topics/useUpdateTopic";
import ProgramTopicsForm from "./ProgramTopicsForm";

export interface UseUpdateSectionProps {
  programTopic: Pick<ProgramTopicType, "name" | "description" | "status" | 'id' | "program_module_id" | "position" >;
  onSuccess?: (programTopic: Pick<ProgramTopicType, "name" | "description" | "status" | 'id' | "program_module_id">) => void;
}

export default function UpdateProgramTopics({programTopic, onSuccess}: UseUpdateSectionProps) {
  const {control, loading, handleSubmit} = useUpdateTopic({
    programTopic,
    onSuccess(data) {
      onSuccess?.(data);
    },
  });

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ProgramTopicsForm control={control} />
        <div className="mt-2 flex items-center justify-end">
          <Button primary  className="bg-[#072082]" loading={loading} type="submit" variant="filled">
            <i className="fa-solid fa-save" /> Actualizar
          </Button>
        </div>
      </form>
    </div>
  );
}
