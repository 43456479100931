import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { DataWalletMovements } from "../../../redux/@types";
import {
  setIsWalletMovementsForUpdate,
  setWalletMovementsForUpdate,
  walletMovementsChangeModalState,
} from "../../../redux";

export const useTableWalletMovements = () => {
  const dispatch = useAppDispatch();

  const changeWalletMovementsForUpdate = (val: DataWalletMovements) => {
    dispatch(setIsWalletMovementsForUpdate(true));
    dispatch(setWalletMovementsForUpdate(val));
    dispatch(walletMovementsChangeModalState());
  };

  return {
    //state
    //methods
    //functions
    changeWalletMovementsForUpdate,
  };
};
