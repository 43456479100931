import { Controller, type Control } from "react-hook-form";
import { ProgramStudentType,} from "../../../../types/dto/program";
import { Select } from "antd";
 
export default function EditProgramStudentFrom({
  control,
}: {
  control?: Control<ProgramStudentType>;
}) {

  return (
    <div className="flex flex-col gap-3">
      <Controller
        control={control}
        name="status"
        render={({ field, fieldState }) => (
          <div>
            <label>Estado del estudiante</label>
            <Select
              {...field}
              showSearch
              style={{ width: 270 }}
              placeholder="Calificación del estudiente"
              optionFilterProp="label"
              filterSort={(optionA: any, optionB: any) => {
                return (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase());
              }}
              options={[
                {
                  value: "active",
                  label: "Activo",
                },
                {
                  value: "inactive",
                  label: "Inactivo",
                },
                {
                  value: "pause",
                  label: "Pausado",
                },
                {
                  value: "completed",
                  label: "Completado",
                },
              ]}
            />
            {fieldState.error ? (
              <span className="mt-1 block text-xs text-red-500">
                {fieldState.error.message}
              </span>
            ) : null}
          </div>
        )}
      />
    </div>
  );
}
