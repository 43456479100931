import { message, Modal } from "antd";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useAddressesUsers } from "./useAddressesUsers";
import {
  addressesUsersChangeModalState,
  deleteAddressesUser,
  refreshTableAddressesUser,
  setAddressesUserForUpdate,
  setIsAddressesUserForUpdate,
} from "../../../redux";
import { IAddressesUsersFind } from "../../../redux/@types";

const { confirm } = Modal;

export const useTableAddressesUsers = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useAddressesUsers();

  const changeAddressesUsersForUpdate = (user: IAddressesUsersFind) => {
    dispatch(setIsAddressesUserForUpdate(true));
    dispatch(setAddressesUserForUpdate(user));
    dispatch(addressesUsersChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la dirección del usuario identificado con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar dirección del usuario" en caso tal solo oprime en "
          Cancelar".`,
      okText: "Borrar dirección del usuario",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteAddressesUser(valId)
          .then(() => {
            dispatch(refreshTableAddressesUser(true));
            message.success(
              `Se ha borrado con éxito el usuario con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeAddressesUsersForUpdate,
    showDeleteConfirm,
  };
};
