import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useProductCharacteristics,
  WrapperModalProductCharacteristics,
} from "../../../../modules";
import { dataProducsCharac } from "../../../../redux/@types";
import {
  selectModalProductCharacteristic,
  selectIsUpdateProductCharacteristic,
  selectProductCharacteristicForUpdate,
} from "../../../../redux/selectors/productCharacteristics.selectors";
import { InputText } from "../inputs";

const { Title } = Typography;

export const ModalProductCharacteristics = () => {
  const modalProductCharacteristic = useSelector(
    selectModalProductCharacteristic
  );
  const isUpdateProductCharacteristic = useSelector(
    selectIsUpdateProductCharacteristic
  );
  const updateProductCharacteristic = useSelector(
    selectProductCharacteristicForUpdate
  );
  const {
    isLoading,
    changeModalProductCharacteristicsState,
    onSubmitCreateOrUpdate,
  } = useProductCharacteristics();

  const { control, handleSubmit: onSubmit } =
    useFormContext<dataProducsCharac>();

  return (
    <WrapperModalProductCharacteristics
      title={
        isUpdateProductCharacteristic
          ? `Actualizando característica de producto: '${updateProductCharacteristic?.id!}'`
          : `Creando característica de producto`
      }
      open={modalProductCharacteristic}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalProductCharacteristicsState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText placeHolder="seguridad" name="name" control={control} />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateProductCharacteristic
          ? "Actualizar información"
          : "Crear característica de producto"}
      </Button>
    </WrapperModalProductCharacteristics>
  );
};
