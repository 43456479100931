// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { message, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  contactsDirectoryChangeModalState,
  createContactsDirectory,
  getCitiesForContactsDirectory,
  refreshTableContactsDirectory,
  selectContactsDirectoryForUpdate,
  selectContactsDirectoryRefreshTable,
  selectIsUpdateContactsDirectory,
  setIsContactsDirectoryForUpdate,
  updateContactsDirectory,
} from "../../../redux";
import {
  DataContactsDirectory,
  ICitiesFin,
  IDataForUpdateContactsDirectory,
  IDirectoryCategoriesFin,
  IUserResFind,
} from "../../../redux/@types";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import {
  citiesService,
  contactsDirectoryCategories,
  contactsDirectoryMedia,
  usersService,
} from "../../../shared/services";
import {
  getCategoriesContactsDirectory,
  updateOrCreateMedia,
} from "../../../redux/actions/contactsDirectory.actions";
import {
  validateCreateContactsDirectory,
  validateUpdateContactsDirectory,
} from "../../../helpers";

const { confirm } = Modal;

interface IGenericArrUsers {
  id: number;
  first_name: string;
  last_name: string;

}

export const useContactsDirectory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const isUpdateContactsDirectory = useSelector(
    selectIsUpdateContactsDirectory
  );
  const dataUpdateContactsDirectory = useSelector(
    selectContactsDirectoryForUpdate
  );
  const isRefreshTableContactsDirectory = useSelector(
    selectContactsDirectoryRefreshTable
  );
  // const arrCities = useSelector(selectArrCitiesForContactsDirectory);

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();
  const [usersArr, setUsersArr] = useState<IGenericArrUsers[]>([])


  const servicesUsersSelectors = async() => {
    await usersService.find({ query: { status: "active",  $limit: 9999999999999,  $select: ["id", "first_name" , "last_name"]  } })
    .then(({data}: IUserResFind[]) =>{
      setUsersArr(data)
    })
    .catch((err)=>{
      console.log({err})
      setUsersArr([])
    })
  }

  useEffect(() => {

      servicesUsersSelectors();

  }, []);

  const changeModalContactsDirectoryState = () => {
    formMethodsCreate.reset();
    dispatch(setIsContactsDirectoryForUpdate(false));
    dispatch(contactsDirectoryChangeModalState());
  };

  const formMethodsCreate = useForm<DataContactsDirectory>({
    resolver: yupResolver(validateCreateContactsDirectory),
  });

  const formMethodsUpdate = useForm<IDataForUpdateContactsDirectory>({
    resolver: yupResolver(validateUpdateContactsDirectory),
  });

  useEffect(() => {
    if (isUpdateContactsDirectory) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateContactsDirectory });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateContactsDirectory, dataUpdateContactsDirectory]);
  const [arrMedia, setArrMedia] = useState([]);

  const getArrMedia = async () => {
    await contactsDirectoryMedia
      .find({
        query: { contact_directory_id: params.id, $limit: 9999999999999 },
      })
      .then((res) => {
        setArrMedia(res.data);
      })
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  const updateMedia = async (val: string, id: number) => {
    return updateOrCreateMedia({ path: val, contact_directory_id: id })
      .then((res: any) => {
        message.success(
          `Se ha actualizado con éxito la Media con id:${res.id}`
        );
        return res;
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
  };

  const showDeleteConfirmOfMedia = (valId: number) => {
    confirm({
      title: `Estás seguro de borrar la IMG con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${valId}", si deseas continuar dale click a "Borrar marca" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar marca",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await contactsDirectoryMedia
          .remove(valId)
          .then(() => {
            dispatch(refreshTableContactsDirectory(true));
            message.success(`Se ha borrado con éxito la img con id:${valId}`);
            tableProps?.refresh();
            getArrMedia();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
        if (isLoading) return;
        setIsLoading(true);
        await updateContactsDirectory({
          id,
          status: value,
        })
        .then((res: Partial<DataContactsDirectory>) => {
          setIsLoading(false);
          if (res.id) {
            // dispatch(bannersChangeModalState());
            message.success(
              `Se ha actualizado con éxito el estado del directorio con id:${res.id}`
            );
            dispatch(refreshTableContactsDirectory(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
  }

  const onSubmitCreateOrUpdate = async (
    data: IDataForUpdateContactsDirectory
  ) => {
    console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateContactsDirectory) {
      //console.log("Actualiando===>", data);
      await updateContactsDirectory({
        id: data.id,
        name: data.name,
        city_id: Number(data.city_id),
        address: data.address,
        category_id: Number(data.category_id),
        user_id: Number(data.user_id),
        score: data.score,
        short_description: data.short_description,
        description: data.description,
        whatsapp_phone: data.whatsapp_phone,
        phone: data.phone,
        status: data.status,
        path_cover: data.path_cover,
        years_of_experience: data.years_of_experience,
        job_availability: data.job_availability,
      })
        .then(async (res: Partial<DataContactsDirectory>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            navigate(`/contactsDirectory`);
            message.success(
              `Se ha actualizado con éxito el directorio con id:${res.id}`
            );
            dispatch(refreshTableContactsDirectory(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createContactsDirectory({
        ...data,
        status: "pending_validation",
        city_id: Number(data.city_id),
        user_id: Number(data.user_id),
        category_id: Number(data.category_id),
      })
        .then((res: DataContactsDirectory) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(contactsDirectoryChangeModalState());
            message.success(
              `Se ha creado con éxito la directorio con id:${res.id}`
            );
            dispatch(refreshTableContactsDirectory(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableContactsDirectory) {
      tableProps?.refresh();
      dispatch(refreshTableContactsDirectory(false));
    }
  }, [isRefreshTableContactsDirectory]);

  const servicesCitiesForContactsDirectory = async () => {
    await citiesService
      .find({ query: { $limit: 999999999 } })
      .then(({ data }: ICitiesFin) => {
        dispatch(getCitiesForContactsDirectory(data));
      });
    await contactsDirectoryCategories
      .find({ query: { $limit: 9999999999999 } })
      .then(({ data }: IDirectoryCategoriesFin) => {
        dispatch(getCategoriesContactsDirectory(data));
      });
  };

  useEffect(() => {
    // if (!arrCities.length) {
      servicesCitiesForContactsDirectory();
    //}
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    //state
    isUpdateContactsDirectory,
    isLoading,
    tableProps,
    usersArr,
    arrMedia,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalContactsDirectoryState,
    updateMedia,
    showDeleteConfirmOfMedia,
    getArrMedia,
    updateStatus,
  };
};
