"use client";
import React from "react";
import { programsModuleAcademy } from "../../../../shared/services";
import { ProgramModuleType } from "../../../../types/dto/program";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import type { Resolver } from "react-hook-form";
import * as yup from "yup";

export interface UseUpdateProgramModuleProps {
  onSuccess?: (programModule: ProgramModuleType) => void;
}

export default function useUpdateProgramModule(
  programModule: ProgramModuleType,
  props?: UseUpdateProgramModuleProps
) {
  const [loading, setLoading] = React.useState(false);
  
  const {
    control,
    handleSubmit: onSubmit,
    reset,
  } = useForm<ProgramModuleType>({
    resolver: yupResolver(yup.object({})) as unknown as Resolver<ProgramModuleType>,
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    await programsModuleAcademy
      .patch(programModule?.id!, {...data, position: +data.position})
      .then((res) => {
        props?.onSuccess?.(res);
      })
      .catch((err: Error) => {
      })
      .finally(() => {
        setLoading(false);
      });
  });


    React.useEffect(() => {
      if (programModule) {
        reset({
          name: programModule.name,
          description: programModule.description,
          program_id: programModule.program_id,
          position: programModule.position,
          status: programModule.status,
        });
      }
    }, [programModule]);
  
    return {
      control,
      loading,
      handleSubmit,
    };}
