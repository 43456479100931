
import {Controller, type Control} from "react-hook-form";
import {  CaUserAcademy, ProgramTeacherType } from "../../../../types/dto/program";
import { Select } from "antd";
import useFilter from "../../hooks/students/useFilterStudents";
import { usersService } from "../../../../shared/services";

export default function AssignedTeacherProgramForm({control, teacher}: {control: Control<ProgramTeacherType>, teacher: CaUserAcademy[]}) {
  
  const { query, setQuery, filteredData } = useFilter<CaUserAcademy>({
    service: usersService,
    initialData: teacher,
    queryKey: 'q',
    student_teacher: 'teacher'
  });


  return (
    <div className="flex flex-col gap-2">
       <label>
          <strong>Selecciona un profesor</strong>
        </label>
        <Controller
          control={control}
          name="user_id"
          render={({ field, fieldState }) => (
            <div>
              <Select
                {...field}
                showSearch
                style={{ width: '100%', height: 30 }}
                placeholder="Selecciona un profesor"
                optionFilterProp="label"
                onSearch={(value) => setQuery(value)}
                options={
                  filteredData?.length > 0
                    ? filteredData.map((it) => ({
                        key: `${it.id}`,
                        value: `${it.id}`,
                        label: `${`${it.first_name} ${it.last_name}`}`,
                      }))
                    : [
                        {
                          key: "",
                          value: "",
                          label: "No hay profesores disponibles",
                        },
                      ]
                }
              />
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
      </div>
 
  );
}
