import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useWalletBonus,
  WrapperModalWalletBonus,
} from "../../../../modules/walletBonus";
import {
  selectIsUpdateWalletBonus,
  selectModalWalletBonus,
} from "../../../../redux";
import { DataWalletBonus } from "../../../../redux/@types";
import { selectWalletBonusForUpdate } from "../../../../redux/selectors/walletBonus.selectors";
import { InputNumber, InputSelect, InputText } from "../inputs";

const { Title } = Typography;

export const ModalWalletBonus = () => {
  const modalWalletBonus = useSelector(selectModalWalletBonus);
  const isUpdateWalletBonus = useSelector(selectIsUpdateWalletBonus);
  const updateWalletBonus = useSelector(selectWalletBonusForUpdate);

  const { isLoading, changeModalWalletBonusState, onSubmitCreateOrUpdate } =
    useWalletBonus();

  const { control, handleSubmit: onSubmit } = useFormContext<DataWalletBonus>();
  return (
    <WrapperModalWalletBonus
      title={
        isUpdateWalletBonus
          ? `Actualizando bonus: '${updateWalletBonus?.id!}'`
          : `Creando bonus`
      }
      open={modalWalletBonus}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalWalletBonusState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText placeHolder="John Doe" name="name" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Cantidad
        </Title>
        <InputNumber placeHolder="3000" name="quantity" control={control} min />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Dias para expirar
        </Title>
        <InputNumber placeHolder="4" name="expire_days" control={control} min />
      </Col>
      {isUpdateWalletBonus && (
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Estado:
          </Title>
          <InputSelect
            name="status"
            control={control}
            dataOptions={[
              { title: "Activo", value: "active" },
              { title: "Inactivo", value: "inactive" },
            ]}
            label="Estado"
          />
        </Col>
      )}
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateWalletBonus ? "Actualizar información" : "Crear bonus"}
      </Button>
    </WrapperModalWalletBonus>
  );
};
