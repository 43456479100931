import {coursesCategoriesService } from "../../shared/services"
import { DataCoursesCategories } from "../@types"
import { coursesCategoriesActions } from "../action-types"

export const coursesCategoriesChangeModalState = (): coursesCategoriesActions.ICoursesCategoriesModalState =>({
    type: coursesCategoriesActions.COURSES_CATEGORIES_MODAL_STATE
})

export const setCoursesCategoriesForUpdate = (val: DataCoursesCategories): coursesCategoriesActions.IGetCoursesCategoriesForUpdate =>({
    type: coursesCategoriesActions.GET_COURSES_CATEGORIES_FOR_UPDATE,
    payload: val
})

export const setIsCoursesCategoriesForUpdate = (val: boolean): coursesCategoriesActions.IIsCoursesCategoriesForUpdate =>({
    type: coursesCategoriesActions.IS_COURSES_CATEGORIES_FOR_UPDATE,
    payload: val
})

export const createCoursesCategories = async(data: Partial<DataCoursesCategories>) =>{
    return await coursesCategoriesService.create(data)
    .then((res: DataCoursesCategories) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateCoursesCategories =async(data: Partial<DataCoursesCategories>) =>{
    return await coursesCategoriesService.patch(data?.id!, data)
    .then((res: DataCoursesCategories) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteCoursesCategories = async(id: number) => {
    return await coursesCategoriesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableCoursesCategories = (val: boolean): coursesCategoriesActions.ISwitchCoursesCategoriesRefreshTable =>({
    type: coursesCategoriesActions.SWITCH_COURSES_CATEGORIES_REFRESH_TABLE,
    payload: val
})