import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { createWalletBonus, walletBonusChangeModalState, refreshTableWalletBonus, selectIsUpdateWalletBonus, selectWalletBonusForUpdate, selectWalletBonusRefreshTable, setIsWalletBonusForUpdate, updateWalletBonus } from "../../../redux";
import { DataWalletBonus } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { validateCreateWalletBonus, validateUpdateWalletBonus } from '../../../helpers';

export const useWalletBonus = () => {
    const dispatch = useAppDispatch();

    const isUpdateWalletBonus = useSelector(
      selectIsUpdateWalletBonus
    );
    const dataUpdateWalletBonus = useSelector(
      selectWalletBonusForUpdate
    );
    const isRefreshTableWalletBonus = useSelector(
      selectWalletBonusRefreshTable
    );

    const [isLoading, setIsLoading] = useState(false);
    const [tableProps, setTableProps] = useState<TableProperties>();

    const changeModalWalletBonusState = () => {
      dispatch(setIsWalletBonusForUpdate(false));
      dispatch(walletBonusChangeModalState());
    };

    const formMethodsCreate = useForm<DataWalletBonus>({
      resolver: yupResolver(validateCreateWalletBonus),
    });

    const formMethodsUpdate = useForm<DataWalletBonus>({
      resolver: yupResolver(validateUpdateWalletBonus),
    });

    useEffect(() => {
      if (isUpdateWalletBonus) {
        formMethodsCreate.reset();
        formMethodsUpdate.reset({ ...dataUpdateWalletBonus });
      }

      return () => {
        formMethodsUpdate.reset();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateWalletBonus, dataUpdateWalletBonus]);

    const updateStatus = async(value: "active" | "inactive", id: any) =>{
        if (isLoading) return;
        setIsLoading(true);
        await updateWalletBonus({
          id,
          status: value,
        })
        .then((res: Partial<DataWalletBonus>) => {
          setIsLoading(false);
          if (res.id) {
            // dispatch(bannersChangeModalState());
            message.success(
              `Se ha actualizado con éxito el estado del bonus con id:${res.id}`
            );
            dispatch(refreshTableWalletBonus(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
    const onSubmitCreateOrUpdate = async (data: DataWalletBonus) => {
      // console.log({ data });
      if (isLoading) return;
      setIsLoading(true);
      if (isUpdateWalletBonus) {
        //console.log("Actualiando===>", data);
        await updateWalletBonus({
          id: data.id,
          name: data.name,
          quantity: data.quantity,
          expire_days: data.expire_days
        })
          .then(async(res: Partial<DataWalletBonus>) => {
            setIsLoading(false);
            // dispatch(mappingUpdatedUser(res))
            if (res.id) {
              // dispatch(walletBonusChangeModalState());
              message.success(
                `Se ha actualizado con éxito el bonus con id:${res.id}`
              );
              dispatch(refreshTableWalletBonus(true));
            } else {
              message.error(res as string);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            console.log(err);
            message.error(err.message);
          });
      } else {
        // console.log('Creando===>',data)
        await createWalletBonus(data)
          .then((res: DataWalletBonus) => {
            setIsLoading(false);
            if (res.id) {
              dispatch(walletBonusChangeModalState());
              message.success(
                `Se ha creado con éxito el bonus con id:${res.id}`
              );
              dispatch(refreshTableWalletBonus(true));
            } else {
              message.error(res as any);
            }
          })
          .catch((err: any) => {
            setIsLoading(false);
            console.log(err);
            message.error(err.message);
          });
      }
    };

    useEffect(() => {
      if (isRefreshTableWalletBonus) {
        tableProps?.refresh();
        dispatch(refreshTableWalletBonus(false));
      }
    }, [isRefreshTableWalletBonus]);

  return {
    //state
    isUpdateWalletBonus,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalWalletBonusState,
    updateStatus,
  }
}
