"use client";
import React from "react";
import { message, Modal } from "antd";
import { programPaymentScheduleAcademy } from "../../../../shared/services";
const { confirm } = Modal;

export default function useDeletePayments() {
  const [loading, setLoading] = React.useState(false);

  const showDeleteConfirm = (valId: number, val: string) => {
    if (!valId ) {
      message.error("programación de pago no válido");
      return;
    }

    confirm({
      title: `Estás seguro de borrar el programación de pago con el id: ${valId}`,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar programación de pago" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar programación de pago",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        setLoading(true);
        try {
           await programPaymentScheduleAcademy.remove(valId);
          message.success(`Se ha borrado con éxito el equipo con id: ${valId}`);
        } catch (err: any) {
          message.error(err.message);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {},
    });
  };

  return { showDeleteConfirm, loading };
}
