import { Button, Col, Typography } from "antd";
import { useSelector } from "react-redux";
import {
  selectAddressesUsersForUpdate,
  selectCitiesAddressesUsers,
  selectIsUpdateAddressesUsers,
  selectModalAddressesUsers,
  selectStateAddressesUsers,
} from "../../../../redux";
import { WrapperModalAddressesUsers } from "../../../../modules/addressesUsers/ui/styled";
import { useFormContext } from "react-hook-form";
import { IAddressesUsersFind } from "../../../../redux/@types";
import { useAddressesUsers } from "../../../../modules";
import { InputNumber, InputSelect, InputText } from "../inputs";

const { Title } = Typography;

export const ModalAddressesUsers = () => {
  const modalAddressesUser = useSelector(selectModalAddressesUsers);
  const isUpdateAddressesUsers = useSelector(selectIsUpdateAddressesUsers);
  const updateAddressesUsers = useSelector(selectAddressesUsersForUpdate);
  const stateAddressesUsers = useSelector(selectStateAddressesUsers);
  const citiesAddressesUsers = useSelector(selectCitiesAddressesUsers);

  const { isLoading, changeModalAddressesUsersState, onSubmitCreateOrUpdate } =
    useAddressesUsers();
  //console.log({ citiesAddressesUsers, stateAddressesUsers });
  const {
    control,
    handleSubmit: onSubmit,
    watch,
  } = useFormContext<IAddressesUsersFind>();
  let pathStateID = watch("state_id");
  // console.log({ pathStateID });
  let dataState = stateAddressesUsers
    ?.filter((item) => item.deletedAt === null)
    .map((item) => ({
      value: item.id.toString(),
      title: item.name,
    }));
  let dataCities = citiesAddressesUsers
    ?.filter((item) => item?.state_id === Number(pathStateID))
    .map((item) => ({
      value: item.id.toString(),
      title: item.name,
    }));
  //console.log({ dataState });
  return (
    <WrapperModalAddressesUsers
      title={
        isUpdateAddressesUsers
          ? `Actualizando dirección de usuario: '${updateAddressesUsers?.id!}'`
          : `Creando dirección de usuario`
      }
      open={modalAddressesUser}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalAddressesUsersState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText
          placeHolder="Casa blanca de esquina"
          name="name"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Dirección:
        </Title>
        <InputText
          placeHolder="Calle 1 #2a - 3"
          name="address"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Departamento:
        </Title>
        <InputSelect
          showSearch
          name="state_id"
          control={control}
          dataOptions={dataState || []}
          label="Departamento"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Ciudad:
        </Title>
        <InputSelect
          showSearch
          name="city_id"
          control={control}
          dataOptions={dataCities || []}
          label="Ciudad"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Latitud:
        </Title>
        <InputNumber
          stringMode={true}
          placeHolder="74.121421"
          name="lat"
          control={control}
          min
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Longitud:
        </Title>
        <InputNumber
          stringMode={true}
          placeHolder="-74.13431"
          name="lng"
          control={control}
          min
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Detalles:
        </Title>
        <InputText
          placeHolder="tiene 90 metros cuadrados de area, un baño y un cuarto"
          name="details"
          control={control}
          rows
        />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateAddressesUsers
          ? "Actualizar información"
          : "Crear dirección de usuario"}
      </Button>
    </WrapperModalAddressesUsers>
  );
};
