// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Avatar, Button, Col, Typography } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import Table from "../../../shared/components/ui/Table";
import lodash from "lodash";

import { productCategoriesService } from "../../../shared/services";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { IProductCategory } from "../../../redux/@types";
import { useTableProductCategories, useProductCategories } from "../hooks";
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";

const { Title } = Typography;
interface Props {
  parent_id?: number;
}

export const TableProductCategories = ({ parent_id }: Props) => {
  const {
    navigateSingleCategory,
    showDeleteConfirm,
    changeProductCategoriesForUpdate,
  } = useTableProductCategories();
  const { setTableProps, updateStatus, isLoading } = useProductCategories();
  const columns: TableColumns<IProductCategory> = [
    {
      title: "ID",
      width: 2,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Imagen",
      width: 3,
      dataIndex: "imagen",
      key: "imagen",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.path_image}`} />
          </Col>
        );
      },
    },
    {
      title: "Nombre",
      width: 6,
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "Estado",
      width: 3,
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <button
            disabled={isLoading}
              onClick={()=>{
                if(item.status === "active"){
                  updateStatus("inactive", item.id)
                }else if( item.status === "inactive"){
                  updateStatus("active", item.id)
                }
              }}
              style={{
                display: "flex",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "white",
                }}
                level={5}
              >
                {item.status}
              </Title>
            </button>
          </Col>
        );
      },
      filters: [
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Inactivo",
          value: "inactive",
        },
      ],
    },

    !parent_id && {
      title: "Mostrar Categorías anidadas",
      width: 3,
      key: "show_sons",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <EyeOutlined
              style={{ fontSize: "27px" }}
              onClick={() => navigateSingleCategory(item)}
            />
          </Col>
        );
      },
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 1,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeProductCategoriesForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <Table<IProductCategory>
      columns={lodash.filter(columns)}
      scroll={{ x: 'max-content' }}
      fetchQueryProps={{
        $sort: { id: -1 },
        ...(parent_id ? { parent_id } : {}),
      }}
      service={productCategoriesService}
      onLoad={setTableProps}
    />
  );
};
