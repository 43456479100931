import { authenticationService } from "../../shared/services";
import { authActions } from "../action-types";
//import { message } from "antd";
// import { IResUserAuthLogin,IUserRes, TAppDispatch } from "../@types";
import { IUserRes } from "../@types";
// import useAppDispatch from "../hooks/useAppDispatch";

// export const authUserFromTokenRequest = (): authActions.IAuthUserFromTokenRequestAction => ({
//     type: authActions.AUTH_USER_FROM_TOKEN_REQUEST,
//     payload: typeof localStorage !== 'undefined' ? localStorage.getItem('jwt') || '' : '',
//   })

  // useEffect(() => {
  //   authUserFromTokenRequest()
  // }, [])

export const authSetToken = (token: string): authActions.IAuthUserSetTokenCredential => ({
  type: authActions.AUTH_USER_SET_TOKEN_CREDENTIAL,
  payload: token
})

export const authSetUserCredentials = (user: IUserRes): authActions.IAuthUserSetCredentials => ({
  type: authActions.AUTH_USER_SET_CREDENTIALS,
  payload: user
})

export const authLogin = async( email: string, password: string)=> {

  return await authenticationService
  .login(email, password)
  .then((res) =>{
    // console.log({res})
    localStorage.setItem('jwt', res.accessToken)
    localStorage.setItem('user', JSON.stringify(res.user))
    return res
  })
  .catch((err) => {
    return err
  })
}

// export const authLogout = () => {
//   localStorage.removeItem('jwt')
//   localStorage.removeItem('user')
//   authUserLogout()
// }

export const authUserLogout =(): authActions.IAuthUserLogout =>({
  type: authActions.AUTH_USER_LOGOUT
})