import { DataCities, DataDirectoryCategories, IContactsDirectoryState, IDataForUpdateContactsDirectory } from "../@types";
import { contactsDirectoryActions } from "../action-types";

type contactsDirectoryType =
| { type: typeof contactsDirectoryActions.CONTACTS_DIRECTORY_MODAL_STATE }
| { type: typeof contactsDirectoryActions.GET_CONTACTS_DIRECTORY_FOR_UPDATE; payload: IDataForUpdateContactsDirectory }
| { type: typeof contactsDirectoryActions.IS_CONTACTS_DIRECTORY_FOR_UPDATE; payload: boolean }
| { type: typeof contactsDirectoryActions.SWITCH_CONTACTS_DIRECTORY_REFRESH_TABLE; payload: boolean }
| { type: typeof contactsDirectoryActions.GET_CITIES_CONTACTS_DIRECTORY; payload: DataCities[] }
| { type: typeof contactsDirectoryActions.GET_CATEGORIES_CONTACTS_DIRECTORY; payload: DataDirectoryCategories[] }

const INITIAL_STATE: IContactsDirectoryState ={
    contactsDirectoryForUpdate: undefined,
    isUpdateContactsDirectory: false,
    contactsDirectoryModalIsOpen: false,
    refreshTableContactsDirectory: false,
    arrCities: [],
    arrCategories: [],
}

export default function contactsDirectoryReducer(state: IContactsDirectoryState = INITIAL_STATE, action: contactsDirectoryType): IContactsDirectoryState {
    switch(action.type){
        case contactsDirectoryActions.CONTACTS_DIRECTORY_MODAL_STATE:
            return{
                ...state,
                contactsDirectoryModalIsOpen: !state.contactsDirectoryModalIsOpen
            }
        case contactsDirectoryActions.GET_CONTACTS_DIRECTORY_FOR_UPDATE:
            return{
                ...state,
                contactsDirectoryForUpdate: action.payload
            }
        case contactsDirectoryActions.IS_CONTACTS_DIRECTORY_FOR_UPDATE:
            return{
                ...state,
                isUpdateContactsDirectory: action.payload
            }
        case contactsDirectoryActions.SWITCH_CONTACTS_DIRECTORY_REFRESH_TABLE:
            return{
                ...state,
                refreshTableContactsDirectory: action.payload
            }
        case contactsDirectoryActions.GET_CITIES_CONTACTS_DIRECTORY:
            return{
                ...state,
                arrCities: action.payload
            }
        case contactsDirectoryActions.GET_CATEGORIES_CONTACTS_DIRECTORY:
            return{
                ...state,
                arrCategories: action.payload
            }
        default:
            return state
    }
}