import { IBannersState } from "../@types";
import { bannersActions } from "../action-types";

type bannersActionType =
| { type: typeof bannersActions.BANNERS_CHANGE_MODAL_STATE }
| { type: typeof bannersActions.GET_BANNER_FOR_UPDATE; payload: any }
| { type: typeof bannersActions.IS_BANNER_FOR_UPDATE; payload: boolean }
| { type: typeof bannersActions.SWITCH_BANNERS_REFRESH_TABLE; payload: boolean }
| { type: typeof bannersActions.SWITCH_BANNERS_PRODUCTS_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: IBannersState = {
    bannersForUpdate: undefined,
    isUpdateBanners: false,
    bannersModalIsOpen: false,
    refreshTableBanners: false,
    refreshTableBannersProducts: false,
}

export default function bannersReducer(state: IBannersState = INITIAL_STATE, action: bannersActionType): IBannersState {
    switch ( action.type ) {
        case bannersActions.BANNERS_CHANGE_MODAL_STATE:
            return{
                ...state,
                bannersModalIsOpen: !state.bannersModalIsOpen
            }
        case bannersActions.GET_BANNER_FOR_UPDATE:
            return{
                ...state,
                bannersForUpdate: action.payload
            }
        case bannersActions.IS_BANNER_FOR_UPDATE:
            return{
                ...state,
                isUpdateBanners: action.payload
            }
        case bannersActions.SWITCH_BANNERS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableBanners: action.payload
            }
        case bannersActions.SWITCH_BANNERS_PRODUCTS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableBannersProducts: action.payload
            }
        default:
            return state
    }
}