import type {Resolver} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import React from "react";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import { programsTeacherAcademy } from "../../../../shared/services";
import { ProgramStudentType } from "../../../../types/dto/program";
import { message } from "antd";

export interface UseAssignedTeacherProgramProps {
  programId: number
  setOpenModal: (val: boolean) => void
}

export default function useAssignedTeacherProgram({programId, setOpenModal}: UseAssignedTeacherProgramProps) {
  const [loading, setLoading] = React.useState(false);
  const {
    control,
    handleSubmit: onSubmit,
    reset,
  } = useForm<ProgramStudentType>({
    resolver: yupResolver(
      yup.object({
      }),
    ) as unknown as Resolver<ProgramStudentType>,
    defaultValues: {
    },
  });

  const handleSubmit = onSubmit(async (data) => {
    if (loading) return;
    setLoading(true);

    await programsTeacherAcademy
      .create({...data, user_id: +data.user_id, program_id: +programId})
      .then((res) => {
        setOpenModal(false)
        message.success('Se ha asignado correctamente el pofesor al programa')
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    control,
    loading,
    handleSubmit,
  };
}