import { Controller, type Control } from "react-hook-form";
import {ProgramStudentModuleTechniqueType,} from "../../../../types/dto/program";
import { Col, Select } from "antd";
import { InputNumber } from "../../../../shared/components";
import { selectUser } from "../../../../redux";
import { useSelector } from "react-redux";
 
export default function EditStudentQualificationForm({
  control,
}: {
  control?: Control<ProgramStudentModuleTechniqueType>;
}) {
  const user = useSelector(selectUser);

  return (
    <div className="flex flex-col gap-3">
       {
        user?.role && ["teacher", "teacher_admin", "admin"].includes(user?.role) && (
          <Controller
          control={control}
          name="qualification_check"
          render={({ field, fieldState }) => (
            <div>
              <label>Estado de la calificación</label>
              <Select
                {...field}
                showSearch
                style={{ width: 270 }}
                placeholder="Selecciona el estado"
                optionFilterProp="label"
                defaultValue={field.value}
                options={ [
                        {
                          value: "approved",
                          label: "Aprobado",
                        },
                        {
                          value: "disapproved",
                          label: "Reprobado",
                        },
                      ]
                }
              />
              {fieldState.error ? (
                <span className="mt-1 block text-xs text-red-500">
                  {fieldState.error.message}
                </span>
              ) : null}
            </div>
          )}
        />
        )
      }
      {
        user?.role && ["teacher_admin", "admin"].includes(user?.role) && (
          <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <label >
            Calificación
          </label>
          <InputNumber
            placeHolder="3"
            name="qualification"
            control={control}
            max={5.0}
            min
          />
        </Col>
        )
      }
    </div>
  );
}
